<form [formGroup]="form" class="example-form" style="position: relative;">
    <mat-form-field (clickOutside)="isOpened = false" appearance="outline" class="matFormField" panelWidth="auto" width="100%">
        <div class="search-container">
          <input
            (focus)="isOpened = true"
            [type]="field.controlType"
            [placeholder]="field.label"
            [attr.arialabel]="field.label"
            autocomplete="off"
            matInput
            [formControlName]="field.control"
          >
          <div (click)="handleIconClick()">
            <lag-icon iconName="arrow_drop_down" iconColor="rgba(26, 26, 26, 0.6)"></lag-icon>
          </div>
        </div>
        <mat-label class="mat-label-background">{{field.label}}</mat-label>
    </mat-form-field>
    <div *ngIf="isOpened" class="main-virtual-scroll-block">
      <cdk-virtual-scroll-viewport [itemSize]="10" class="single-select-input-virtual-scroll">
        <mat-option
            (onSelectionChange)="handleSelect($event)"
            class="mat-option-text-style mat-option-block"
            *cdkVirtualFor="let item of filteredOptions | async "
            [value]="item">
            {{ item.name }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
      <div class="scrollbar-divider"></div>
    </div>
    <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</form>