import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { LogModel } from '../models/logModel';
import { SKIP_DEFAULT_ERROR_HANDLER } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private url = environment.adminUrl + '/api/logging/log';
  private spinnerLessHeader = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('X-Skip-Interceptor', '')
  .set(SKIP_DEFAULT_ERROR_HANDLER, '');

  constructor(private httpClient: HttpClient, private router: Router) {}

  public logError(logModel: LogModel): void {
    console.log('TCL: LoggingService -> logError -> message', logModel);
    logModel.severity = 'ERROR';
    this.logIt(this.url, logModel);
  }

  public logInfo(logModel: LogModel) {
    console.log('TCL: LoggingService -> logInfo -> message', logModel);
    logModel.severity = 'INFO';
    this.logIt(this.url, logModel);
  }

  public logWaring(logModel: LogModel) {
    logModel.severity = 'WARN';
    this.logIt(this.url, logModel);
  }

  // The SERVER sent this back....log it somewhere in the client so that we can see the problem the server ran into.
  public logHttpErrorResponse(error: HttpErrorResponse): string {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return 'Something bad happened; please try again later.';
  }

  private logIt(loggingUrl: string, logModel: LogModel) {
    this.httpClient
      .post(loggingUrl, logModel, { headers: this.spinnerLessHeader })
      .pipe(catchError(this.logHttpErrorResponse))
      .subscribe((error) => {
          console.log('Unable to log error to the server.  ', error);
        },
      );
  }
}
