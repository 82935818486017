import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/spinner.service';
import { Project } from 'src/app/models/project';
import { ProjectInfoService } from 'src/app/services/projectInfo.service';
import { ProjectService } from 'src/app/services/projects.service';
import { Util } from 'src/app/util/Util';

@Component({
  selector: 'app-single-project-info',
  templateUrl: './single-project-info.component.html',
  styleUrls: ['./single-project-info.component.scss'],
})
export class SingleProjectInfoComponent implements OnInit {
  projectFilteredOptions: Observable<Project[]>;
  projectNameControl = new FormControl();
  projectInfoForm: FormGroup;

  isNewProject: boolean;
  isProjSelected: boolean;

  projectInfo: Project;
  projectInfoOnLoad: any;

  brokerOfficeOptions: any[] = [
    { value: 'Office', text: 'Office' },
    { value: 'Industrial', text: 'Industrial' },
  ];
  representationOptions: any[] = [
    { value: 'Investor', text: 'Investor' },
    { value: 'Occupier', text: 'Occupier' },
  ];

  Apierrors: any = {};

  projects: Project[] = [];
  userName: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
    private util: Util,
    private activatedrouter: ActivatedRoute,
    private projectInfoService: ProjectInfoService,
    private projectsService: ProjectService,
    private userService: UserService
  ) {}
  ngOnInit() {
    this.projectInfo = new Project();
    this.isProjSelected = false;
    this.buildForm();
    this.userService.getUser().subscribe(user => {
      this.userName = user.email
    })
    this.getProjects();
  }

  private buildForm(): void {
    this.projectInfoForm = this.fb.group({
      projectNameControl: [null, null],
      primaryBroker: [null, null],
      brokerTitle: [null, null],
      brokerOffice: [null, null],
      representation: [null, null],
      source: [null, null],
      lagFee: [null, null],
      cbreGrossFee: [null, null],
      estPaymentDate: [null, null],
      operationType: [null, null],
    });
  }

  getProjects() {
    this.spinnerService.show('Loading projects...');
    this.projectsService.getProjects('single').subscribe(
      (res) => {
        console.log('Projects fetch', res);
        this.spinnerService.hide();
        this.projects = res;

        this.projectFilteredOptions = this.projectNameControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value)),
        );
      },
      (error) => {
        this.spinnerService.hide();
        console.log('TCL: projectinfo component -> getProjects -> error-comp', error);
        if (error.status == 0) {
          //or whatever condition you like to put
          this.router.navigate(['/Error']);
        }
      },
    );
  }

  onSubmit() {
    this.Apierrors.message = null;

    this.projectInfoService.projectName = this.projectInfo.projectName;

    if (this.isNewProject == true) {
      this.projectInfo.brokerOffice = this.projectInfoForm.value.brokerOffice;
      this.projectInfo.brokerTitle = this.projectInfoForm.value.brokerTitle;
      this.projectInfo.potCBREGrossFee = this.projectInfoForm.value.cbreGrossFee;
      this.projectInfo.estPaymentDt = this.projectInfoForm.value.estPaymentDate;
      this.projectInfo.LAGFee = this.projectInfoForm.value.lagFee;
      this.projectInfo.primaryBroker = this.projectInfoForm.value.primaryBroker;
      this.projectInfo.representation = this.projectInfoForm.value.representation;
      this.projectInfo.source = this.projectInfoForm.value.source;
      this.projectInfo.operationType = this.projectInfoForm.value.operationType;
      this.projectInfo.projectType = 'Single';
      this.projectInfo.createdBy = this.userName;
      this.projectInfo.updatedBy = this.userName;

      this.spinnerService.show('Creating a new project...');

      this.projectsService.createProject(this.projectInfo).subscribe(
        (res) => {
          console.log('project is successfully created', res.id);
          this.spinnerService.hide();
          this.router.navigate(['/single-site/home']);
        },
        (error) => {
          this.Apierrors.message =
            'Error in creating a new project, please retry in few minutes. If error persists, please contact system administrator.';
          console.log('project could not be created');
          this.spinnerService.hide();
        },
      );
    } else {
      // add logic to check if form value have been modified nad then only call Update project service
      // console.log("this.projectInfoOnLoad" + JSON.stringify(this.projectInfoOnLoad))
      // console.log("this.projectInfoForm.value" + JSON.stringify(this.projectInfoForm.value))

      if (!this.util.compareTwoJsonObects(this.projectInfoOnLoad, this.projectInfoForm.value)) {
        this.projectInfo.brokerOffice = this.projectInfoForm.value.brokerOffice;
        this.projectInfo.brokerTitle = this.projectInfoForm.value.brokerTitle;
        this.projectInfo.potCBREGrossFee = this.projectInfoForm.value.cbreGrossFee;
        this.projectInfo.estPaymentDt = this.projectInfoForm.value.estPaymentDate;
        this.projectInfo.LAGFee = this.projectInfoForm.value.lagFee;
        this.projectInfo.primaryBroker = this.projectInfoForm.value.primaryBroker;
        this.projectInfo.representation = this.projectInfoForm.value.representation;
        this.projectInfo.source = this.projectInfoForm.value.source;
        this.projectInfo.operationType = this.projectInfoForm.value.operationType;
        this.projectInfo.projectType = 'Single';
        this.projectInfo.updatedBy = this.userName;

        this.spinnerService.show('Updating project details...');

        this.projectsService.updateProject(this.projectInfo).subscribe(
          (res) => {
            console.log('project is successfully updated');
            this.spinnerService.hide();
            this.router.navigate(['/single-site/home']);
          },
          (error) => {
            console.log('project could not be updated');
            this.Apierrors.message =
              'Error in updating project, please retry in few minutes. If error persists, please contact system administrator.';
            this.spinnerService.hide();
          },
        );
      } else {
        this.router.navigate(['/single-site/home']);
      }
    }
  }

  setProjectName(option: any) {
    if (option == null || option == '') {
    } else {
      this.spinnerService.show('Loading project details..');
      this.isNewProject = false;
      this.isProjSelected = true;

      this.projectInfo = this.projects.find((temp) => temp.projectName.toLowerCase() == option.value.toLowerCase());
      this.spinnerService.hide();
      this.setFormDataFromExistingProject(this.projectInfo);
      this.projectInfoOnLoad = JSON.parse(JSON.stringify(this.projectInfoForm.value));
    }
  }

  setNewProjectName(option: any) {
    // console.log("new project : " + option)
    if (option == null || option == '') {
    } else {
      const project = this.projects.find((temp) => temp.projectName.toLowerCase() == option.toLowerCase());
      if (project == undefined || project == null) {
        this.projectInfoForm.reset();
        this.isNewProject = true;
        this.isProjSelected = true;
        this.projectInfo.projectName = option;
        this.projectInfo.id = null;
      } else {
        this.setFormDataFromExistingProject(project);
      }
    }
  }

  setFormDataFromExistingProject(projectInfo: Project) {
    let paymentDate = null;

    if (projectInfo.estPaymentDt == null) {
      paymentDate = null;
    } else {
      paymentDate = new Date(
        projectInfo.estPaymentDt[0],
        projectInfo.estPaymentDt[1] - 1,
        projectInfo.estPaymentDt[2],
        0,
        0,
        0,
        0,
      )
        .toISOString()
        .substring(0, 10);
    }

    this.projectInfoForm.patchValue({
      primaryBroker: projectInfo.primaryBroker,
      brokerTitle: projectInfo.brokerTitle,
      brokerOffice: projectInfo.brokerOffice,
      operationType: projectInfo.operationType,
      representation: projectInfo.representation,
      lagFee: projectInfo.LAGFee,
      cbreGrossFee: projectInfo.potCBREGrossFee,
      source: projectInfo.source,
      estPaymentDate: paymentDate,
    });
  }

  private _filter(value: string): Project[] {
    const filterValue = value.toLowerCase();
    return this.projects.filter((option) => option.projectName.toLowerCase().includes(filterValue));
    // return this.projects.filter(option => option.name.toLocaleLowerCase().startsWith(filterValue));
  }
}
