import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { filter } from 'rxjs';

import { SnackbarService } from '@core/snackBar.notification.service';
import { UserService } from '@services/user.service';
import { UserProfileService } from '@services/userProfile.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-approve-access-dialog',
  templateUrl: './approve-access-dialog.component.html',
  styleUrls: ['./approve-access-dialog.component.scss'],
})
export class ApproveAccessDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ApproveAccessDialogComponent>,
    private snackBarService: SnackbarService,
    private userProfileService: UserProfileService,
    private userService: UserService,
    public dialog: MatDialog,
  ) {}
  approver = {
    firstName: '',
    lastName: '',
    email: '',
  };

  geonavigatorUrl = window.location.origin;

  messageField = {
    label: '',
    control: 'message',
  };
  form = new FormGroup({
    message: new FormControl(this.data?.message),
  });

  ngOnInit() {
    this.form.controls.message.disable();
    this.userService
      .getUser()
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        const { firstName, lastName, email } = user;
        this.approver = {
          firstName,
          lastName,
          email,
        };
      });
  }

  approve() {
    const approve_payload = {
      requestStatus: 'Approved',
      requestId: this.data?.requestId,
      url: this.geonavigatorUrl,
    };
    this.userProfileService.postAccessRequestAction(approve_payload)
    .subscribe((res) => {
      if (this.approver.email === res.requestAddressedByEmail) {
        this.snackBarService.showSuccess(
          `${this.data?.email} has been approved`
        );
      } else {
        this.snackBarService.showSuccess(
          `${this.data?.email} has been approved by ${res?.requestAddressedByEmail}`,
        );
      }
      this.dialogRef.close();
    });
  }

  reject() {
    const reject_payload = {
      requestStatus: 'Rejected',
      requestId: this.data?.requestId,
      url: this.geonavigatorUrl,
    };
    this.userProfileService.postAccessRequestAction(reject_payload)
    .subscribe((res) => {
      if (this.approver.email === res.requestAddressedByEmail) {
        this.snackBarService.showError(
          `${this.data?.email} has been rejected`
        );
      } else {
        this.snackBarService.showError(
          `This request has been rejected already by ${res?.requestAddressedByEmail}`,
        );
      }
      this.dialogRef.close();
    });
  }
}
