import { CdkDragEnd } from "@angular/cdk/drag-drop";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewChecked,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";

import { BehaviorSubject } from "rxjs";

interface SliderItem {
  value: number;
  color: string;
  isDisabled: boolean;
}

interface SliderItemWithPosiiton extends SliderItem {
  x: number;
  y: number;
  initialXPosition: number;
}

@Component({
  selector: "lag-multiple-slider",
  templateUrl: "./multiple-slider.component.html",
  styleUrls: ["./multiple-slider.component.css"],
})
export class MultipleSliderComponent implements OnInit, AfterViewChecked {
  @Input() title: string;
  @Input() defaultValue: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number = 50;
  @Input() items: SliderItem[] = [];
  @Input() formatValue = (value) => {
    return value;
  };
  @Output()
  changeValue = new EventEmitter<{ index: number; value: number }>();

  private currentSliderWidth$$ = new BehaviorSubject<number>(0);
  currentSliderWidth$ = this.currentSliderWidth$$.asObservable();

  startXPosition = 0;
  endXPosition;
  positionOfItems: SliderItemWithPosiiton[] = [];
  oneStepInPercent;

  @ViewChild("sliderRangeLine", { read: ElementRef })
  sliderRangeLine: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  convertValueInPercents(value: number): number {
    return ((value - this.min) / (this.max - this.min)) * 100;
  }

  convertPercentIntoPx(maxValue: number, percent: number) {
    return (percent / 100) * maxValue;
  }

  getXPosition(event: CdkDragEnd, index) {
    const { x } = event.distance;
    let simulatedOffset = this.positionOfItems[index].x + x;

    simulatedOffset =
      simulatedOffset > this.endXPosition
        ? this.endXPosition
        : simulatedOffset < this.startXPosition
        ? this.startXPosition
        : simulatedOffset;

    return simulatedOffset;
  }

  roundToNearStep(offset) {
    const currentValue = Math.round((offset / this.endXPosition) * 100);
    const percent = Math.round(currentValue / this.oneStepInPercent);
    const value = this.min + percent * this.step;

    return value;
  }

  dragMoved(event: CdkDragEnd, index) {
    if (this.min === this.max) return;

    const simulatedOffset = this.getXPosition(event, index);
    const value = this.roundToNearStep(simulatedOffset);
    this.positionOfItems[index].value = value;
    this.changeValue.emit({ index, value });
  }

  dragEnded(event: CdkDragEnd, index) {
    if (this.min === this.max) return;

    const simulatedOffset = this.getXPosition(event, index);
    const value = this.roundToNearStep(simulatedOffset);
    this.positionOfItems[index].x = simulatedOffset;
    this.positionOfItems[index].value = value;
    this.changeValue.emit({ index, value });
  }

  ngOnInit(): void {
    this.oneStepInPercent = 100 / ((this.max - this.min) / this.step);
    this.currentSliderWidth$.subscribe((value) => {
      if (value === 0) return;

      this.endXPosition = value;
      this.positionOfItems = [];
      this.items.forEach((item) => {
        let xAxis = this.convertPercentIntoPx(
          this.endXPosition,
          this.convertValueInPercents(
            item.value > this.max
              ? this.max
              : item.value < this.min
              ? this.min
              : item.value
          )
        );

        this.positionOfItems.push({
          ...item,
          y: 0,
          x: xAxis,
          initialXPosition: xAxis,
        });
      });
      this.cd.detectChanges();
    });
  }
  ngAfterViewChecked(): void {
    if (
      this.currentSliderWidth$$.value === 0 ||
      this.sliderRangeLine.nativeElement.offsetWidth !==
        this.currentSliderWidth$$.value
    ) {
      this.currentSliderWidth$$.next(
        this.sliderRangeLine.nativeElement.offsetWidth
      );
    }
  }
}
