<div class="table-form-group-cell-container" [ngClass]="cellStyle" (mouseenter)="showEditByCellIcon= true"
    (mouseleave)="showEditByCellIcon= false">
    <ng-container *ngIf="rowData?.value?.editableCell === key && rowData?.value?.isEditable; else formControlData">
        <ng-container [formGroup]="rowData">
            <!-- with emerald design -->
            <mat-text-input *ngIf="isEmerald && isTextInput()" [form]="rowData" [field]="{label: columnLabel.label, control: key}"></mat-text-input>
            <mat-select-emerald
                *ngIf="isEmerald && isDropdown()"
                [form]="rowData"
                [isTypeahead]="isTypeahead()"
                [field]="{label: columnLabel.label, control: key, options: getColumOptions(key)}"
            >
            </mat-select-emerald>
            <table-date *ngIf="cellType?.[key]?.type === 'date'" [form]="rowData"
                [field]="{label: columnLabel.label, control: key}"></table-date>
            <!-- without emerald design -->
            <input *ngIf="!isEmerald && isTextInput()" matInput type="text" class="mat-input-element-style"
                [formControlName]="key">
            <mat-singleselect *ngIf="!isEmerald && isDropdown()" [label]="columnLabel"
                [optionsData]="columnOptions[key]" [controlName]="rowData.get(key)"></mat-singleselect>
            <!-- action icons -->
            <div class="icons-block">
                <mat-icon [ngClass]="isValidationError ? 'doneMark disabled' : 'doneMark'"
                    (click)="!isValidationError && handleSubmit(rowData, key)">done</mat-icon>
                <div style="width: 1px;background: #003F2D1A;"></div>
                <mat-icon class="closeMark" (click)="handleCancel(rowData, key)">close</mat-icon>
            </div>
        </ng-container>
    </ng-container>
    
    <ng-template #formControlData>
        <ng-container *ngIf="rowData?.value; else rowDataTemplate" [ngTemplateOutletContext]="{rowData: rowData, columnOptions:columnOptions}">
            <span *ngIf="!columnOptions[key] && rowData?.value[key]">{{rowData?.value?.[key]}}</span>
            <span *ngIf="columnOptions[key] && rowData?.value[key]">{{getSelectedOptionName(rowData.value[key],
                columnOptions[key])}}</span>
            <span *ngIf="required && !(rowData?.value[key])" class="left-align-error"
                style="color: #A03530; display: inline-flex;min-width: 100px;">
                <i class="material-icons"
                    style="color: #FF543E;font-size: inherit;line-height: inherit;padding-right:2px"
                    matSuffix>error_outline</i> Required field</span>
            <mat-icon *ngIf="!nonEditableColumns" class="editMark"
                [ngStyle]="{visibility: showEditByCellIcon ? 'visible' : 'hidden', color: '#003F2D'}"
                (click)="handleEdit(rowData)">edit</mat-icon>
        </ng-container>
    </ng-template>


    <ng-template #rowDataTemplate>
        <span *ngIf="!columnOptions[key] && rowData[key]">{{rowData[key]}}</span>
        <span *ngIf="columnOptions[key] && rowData[key]">{{getSelectedOptionName(rowData[key],
            columnOptions[key])}}</span>
        <span *ngIf="required && !(rowData[key])" class="left-align-error"
            style="color: #A03530; display: inline-flex;min-width: 100px;">
            <i class="material-icons" style="color: #FF543E;font-size: inherit;line-height: inherit;padding-right:2px"
                matSuffix>error_outline</i> Required field</span>
        <mat-icon *ngIf="!nonEditableColumns" class="editMark"
            [ngStyle]="{visibility: showEditByCellIcon ? 'visible' : 'hidden', color: '#003F2D'}"
            (click)="handleEdit(rowData)">edit</mat-icon>
    </ng-template>

</div>