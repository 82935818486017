import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '@services/userProfile.service';
import { CONSTANT_URLS } from '@core/models/urls.model';

@Component({
  selector: 'app-pending-request-dialog',
  templateUrl: './pending-request-dialog.component.html',
  styleUrls: ['./pending-request-dialog.component.scss'],
})
export class PendingRequestDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PendingRequestDialogComponent>,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      window.location.href = CONSTANT_URLS.LABOR_ANALYTICS;
    }, 10000);
  }

  close() {
    window.location.href = CONSTANT_URLS.LABOR_ANALYTICS;
  }
}
