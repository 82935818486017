<form [formGroup]="form" class="example-form">
    <div class="select-container">
    <mat-form-field appearance="outline" class="matFormField" panelWidth="auto" width="100%" [hideRequiredMarker]="hideRequiredMarker">
        <mat-label class="">{{field.label}}</mat-label>
        <ng-container *ngIf="field.optionValueType==='object'">
          <mat-select [disableOptionCentering]="true" class="mat-option-text-style" #dynamicSelect [formControlName]="field.control" [compareWith]="selectedValue" panelClass="panelClass"
             (selectionChange)="changedValue(dynamicSelect)">
              <mat-option class="mat-option-text-style" *ngFor="let option of field.options" [value]="option" [disabled]="option?.disabled">
                {{option.text??option.name}}
              </mat-option>
          </mat-select>
       </ng-container>
           <ng-container *ngIf="!(field.optionValueType==='object')">
            <mat-select [disableOptionCentering]="true" class="mat-option-text-style" #dynamicSelect [formControlName]="field.control" [panelClass]="isMultiple ? 'panelMultipleClass' : 'panelClass'" [multiple]="isMultiple"
             (selectionChange)="changedValue(dynamicSelect)">
             <mat-select-trigger *ngIf="field.optionType === 'subtitle'">
              {{ dynamicSelect.value?.name }} ({{ dynamicSelect.value?.subtitle}})
            </mat-select-trigger>
              <ng-container [ngSwitch]="field.optionType">
                <ng-container *ngSwitchCase="'subtitle'">
                  <mat-option class="mat-option-subtitle-style" *ngFor="let option of field.options" [value]="option" [disabled]="option?.disabled">
                    <p>{{ option.name }}</p>
                    <p>{{ option.subtitle }}</p>
                 </mat-option>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <mat-option class="mat-option-text-style" *ngFor="let option of field.options" [value]="option.value??option.id" [disabled]="option?.disabled">
                    {{option.text??option.name}}
                </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
      </ng-container>
    </mat-form-field>
    <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</div>
</form>