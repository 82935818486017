export enum NotificationTypes {
  ARTICLE = 'Article',
  INTERVIEW = 'Interview',
  LABORPLAN = 'Labor Plan',
  ACCESS_REQUEST = 'Access Request',
  COMPETITION = 'Competition',
}

const getTitleAndSubtitle = (title, subTitle) => ({
  title: `${title} follow up`,
  subtitle: `You have one ${subTitle} marked as follow up.`,
});

export const REROUTING_ACCORD_TO_TYPE = {
  [NotificationTypes.ARTICLE]: '/articles',
  [NotificationTypes.INTERVIEW]: '/interviews',
  [NotificationTypes.LABORPLAN]: '/labor-plan',
  [NotificationTypes.COMPETITION]: '/competition/searchcompetition',
};

export const notificationText = {
  [NotificationTypes.ARTICLE]: getTitleAndSubtitle('Articles', 'article'),
  [NotificationTypes.INTERVIEW]: getTitleAndSubtitle('Interviews', 'interview'),
};
