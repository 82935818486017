import { Component, Input } from '@angular/core';

@Component({
    selector: 'table-menu-cell',
    templateUrl: './table-menu-cell.component.html',
    styleUrls: ['./table-menu-cell.component.scss']
})
export class MenuCellComponent {
    @Input() cellType: string;
    @Input() rowData: any;
    @Input() menuItems: [{ label: string, action: Function, event: object }];
    @Input() title: string;
    open;
}
