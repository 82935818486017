<div class="dialog">
  <h2 mat-dialog-title class="modal-header">{{data.headerText}}</h2>
  
  <button mat-icon-button class="close-button" (click) = "dialog.closeAll()" mat-dialog-close="true">
    <mat-icon class="close-icon" >close</mat-icon>
  </button>

<mat-dialog-content *ngIf="data.bodyText" class="row mat-typography" style="width:700px">
  <h3>{{data.bodyText}}</h3>
</mat-dialog-content>



<mat-dialog-actions align="end">
  <button class="button secondary" mat-button [mat-dialog-close]="true" >{{data.okBtnName}}</button>
  <button class="button primary" mat-button (click)="apply()">{{data.cancelBtnName}}</button>
</mat-dialog-actions>
</div>