
<div class="dialog">
  <button mat-icon-button class="close-button" (click) = "dialog.closeAll()" mat-dialog-close="true">
    <mat-icon class="close-icon" >close</mat-icon>
  </button>
  <!-- <h1 mat-dialog-title>{{data.title}}</h1> -->
  <div class="row mt-2" mat-dialog-content>
    <mat-icon class="mr-1" style="color: #ffd740; cursor: pointer;">warning</mat-icon>
    {{data.message}}</div>
  <!-- <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div> -->
</div>