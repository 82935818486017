import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  constructor(public http: HttpClient) {}

  getPromise(url: string, options?: any): Promise<any> {
    return this.http
      .get(url, options)
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch(this.handleError);
  }

  postPromise(url: string, params: any, options?: any): Promise<any> {
    return this.http.post(url, params, options).toPromise().catch(this.handleError);
  }

  public handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    // ToDo:To Decided shoule we Log Error message to server, if yes then do it here
    // console.error(errMsg);
    throw errMsg;
  }
}
