import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';

@Component({
  selector: 'image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss'],
})
export class ImageUploadDialogComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild(MatSort) matSort: MatSort;

  isSubmitFailed = false;
  isImportInProgress = false;
  files: any[] = [];
  spinnerEnabled = false;
  isCancelUpload = false;
  isInvalidFile = false;
  msgError: string = '';
  delaySubscription;
  MAX_FILE_SIZE_LIMIT = 5_000_000; // 5Mb

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ImageUploadDialogComponent>,
  ) {}

  ngOnInit() {}

  onFileDropped($event) {
    this.prepareFilesList($event);
    this.onFileUpload($event);
  }

  fileBrowseHandler($event) {
    this.prepareFilesList($event?.target?.files);
    this.onFileUpload($event);
  }

  deleteFile() {
    clearTimeout(this.delaySubscription);
    this.snackBarService.showInformation('Your file import has been canceled');
    this.dialog.closeAll();
  }

  private prepareFilesList(files: Array<any>) {
    if (files?.length > 1) {
      return;
    }
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  private getUrl(file) {
    const image = file.kind === 'file' ? file.getAsFile() : file;

    return URL.createObjectURL(image);
  }

  private async onFileUpload(evt) {
    const target: DataTransfer = <DataTransfer>evt.target;
    const isImage = !!target.files[0].name?.toLowerCase()?.match(/(.jpeg|.png)/);
    const isFileWithinSizeLimit = target.files[0]?.size <= this.MAX_FILE_SIZE_LIMIT;

    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
      this.msgError = 'You can not upload more than one image.'
      return;
    }

    if (!isFileWithinSizeLimit) {
      this.msgError = 'Max file size is 5 MB.';
      this.isImportInProgress = false;
      return;
    } else {
      this.msgError = '';
    }

    const file: any = target.files[0];
    const imageUrl = this.getUrl(file);

    if (isImage) {
      this.msgError = '';
      this.isImportInProgress = true;
      this.isCancelUpload = true;
      this.delaySubscription = setTimeout(() => {
        this.isCancelUpload = false;
        this.dialogRef.close({ url: this.domSanitizer.bypassSecurityTrustUrl(imageUrl), originalFile: file });
      }, 1500);
    } else {
      this.msgError = 'Please upload the valid type of file.';
    }
  }

  ngOnDestroy(): void {
    if (this.delaySubscription) {
      clearTimeout(this.delaySubscription);
    }
  }
}
