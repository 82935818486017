import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BaseService } from '../base.service';
import { InterceptorSkipHeader } from '../../httpInterceptor/TokenHttpInterceptor';
import { CompetitionItem } from '../../models/competitionItem.model';

@Injectable({
  providedIn: 'root',
})
export class DriveTimeService extends BaseService {
  private competitionStaticData$: Observable<any> = null;
  private interviewStaticData$:Observable<any> = null;
  public competitionDeatils$!: Observable<any>;
  private url = environment.baseUrl + 'api/';
  private spinnerLessHeader = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('X-Skip-Interceptor', '');
  dataChanges = new Subject<any>();
  constructor(http: HttpClient) {
    super(http);
    this.competitionDeatils$ = this.getCompetitionEmployerDetails().pipe(
      switchMap((initialState) =>
        this.dataChanges.pipe(
          scan((state, newItem) => [...state, newItem] /* modify how you like */, initialState),
          startWith(initialState),
        ),
        shareReplay(1)
      ));
    }

    getCompetitionStaticData(): Observable<any> {
        // load from the api and cache if data was not loaded before
        if (!this.competitionStaticData$) {
            const apiuri = '/api/competition/staticData';
            const url = environment.competitionApiUrl + apiuri;
    
            this.competitionStaticData$ = this.http.get(url)
                .pipe(
                    shareReplay(1),
                    catchError((e) => this.handleError(e))
                )
        } 
        return this.competitionStaticData$
    }

    
    getInterviewStaticData(): Observable<any> {
        // load from the api and cache if data was not loaded before
        if (!this.interviewStaticData$) {
            const apiuri = '/api/interview/staticData';
            const url = environment.competitionApiUrl + apiuri;
        
           this.interviewStaticData$ = this.http.get(url)
            .pipe(
                shareReplay(1),
                catchError((e) => this.handleError(e))
            )
        } 
        return this.interviewStaticData$
    }

    getProfiles(): Observable<any> {
    	const apiUrl =  environment.adminUrl + '/profiles?countryAbbr=USA';
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(apiUrl)
            .pipe(
                map(res => res),
                catchError(this.handleError)
            );
    }

    getClients(): Observable<any> {
    	const apiUrl =  environment.competitionApiUrl + '/api/client';
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(apiUrl)
            .pipe(
                map(res => res),
                catchError(this.handleError)
            );
    }

    getMarkets(): Observable<any> {
    	const apiUrl =  environment.adminUrl + '/market/get-cbsa-locations';
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

	return this.http.get(apiUrl, header).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getProfileMetaData(): Observable<any> {
        const apiUrl =  environment.adminUrl + '/profile/indicators';
        return this.http.get(apiUrl)
            .pipe(
                map(res => res),
                catchError(this.handleError) 
            );
      }

    postSingleSiteAuditinfo(parameters: any = {}): Observable<any> {
        const apiUrl =  environment.adminUrl + '/api/single-site-audit';
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        
	const payload = {
		parameters : JSON.stringify(parameters)
	};
	return this.http.post(apiUrl, payload, header).pipe(
            map(res => res),
            catchError(this.handleError) 
        );
    }

    updateSingleSiteAuditinfo(auditId: number, exportedExcel = false, exportedPdf = false): Observable<any> {

        const apiUrl =  environment.adminUrl + '/api/single-site-audit?AuditId=' + auditId + '&ExportedExcel=' + exportedExcel + '&ExportedPdf=' + exportedPdf;
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.put(apiUrl, null, header).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getPostSecondarySchools(params: string): Observable<any> {

        const apiuri = '/v1/education-api/postsecondaryschools';
        const apiUrl = environment.educationApiUrl + apiuri + '?' + params;

        const header = { };

        return this.http.get(apiUrl, header);
    }

    getMedianWages(params: string): Observable<any> {

        const apiuri = '/v1/wages-api/geoskillwages';
        const apiUrl = environment.eriApiUrl + apiuri + '?' + params;

        const header = { };

        return this.http.get(apiUrl, header);
    }

    getDriveTimeReport(params: string): Observable<any> {
        const apiuri = '/v1/ondemand-api';
        const url = environment.ondemandApiUrl + apiuri + '?' + params;
        const header = {
        };

        return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }

    getDemographicsNationalvalues(): Observable<any> {
        const apiuri = '/v1/demographics-api/geoskill/national';
        const url = environment.educationApiUrl + apiuri;

        const header = { };

        return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }

    getEducationNationalvalues(): Observable<any> {
        const apiuri = '/v1/education-api/education/national';
        const url = environment.educationApiUrl + apiuri;

        const header = { };

        return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }

    getOccupationsNationalvalues(): Observable<any> {
        const apiuri = '/bls-webapp/api/drivetime/occupations/national';
        const url = environment.blsApiUrl + apiuri;

        const header = { };

       return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));

    }

    getProjects(): Observable<any> {
        const apiuri = 'api/projects/all';
        const url = environment.baseUrl + apiuri;

        const header = { };

        return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));

    }

    override handleError(error: any): Promise<any> {
        return Promise.reject(error);
    }


    getDataExplorerResults(payload: any): Observable<any[]> {
        const apiuri = '/v1/education-api/dataexplorer/allmarkets';
        const url =  environment.educationApiUrl + apiuri;
        const header = {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        };

        return this.http.post(url, payload, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }
    getSearchCompetitionResults(payload: any): Observable<any[]> {
        const apiuri = '/api/competition';
        const url =  environment.competitionApiUrl + apiuri;
        const header = {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set(InterceptorSkipHeader, '')
        };

        return this.http.post(url, payload, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }
    


    getAddCompetitionHistory(empId,competitionId): Observable<any> {
        const apiuri = '/api/operations/history';
        const url = environment.competitionApiUrl + apiuri+'?competitionId='+competitionId;

        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(url, header).pipe(map(res => res), catchError((e) => this.handleError(e)));

    }

    getCompetitionEmployerDetails(): Observable<any> {
        const apiuri = '/api/competition/getallcompetitions';
        const url = environment.competitionApiUrl + apiuri;

        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(url, header).pipe( map(res => res), catchError((e) => this.handleError(e)));

    }

    getOperations(empId, competitionId) {
        const apiuri = '/api/operations';
        const url = environment.competitionApiUrl + apiuri + '?empId=' + empId + '&competitionId=' + competitionId;

        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(url, header).pipe(map(res => res),catchError((e) => this.handleError(e)));
    }

    saveOperationsData(empId: number, competitionId: number, payload: any): Observable<any[]> {
        const apiuri = '/api/operations';
        const url =  environment.competitionApiUrl + apiuri + '?empId=' + empId + '&competitionId=' + competitionId;
        const header = {headers: new HttpHeaders() .set('Content-Type', 'application/json') };

        return this.http.post(url, payload, header).pipe( map(res => res), catchError((e) => this.handleError(e)));
    }

    saveEmployeeData(payload: any): Observable<any[]> {
        const apiuri = '/api/competition/employer';
        const url =  environment.competitionApiUrl + apiuri;
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.post(url, payload, header).pipe(map(res => res), catchError((e) => this.handleError(e)));
    }

    getCompetitionById(id): Observable<CompetitionItem> {
        const apiuri = `/api/competition/${id}`;
        const url =  environment.competitionApiUrl + apiuri;
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json').set(InterceptorSkipHeader, '')};

        return this.http.get<CompetitionItem>(url, header).pipe(map(res => res),catchError((e) => this.handleError(e)));
    }

    updateEmpoyerData(payload: any): Observable<any[]> {
        const apiuri = '/api/competition/employer';
        const url =  environment.competitionApiUrl + apiuri;
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.put(url, payload, header).pipe(map(res => res),catchError((e) => this.handleError(e)));
    }

    getCompetitionSavedSearchDetails(): Observable<any> {
        const apiuri = '/api/searchQuery';
        const url = environment.competitionApiUrl + apiuri;

        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return this.http.get(url, header).pipe(map(res => res), catchError((e) => this.handleError(e)));
    }


    getCompetitionStaticDataByCountryId(countryId: number): Observable<any> {
        const url = `${environment.competitionApiUrl}/api/competition/staticData/${countryId}`;
        const header = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };

        return this.http.get(url, header).pipe(
            map((res) => res),
            catchError((e) => this.handleError(e)),
        );
    }

    getCompetitionEmployersDetails(): Observable<any> {
        const apiuri = '/api/competition/employers';
        const url = environment.competitionApiUrl + apiuri;

        const header = { 
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set(InterceptorSkipHeader, ''),
        };

        return this.http.get(url, header).pipe(map(res => res),catchError((e) => this.handleError(e)));
    }

    getCompetitionEmployers(): Observable<any> {
        const apiuri = '/api/competition/employersCompetition';
        const url = environment.competitionApiUrl + apiuri;

        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')
        };

        return this.http.get(url, header).pipe(map(res => res),catchError((e) => this.handleError(e)));
    }

    postCompetitionAddressByEmployer(id): Observable<any> {
        const apiuri = `/api/competition/competitionEmployer/${id}`;
        const url = environment.competitionApiUrl + apiuri;
        const header = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.get(url, header).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    postCreateCompetitionData(parameters: any = {}): Observable<any> {
        const apiuri = '/api/searchQuery';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, parameters).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    updateCreateCompetitionData(parameters: any = {}): Observable<any> {
        const apiuri = '/api/searchQuery';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.patch(url, parameters).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    updateMassUpdate(parameters: any = {}): Observable<any> {
        const apiuri = '/api/competition/massupdate';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.patch(url, parameters).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    updateCompetitionData(parameters: any = {}): Observable<any> {
        const apiuri = '/api/competition/inlineUpdateCompetition';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, parameters, { headers: this.spinnerLessHeader }).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    deleteMassUpdate(parameters: any = {}): Observable<any> {
        const apiuri = '/api/competition/removeComps';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, parameters).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    postQuickUpdate(parameters: any = {}): Observable<any> {
        const apiuri = '/api/competition/quickUpdate';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, parameters).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    deleteOperations(payload): Observable<any> {
        const apiuri = '/api/competition/removeComps';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, payload).pipe(
            map(res => res)
        );
    }

    importCompCancel(payload): Observable<any> {
        const apiuri = '/api/competition/import/cancel';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, payload, { headers: this.spinnerLessHeader }).pipe(
            map(res => res)
        );
    }

    importComp(payload): Observable<any> {
        const apiuri = '/api/competition/import';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, payload, { headers: this.spinnerLessHeader }).pipe(
            map(res => res)
        );
    }


   getallcompetitions(): Observable<any>{
        return this.competitionDeatils$.pipe(map(res => res),
        catchError((e) => this.handleError(e)));
    }

    getInterviewQuestionSets(): Observable<any> {
        const apiUrl = '/api/questionsets';
        const url = environment.competitionApiUrl + apiUrl;
        const header = {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        };
        return this.http.get(url, header).pipe(
            map(res => res),
            catchError((e) => this.handleError(e)));
    }

    postMarketScore(payload): Observable<any> {
        const apiuri = '/api/interview/marketscores';
        const url = environment.competitionApiUrl + apiuri;
        return this.http.post(url, payload).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    createQuestionSet(payload): Observable<any> {
        const apiUrl = '/api/questionsets/create';
        const url = environment.competitionApiUrl + apiUrl;
        return this.http.post(url, payload).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    deleteQuestion(Id): Observable<any> {
        const url = '/api/questionsets/delete/' + Id;
        const uri = environment.competitionApiUrl + url;
        return this.http.delete(uri);
    }

  updateQuestion(payload): Observable<any> {
        const url = '/api/questionsets/update';
        const uri = environment.competitionApiUrl + url;
        return this.http.patch(uri, payload).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getUnemployment(): Observable<any> {
        const url = environment.blsApiUrl + '/bls-webapp/api/unemploymentrate';
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }
    getWage(): Observable<any> {
        const url = environment.eriApiUrl + '/industryWages';
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getEmploymentYoY({startYear, endYear}): Observable<any> {
        const url = environment.blsApiUrl + `/bls-webapp/api/monthlyemploymentgrowth?startYear=${startYear}&&endYear=${endYear}`;
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            catchError(this.handleError)
        );
    }

    getUnemploymentkpi({startYear, endYear, startMonth, endMonth}): Observable<any> {
        const url = environment.blsApiUrl + `/bls-webapp/api/monthlyunemploymentrate?startYear=${startYear}&&endYear=${endYear}&&startMonth=${startMonth}&&endMonth=${endMonth}`;
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            catchError(this.handleError)
        );
    }

    getInflationkpi({startYear, endYear, startMonth, endMonth}): Observable<any> {
        const url = environment.blsApiUrl + `/bls-webapp/api/inflationrate?startYear=${startYear}&&endYear=${endYear}&&startMonth=${startMonth}&&endMonth=${endMonth}`;
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            catchError(this.handleError)
        );
    }

    getLayoffkpi({startYear, endYear, startMonth, endMonth}): Observable<any> {
        const url = environment.blsApiUrl + `/bls-webapp/api/monthlylayoffdata?startYear=${startYear}&&endYear=${endYear}&&startMonth=${startMonth}&&endMonth=${endMonth}`;
        return this.http.get(url, { headers: this.spinnerLessHeader }).pipe(
            catchError(this.handleError)
        );
    }
    
    createClient(payload: { name: string, industryId: number }): Observable<any> {
        const url = environment.competitionApiUrl + '/api/client';
        return this.http.post(url, payload);
    }
    createProject(payload: { projectName: string }): Observable<any> {
        const url = environment.competitionApiUrl + '/api/project/create';
        return this.http.post(url, payload);
    }
}
