import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileInfo } from 'src/app/models/userProfile.model';


interface DialogData {
  staticData: any;
  userProfile: UserProfileInfo;
}
@Component({
  selector: 'app-newsletter-dialog',
  templateUrl: './newsletter-dialog.component.html',
  styleUrls: ['./newsletter-dialog.component.scss'],
})
export class NewsLetterDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<NewsLetterDialogComponent>,
    public dialog: MatDialog,
  ) {  }

  staticData;
  form = new FormGroup({
    industries: new FormControl([], Validators.required),
    topics: new FormControl([], Validators.required),
  });
  matFields = {
    industries: {
      label: 'Industry',
      control: 'industries',
      controlType: 'required',
      options: [],
    },
    topics: {
      label: 'Topics',
      control: 'topics',
      controlType: 'required',
      options: [],
    },
  };

  ngOnInit() {
    this.staticData = this.data.staticData;

    const articleTopics = this.staticData?.find((data) => data.dataType === 'ArticleTopics')?.data;
    const industries = this.staticData?.find((data) => data.dataType === 'IndustryTypes')?.data;

    if (industries?.length) {
      this.matFields.industries.options = industries;
    }

    if (articleTopics?.length) {
      this.matFields.topics.options = articleTopics;
    }

    this.form.reset({
      industries: industries.filter(x => this.data.userProfile.newsLetter.industries.indexOf(x.id) >= 0),
      topics: articleTopics.filter(x => this.data.userProfile.newsLetter.topics.indexOf(x.id) >= 0),
    })
  }

  isSubscribeBtnDisabled() {
    return this.form.status === 'INVALID';
  }

  getResponse() {
    this.dialogRef.close(this.form.value);
  }
}
