import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-single-typeahead',
    templateUrl: './single-typeahead.component.html',
    styleUrls: ['./single-typeahead.component.css']
})

export class SingleTypeaheadComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form:FormGroup;
  @Input() field: FormFieldDropdown;
  @Input() onOptionSelected: (event) => void;

  @ViewChild('cdkVirtualScroll') virtualScroll: CdkVirtualScrollViewport;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  // single option height
  itemSize = OptionTypeHeight.singleLine;
  filteredOptions = [];

  constructor(
    @Optional() private formgroupDirective:FormGroupDirective,
    private formService: FormService,
  ) {}

  destroy$ = new Subject<void>();

  ngOnInit(): void {
      if (this.field.optionType === 'twoLine') { 
          this.itemSize = OptionTypeHeight.twoLine;
      }
      this.form = this.form ?? this.formgroupDirective.control;

      this.form.controls[this.field.control].valueChanges.pipe(
          startWith(''),
          distinctUntilChanged(),
          debounceTime(500),
          takeUntil(this.destroy$),
      ).subscribe((val) => {
          this.filteredOptions = this._filter(val, 'name') || [];
          this.formService.analytics('track', `Dropdown selected value`, {
            value: val,
            field: this.field.label,
            component: "single-typeahead"
          });
      });
  }

  ngAfterViewInit(): void {
      this.trigger.panelClosingActions
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => {
          if (!(e && e.source)) {
            if(this.field.requireSelection) {
                this.form.controls[this.field.control].setValue('');
                this.trigger.closePanel();
            }
          }
      });
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  private _filter(value: any, filterKey?:string): string[] {
    if (value === null) return this.field?.options;
      const filterValue = value?.name?.toLowerCase() || String(value).toLowerCase();
      return this.field?.options.filter(option => String(option[filterKey]).toLowerCase().includes(filterValue));
  }

  displayFn(value){
      return value?.name;
  }

  onOpened() {
      this.virtualScroll.checkViewportSize();
  }
}

class FormField {
    label: string;
    options: any[];
    control: string;
    helperText: string;
    hideRequiredMarker: boolean;
    requireSelection: boolean;
}

class FormFieldDropdown extends FormField {
    optionType: OptionType;
}

enum OptionTypeHeight {
    twoLine = 56,
    singleLine = 40,
}

type OptionType = 'twoLine' | 'singleLine';

// ToDo
// custom display function
