import { EnvConfigService } from '../services/env-config.service';

// Warning!!! Make sure that the order of your services matches what's in app.module deps array!
export function DataLoader(envConfigService: EnvConfigService): () => Promise<any> {
  return (): Promise<any> => {
    return Promise.all([envConfigService.getEnvSettings()]).then((data) => {
      envConfigService.init(data[0]);
    });
  };
}
