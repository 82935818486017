import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[libCellTooltip]'
})
export class CellTooltipDirective implements AfterViewInit {
  @Input('libCellTooltip') text: string;

  constructor(private element: ElementRef, private tooltip: MatTooltip) { }

  ngAfterViewInit(): void {
    const el = this.element.nativeElement;
    if (el.offsetHeight < el.scrollHeight) {
      this.tooltip.message = this.text;
    }
  }
}
