import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(originalText: any, searchText: any): any {
    if (!searchText) {return originalText;}
    return this.sanitizer.sanitize(SecurityContext.HTML, originalText.replace(searchText, `<u>${searchText}</u>`));
  }
}
