import { Inject, Injectable, Optional } from '@angular/core';

import { GTMConfig } from '../interfaces/GTMConfig.interface';
import { GTMConfigurationService } from '../services/gtm-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  private isLoaded = false;
  private config: GTMConfig | null;

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(
    @Optional()
    @Inject(GTMConfigurationService)
    public gTMConfiguration: GTMConfigurationService,

    @Optional()
    @Inject('gTMId')
    public gTMId: string,

    @Optional()
    @Inject('gTMAuth')
    public gTMAuth: string,

    @Optional()
    @Inject('gTMPreview')
    public gTMPreview: string,

    @Optional()
    @Inject('gTMResourcePath')
    public gTMResourcePath: string,

    @Optional()
    @Inject('gTMCSPNonce')
    public gTMCSPNonce: string,
  ) {
    this.config = this.gTMConfiguration.get();
    if (this.config == null) {
      this.config = { id: null };
    }

    this.config = {
      ...this.config,
      id: gTMId || this.config.id,
      gtm_auth: gTMAuth || this.config.gtm_auth,
      gtm_preview: gTMPreview || this.config.gtm_preview,
      gtm_resource_path: gTMResourcePath || this.config.gtm_resource_path,
    };
    if (this.config.id == null) {
      throw new Error('Google tag manager ID not provided.');
    }
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  public addGtmToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const doc = this.browserGlobals.documentRef();
      const isGTMscriptAddedtoDOM = !!doc.getElementById('GTMscript');
      if (this.isLoaded || isGTMscriptAddedtoDOM) {
        return resolve(this.isLoaded);
      }

      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(
        this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
      );
      gtmScript.addEventListener('load', () => {
        return resolve((this.isLoaded = true));
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      if (this.gTMCSPNonce) {
        gtmScript.setAttribute('nonce', this.gTMCSPNonce);
      }
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }

  public pushTag(item: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoaded) {
        this.addGtmToDom()
          .then(() => {
            this.pushOnDataLayer(item);
            return resolve();
          })
          .catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
}
