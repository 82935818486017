<form [formGroup]="form" class="example-form">
<mat-form-field [hideRequiredMarker]="hideRequiredMarker" appearance="outline" class="matFormField" panelWidth="auto" width="100%">
    <mat-label class="">{{field.label}}</mat-label>
    <input
        matInput
        #ref
        (focus)="picker.open()"
        [formControlName]="field.control"
        readonly
        placeholder="MM/DD/YYYY"
        [attr.arialabel]="field.label"
        [matDatepicker]="picker"
        [matDatepickerFilter]="filter"
        >
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
</form>
