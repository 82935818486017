import { Component, OnInit } from '@angular/core';
import { CONSTANT_URLS } from '@core/models/urls.model';

@Component({
  selector: 'app-no-access-redirect',
  templateUrl: './no-access-redirect.component.html',
})
export class NoAccessRedirectComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.location.href = CONSTANT_URLS.OCCUPIER;
  }
}
