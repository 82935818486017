import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CoreModule } from './core.module';

@Injectable({
  providedIn: CoreModule,
})
export class NotificationService {
  public success: Subject<string> = new Subject();
  public error: Subject<string> = new Subject();
}
