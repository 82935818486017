<div class="dialog">
  <div class="header-content">
    <h2 mat-dialog-title class="header-title">Access request</h2>
    <button class="header-close-button" (click)="dialog.closeAll()" mat-dialog-close="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="body-content">
    <div class="body-content__subtitle">
      {{ data?.email }} is requesting for access to CBRE GeoNavigator&trade;.
    </div>
    <div *ngIf="form.value?.message" [formGroup]="form" class="body-content__interests">
      <lag-text [istextarea]="true" [field]="messageField" [hideRequiredMarker]="true" [readonly]="true"> </lag-text>
    </div>
  </div>
  <mat-dialog-actions style="justify-content: flex-end">
    <button class="button secondary" (click)="reject()">Reject access</button>
    <button class="button primary" (click)="approve()">Approve access</button>
  </mat-dialog-actions>
</div>
