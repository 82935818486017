import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimeZones {
  public list = [
    { name: 'Eastern', value: 'ET' },
    { name: 'Central', value: 'CT' },
    { name: 'Mountain', value: 'MT' },
    { name: 'Pacific', value: 'PT' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Hawaiian', value: 'HST' },
    { name: 'Alaskan', value: 'AKST' },
  ];
}
