import {
  Component,
  ElementRef,
  Input,
  OnChanges, OnInit,
  Optional,
  SimpleChanges, ViewChild,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { Observable, of } from "rxjs";
import { CommonService } from "../services/common.service";
import { FormService } from "../services/form.service";

@Component({
  selector: "lag-single-select-search",
  templateUrl: "./single-select-search.component.html",
  styleUrls: ["./single-select-search.component.css"],
})
export class SingleSelectSearchComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() field: any;
  @ViewChild("filterSearchTextBox") seachTextbox: ElementRef;

  value: string;

  @Input() optionsData$: Observable<any[]>;

  constructor(
    @Optional() private formgroupDirective: FormGroupDirective,
    private commonService: CommonService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.form = this.form ?? this.formgroupDirective.control;
    this.sortAndUpdateOptions(this.field);
  }

  sortAndUpdateOptions(field): void {
    const filteredListAsc = this.commonService.sortArray(
      field.options,
      "name"
    );
    if (
      field.options?.length > 15 &&
      this.form.get(field.control)?.value
    ) {
      const id = this.form.get(field.control)?.value;
      filteredListAsc.sort((x, y) => {
        return x.id == id ? -1 : y == id ? 1 : 0;
      });
    }
    this.optionsData$ = of(filteredListAsc);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.field.firstChange) {
      this.sortAndUpdateOptions(changes.field.currentValue);
    }
  }

  handleFilterOptions(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredList = this.field?.options?.filter(
      (option) => option?.name?.toLowerCase()?.indexOf(filterValue) !== -1
    );
    const filteredListAsc = this.commonService.sortArray(filteredList, "name");
    this.optionsData$ = of(filteredListAsc);
    this.formService.analytics('track', `Filtered list of options in dropdown event`, { value: filterValue, component: "single-select-search", field: this.field.label });
  }

  clearSearch() {
    this.seachTextbox.nativeElement.value = "";
    const filteredListAsc = this.commonService.sortArray(
      this.field?.options,
      "name"
    );
    this.optionsData$ = of(filteredListAsc);
  }
}
