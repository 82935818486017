<form [formGroup]="form">
    <div class="custom-date-container">
        <label class="label">{{field.label}}</label>
        <mat-radio-group class="filter-date-container" [value]="filterDateType" (change)="onSelectionChange($event)">
            <mat-radio-button disableRipple [value]="'relativeDate'">Relative dates</mat-radio-button>
            <ng-container *ngIf="filterDateType === 'relativeDate'">
              <mat-chip-list selectable>
                <mat-chip (click)="onRelativeDateChange(chip)" [selected]="chip === selectedChip" [value]="chip" *ngFor="let chip of dateChipList">
                  <lag-icon iconName="check" class="check-icon" *ngIf="chip === selectedChip"></lag-icon>
                  {{ chip }}
                </mat-chip>
              </mat-chip-list>
            </ng-container>

            <mat-radio-button disableRipple [value]="'customDate'">Custom dates</mat-radio-button>
            <div *ngIf="filterDateType === 'customDate'" class="d-flex gap-3">
              <div style="flex: 1;">
                <lag-date class="matFormField" [field]="filterFields.customStartDate"></lag-date>
                <!-- <mat-error *ngIf="filterForm.get('customStartDate').hasError('dateError')">Start date cannot be after end date</mat-error> -->
              </div>
              <div style="flex: 1;">
                <lag-date class="matFormField" [field]="filterFields.customEndDate"></lag-date>
              </div>
            </div>
          </mat-radio-group>
    </div>

</form>
