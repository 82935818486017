import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output()
  clickOutside = new EventEmitter();

  constructor(public el: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (!this.el.nativeElement.contains(target)) {
        this.clickOutside.emit();
    }
  }
}
