<div class="dialog">
  <div class="header-content">
    <h2 mat-dialog-title class="header-title">{{data.headerText}}</h2>
    <div style="width: 24px; height: 24px; margin-bottom: 20px;">
      <button class="header-close-button" (click) = "dialog.closeAll()" mat-dialog-close="true">
        <mat-icon class="close-icon" >close</mat-icon>
      </button>
    </div>
  </div>

  <!-- <mat-dialog-content *ngIf="!data.bodyText" class="mat-typography" style="width:100%">
    
  </mat-dialog-content> -->
  <div *ngFor="let text of data.bodyText" class="body-content">
    {{ text }}
  </div>
  <mat-dialog-actions align="space-between">
    <button class="button secondary flex-container" (click)="applyOne()" >{{data.okBtnName}}</button>
    <button class="button primary flex-container" (click)="applyAll()" >{{data.cancelBtnName}}</button>
  </mat-dialog-actions>
</div>