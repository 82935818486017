import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'table-form-group-cell',
    templateUrl: './table-form-group-cell.component.html',
    styleUrls: ['./table-form-group-cell.component.scss']
})
export class FormGroupCellComponent {
    @Output() onEditSubmit = new EventEmitter();
    @Output() onEditCancel = new EventEmitter();
    @Output() onEditStart = new EventEmitter();

    @Input() cellType: string;
    @Input() rowData: any;
    @Input() key: string;
    @Input() editable: boolean;
    @Input() disabled: boolean;
    @Input() editByCell: boolean;

    showEditByCellIcon = false;

    handleSubmit(rowData, key) {
        this.onEditSubmit.emit({ rowData: rowData, key: key });
    }

    handleCancel(rowData, key) {
        this.onEditCancel.emit({ rowData: rowData, key: key });
    }

    handleEdit(rowData) {
        this.onEditStart.emit({ rowData: rowData });
    }
}
