import { Component, Input, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import More from "highcharts/highcharts-more";
import * as Highcharts from "highcharts";

More(Highcharts);

export const defaultHighChartColors = [
  "#5975BB",
  "#80BBAD",
  "#A0DCCE",
  "#91A6D3",
  "#435354",
  "#FF9A10",
];

@Component({
  selector: "lag-high-charts",
  templateUrl: "./high-charts.component.html",
  styleUrls: ["./high-charts.component.css"],
})
export class HighChartsComponent implements OnInit {
  constructor() {}
  @Input() options: Highcharts.Options;
  @Input() render: () => void;
  chart: Chart;

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  init(): void {
    if (this.options) {
      const defaultOpts: Highcharts.Options = {
        colors: this.options.colors || defaultHighChartColors,
        tooltip: {
          outside: true,
          className: "my-tooltip",
          shadow: {
            color: "#00000033",
          },
          style: {
            zIndex: 100,
            fontSize: "14px",
            lineHeight: "20px",
            ...this.options.tooltip?.style,
          },
          useHTML: true,
          borderRadius: 8,
          borderColor: "rgba(0, 0, 0, 0.01)",
          backgroundColor: "#FFFFFF",
          ...this.options.tooltip,
        },
        xAxis: this.options.xAxis,
        yAxis: this.options.yAxis,
        chart: {
          backgroundColor: this.options.chart.backgroundColor || '#FFFFFF',
          height: this.options.chart.height,
          spacingLeft: 0,
          spacingRight: 0,
          spacingBottom: 30,
          style: {
            fontFamily: "Calibre-R",
          },
          events: {
            render: this.options.chart.events?.render,
          },
          ...this.options.chart,
        },
        title: {
          align: "left",
          margin: 60,
          style: {
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "24px",
            letterSpacing: 0,
            textAlign: "left",
            // whiteSpace: 'nowrap'
          },
          ...this.options.title,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
          symbolRadius: 0,
          itemStyle: {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "10px",
          },
          ...this.options.legend,
        },
        series: this.options.series,
      };

      if (this.options.plotOptions) {
        defaultOpts.plotOptions = {
          ...this.options.plotOptions,
        };
      }
      this.chart = new Chart(defaultOpts);
    }
  }
}
