import { Component, ElementRef, ViewChild, OnInit, HostBinding, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Observable, filter, combineLatest, debounceTime } from 'rxjs';

import { CommonService, FormService } from 'core';
import { LiveboardEmbed, RuntimeFilterOp, Action, EmbedEvent } from '@thoughtspot/visual-embed-sdk';
import { MegamenuService } from '@services/megamenu.service';

import { geographyTypes, thoughtspotFilterMetadata } from './thoughtspot-metadata';
import { Util } from 'src/app/util/Util';
import { dateChipList } from '../../research/model/article-info';

import { DriveTimeService } from '@services/drivetime/drivetime.service';
import { environment } from '@env/environment';
import { DynamicComponentType } from './thoughtspot.model';
import { UserProfileService } from '@services/userProfile.service';
import { SnackbarService } from '@core/snackBar.notification.service';

type Filter = {
  columnName: string;
  operator: RuntimeFilterOp;
  values: Array<any>;
};

type DynamicComponent = {
  position: number;
  label: string;
  componentType: DynamicComponentType;
  filterControlsAPIId?: number;
  columnNameForBI: string;
};

@Component({
  selector: 'app-thought-spot',
  templateUrl: './thoughtspot.component.html',
  styleUrls: ['./thoughtspot.component.scss'],
})
export class ThoughtSpotComponent implements OnInit {
  @HostBinding('class.emeraldstandard') public emeraldstandard = true;
  @ViewChild('vizContainer') containerDiv: ElementRef;

  viz: any;
  filters: Filter[] = [];
  liveboardId: string;
  menuTitle: string;
  menuDescription: string;
  megamenuItem: any;
  filterForm: FormGroup = null;
  filterFormMetadata = thoughtspotFilterMetadata;
  filterValues;
  filterDateTypes = {
    RELATIVE: 'relativeDate',
    CUSTOM: 'custom',
  };
  filterDateType = this.filterDateTypes.RELATIVE;
  dateChipList = dateChipList;
  isFilterDrawerOpened$: Observable<boolean>;
  staticData;
  allLocations;
  isFilterShow = true;

  matSidenav = `width: 360px; height: calc(100vh - ${this.thoughtSpotFilterGap}px)`;

  operatorByComponent = {
    [DynamicComponentType.MULTI_SELECT]: RuntimeFilterOp.IN,
    [DynamicComponentType.SINGLE_SELECT]: RuntimeFilterOp.CONTAINS,
    [DynamicComponentType.TEXTBOX]: RuntimeFilterOp.CONTAINS,
  };

  dynamicComponents: DynamicComponent[] = [];

  disabledActions = [
    Action.Download,
    Action.DownloadAsCsv,
    Action.DownloadAsPdf,
    Action.DownloadAsPng,
    Action.DownloadAsXlsx,
    Action.DownloadTrace,
  ];

  disabledThoughtSpotActions: any = [
    Action.AxisMenuEdit,
    Action.MakeACopy,
    Action.EditACopy,
    Action.EditDetails,
    Action.Explore,
    Action.Pin,
    Action.Edit,
    Action.Present,
    Action.CopyLink,
    Action.SpotIQAnalyze,
    Action.SyncToOtherApps,
    Action.SyncToSheets,
    Action.ManagePipelines,
    Action.RenameModalTitleDescription,
    Action.AxisMenuRename,
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackbarService,
    private megaMenuService: MegamenuService,
    private formService: FormService,
    private driveTimeService: DriveTimeService,
    private commonService: CommonService,
    private util: Util,
    private userProfileService: UserProfileService,
  ) {}

  get thoughtSpotFilterGap() {
    const currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    const navbarHeight = 61;
    const newHeight = navbarHeight - currentScrollPosition;

    return newHeight <= 0 ? 0 : newHeight;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.matSidenav = `width: 360px; height: calc(100vh - ${this.thoughtSpotFilterGap}px)`;
  }

  getUnixTime(date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }

  updateFilters() {
    const formValues = this.filterForm?.value;
    this.filters = [];

    const isCustomDate = formValues?.customStartDate || formValues?.customEndDate;
    const isRelativeDate = formValues?.dateFilter;

    const getDateFilter = (date, operator) => ({
      columnName: 'filterDate',
      operator: operator,
      values: [this.getUnixTime(date)],
    });

    if (isCustomDate) {
      if (formValues?.customStartDate) {
        this.filters.push(getDateFilter(formValues.customStartDate, RuntimeFilterOp.GE));
      }

      if (formValues?.customEndDate) {
        this.filters.push(getDateFilter(formValues.customEndDate, RuntimeFilterOp.LE));
      }
    }

    if (isRelativeDate) {
      if (formValues?.startDate) {
        this.filters.push(getDateFilter(formValues.startDate, RuntimeFilterOp.GE));
      }

      if (formValues?.endDate) {
        this.filters.push(getDateFilter(formValues.endDate, RuntimeFilterOp.LE));
      }
    }

    if (formValues?.country?.length) {
      this.filters.push({
        columnName: 'country',
        operator: RuntimeFilterOp.IN,
        values: formValues?.country?.map((loc) => loc.id),
      });
    }

    if (formValues?.locations?.length) {
      this.filters.push({
        columnName: 'locations',
        operator: RuntimeFilterOp.IN,
        values: formValues?.locations?.map((loc) => loc.id),
      });
    }

    if (formValues?.geographyType?.name) {
      this.filters.push({
        columnName: 'geographyType',
        operator: RuntimeFilterOp.EQ,
        values: [formValues?.geographyType?.name],
      });
    }

    // pushing dynamic filters into an array.
    this.dynamicComponents?.forEach((component) => {
      const currentValue = this.filterForm.value[component.columnNameForBI];
      const operator = this.operatorByComponent[component.componentType];
      const isMultiSelect = component.componentType === 'multi-select';
      const isSingleSelect = component.componentType === 'single-select';
      const filter = {
        columnName: component.columnNameForBI,
        operator: operator,
      };

      if ((Array.isArray(currentValue) && currentValue?.length === 0) || !currentValue) return;

      if (isMultiSelect) {
        this.filters.push({
          ...filter,
          values: currentValue?.map((i) => i.name),
        });
      } else if (isSingleSelect) {
        const option = this.filterFormMetadata?.[component.columnNameForBI]?.options?.find(
          (c) => c.id === currentValue,
        );

        if (option !== -1) {
          this.filters.push({
            ...filter,
            values: [option?.name],
          });
        }
      } else {
        this.filters.push({
          ...filter,
          values: [currentValue],
        });
      }
    });

    this.initThoughtSpot();
  }

  getAllStaticDataForDynamicFilters() {
    const filteredFilters = this.dynamicComponents?.filter((i) => i?.filterControlsAPIId !== null);
    const observables: Array<Observable<any>> = filteredFilters?.map((filter) => {
      return this.commonService.callApi(
        'GET',
        `${environment.indexingApiUrl}/megamenufilter/${filter.filterControlsAPIId}`,
      );
    });

    combineLatest(observables).subscribe((data) => {
      data.forEach((itemData, index) => {
        const field = filteredFilters?.[index]?.columnNameForBI;
        const metadataField = this.filterFormMetadata[field];

        if (metadataField) {
          this.filterFormMetadata[field] = {
            ...this.filterFormMetadata[field],
            options: itemData.map((i) => ({ id: i.id, name: i.value })),
          };
        }
      });
    });
  }

  applyFilter() {
    this.updateFilters();
    this.drawer(false);
    this.filterValues = { ...this.filterForm.value };
  }

  drawer(v: boolean) {
    this.formService.rightSideDrawerHandler(v);
  }

  startDateEndDateValidator(filter_form: FormGroup): ValidatorFn {
    const startDate = filter_form.controls.customStartDate.value;
    const endDate = filter_form.controls.customEndDate.value;
    if (startDate && endDate) {
      if (startDate > endDate) {
        filter_form.controls.customStartDate.setErrors({ dateError: true });
      } else {
        filter_form.controls.customStartDate.setErrors(null);
      }
    }
    return null;
  }

  createFilterForm() {
    const defaultFields = {
      country: new FormControl(this.filterForm?.value?.country || []),
      geographyType: new FormControl(this.filterForm?.value?.geographyType || ''),
      locations: new FormControl(this.filterForm?.value?.locations || []),
      startDate: new FormControl(this.filterForm?.value?.startDate || ''),
      endDate: new FormControl(this.filterForm?.value?.endDate || ''),
      dateFilter: new FormControl(this.filterForm?.value?.dateFilter || ''),
      customStartDate: new FormControl(this.filterForm?.value?.customStartDate || ''),
      customEndDate: new FormControl(this.filterForm?.value?.customEndDate || ''),
    };

    if (this.dynamicComponents?.length) {
      const updatedMetadata = JSON.parse(JSON.stringify(thoughtspotFilterMetadata)); // in order to not mutate initial object with metadata
      this.dynamicComponents = this.dynamicComponents.sort((comp1, comp2) => comp1.position - comp2.position);
      this.dynamicComponents.forEach((component) => {
        const isMultiSelect = component.componentType === DynamicComponentType.MULTI_SELECT;

        defaultFields[component.columnNameForBI] = new FormControl(isMultiSelect ? [] : null);
        updatedMetadata[component.columnNameForBI] = {
          label: component.label,
          control: component.columnNameForBI,
          isFilterHidden: false,
        };

        if (isMultiSelect) {
          updatedMetadata[component.columnNameForBI].filterRetrieveAttr = 'name';
          updatedMetadata[component.columnNameForBI].filterSelectedAll = `All ${component.label}`;
        }

        if (component.componentType === DynamicComponentType.SINGLE_SELECT) {
          updatedMetadata[component.columnNameForBI].filterRetrieveAttr = 'id';
        }

        if (isMultiSelect || component.componentType === DynamicComponentType.SINGLE_SELECT) {
          updatedMetadata[component.columnNameForBI].options = [];
        }
      });
      this.filterFormMetadata = updatedMetadata;
    }

    return new FormGroup(defaultFields, {
      validators: [this.startDateEndDateValidator],
    });
  }

  onCountrySelectionChange() {
    this.filterForm.controls.country.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if (value?.length === 0 || value === '' || value === undefined) return;

      const countryIds = value?.map((i) => i.id);
      const filteredLocations = this.allLocations.filter((location) => countryIds.includes(location.countryId));
      this.filterFormMetadata.locations = {
        ...this.filterFormMetadata.locations,
        options: filteredLocations,
      };

      if (this.filterForm.value?.locations?.length !== 0) {
        // if user deselects country from the list, we clear selected locations with this country.
        const updatedLocations = this.filterForm.value.locations?.filter((loc) => countryIds.includes(loc.countryId));
        this.filterForm.controls.locations.patchValue(updatedLocations);
      }

      this.setLocations(this.filterForm.value.geographyType?.id);
    });
  }

  onGeographyTypeSelection = (data) => {
    this.filterForm.controls.locations.patchValue([]);
    this.setLocations(data?.option?.value?.id);
  };

  onSelectionChange = ({ value }) => {
    // reset custom date
    this.filterForm.get('customStartDate').reset();
    this.filterForm.get('customEndDate').reset();
    this.filterForm.get('startDate').reset();
    this.filterForm.get('endDate').reset();
    this.filterDateType = value;
  };

  onRelativeDateChange = (value) => {
    this.filterForm.controls.dateFilter.patchValue(value);
    const endDate = new Date().toLocaleDateString('en-US');
    const startDate = this.util.dateConverter(value);
    this.filterForm.controls.startDate.patchValue(startDate);
    this.filterForm.controls.endDate.patchValue(endDate);
  };

  ngOnInit(): void {
    this.drawer(false);
    this.isFilterDrawerOpened$ = this.formService.isRightSideDrawerOpened$;
    this.driveTimeService
      .getCompetitionStaticData()
      .pipe(filter(Boolean))
      .subscribe((data) => {
        this.staticData = data;
        this.allLocations = this.util.getLocations(data, true);
        data.forEach((d) => {
          if (d.dataType === 'Country') {
            this.filterFormMetadata.country = { ...this.filterFormMetadata.country, options: d.data.filter(
              countryItem => countryItem.name === 'United States' || countryItem.name === 'Canada')};
          }
        });
      });
  }

  ngAfterViewInit() {
    this.route.params.subscribe((params) => {
      this.isFilterShow = false;
      this.liveboardId = params.id;
      this.findMenuItem(params.megamenuItemId);
      this.filterForm?.value?.country.length === 0 && this.filterForm?.value?.country.push(
        this.filterFormMetadata.country.options.find(countryItem => countryItem.name === 'United States'));
      this.updateFilters();
      this.filterValues = { ...this.filterForm.value };
      setTimeout(() => {
        this.isFilterShow = true;
      }, 100);
    });
  }

  renderLiveboard() {
    const options = {
      frameParams: {
        width: '100%',
        height: '100%',
      },
      liveboardId: this.liveboardId,
      fullHeight: true,
      hideLiveboardHeader: true,
      runtimeFilters: this.filters,
      hiddenActions: this.disabledThoughtSpotActions,
      disabledActions: this.userProfileService.canDownload() ? [] : this.disabledActions,
    };
    this.viz = new LiveboardEmbed(this.containerDiv.nativeElement, options);
    this.viz.render();
    this.viz.on(EmbedEvent.Error, (err) => {
      const errMsg = err?.data?.error?.at(0)?.message?.toLowerCase();
      if (errMsg?.includes('no pinboard_answer_book found')) {
        this.snackBarService.showError('Liveboard was not found');
        this.router.navigate(['/']);
      }
    });
  }

  initThoughtSpot() {
    this.renderLiveboard();
  }

  getLocations(locationType: 'City' | 'CBSA' | 'county' | 'State') {
    const countryIds = this.filterForm.value.country?.map((i) => i.id);
    return this.allLocations?.filter((loc) => loc.type === locationType && countryIds.includes(loc.countryId));
  }

  setLocations(id: number) {
    this.filterFormMetadata.locations = {
      ...this.filterFormMetadata.locations,
    };
    if (id === 1) {
      this.filterFormMetadata.locations.options = this.getLocations('City');
    } else if (id === 2) {
      this.filterFormMetadata.locations.options = this.getLocations('CBSA');
    } else if (id === 3) {
      this.filterFormMetadata.locations.options = this.getLocations('county');
    } else if (id === 4) {
      this.filterFormMetadata.locations.options = this.getLocations('State');
    }
  }

  findMenuItem(megamenuItemId): void {
    this.megaMenuService.megaMenuURLData$.subscribe((data: any[]) => {
      if (Array.isArray(data) && data?.length > 0) {
        this.megamenuItem = data.filter((option) => option.id == megamenuItemId)[0];
        if (this.megamenuItem?.name) {
          this.menuTitle = this.megamenuItem.name;
        }
        if (this.megamenuItem?.description) {
          this.menuDescription = this.megamenuItem.description;
        }

        this.dynamicComponents = this.megamenuItem.megaMenuFilters;
        this.filterForm = this.createFilterForm();
        this.filterValues = { ...this.filterForm.value };
        this.onCountrySelectionChange();
        this.getAllStaticDataForDynamicFilters();
      }
    });
  }
}
