import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type StaticData = {
  dataType: string,
  data: Array<{
    id: number,
    pid?: number | string,
    name: string,
    metro?: string,
    date?: string,
  }>
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  sortAsc(arrayOfObjects, Key) {
    return arrayOfObjects?.sort((a, b) => (a[Key] < b[Key] ? -1 : 1));
  }
}
