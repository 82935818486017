import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ThemeTypes } from '../models/themes.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme$$ = new BehaviorSubject<'light' | 'dark'>('dark');
  public currentTheme$ = this.currentTheme$$.asObservable();
  public THEME_TYPES = ['light', 'dark'];


  constructor(@Inject(DOCUMENT) private document: Document) { }

  showDarkTheme(bool: boolean) {
    this.document.body.classList.toggle('dark', bool);
  }
  updateTheme(themeType: ThemeTypes): void {
    this.THEME_TYPES.map((theme) => {
      this.document.body.classList.remove(theme);
    });
    if (!this.THEME_TYPES.includes(themeType)) {
      this.document.body.classList.add('dark');
      this.currentTheme$$.next('dark');
      return;
    }
    this.currentTheme$$.next(themeType);
    this.document.body.classList.add(themeType);
    return;
  }

  getCurrentTheme(): ThemeTypes {
    return this.currentTheme$$.getValue();
  }
}
