import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CellTooltipDirective } from './cell-tooltip.directive';
import { HorizontalTableComponent } from './horizontal-table/horizontal-table.component';
import { I18nModule } from '@mycomp/ui-sdk/i18n';
import { LibCoreModule } from 'core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from 'shared';

import { FormGroupCellComponent } from './cell-components/cell-form-group/table-form-group-cell.component';
import { TableDateComponent } from './cell-components/date/table-date.component';
import { MatSelectEmeraldComponent } from './cell-components/mat-select/mat-select-emerald.component';
import { MatSingleselectComponent } from './cell-components/mat-singleselect/mat-singleselect.component';
import { TableCellEditComponent } from './cell-components/table-cell-edit/table-cell-edit.component';
import { MenuCellComponent } from './cell-components/table-menu-cell/table-menu-cell.component';
import { TextInputComponent } from './cell-components/text/text.component';
import { CustomPaginator } from './CustomPaginatorConfiguration';
import { TableDataOnlyComponent } from './table-dataOnly.component';
import { TableComponent } from './table.component';
import { StatusDirective } from './util/status.directive';
import { TrimStr } from './util/trimStr.pipe';
import {TypeOfPipe} from './util/typeof.pipe';
import { HighlightTextPipe } from './util/highlight-text.pipe';


@NgModule({
  declarations: [TableComponent, TableDataOnlyComponent, TypeOfPipe, TrimStr, FormGroupCellComponent, MenuCellComponent, TableCellEditComponent, MatSingleselectComponent, TextInputComponent, MatSelectEmeraldComponent, TableDateComponent, StatusDirective, CellTooltipDirective, HorizontalTableComponent, HighlightTextPipe],
  imports: [CommonModule, LibCoreModule, SharedModule, I18nModule, MatInputModule, MatTableModule, DragDropModule, MatFormFieldModule,
            MatListModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatMenuModule, MatIconModule,
            MatChipsModule, NgxSkeletonLoaderModule, MatSortModule, MatPaginatorModule, ScrollingModule, MatOptionModule, MatDatepickerModule, MatNativeDateModule, MatTooltipModule],
  exports: [TableComponent, TableDataOnlyComponent, TypeOfPipe, FormGroupCellComponent, MenuCellComponent, TableCellEditComponent, MatSingleselectComponent, HorizontalTableComponent, MatSelectEmeraldComponent, HighlightTextPipe],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }]
})
export class TableModule {}
