<ng-container *ngIf="cellType==='body'; else moreVertIconOnly">
    <mat-icon [matMenuTriggerFor]="i">more_vert</mat-icon>
    <mat-menu #i="matMenu">
        <ng-template matMenuContent let-item="element;">
            <div style="font-size: 20px; font-weight: 500; padding: 16px" *ngIf="title">
                {{title}}
            </div>
            <button mat-menu-item *ngFor="let item of menuItems" (click)="item.action(item.event)">
                <span>{{item.label}}</span>
            </button>
        </ng-template>
    </mat-menu>
</ng-container>
<ng-template #moreVertIconOnly>
    <mat-icon>more_vert</mat-icon>
</ng-template>