import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogConfig {
  title?: string;
  description?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  config: ConfirmDialogConfig = {
    title: 'Confirmation',
    description: 'Are you sure you want to proceed with this actions',
    confirmBtnText: 'Confirm',
    cancelBtnText: 'Cancel'
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig) { }

  ngOnInit(): void {
    // apply default values
    this.config = {
      ...this.config,
      ...this.data
    }
  }

}
