import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-dropdown-singleselect',
  templateUrl: './dropdown-singleselect.component.html',
  styleUrls: ['./dropdown-singleselect.component.scss']
})
export class DropdownSingleselectComponent implements OnInit {

  @Input() optionsData: any[];
  @Input() formControlName;
  @Input() labelName: string;

  @ViewChild('filterSearchTextBox') seachTextbox: ElementRef;

  value: string;

  optionsData$: Observable<any[]>;

  constructor(private commonService: CommonService) {

  }

  ngOnInit(): void {
    const filteredListAsc = this.commonService?.sortAsc(this.optionsData, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

  handleFilterOptions(event){
    const filterValue = event.target.value.toLowerCase();
    const filteredList = this.optionsData?.filter((option) => option?.name?.toLowerCase()?.indexOf(filterValue) !== -1);
    const filteredListAsc = this.commonService?.sortAsc(filteredList, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

  clearSearch(){
    console.log(this);
    this.seachTextbox.nativeElement.value = '';
    const filteredListAsc = this.commonService?.sortAsc(this.optionsData, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

}
