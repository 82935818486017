import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BenchMarkingComponent } from './bench-marking/bench-marking.component';

export const benchMarkingRoutesMapping = {
  HOME: 'home',
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: BenchMarkingComponent,
    data: { key: 'bench-mark/home', shouldDetach: false },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BenchMarkingRoutingModule {}
