export const geographyTypes = [
  {
    id: 1,
    name: 'City',
  },
  {
    id: 2,
    name: 'CBSA',
  },
  {
    id: 3,
    name: 'County',
  },
  {
    id: 4,
    name: 'State',
  },
];
export const thoughtspotFilterMetadata = {
  country: {
    label: 'Country',
    control: 'country',
    options: [],
    displaySearchIcon: false,
    displayDropdownIcon: true,
    isNewValueAllowed: false,
    isFilterHidden: false,
    filterRetrieveAttr: 'name',
    filterSelectedAll: 'All countries',
  },
  geographyType: {
    label: 'Geography type',
    control: 'geographyType',
    options: geographyTypes,
    displaySearchIcon: false,
    displayDropdownIcon: true,
    isNewValueAllowed: false,
    isFilterHidden: false,
    filterRetrieveAttr: 'name',
    isFilterRemovable: false,
  },
  locations: {
    label: 'Location(s)',
    control: 'locations',
    options: [],
    isFilterHidden: false,
    filterName: '',
    filterRetrieveAttr: 'name',
    filterSelectedAll: 'All locations',
  },
  startDate: {
    label: 'Start date',
    control: 'startDate',
    isFilterHidden: false,
    filterFormatValue: 'startDate - endDate',
    filterKeys: ['startDate', 'endDate', 'dateFilter'],
    filterDateFormat: 'MM/DD/YYYY',
    filterShowOnlyIf: [
      {
        key: 'startDate',
        shouldBeEmpty: false,
      },
      {
        key: 'endDate',
        shouldBeEmpty: false,
      },
      {
        key: 'customStartDate',
        shouldBeEmpty: true,
      },
      {
        key: 'customEndDate',
        shouldBeEmpty: true,
      },
    ],
  },
  endDate: {
    label: 'End date',
    control: 'endDate',
  },
  customStartDate: {
    label: 'Start date',
    control: 'customStartDate',
    isFilterHidden: false,
    filterFormatValue: 'customStartDate',
    filterKeys: ['customStartDate'],
    filterDateFormat: 'MM/DD/YYYY',
    filterShowOnlyIf: [
      {
        key: 'customStartDate',
        shouldBeEmpty: false,
      },
      {
        key: 'startDate',
        shouldBeEmpty: true,
      },
      {
        key: 'endDate',
        shouldBeEmpty: true,
      },
    ],
  },
  customEndDate: {
    label: 'End date',
    control: 'customEndDate',
    isFilterHidden: false,
    filterFormatValue: 'customEndDate',
    filterKeys: ['customEndDate'],
    filterDateFormat: 'MM/DD/YYYY',
    filterShowOnlyIf: [
      {
        key: 'customEndDate',
        shouldBeEmpty: false,
      },
      {
        key: 'startDate',
        shouldBeEmpty: true,
      },
      {
        key: 'endDate',
        shouldBeEmpty: true,
      },
    ],
  },
};
