import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OnlynumberDirective } from './numbersonly/numbersonly.directive';
import { ClickOutsideDirective } from 'core';
import { TrackInputDirective } from './trackInput/track-input.directive';

@NgModule({
  declarations: [OnlynumberDirective, TrackInputDirective],
  exports: [OnlynumberDirective, TrackInputDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}
