<form [formGroup]="driveTimeForm">
    <div class="row" style="height: 100%;width: 100%;">
        <div class="col-6" style="background: #F9F9FC;min-height: 87vh;max-height: 100vh;">
            <div class="row div-img-center">
                <img src="../../../assets/images/singlesite_logo.png" />
            </div>
        </div>
        <div class="col-6 mt-4">
            <div class="div-params-center">
                <div *ngIf="Apierrors.Addressnotfound" class="alert alert-danger row ml-10" role="alert">
                    {{Apierrors.Addressnotfound}}
                </div>
                <div class="row ml-10 mt-2 mb-3">
                    <div class="W-auto pl-0 pr-0"  style="font-weight: bold;">
                        Project Name:
                    </div>
                    <div class="col-3" >
                        {{projectName}}
                    </div>
                  </div>              
                <div class="row mt-3 ml-10 bold">
                    Select a Profile:
                </div>
                <div class="row ml-10 mt-1">
                    <select class="form-control" (change)="selectProfile($event)" style="width: 50%;"
                        formControlName="profile">
                        <option *ngFor="let item of profiles" [value]="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="row mt-5 ml-10 bold">
                    Select a Drive Time:
                </div>
                <div class="row mt-3 ml-10">
                    <div class="col-3 btn-group btn-group-toggle" *ngFor="let item of driveTimeOptions"
                        data-toggle="buttons" style="max-width: 18%;">
                        <button [class.active]="isDriveTimeSelected(item.value)" type="button"
                            class="btn btn-outline-success btn-sm"
                            (click)="selectDriveTime(item.value)">{{item.text}}</button>
                    </div>
                </div>
                <div class="row mt-5 ml-10 bold">
                    Search by: Select one 
                    <div *ngIf="information" id="infoSection">
                        <div class="col-md-5" style="display: flex;align-items: center;justify-content: center;">
                            <mat-icon class="mr-1" style="color:rgb(58, 155, 199); cursor: help;font-size: 18px;" title={{information}}>info</mat-icon>
                        </div>                       
                    </div> 
                </div>
                <div class="row mt-3 ml-10">
                    <div id="div-address" class="col-6"
                        [ngStyle]="{'border': isAddressValidationFailure == true ? 'red' : 'none' , 'border-style': isAddressValidationFailure == true ? 'dashed' : 'none' }" >
                        <div class="row mt-3 ml-10">
                            Address
                        </div>
                        <div class="row ml-10 mr-2">
                            <input id="addressid" class="form-control" type="text" formControlName="address"
                                (focus)="loadAddressDiv()" (input) = "disableDiv()" autocomplete="off"/>
                        </div>
                        <div class="row mt-3" style="margin-left: -5px;">
                            <div class="col-6">
                                City, State
                            </div>
                            <div class="col-6">
                                Zip
                            </div>
                        </div>
                        <div class="row mb-3" style="margin-left: -5px;">
                            <div class="col-6">
                                <input id="citystateid" [(ngModel)]="selectedMarket" [ngModelOptions]="{standalone: true}"
                                    class="form-control required" [typeahead]="markets" [typeaheadOptionsLimit]="7" 
                                    typeaheadOptionField="name" (typeaheadNoResults)="typeaheadNoResults($event)"
                                    (typeaheadOnSelect)="onMarketSelect($event)" (focus)="loadAddressDiv()"  (input) = "disableDiv()"
                                    autocomplete="off" />
                            </div>
                            <div class="col-6">
                                <input class="form-control" maxlength="5" formControlName="zip"
                                    (focus)="loadAddressDiv()" appOnlynumber  (input) = "disableDiv()" autocomplete="off"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 verticalLine">
                    </div>
                    <div id="div-latlong" class="col-4" style="margin-left: -25px;"
                        [ngStyle]="{'border': isLatLongValidationFailure == true ? 'red' : 'none' , 'border-style': isLatLongValidationFailure == true ? 'dashed' : 'none' }">
                        <div class="row mt-2 ml-10">
                            Latitude(y)
                        </div>
                        <div class="row ml-10 mr-2">
                            <input id="latid" class="form-control" type="text" formControlName="lat" (focus)="loadLatLongDiv()" 
                            (input) = "disableDiv()" autocomplete="off" />
                        </div>
                        <div class="row mt-2 ml-10">
                            Longitude(x)
                        </div>
                        <div class="row ml-10 mr-2">
                            <input id="longid" class="form-control" type="text" formControlName="lon" (focus)="loadLatLongDiv()" 
                            (input) = "disableDiv()" autocomplete="off" />
                        </div>
                    </div>
                </div>
                <div *ngIf="warning" id="warningsSection">
                    <div class="row mt-1" style="display: flex;align-items: center;justify-content: center;">
                        <mat-icon class="mr-3" style="color: #ffd740; cursor: pointer;">warning</mat-icon>
                        {{warning}}                                                             
                    </div>                       
                </div>
                <div class="row p-2" style="float: right;">
                    <button type="button" class="btn btn-primary mx-auto" style="width: 100px;" (click)="onSubmit()"
                        [disabled]="driveTimeForm.value.profile==null || driveTimeForm.value.driveTime===''">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row w-50" style="margin-top: -6vh;">
        <div class="col-md-7" style="text-align: left;">
            <p>Powered by : CBRE GeoNavigator</p>
        </div>
        <div class="col-md-5 text-md-right">
            <a href="#" [routerLink]="['/Disclaimer']" class="text-dark">Disclaimer</a>
            <span class="text-muted mx-2">|</span>
            <a href="#" [routerLink]="['/Logout']" class="text-dark">Log Out</a>
        </div>
    </div>
</form>