import { ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnInit, Optional, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

enum RadioOptions {
    relativeDate='relativeDate',
    customDate= 'customDate'
}

export enum RelativeDateOptions {
  week = 'Last 7 days',
  twoweeks = 'Last 2 weeks',
  month = 'Last 30 days',
  halfyear = 'Last 6 months',
  year = 'Last year',
  twoyears ='Last 2 years',
}

export const filterMetadata = {
    customStartDate: {
        label: 'Start date',
        control: 'interviewStartDate',
    },
    customEndDate: {
        label: 'End date',
        control: 'interviewEndDate',
    },
};

@Component({
    selector: 'lag-custom-date',
    templateUrl: './custom-date.component.html',
    styleUrls: ['./custom-date.component.css']
})
export class CustomDateComponent implements OnInit {
  @ViewChild('dynamicContent', { read: ViewContainerRef}) dynamicContent!: ViewContainerRef;
  @ViewChild('dcontent') dcontent!: ElementRef;
  @ViewChild('RelativeDates', { static: true }) RelativeDates!: TemplateRef<any>;
  @ViewChild('CustomDates', { static: true }) CustomDates!: TemplateRef<any>;
  @Input() form:FormGroup;
  @Input() field:any;
  
  radioOptions=[RadioOptions.relativeDate, RadioOptions.relativeDate];
  filterDateType:string;
  dateChipList = [
      RelativeDateOptions.week,
      RelativeDateOptions.twoweeks,
      RelativeDateOptions.month,
      RelativeDateOptions.halfyear,
      RelativeDateOptions.year,
      RelativeDateOptions.twoyears,
  ];
  selectedChip:string;
  filterFields=filterMetadata;

  constructor(@Optional() private formgroupDirective:FormGroupDirective, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
      this.form = this.form?? this.formgroupDirective.control;
  }

  onSelectionChange(event:MatRadioChange){
      this.filterDateType=event.value;
      
      if(this.filterDateType===RadioOptions.relativeDate){
          this.selectedChip=RelativeDateOptions.month;
          this.onRelativeDateChange(this.selectedChip);
      }
  }

  onRelativeDateChange(chip){
      this.form.get(filterMetadata.customStartDate.control).reset();
      this.form.get(filterMetadata.customEndDate.control).reset();
      if(this.filterDateType===RadioOptions.relativeDate){
          this.selectedChip=chip;
          this.form.get(filterMetadata.customStartDate.control).patchValue(this.dateConverter(chip));
          this.form.get(filterMetadata.customEndDate.control).patchValue(this.dateConverter('today'));
      }
  }

  dateConverter = (value: string) => {
      let targetDate;
      switch(value) {
      case RelativeDateOptions.week:
          targetDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() - 7);
          break;
      case RelativeDateOptions.twoweeks:
          targetDate = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() - 14);
          break;
      case RelativeDateOptions.month:
          targetDate = new Date(new Date().getFullYear(),new Date().getMonth() - 1,new Date().getDate());
          break;
      case RelativeDateOptions.halfyear:
          targetDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
          break;
      case RelativeDateOptions.year:
          targetDate = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
          break;
      case RelativeDateOptions.twoyears:
          targetDate = new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate());
          break;
      default:
          targetDate = new Date();
      }
      return targetDate?.toLocaleDateString('en-US');
  };
}
