<div class="dialog">
  <div class="header-content">
    <h2 mat-dialog-title class="header-title">Access denied</h2>
    <button class="header-close-button" (click)="dialog.closeAll()" mat-dialog-close="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="body-content">
    <div class="body-content__subtitle">
      You do not have permission to access this site. Please request for access or
      <a [href]="mailToHref" style="color: #262626; text-decoration: underline; text-decoration-color: #262626"
        >contact an administrator</a
      >
      for help.
    </div>
    <div [formGroup]="form" class="body-content__interests">
      <lag-text [istextarea]="true" [maxLength]="200" [field]="messageField" [hideRequiredMarker]="true"> </lag-text>
    </div>
  </div>
  <mat-dialog-actions style="justify-content: flex-end">
    <button class="button text" (click)="dialog.closeAll()">Cancel</button>
    <button class="button primary" (click)="getResponse()">Request access</button>
  </mat-dialog-actions>
</div>
