import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dt-report-footer',
  templateUrl: './report-footer.component.html',
  styleUrls: ['./report-footer.component.scss'],
})
export class DTReportFooterComponent implements OnInit {
  // dtCBREBuildOnAdvantageLogo: any = require('../../../assets/images/CBRE-Build-On-Advantage logo.png');
  // dtFooterLineLogo: any = require('../../../assets/images/DT-horizontal-line logo.png');
  @Input() pageNumber: string;
  @Input() totalPages: string;
  // @Output() footerLoadEvent: EventEmitter<number> = new EventEmitter<number>();
  // @Input() loadOnHold: boolean = false;

  constructor() {}

  ngOnInit() {
    //    this.loadOnHold = true;
    //    this.footerLoadEvent.emit(this.pageNumber);
  }
}
