import { HttpErrorResponse, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SnackbarService } from "@core/snackBar.notification.service";
import { catchError, throwError } from "rxjs";
import { SKIP_DEFAULT_ERROR_HANDLER } from "src/app/app.module";

@Injectable()
export class GlobalHTTPErrorHandler implements HttpInterceptor {
  constructor(
    private snackbarService: SnackbarService,
  ) {}
  intercept(request: HttpRequest<any>, next: any): any {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(!request.headers.has(SKIP_DEFAULT_ERROR_HANDLER)) {
          this.snackbarService.showError( error?.error?.message || error?.message ||  'Unexpected error');
        }
        return throwError(error);
      })
    );
  }
}