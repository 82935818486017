<div class="dialog">
    <form [formGroup]="form">
   
            <button mat-icon-button class="close-button" (click)="dialog.closeAll()" mat-dialog-close="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
    
            <h2 mat-dialog-title style="font-weight:500">{{(isGridAvailable && (dataSource?.length > 0))?'Imported records':data.headerText}}</h2>
      
        <!-- <span *ngIf="isDupicatesFound" style="color:red">Please review the duplicate records.</span> -->
        <!-- <div class="warning" *ngIf="isGridAvailable && errorCount > 0"> Resolve all errors before import. {{errorCount}}</div> -->

        <mat-dialog-content *ngIf ="!isGridAvailable" class="mat-typography">
            <div *ngIf="!isImportInProgress" class="container modalContainer" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #inputFile id="inputFile" (change)="fileBrowseHandler($event)" />
                <!-- <mat-icon matPrefix>cloud_upload</mat-icon> -->
                <h2 class="upload-h2" for="inputFile">Upload file</h2>
                <h4 class="upload-text">Drag and drop here or <span class="files-list template-link"> browse file</span></h4>
                <h4 class="upload-text">Accepted file types: XLSX</h4>

            </div>
            <mat-progress-spinner *ngIf="isImportInProgress" class="example-margin" color="primary" mode="indeterminate" [value]="40">> </mat-progress-spinner>
            <div class="files-list">
                <div class="d-flex justify-content-between">
                    <div class="button-container justify-content-start">
                        <div class="template-link" (click)="downloadTemplate()">Download template</div>
                     </div>
                    <div class="button-container justify-content-end">
                        <button class="button text " [ngClass]="!isImportInProgress  ? 'cancel-import'  : ''" [disabled]="!isImportInProgress" (click)="deleteFile()">Cancel import</button>
                     </div>
                </div>
      
            </div>
        </mat-dialog-content>

        <mat-dialog-content *ngIf="isGridAvailable && (dataSource?.length > 0)" class="matContent">
            <div class="example-container">
                <lib-table 
                    [data]="dataSource" 
                    [columns]="columns" 
                    [title]="'Review errors'"
                    [subTitle]="isGridAvailable && errorCount > 0?'Resolve all errors before import (' + errorCount + ')':''" 
                    [subTitleStyle]="{'color': '#FF543E','font-weight':'400'}"
                    [stickyHeader]="true" 
                    [pagination]="true"  
                    [formGroupSort]="true"
                    [pageSizeOptions]="[10,20,30]" 
                    [tableHeight]="'350px'"
                    [pageSize]="10"  
                    [setRowStyle]="setRowStyle">
                </lib-table>
                <div *ngFor="let controlName of ['']"></div>
            </div>
        </mat-dialog-content>


        <mat-dialog-actions *ngIf="isGridAvailable && dataSource?.length" align="end">
            <button mat-button style="color: #003F2D;font-weight: 500;
            font-size: 16px;" [mat-dialog-close]="true" (click)="dialogRef?.close()"> Cancel import</button>
            <button
                mat-button
                matSuffix
                type="button"
                class="button secondary"
                (click)="exportErrorsTemplateTable('err')"
                [disabled]="errorCount === 0"
            >Export errors</button>
            <button mat-button matSuffix type="button" class="button primary" [disabled]="dataSource?.invalid || errorCount > 0"
                (click)="uploadValidData()" > Upload 
            </button>
        </mat-dialog-actions>

        <div class="warning" *ngIf="isInavlidFile"> Please upload the valid file to import data.</div>

    </form>
</div>

<ng-template #deleteRowBtn let-key="key" let-rowData="rowData" let-cellType="cellType">
    <mat-icon  style="cursor: pointer; font-size: 20px; margin-left: -2px;color:#003F2D"
    (click)="cellType === 'head' ? undefined : deleteRow(rowData)">delete_outline</mat-icon>
</ng-template>

<ng-template #tableFormGroup let-key="key" let-rowData="rowData" let-cellType="cellType" let-columnKey="columnKey" let-rowIndex="rowIndex">
        <table-cell-edit 
            [nonEditableColumns]="nonEditableColumns[key]"
            [rowData]="rowData" 
            [key]="key" 
            [cellStyle]="cellStyles[key]"
            [columnOptions]="columnOptions"
            [isEmerald]="true"
            [required]="requiredFields[key]" 
            [columnLabel]="getColumnLabel(key)"
            [cellType]="extraFieldsWithData"
            (onEditStart)="editVal($event, key)" 
            (onEditSubmit)="doneVal($event)" 
            (onEditCancel)="cancelVal($event)">
        </table-cell-edit>
        <mat-error *ngIf="rowData.get(key)?.errors?.pattern">Invalid data</mat-error>
</ng-template>
