<div class="snackbar-warning"> 
    <div class="d-flex align-items-center">
        <fa-icon [icon]="explamationTriangle"  style="color: #ffd740;"></fa-icon>
    </div>
    <div class="d-flex align-items-center">
        <p style="color: #B85F00; font-size: 16px; margin-bottom: 0px;">{{data}}</p>
    </div>
    <div class="d-flex align-items-center">
        <button mat-icon-button class="close" (click)="closeSnackbar()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
</div>
