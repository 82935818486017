<form [formGroup]="form" class="form">
     <ng-container [ngTemplateOutlet]="childElement" [ngTemplateOutletContext]="{childElements:fields$|async, formGroup:form}">
    </ng-container>
</form>


<ng-template #childElement let-elements="childElements" let-form="formGroup">
    <ng-container *ngFor="let element of model|filterBy:'parentId':elements?.id">
        <ng-container [ngSwitch]="element?.type">

          <ng-container *ngSwitchCase="'div'" >

            <div class="{{element.className}}">
               {{element.text}}
               <ng-container *ngIf="!element?.dynamicContent">
                  <ng-container [ngTemplateOutlet]="childElement" [ngTemplateOutletContext]="{childElements:element, formGroup:form}" ></ng-container>
               </ng-container>

               <ng-container *ngIf="element?.dynamicContent">
                  <ng-container *ngIf="element?.templateRef==='operator'" #operator [ngTemplateOutlet]="childElement" [ngTemplateOutletContext]="{childElements:element, formGroup:form}" ></ng-container>
                  <ng-container *ngIf="element?.templateRef==='value'" #value [ngTemplateOutlet]="childElement" [ngTemplateOutletContext]="{childElements:element, formGroup:form}" ></ng-container>
               </ng-container>

            </div>
          </ng-container>

          <lag-expansion-panel *ngSwitchCase="'expansion-panel'" [title]="element.text" [expanded]="element.expanded">
            <ng-container [ngTemplateOutlet]="childElement" [ngTemplateOutletContext]="{childElements:element, formGroup:form}" ></ng-container>
          </lag-expansion-panel>

          <ng-container *ngSwitchCase="'icon'">
            <lag-icon [iconName]="element.iconName" [iconColor]="element.iconColor"></lag-icon>
          </ng-container>

          <ng-container *ngSwitchCase="'nested-form'">
             <ng-container #nestedForm> </ng-container>
         </ng-container>

         <ng-container *ngSwitchCase="'hidden'"></ng-container>

         <ng-container *ngSwitchDefault>
            <lag-dynamic-field [field]="element" [formGroup]="form" [unique_key]="unique_key" [parent]="parent" [attr.id]="element.id_attr"></lag-dynamic-field>
         </ng-container>
      </ng-container>
   </ng-container>
</ng-template>
