import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { faGreaterThanEqual, faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DialogService } from '../../../core/dialog.service';
import { LoggingService } from '../../../core/services/logging.service';
import { SnackbarService } from '../../../core/snackBar.notification.service';
import { SpinnerService } from '../../../core/spinner.service';
import { MarketIndexService } from '../../../marketindex-report/marketindex.service';
import { Market } from '../../../models/market';
import { Profile } from '../../../models/profile';
import { DriveTimeService } from '../../../services/drivetime/drivetime.service';
import { Util } from '../../../util/Util';
import { BenchMarkQueryParams } from '../models/benchmark-query-params.model';
import { FilterDetails } from '../models/filterDetails';
import { Filters } from '../models/filters';
import { States } from '../models/states';
import { TimeZones } from '../models/timeZones';
import { BenchmarkingExcelService } from '../services/bench-marking-excel-service';
import { UserProfileService } from '@services/userProfile.service';

@Component({
  selector: 'app-bench-marking',
  templateUrl: './bench-marking.component.html',
  styleUrls: ['./bench-marking.component.scss'],
})
export class BenchMarkingComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  initialRange: ListRange = { start: 0, end: 10 } as ListRange;

  isShowing = true;
  panelOpenState = false;
  marketSearchResults: Observable<Market[]>;
  baseLineSiteControl = new FormControl();
  profileControl = new FormControl();

  prevDtSelection;

  visible = true;
  selectable = true;
  removable = true;

  allDataBaseMarkets: Market[] = [];
  cities: Market[] = [];
  metros: Market[] = [];
  filteredMarkets: Market[] = [];
  siteSelectionMarkets: Market[] = [];
  profiles: Profile[] = [];
  filteredProfiles: Observable<Profile[]>;

  step = 6;

  baseLineOptions: any = [
    { name: 'National Average', value: 'NATIONAL' },
    { name: 'Data Set Average', value: 'SITEAVERAGES' },
    { name: 'Data Set Median', value: 'SITEMEDIANS' },
    { name: 'Selected Site', value: 'SITEASBASELINE' },
  ];
  baseLineSelection = 'NATIONAL';
  driveTimeOptions: any = [
    { name: '15 Min Drive Time', value: '15' },
    { name: '30 Min Drive Time', value: '30' },
    { name: '45 Min Drive Time', value: '45' },
    { name: '60 Min Drive Time', value: '60' },
    { name: '10 Mile Radius', value: '10.0 Miles' },
    { name: '20 Mile Radius', value: '20.0 Miles' },
    { name: '30 Mile Radius', value: '30.0 Miles' },
    { name: 'Metro', value: 'Metro' },
  ];
  defaultDriveTime = 'Metro';

  filterOptions: any;
  defaultFilterOptions: any = [
    { name: 'Time Zone', value: 'timezones', operation: 'inList', list: [] },
    { name: 'States', value: 'states', operation: 'inList', list: [] },
    {
      name: 'Labor Force',
      value: 'laborforce',
      operation: 'inRange',
      value1: '',
      value2: '',
    },
    {
      name: 'Population',
      value: 'population',
      operation: 'inRange',
      value1: '',
      value2: '',
    },
    { name: 'Remove Micros', value: 'removeMicros' },
    { name: 'Select Metros', value: 'selectMetros' },
    { name: 'All Markets', value: 'allmarkets' },
  ];

  filterSelection = 'allmarkets';

  @ViewChild('additionalMarkets') addSearchTextBox: ElementRef;
  @ViewChild('citiesMarkets') citiesSearchTextBox: ElementRef;
  @ViewChild('filterSelectedMarketsTextBox') filterSelectedMarketsTextBox: ElementRef;

  addSelectFormControl = new FormControl();
  addSearchTextboxControl = new FormControl();
  addSelectedMarkets: string[] = [];
  addFilteredOptions: Observable<any[]>;

  citiesSelectFormControl = new FormControl();
  citiesSearchTextboxControl = new FormControl();
  citiesSelectedMarkets: string[] = [];
  citiesFilterSelectedMarketIds: number[] = [];
  citiesFilteredOptions: Observable<any[]>;

  filterSelectFormControl = new FormControl();
  filterSearchTextboxControl = new FormControl();
  filterSelectedMarkets: string[] = [];
  filterSelectedMarketIds: number[] = [];
  filterFilteredOptions: Observable<any[]>;

  benchMarkingResponse: any;

  lessThanEqual = faLessThanEqual;
  greaterThanEqual = faGreaterThanEqual;

  filters: Filters = new Filters();
  prevStates;
  prevTimeZones;

  @ViewChild('statesSelect') statesSelect: MatSelect;
  allStatesSelected = false;

  @ViewChild('allMarketsFilterSelect') allMarketsFilterSelect: MatSelect;
  @ViewChild('allCitiesMarketsSelect') allCitiesMarketsSelect: MatSelect;
  allMarketsFilterSelected = false;
  allCitiesMarketsSelected = false;

  constructor(
    private router: Router,
    private util: Util,
    private driveTimeService: DriveTimeService,
    private spinnerService: SpinnerService,
    private activatedrouter: ActivatedRoute,
    private loggingService: LoggingService,
    private marketIndexServvice: MarketIndexService,
    private benchMarkExcelService: BenchmarkingExcelService,
    public benchMarkSelectionCriteria: BenchMarkQueryParams,
    public timeZones: TimeZones,
    public states: States,
    private dialogService: DialogService,
    private snackBarService: SnackbarService,
    public userProfileService: UserProfileService,
  ) {}

  ngOnInit() {
    this.benchMarkSelectionCriteria.profileName = ''; // select default profile as "chose a profile"
    this.benchMarkSelectionCriteria.driveTime = this.defaultDriveTime; // select default drive time (30)
    this.benchMarkSelectionCriteria.baseLine = this.baseLineSelection; // select National Baseline as default baseline
    // this.benchMarkSelectionCriteria.baseLineMarket = "";
    this.resolve();
    this.getMarkets();
    this.filterOptions = JSON.parse(JSON.stringify(this.defaultFilterOptions));
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  resolve() {
    this.activatedrouter.data.subscribe((data) => {
        // this.spinnerService.show('Loading Benchmarking module ...');
        this.getProfiles();
    });
  }

  getMarkets() {
    this.spinnerService.show('Loading markets...');

    this.driveTimeService.getMarkets().subscribe(
      (res) => {
        this.allDataBaseMarkets = this.util.sortArrayByPropertyName(res, 'name');

        // filter the markets by Metro as Metro is the default option now
        this.cities = this.allDataBaseMarkets.filter((x) => x.locationType == 'Metro');
        this.metros = JSON.parse(JSON.stringify(this.cities)); // deep copy for metro selection from filters

        this.prevDtSelection = 'Metro';

        this.siteSelectionMarkets = JSON.parse(JSON.stringify(this.cities)); // deep copy for site selection as base line site

        this.marketSearchResults = this.baseLineSiteControl.valueChanges.pipe(
          startWith(''),
          map((value) => this.baseLineSiteCompleteFilter(value)),
        );

        // for filtered markets
        this.setUpFilterOnMetros();

        // for Additional markets
        this.setUpFilterOnAddFilters();

        // for cities filter
        this.setUpFilterOnCitiesFilters();

        this.allMarketFilter();

        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        console.log('TCL: BenchMarking -> getMarkets -> error-comp', error);
        this.loggingService.logError(
          this.util.getLogObject(error.toString(), 'error while getting markets', 'ParamsComponent.getMarkets'),
        );
        if (error.status == 0) {
          this.router.navigate(['/Error']);
        }
      },
    );
  }

  setUpFilterOnMetros() {
    this.filterFilteredOptions = this.filterSearchTextboxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this.filterMarketsSearchFilter(name)),
    );
  }

  setUpFilterOnAddFilters() {
    this.addFilteredOptions = this.addSearchTextboxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this.addMarketsSearchFilter(name)),
    );
  }

  setUpFilterOnCitiesFilters() {
    this.citiesFilteredOptions = this.citiesSearchTextboxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this.citiesMarketsSearchFilter(name)),
    );
  }

  getProfiles() {
    this.driveTimeService.getProfiles().subscribe(
      (res) => {
        res = res.filter((x) => x.visibleInBenchmark);

        this.profiles = this.util.sortArrayByPropertyName(res, 'profileName');

        // var profile = this.defaultProfile;

        // this.profiles = [this.defaultProfile, ...this.profiles]

        this.filteredProfiles = this.profileControl.valueChanges.pipe(
          startWith(''),
          map((value) => this.profileAutoCompleteFilter(value)),
        );

        this.profiles.forEach((element) => {
          element.profileName = element.profileName.replace('GeoSkill - ', '');
        });
      },
      (error) => {
        this.spinnerService.hide();
        console.log('TCL: Bench-Marking component -> getProfiles -> error-comp', error);
        if (error.status == 0) {
          this.router.navigate(['/Error']);
        }
      },
    );
  }

  private citiesMarketsSearchFilter(name: string): Market[] {
    const filterValue = name.toLowerCase();
    this.citiesSelectFormControl.patchValue(this.citiesSelectedMarkets);
    const filteredList = this.cities.filter((option) => option.name.toLowerCase().indexOf(filterValue) !== -1);
    return filteredList;
  }

  private addMarketsSearchFilter(name: string): Market[] {
    const filterValue = name.toLowerCase();
    this.addSelectFormControl.patchValue(this.addSelectedMarkets);
    const filteredList = this.cities.filter((option) => option.name.toLowerCase().indexOf(filterValue) !== -1);
    return filteredList;
  }

  private filterMarketsSearchFilter(name: string): Market[] {
    const filterValue = name.toLowerCase();
    this.filterSelectFormControl.patchValue(this.filterSelectedMarkets);
    const filteredList = this.metros.filter((option) => option.name.toLowerCase().indexOf(filterValue) !== -1);
    return filteredList;
  }

  private baseLineSiteCompleteFilter(value: string): Market[] {
    const filterValue = value.toLowerCase();
    return this.siteSelectionMarkets.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  private profileAutoCompleteFilter(value: string): Profile[] {
    const filterValue = value.toLowerCase();
    return this.profiles.filter((profile) => profile.profileName.toLowerCase().includes(filterValue));
  }

  // Remove from selected values based on uncheck
  handleAddMarketsChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      const index = this.addSelectedMarkets.indexOf(event.source.value);
      this.addSelectedMarkets.splice(index, 1);
    } else if (event.isUserInput && event.source.selected == true) {
      this.addSelectedMarkets.push(event.source.value);
    }
  }

  // Remove from selected values based on uncheck
  handleCitiesMarketsChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      const index = this.citiesSelectedMarkets.indexOf(event.source.value);
      this.citiesSelectedMarkets.splice(index, 1);
    } else if (event.isUserInput && event.source.selected == true) {
      this.citiesSelectedMarkets.push(event.source.value);
    }

    if (this.citiesSelectedMarkets != undefined && this.citiesSelectedMarkets.length > 0) {
      // check if there are any items unselected and set check box "Select All" accordingly
      if (this.citiesSelectedMarkets.length != this.cities.length) {
        this.allCitiesMarketsSelected = false;
      } else {
        this.allCitiesMarketsSelected = true;
      }
      // show filter criteria to the user
      this.setFilterDetails('selectCities', 'Selected Cities (' + this.citiesSelectedMarkets.length + ')');
      // get cities selected by user
      const tempMarketList = this.cities.filter((market) => {
        return this.citiesSelectedMarkets.some((item) => item === market.name);
      });
      this.citiesFilterSelectedMarketIds = tempMarketList.map((market) => market.id);
    } else if (this.citiesSelectedMarkets != undefined && this.citiesSelectedMarkets.length == 0) {
      this.citiesSelectedMarkets = [];
      this.citiesFilterSelectedMarketIds = [];
      this.removeItemFromFilterDetails('selectCities');
    }
  }

  handleFilterMarketsChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      const index = this.filterSelectedMarkets.indexOf(event.source.value);
      this.filterSelectedMarkets.splice(index, 1);
    } else if (event.isUserInput && event.source.selected == true) {
      this.filterSelectedMarkets.push(event.source.value);
    }

    if (this.filterSelectedMarkets != undefined && this.filterSelectedMarkets.length > 0) {
      // check if there are any items unselected and set check box "Select All" accordingly
      if (this.filterSelectedMarkets.length != this.cities.length) {
        this.allMarketsFilterSelected = false;
      } else {
        this.allMarketsFilterSelected = true;
      }
      // show filter criteria to the user
      this.setFilterDetails('selectMetros', 'Selected Metros (' + this.filterSelectedMarkets.length + ')');
      // get metros selected by user
      const tempMarketList = this.metros.filter((market) => {
        return this.filterSelectedMarkets.some((item) => item === market.name);
      });
      // Extract the city ids if the geographic boundary selected is non metro.
      if (this.benchMarkSelectionCriteria.driveTime != 'Metro') {
        const citiesToAdd = this.cities.filter((market) => {
          return tempMarketList.some((item) => item.metroId === market.metroId);
        });
        this.filterSelectedMarketIds = citiesToAdd.map((market) => market.id);
      } else {
        this.filterSelectedMarketIds = tempMarketList.map((market) => market.id);
      }
    }
  }

  openedCitiesChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.citiesSearchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.citiesSearchTextBox.nativeElement.focus();
    } else {
      this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
      this.cdkVirtualScrollViewport.setRenderedRange(this.initialRange);
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.addSearchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.addSearchTextBox.nativeElement.focus();
    } else {
      this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
      this.cdkVirtualScrollViewport.setRenderedRange(this.initialRange);
    }
  }

  openedMetro(e) {
    // Set search textbox value as empty while opening selectbox
    this.filterSearchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.filterSelectedMarketsTextBox.nativeElement.focus();
    } else {
      this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
      this.cdkVirtualScrollViewport.setRenderedRange(this.initialRange);
    }
  }

  // Clearing search textbox value
  clearSearch(event, name) {
    event.stopPropagation();
    if (name == 'filter') {
      this.filterSearchTextboxControl.patchValue('');
    } else if (name == 'cities') {
      this.citiesSearchTextboxControl.patchValue('');
    } else {
      this.addSearchTextboxControl.patchValue('');
    }
  }

  clearAdditionalMarkets() {
    if (this.addSelectedMarkets.length > 0) {
      this.addSelectedMarkets.splice(0);
    }
    this.addSelectedMarkets = [];
  }

  clearFilteredMarkets() {
    if (this.filterSelectedMarkets.length > 0) {
      this.filterSelectedMarkets.splice(0);
    }
    this.allMarketsFilterSelected = false;
    this.filterSelectedMarkets = [];
    this.filterSelectedMarketIds = [];
    this.addSearchTextboxControl.patchValue('');
    this.setUpFilterOnMetros();
  }

  clearCitiesMarkets() {
    if (this.citiesSelectedMarkets.length > 0) {
      this.citiesSelectedMarkets.splice(0);
    }
    this.allMarketsFilterSelected = false;
    this.allCitiesMarketsSelected = false;
    this.citiesSelectedMarkets = [];
    this.citiesFilterSelectedMarketIds = [];
    this.setUpFilterOnCitiesFilters();
  }

  clearFilterItem(filterAttributeName: string) {
    if (filterAttributeName == 'selectMetros') {
      this.removeItemFromFilterDetails(filterAttributeName);
      this.clearFilteredMarkets();
    } else if (filterAttributeName == 'selectCities') {
      this.removeItemFromFilterDetails(filterAttributeName);
      this.clearCitiesMarkets();
    } else if (this.filters.details.length > 1) {
      this.removeItemFromFilterDetails(filterAttributeName);
      this.applyAllSelectedFilters();
      this.resetFilterItem(filterAttributeName); //  reset the filter item
      if (filterAttributeName == 'states') {
        this.allStatesSelected = false;
      }
    } else {
      // this condition means that there is only one filter applied which is being cleared so call clearAllFilters
      this.clearAllFilters();
    }
  }

  clearAllFilters() {
    this.filters.details = []; // reset existing filters
    // this.filteredMarkets = JSON.parse(JSON.stringify(this.markets));                // deep copy the total markets again
    this.filteredMarkets = [];
    this.filterSelectedMarkets = []; // clear filtered markets by name...
    this.filterSelectedMarketIds = [];
    this.allMarketsFilterSelected = false;
    this.citiesSelectedMarkets = [];
    this.citiesFilterSelectedMarketIds = [];
    this.allCitiesMarketsSelected = false;
    this.filterOptions = JSON.parse(JSON.stringify(this.defaultFilterOptions)); // restore filter defaults
    this.filterSelection = '';
    this.filterSelection = 'timezones'; // show default filter selection item
    this.allStatesSelected = false;
    if (this.statesSelect != undefined && this.statesSelect.options != undefined) {
      this.statesSelect.options.forEach((item: MatOption) => item.deselect()); // to remove any states that were selected...
    }
    this.addCitiesFilterOption();
  }

  removeMarket(market: string): void {
    const index = this.addSelectedMarkets.indexOf(market);

    if (index >= 0) {
      this.addSelectedMarkets.splice(index, 1);
    }
  }

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  private clearMarketsAndFilters() {
    this.clearAdditionalMarkets();
    this.clearFilteredMarkets();
    this.clearAllFilters();
  }

  addCitiesFilterOption() {
    if (this.benchMarkSelectionCriteria.driveTime != 'Metro') {
      const selectCitiesIndex = this.filterOptions.findIndex((filter) => filter.value == 'selectCities');
      if (selectCitiesIndex < 0) {
        const selectCities = { name: 'Select Cities', value: 'selectCities' };
        const itemIndex = this.filterOptions.findIndex((filter) => filter.value == 'selectMetros');
        this.filterOptions.splice(itemIndex + 1, 0, selectCities);
      }
    }
  }

  handleDriveTimeChange(event) {
    if (event.value == 'Metro') {
      if (this.prevDtSelection !== event.value) {
        this.clearMarketsAndFilters();
      }
      this.cities = this.allDataBaseMarkets.filter((x) => x.locationType == 'Metro');
      this.allMarketFilter();
    } else {
      if (this.prevDtSelection === 'Metro') {
        this.clearMarketsAndFilters();
      }
      this.addCitiesFilterOption();
      this.cities = this.allDataBaseMarkets.filter((x) => x.locationType == 'City');

      if (this.prevDtSelection === 'Metro') {
        this.allMarketFilter();
      }
    }

    this.prevDtSelection = event.value; // set the selection into the variable

    this.siteSelectionMarkets = JSON.parse(JSON.stringify(this.cities));

    if (this.filters.details != undefined && this.filters.details.length > 0) {
      // this.applyAllSelectedFilters();
    }
  }

  setFilterSelection(event) {
    if (event.value == 'allmarkets') {
      this.allMarketFilter();
    } else if (event.value == 'removeMicros') {
      this.setFilterDetails('removeMicros', 'Removed Micros');
      this.applyAllSelectedFilters();
    } else {
      // if all markets filter was already applied then remove it...
      if (this.filters.details != undefined) {
        const tempArray = this.filters.details.filter((item) => item.marketAttribute == 'allmarkets'); // check if all markets filter was applied...
        if (tempArray != undefined && tempArray.length == 1) {
          this.clearAllFilters();
        }
      }
    }
    this.filterSelection = event.value;
  }

  filterStates(event, states) {
    if (event == false) {
      if (this.hasSelectionChanged(this.prevStates, states)) {
        if (states.length > 0) {
          if (states.length <= 5) {
            this.setFilterDetails('states', 'States (' + states.join(', ') + ') ');
          } else {
            this.setFilterDetails('states', 'States (' + states.length + ') ');
          }
          this.applyAllSelectedFilters();
        } else {
          this.removeItemFromFilterDetails('states');
        }
      }
    } else {
      this.prevStates = states;
    }
  }

  toggleAllStatesSelection() {
    if (this.allStatesSelected) {
      this.statesSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.statesSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllFilteredMarketsSelection() {
    if (this.allMarketsFilterSelected) {
      // this.allMarketsFilterSelect.options.forEach((item: MatOption) => item.select());
      this.filterSelectedMarkets = this.cities.map((market) => market.name);
      this.filterSelectFormControl.patchValue(this.filterSelectedMarkets);
    } else {
      this.allMarketsFilterSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllCitiesMarketsSelection() {
    if (this.allCitiesMarketsSelected) {
      // this.allMarketsFilterSelect.options.forEach((item: MatOption) => item.select());
      this.citiesSelectedMarkets = this.cities.map((market) => market.name);
      this.citiesSelectFormControl.patchValue(this.citiesSelectedMarkets);
    } else {
      this.allCitiesMarketsSelect.options.forEach((item: MatOption) => item.deselect());
      if (this.citiesSelectedMarkets.length > 0) {
        this.citiesSelectedMarkets.splice(0);
        this.removeItemFromFilterDetails('selectCities');
      }
    }
  }

  optionClick() {
    let newStatus = true;
    this.statesSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allStatesSelected = newStatus;
  }

  filterTimeZones(event, timeZones) {
    if (event == false) {
      if (this.hasSelectionChanged(this.prevTimeZones, timeZones)) {
        this.setFilterDetails('timezones', 'Time Zones (' + timeZones.join(', ') + ') ');

        // let timeZoneFullNames = timeZones.map((shortName) => {
        //   return this.timeZones.list.filter(longName => longName.value == shortName.value)[0].name.toLowerCase();
        // });
        // this.filteredMarkets = this.filteredMarkets.filter( market => market.timeZone !=null && timeZoneFullNames.includes(market.timeZone.toLowerCase()));
        this.applyAllSelectedFilters();
      }
    } else {
      this.prevTimeZones = timeZones;
    }
  }

  hasSelectionChanged(prevSelecton, currentSelection) {
    if (prevSelecton == undefined || prevSelecton.length == 0) {
      if (currentSelection != undefined && currentSelection.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      const arrEqual =
        prevSelecton.length === currentSelection.length &&
        prevSelecton.every((item, index) => currentSelection[index] === item);
      return !arrEqual;
    }
  }

  filterLaborForce() {
    let displayText = '';
    if (
      this.filterOptions[2].operation != 'inRange' &&
      this.filterOptions[2].value1 != undefined &&
      this.filterOptions[2].value1 != ''
    ) {
      // this.applyNumberFilters(this.benchMarkSelectionCriteria.driveTime, this.filterOptions[2].operation, 'laborForce', this.filterOptions[2].value1, this.filterOptions[2].value2);
      const temp = this.filterOptions[2].operation == 'lessThan' ? '<=' : '>=';
      this.filterOptions[2].value1 = this.numberFormatForFilterDisplay(this.filterOptions[2].value1);
      displayText = 'Labor force ' + temp + ' ' + this.filterOptions[2].value1;
      this.setFilterDetails('laborforce', displayText);
      this.applyAllSelectedFilters();
    } else if (
      this.filterOptions[2].operation == 'inRange' &&
      this.filterOptions[2].value1 != '' &&
      this.filterOptions[2].value2 != ''
    ) {
      this.filterOptions[2].value1 = this.numberFormatForFilterDisplay(this.filterOptions[2].value1);
      this.filterOptions[2].value2 = this.numberFormatForFilterDisplay(this.filterOptions[2].value2);
      displayText = 'Labor force (' + this.filterOptions[2].value1 + '-' + this.filterOptions[2].value2 + ')';
      this.setFilterDetails('laborforce', displayText);
      this.applyAllSelectedFilters();
    }
  }

  filterPopulation() {
    let displayText = '';
    if (
      this.filterOptions[3].operation != 'inRange' &&
      this.filterOptions[3].value1 != undefined &&
      this.filterOptions[3].value1 != ''
    ) {
      // this.applyNumberFilters(this.benchMarkSelectionCriteria.driveTime, this.filterOptions[3].operation, 'population', this.filterOptions[3].value1, this.filterOptions[3].value2);
      const temp = this.filterOptions[3].operation == 'lessThan' ? '<=' : '>=';
      this.filterOptions[3].value1 = this.numberFormatForFilterDisplay(this.filterOptions[3].value1);
      displayText = 'Population ' + temp + ' ' + this.filterOptions[3].value1;
      this.setFilterDetails('population', displayText);
      this.applyAllSelectedFilters();
    } else if (
      this.filterOptions[3].operation == 'inRange' &&
      this.filterOptions[3].value1 != '' &&
      this.filterOptions[3].value2 != ''
    ) {
      this.filterOptions[3].value1 = this.numberFormatForFilterDisplay(this.filterOptions[3].value1);
      this.filterOptions[3].value2 = this.numberFormatForFilterDisplay(this.filterOptions[3].value2);
      displayText = 'Population (' + this.filterOptions[3].value1 + '-' + this.filterOptions[3].value2 + ')';
      this.setFilterDetails('population', displayText);
      this.applyAllSelectedFilters();
    }
  }

  applyNumberFilters(driveTime, operation: string, attribute, value1, value2) {
    this.filteredMarkets = this.filteredMarkets.filter(function (market) {
      const marketInfo = market.marketInfo;
      const driveTimeNode = marketInfo.filter((item) => item.driveTime == driveTime);
      if (driveTimeNode.length == 0) {
        return true;
      } else {
        if (operation.includes('lessThan')) {
          return driveTimeNode[0][attribute] != null && driveTimeNode[0][attribute] <= value1;
        } else if (operation.includes('moreThan')) {
          return driveTimeNode[0][attribute] != null && driveTimeNode[0][attribute] >= value1;
        } else if (operation.includes('inRange')) {
          return (
            driveTimeNode[0][attribute] != null &&
            driveTimeNode[0][attribute] >= value1 &&
            driveTimeNode[0][attribute] <= value2
          );
        }
      }
    });
  }

  applyAllSelectedFilters() {
    this.filters.details = this.filters.details.filter((item) => item.marketAttribute != 'allmarkets'); // remove all markets from filter if it was applied..
    const tempFilters = this.filters.details.filter(
      (item) => item.marketAttribute != 'selectMetros' && item.marketAttribute != 'selectCities',
    ); // remove selected markets from filter if it was applied..

    if (tempFilters.length > 0) {
      // if there are any other types of filters exist other than above two then apply those filters only...

      this.filteredMarkets = JSON.parse(JSON.stringify(this.cities)); // deep copy for applying filters. Do this everytime filter(s) change..

      this.filters.details.forEach((filter) => {
        if (filter.marketAttribute == 'states') {
          this.filteredMarkets = this.filteredMarkets.filter((market) =>
            this.filterOptions[1].list.includes(market.name.substring(market.name.length - 2, market.name.length)),
          );
        } else if (filter.marketAttribute == 'timezones') {
          const timeZoneFullNames = this.filterOptions[0].list.map((shortName) => {
            const zoneArr = this.timeZones.list.filter((zone) => zone.value == shortName);
            return zoneArr[0].name.toLowerCase();
          });
          this.filteredMarkets = this.filteredMarkets.filter(
            (market) => market.timeZone != null && timeZoneFullNames.includes(market.timeZone.toLowerCase()),
          );
        } else if (filter.marketAttribute == 'laborforce') {
          this.applyNumberFilters(
            this.benchMarkSelectionCriteria.driveTime,
            this.filterOptions[2].operation,
            'laborForce',
            this.filterOptions[2].value1.split(',').join(''),
            this.filterOptions[2].value2.split(',').join(''),
          );
        } else if (filter.marketAttribute == 'population') {
          this.applyNumberFilters(
            this.benchMarkSelectionCriteria.driveTime,
            this.filterOptions[3].operation,
            'population',
            this.filterOptions[3].value1.split(',').join(''),
            this.filterOptions[3].value2.split(',').join(''),
          );
        } else if (filter.marketAttribute == 'removeMicros') {
          this.filteredMarkets = this.filteredMarkets.filter((market) => market.msaType == 1);
        }
      });
    } else {
      this.filteredMarkets = []; // reset filtered markets...
    }
  }

  setFilterDetails(attributeName: string, details: string) {
    const filterDeatils = new FilterDetails();
    filterDeatils.marketAttribute = attributeName;
    filterDeatils.displayText = details;

    if (this.filters.details != undefined) {
      const tempArray = this.filters.details.filter((filter) => filter.marketAttribute == attributeName);
      if (tempArray.length == 0) {
        this.filters.details.push(filterDeatils);
      } else {
        tempArray[0].displayText = details;
      }
    } else {
      this.filters.details = [].concat(filterDeatils);
    }
  }

  removeItemFromFilterDetails(attributeName: string) {
    this.filters.details = this.filters.details.filter((filter) => filter.marketAttribute != attributeName);
  }

  resetFilterItem(attributeName: string) {
    const defaultItemSettings = this.defaultFilterOptions.filter((filter) => filter.value == attributeName); // get the default filter item settings
    const itemIndex = this.filterOptions.findIndex((filter) => filter.value == attributeName); // find the index of filter item that needs to be reset
    this.filterOptions[itemIndex] = defaultItemSettings[0]; // add the default filter item settings to remaning items
  }

  OnSubmit() {
    const allMarketsFlag = false;
    let additionalMarketIds = [];
    let excludedGeographyIds = [];
    let selMarketNames = [];
    let allMarketIds = [];

    // get additional markets ids
    if (this.addSelectedMarkets.length > 0) {
      const tempArr = this.cities.filter((e) => {
        return this.addSelectedMarkets.some((item) => item === e.name);
      });
      additionalMarketIds = tempArr.map((market) => market.id);
    }
    // get filtered markets
    let filteredMarkets = this.filteredMarkets.map((market) => market.id);

    // Add filter selected markets to the above list
    filteredMarkets = filteredMarkets.concat(this.filterSelectedMarketIds);
    filteredMarkets = filteredMarkets.concat(this.citiesFilterSelectedMarketIds);
    // // Selected Markets in filters need to be combined with other filters using OR condition. So get selected markets and add them to filtered markets if there are any...
    // if (this.filterSelectedMarkets !=  undefined && this.filterSelectedMarkets.length > 0) {
    //   // get metros selected by user
    //   let tempMarketList = this.metros.filter(market => {
    //     return this.filterSelectedMarkets.some(item => item === market.name);
    //   });
    //   // add this to filtered markets list directly if the geographic boundary is Metro or get the cities from these metros and add them to filtered markets.
    //   if (this.benchMarkSelectionCriteria.driveTime != 'Metro'){
    //     let citiesToAdd = this.cities.filter(market => {
    //       return tempMarketList.some(item => item.metroId === market.metroId);
    //     });
    //     let filteredCityIds = citiesToAdd.map(market => market.id);
    //     filteredMarkets = filteredMarkets.concat(filteredCityIds);
    //   } else {
    //     let filteredMetroIds = tempMarketList.map(market => market.id);
    //     filteredMarkets = filteredMarkets.concat(filteredMetroIds);
    //   }
    // }

    // For site as baseline report, extract the baseline market id ..
    if (this.benchMarkSelectionCriteria.baseLine === 'SITEASBASELINE') {
      // extract baseline market id
      const market = this.cities.find((market) => market.name == this.benchMarkSelectionCriteria.baseLineMarketName);
      this.benchMarkSelectionCriteria.baseLineMarket = market.id;
      // if this market is also chosen as markets to be scored then remove that entry from list of markets to be scored.
      let index = filteredMarkets.indexOf(this.benchMarkSelectionCriteria.baseLineMarket);
      if (index != -1) {
        filteredMarkets.splice(index, 1);
      }
      // if this market is alos chosen as additional markets then remove it.
      index = additionalMarketIds.indexOf(this.benchMarkSelectionCriteria.baseLineMarket);
      if (index != -1) {
        additionalMarketIds.splice(index, 1); // remove from selected markets.
        const temp = this.cities.filter((market) => {
          return additionalMarketIds.some((item) => item === market.id);
        });
        selMarketNames = temp.map((market) => market.name);
        this.benchMarkSelectionCriteria.additionalMarkets = selMarketNames; // assign it back to additional markets
      } else {
        this.benchMarkSelectionCriteria.additionalMarkets = this.addSelectedMarkets;
      }
    } else {
      this.benchMarkSelectionCriteria.baseLineMarket = 0;
      this.benchMarkSelectionCriteria.additionalMarkets = this.addSelectedMarkets;
    }

    // for Site Averages and Site Median send the additional markets as markets to exclude from base line calculation
    let marketsBody;
    excludedGeographyIds = additionalMarketIds.filter((market) => !filteredMarkets.includes(market));
    allMarketIds = additionalMarketIds.concat(filteredMarkets); // combine filtered markets and additional markets
    if (
      this.benchMarkSelectionCriteria.baseLine === 'SITEAVERAGES' ||
      this.benchMarkSelectionCriteria.baseLine === 'SITEMEDIANS'
    ) {
      if (filteredMarkets.length === 0) {
        this.snackBarService.showWarning(
          'Markets are not chosen for baseline calculation. Please select 2 or more markets from filters and retry.',
        );
        return;
      } else {
        const tempList = filteredMarkets.filter((market) => !additionalMarketIds.includes(market));
        if (tempList.length < 2) {
          this.snackBarService.showWarning(
            'Not enough filtered markets for baseline calculations, please change filter criteria.',
          );
          return;
        }
      }
      marketsBody = {
        geographyIds: allMarketIds,
        // excluded_geographyIds: additionalMarketIds
        excluded_geographyIds: excludedGeographyIds,
      };
    } else {
      marketsBody = {
        geographyIds: allMarketIds,
        excluded_geographyIds: [],
      };
    }

    this.spinnerService.show('Working on the Report...');

    const selectedProfile = this.profiles.find(
      (profile) => profile.profileName == this.benchMarkSelectionCriteria.profileName,
    );
    this.benchMarkSelectionCriteria.profile = selectedProfile.profileID;
    this.benchMarkSelectionCriteria.filterMarkets = filteredMarkets;

    this.marketIndexServvice
      .getBenchMarkScores(
        this.benchMarkSelectionCriteria.profile,
        this.benchMarkSelectionCriteria.driveTime,
        this.benchMarkSelectionCriteria.baseLine,
        this.benchMarkSelectionCriteria.baseLineMarket,
        marketsBody,
        allMarketsFlag,
      )
      .subscribe(
        (response) => {
          this.benchMarkingResponse = response.indexingData;
          this.spinnerService.hide();
          this.benchMarkExcelService.createBenchMarkingReport(
            response.indexingData,
            response.dataPointFormats,
            this.cities,
            this.benchMarkSelectionCriteria,
          );
        },
        (error) => {
          console.error('Drive-Time: benchmarking -> getBenchMarkingDetails -> error', error);
          this.spinnerService.hide();
          this.dialogService.show('Unable to run benchmark report. Please contact application support team.');
        },
      );

    //  this.benchMarkSelectionCriteria.filterCriteria = this.filters.details;
    // remove all states count and add state details if it was a crieteria applied in filter details object...
    let filterDetailsCopy;
    if (this.filters.details != undefined) {
      filterDetailsCopy = JSON.parse(JSON.stringify(this.filters.details)); // deep copy
      if (filterDetailsCopy != undefined && filterDetailsCopy.length > 0) {
        filterDetailsCopy.forEach((filter) => {
          if (filter.marketAttribute == 'states') {
            filter.displayText = 'States (' + this.filterOptions[1].list.join(', ') + ') ';
          }
        });
      }
    }

    this.benchMarkSelectionCriteria.filterCriteria = filterDetailsCopy;

    // invoke audit service to log the details of bench mark report that was run
    this.BenchMarkAuditinfo();
  }

  scrolledIndexChange($event) {
    if (!this.initialRange) {
      this.initialRange = this.cdkVirtualScrollViewport.getRenderedRange();
    }
  }

  private BenchMarkAuditinfo() {
    const parameters = JSON.parse(JSON.stringify(this.benchMarkSelectionCriteria));
    parameters['Report type'] = 'Benchmark';
    this.driveTimeService.postSingleSiteAuditinfo(parameters).subscribe(
      (res) => {
        console.log('BenchmarkingComponent -> res', res);
      },
      (error) => {
        console.log('BenchmarkingComponent -> error', error);
      },
    );
  }

  private numberFormatForFilterDisplay(value: string) {
    return parseInt(value.split(',').join('')).toLocaleString();
  }

  private allMarketFilter() {
    this.clearAllFilters();
    this.filteredMarkets = JSON.parse(JSON.stringify(this.cities)); // select all markets
    this.setFilterDetails('allmarkets', 'All Markets ');
    this.filterSelection = 'allmarkets';
  }

  toggleFilters() {
    this.isShowing = !this.isShowing;
  }
}
