<div class="top-margin">
  <div class="row mt-5">
    <!-- <img src="{{dtFooterLineLogo}}" height="12px" width="100%" /> -->
  </div>

  <div class="row">
    <div class="col-sm-9 align-content-start">
      <span style="font-size: x-small;">
        Disclaimer: © 2019 CBRE, Inc. All rights reserved.
        This report contains information from sources including, but not limited to:
        Demographics (r) Source: ESRI 2018 and Easy Analytic Software 2018,
        Easy Analytic Software Quality of Life Data 2018,
        Economic Research Institute Q2 2019 of Labor Statistics 2Q May 2018
        National Center for Education Statistics:
        Integrated Post Secondary Education Data Systems (IPEDS).
        We believe these sources to be reliable; however, we make no guarantee,
        warranty or representation of its accuracy. Opinions, assumptions and
        estimates constitute CBRE·s judgment as of the date this report is
        first released and are subject to change without notice.
        This report is published for the use of CBRE and its clients only.
        Redistribution in whole or part to any third party without the
        prior written consent of CBRE is strictly prohibited.
      </span>
    </div>
    <div class="col-sm-1 align-content-start">
      <!-- <img src="{{dtCBREBuildOnAdvantageLogo}}" height="100px" width="250px" /> -->
    </div>

  </div>

  <div class="row mt-5 justify-content-center">
    <h5 style="text-align: justify;">Page {{this.pageNumber}} of {{this.totalPages}}</h5>
  </div>

  <div class="row mt-3">
    <div class="horizontal_dotted_line">
    </div>
  </div>

</div>