import { Component, ElementRef, ViewChild } from '@angular/core';
declare let tableau: any;

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss'],
})
export class CompetitionComponent {
  viz: any;
  @ViewChild('vizContainer') containerDiv: ElementRef;

  ngAfterViewInit() {
    this.initTableau();
  }

  initTableau() {
    // const containerDiv = document.getElementById("vizContainer");
    const vizUrl =
      'https://usinsightreporting.cbre.com/t/LaborAnalyticsSandbox/views/CompetitionReportDraft/MarketCompetitionReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' +
      '&output=embed';
    const options = {
      hideTabs: true,
      onFirstInteractive: () => {
        console.log('First chart display');
      },
      onFirstVizSizeKnown: () => {
        console.log('First chart size');
      },
    };
    this.viz = new tableau.Viz(this.containerDiv.nativeElement, vizUrl, options);
  }
}
