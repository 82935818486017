<mat-sidenav-container class="example-container" id="drawerContainer" hasBackdrop="false" autosize>
  <control-comp-table></control-comp-table>
  <mat-sidenav
    class="right-sidenav"
    hasBackdrop="false"
    autosize
    mode="over"
    position="end"
    [opened]="isRightSidenavOpened$ | async"
  >
    <app-right-sidenav-content></app-right-sidenav-content>
  </mat-sidenav>
  <app-right-sidenav-filter></app-right-sidenav-filter>
  <app-right-sidenav-add-edit></app-right-sidenav-add-edit>
</mat-sidenav-container>
