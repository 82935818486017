import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() field:any;
  @Input() istextarea = false;
  @Input() maxLength = 0;
  @Input() hideRequiredMarker= false;
  @Input() readonly = false;
  isTouched = false;
  isBlurred = false;

  constructor(@Optional() private formgroupDirective:FormGroupDirective, private formService: FormService) {
  }

  ngOnInit(): void {
    this.form = this.form?? this.formgroupDirective.control;

    const control = this.form?.controls[this.field.control];
    control?.valueChanges?.pipe(debounceTime(800)).subscribe((value) => {
      if (!value) return;

      const isSearch = this.field?.label?.toLowerCase()?.includes('search');
      const title = isSearch ? 'Search query event' : 'Input value change event';
      this.formService.analytics('track', title, { value, component: 'text', field: this.field.label, search_query: value });
    });
  }

  handleTouchAndBlur(type: string) {
      if (type === 'focus') this.isTouched = true;
      if (type === 'blur') this.isBlurred = true;
  }
}
