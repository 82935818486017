import { Directive, ElementRef, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appToolTip]',
})
export class ToolTipDirective implements AfterViewInit {
  @Input()
  isMatToolTipDisabled: boolean;
  @Output()
  disableMatTooltip = new EventEmitter<boolean>();

  constructor(public el: ElementRef) {}

  ngAfterViewInit(): void {
    // condition for checking if text-overflow: ellipsis works for truncated text
    if (!this.isMatToolTipDisabled && this.el.nativeElement.offsetWidth >= this.el.nativeElement.scrollWidth) {
      this.disableMatTooltip.emit(true);
    }

    if (this.isMatToolTipDisabled && this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth) {
      this.disableMatTooltip.emit(false);
    }
  }
}
