import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Workbook, stream } from 'exceljs';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StatesNamesService } from '../drive-time/multi-site/services/stateNames.service';
import { BaseService } from '../services/base.service';
import { InterceptorSkipHeader } from '../httpInterceptor/TokenHttpInterceptor';

export class ReportParams {
  profileID?: string;
  profileName?: string;
  latitude?: string;
  longitude?: string;
  driveTimeInMinutes?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  isReverseGeoCodeData?: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class MarketIndexService extends BaseService {
  constructor(http: HttpClient, private datePipe: DatePipe, private stateNameService: StatesNamesService) {
    super(http);
  }

  getMarketIndex(params: ReportParams): Observable<any> {
    const apiuri = '/v1/index/geoskill';
    const url = environment.indexingApiUrl + apiuri + '?' + this.prepareparams(params);

    const header = {};

    return this.http.get(url, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getMultiSiteMarketIndex(params: string, sites: any[]): Observable<any> {
    const apiuri = '/v1/multisite/marketscore';
    const url = environment.adminUrl + apiuri + '?' + params;
    // var url = 'https://lag-indexapi.azurewebsites.net/v1/marketscore' + '?' + params;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(url, sites, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getSiteValidationResults(sites: any[]): Observable<any[]> {
    const apiuri = '/validateSite';
    const url = environment.adminUrl + apiuri;
    // var url = environment.adminTempUrl + apiuri;
    // var url = 'https://lag-indexapi.azurewebsites.net/v1/marketscore' + '?' + params;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(url, sites, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getWagesForMultiSite(params: string, sites: any[]): Observable<any[]> {
    const apiuri = '/v1/wages-api/multisite';
    const url = environment.eriApiUrl + apiuri + '?' + params;
    // var url = 'https://lag-eriapi.azurewebsites.net/v1/wages-api/multisite' + '?' + params;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(url, sites, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getDemographicsForMultiSite(params: string, sites: any[]): Observable<any[]> {
    const apiuri = '/v1/demographics-api/multisite';
    const url = environment.educationApiUrl + apiuri + '?' + params;
    //  var url = 'https://lag-educationapi.azurewebsites.net/v1/demographics-api/multisite' + '?' + params;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(url, sites, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  prepareparams(params: ReportParams): string {
    const apiParams: string =
      'profileID=' +
      params.profileID +
      '&city=' +
      params.city +
      '&state=' +
      params.state +
      '&lat=' +
      params.latitude +
      '&long=' +
      params.longitude +
      '&driveTimeInMinutes=' +
      params.driveTimeInMinutes;

    return apiParams;
  }

  getEducationNationalvalues(): Observable<any> {
    const apiuri = '/v1/education-api/education/national';
    const url = environment.educationApiUrl + apiuri;

    const header = {
      headers: new HttpHeaders(),
    };

    return this.http.get(url, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getOccupationsNationalvalues(): Observable<any> {
    const apiuri = '/bls-webapp/api/drivetime/occupations/national';
    const url = environment.educationApiUrl + apiuri;

    const header = {
      headers: new HttpHeaders(),
    };

    return this.http.get(url, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  getBenchMarkScores(
    profile: number,
    driveTimeInMinutes: string,
    baseLineType: string,
    baseLineSite: number,
    marketsBody: any,
    allMarkets: boolean,
  ): Observable<any> {
    let apiuri = '';

    let params: string =
      'profileID=' +
      profile +
      //+ '&driveTimeInMinutes=' + driveTimeInMinutes
      '&baseLineType=' +
      baseLineType +
      '&baseLineSite=' +
      baseLineSite +
      // + '&geographyIds=' + marketIds
      '&allMarkets=' +
      allMarkets;

    if (driveTimeInMinutes.toString() != 'Metro' && !driveTimeInMinutes.toString().includes('Miles')) {
      apiuri = '/v1/dt/marketscore/retrievebenchmarks';
      params = params + '&driveTimeInMinutes=' + driveTimeInMinutes;
    } else if (driveTimeInMinutes.toString() == 'Metro') {
      apiuri = '/v1/radii/marketscore/retrievebenchmarks';
      params = params + '&radius=' + 'N/A';
    } else if (driveTimeInMinutes.toString().includes('Miles')) {
      apiuri = '/v1/radii/marketscore/retrievebenchmarks';
      params = params + '&radius=' + driveTimeInMinutes;
    }

    const url = environment.adminUrl + apiuri + '?' + params;
    // var url = 'https://lag-indexapi.azurewebsites.net/v1/marketscore' + '?' + params;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set(InterceptorSkipHeader, ''),
    };

    // return this.http.get(url, header).pipe(
    //     map(res => res),
    //     catchError((e) => this.handleError(e)))

    return this.http.post(url, marketsBody, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  exportAsExcelFile(data: any[]): void {
    this.generateExcel(data);
  }

  generateExcel(exportData: any[]) {
    const fileName = 'SingleSite.xlsx';

    console.log('TCL: MarketIndexService -> generateExcel -> exportData', exportData);
    //Excel Title, Header, Data
    const title = 'Single Site Report';

    let latLongDisplay = '';
    if (exportData[0].isReverseGeoCodeData)
      latLongDisplay = 'lat (' + exportData[0].Latitude + ') / long (' + exportData[0].Longitude + ')';

    //Create workbook
    const workbook = new Workbook();

    //Create Metadata worksheet
    const worksheet_metadata = workbook.addWorksheet('MetaData');

    //Add Row and formatting
    const titleRow = worksheet_metadata.addRow([title]);
    titleRow.font = {
      name: 'Futura lt BT',
      family: 4,
      size: 14,
      underline: 'none',
      bold: true,
    };
    worksheet_metadata.addRow([]);
    const dateRow = worksheet_metadata.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
    dateRow.font = {
      name: 'Futura lt BT',
      family: 4,
      size: 10,
      underline: 'none',
      bold: true,
    };

    worksheet_metadata.addRow([]);

    const adrs =
      latLongDisplay != ''
        ? latLongDisplay + ' :- ' + exportData[0].LocationDetails
        : sessionStorage.getItem('DTAddress');

    const addressRow = worksheet_metadata.addRow(['Address : ' + adrs]);
    addressRow.font = {
      name: 'Futura lt BT',
      family: 4,
      size: 10,
      underline: 'none',
      bold: true,
    };

    worksheet_metadata.addRow([]);

    const driveTimeRow = worksheet_metadata.addRow(['Drive Time(Minutes): ' + exportData[0].DriveTimeInMinutes]);
    driveTimeRow.font = {
      name: 'Futura lt BT',
      family: 4,
      size: 10,
      underline: 'none',
      bold: true,
    };

    worksheet_metadata.mergeCells('A1', 'D2');

    //Create SingleSite worksheet
    const worksheet_drivetime = workbook.addWorksheet('SingleSite');

    var cell = worksheet_drivetime.getCell('A1');
    cell.value = 'Location Details';
    var cell = worksheet_drivetime.getCell('A2');
    cell.value = 'National';
    var cell = worksheet_drivetime.getCell('A3');
    cell.value = latLongDisplay != '' ? latLongDisplay : exportData[0].LocationDetails;

    var cell = worksheet_drivetime.getCell('B1');
    cell.value = 'State';
    var cell = worksheet_drivetime.getCell('B3');

    cell.value =
      latLongDisplay != ''
        ? this.stateNameService.convertRegion(exportData[0].State, this.stateNameService.TO_ABBREVIATED)
        : exportData[0].State;

    var cell = worksheet_drivetime.getCell('C1');
    cell.value = 'ZipCode';
    var cell = worksheet_drivetime.getCell('C3');
    cell.value = exportData[0].ZipCode;

    var cell = worksheet_drivetime.getCell('D1');
    cell.value = 'TimeZone';
    var cell = worksheet_drivetime.getCell('D3');
    cell.value = exportData[0].TimeZone;

    var cell = worksheet_drivetime.getCell('E1');
    cell.value = 'Total Index';
    var cell = worksheet_drivetime.getCell('E2');
    cell.value = 100;
    var cell = worksheet_drivetime.getCell('E3');
    cell.value = exportData[0].TotalIndex;

    //Coloumn width
    worksheet_drivetime.getColumn('A').width = 18;
    worksheet_drivetime.getColumn('B').width = 8;
    worksheet_drivetime.getColumn('C').width = 10;
    worksheet_drivetime.getColumn('D').width = 10;
    worksheet_drivetime.getColumn('E').width = 12;

    //------------------------------------
    // Market Index
    let mi = 5;
    exportData[0].MarketIndex.forEach((element) => {
      //F - K
      const cpname = (mi + 10).toString(36) + '1';
      var cell = worksheet_drivetime.getCell(cpname.toUpperCase());
      cell.value = element.datapointname;
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (mi + 10).toString(36) + '2';
      var cell = worksheet_drivetime.getCell(cpvalue.toUpperCase());
      cell.value = 100;
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (mi + 10).toString(36) + '3';
      var cell = worksheet_drivetime.getCell(cpvalue.toUpperCase());
      cell.value = element.index.toFixed(2);
      cell.alignment = { horizontal: 'right' };

      mi++;
    });
    //Coloumn width
    worksheet_drivetime.getColumn('F').width = 15;
    worksheet_drivetime.getColumn('G').width = 25;
    worksheet_drivetime.getColumn('H').width = 35;
    worksheet_drivetime.getColumn('I').width = 15;
    worksheet_drivetime.getColumn('J').width = 15;
    worksheet_drivetime.getColumn('K').width = 15;

    //------------------------------------
    // Demographics Index
    let di = 11;
    exportData[0].Demographics.forEach((element) => {
      //L - R
      const cpname = (di + 10).toString(36) + '1';
      var cell = worksheet_drivetime.getCell(cpname.toUpperCase());
      cell.value = element.dataPointName;
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (di + 10).toString(36) + '2';
      var cell = worksheet_drivetime.getCell(cpvalue.toUpperCase());
      cell.value = element.national;
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (di + 10).toString(36) + '3';
      var cell = worksheet_drivetime.getCell(cpvalue.toUpperCase());
      cell.value = element.dataPointValue;
      cell.alignment = { horizontal: 'right' };

      di++;
    });
    //Coloumn width
    worksheet_drivetime.getColumn('L').width = 30;
    worksheet_drivetime.getColumn('M').width = 20;
    worksheet_drivetime.getColumn('N').width = 20;
    worksheet_drivetime.getColumn('O').width = 30;
    worksheet_drivetime.getColumn('P').width = 30;
    worksheet_drivetime.getColumn('Q').width = 25;
    worksheet_drivetime.getColumn('R').width = 30;

    //------------------------------------
    // College Values
    var cell = worksheet_drivetime.getCell('S1');
    cell.value = '2- yr colleges';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('S2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('S3');
    cell.value = exportData[0].postSecondarySchools.TwoYearColleges;
    cell.alignment = { horizontal: 'right' };

    var cell = worksheet_drivetime.getCell('T1');
    cell.value = '2- yr enrollment';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('T2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('T3');
    cell.value = exportData[0].postSecondarySchools.TwoYearEnrollment;
    cell.alignment = { horizontal: 'right' };

    var cell = worksheet_drivetime.getCell('U1');
    cell.value = '4- yr colleges';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('U2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('U3');
    cell.value = exportData[0].postSecondarySchools.FourYearColleges;
    cell.alignment = { horizontal: 'right' };

    var cell = worksheet_drivetime.getCell('V1');
    cell.value = '4- yr enrollment';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('V2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('V3');
    cell.value = exportData[0].postSecondarySchools.FourYearEnrollment;
    cell.alignment = { horizontal: 'right' };

    var cell = worksheet_drivetime.getCell('W1');
    cell.value = 'Total # of Colleges';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('W2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('W3');
    cell.value = exportData[0].postSecondarySchools.TotalColleges;
    cell.alignment = { horizontal: 'right' };

    var cell = worksheet_drivetime.getCell('X1');
    cell.value = 'Total Enrollment';
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('X2');
    cell.value = 'N/A';
    cell.alignment = { horizontal: 'right' };
    var cell = worksheet_drivetime.getCell('X3');
    cell.value = exportData[0].postSecondarySchools.TotalEnrollment;
    cell.alignment = { horizontal: 'right' };

    //Coloumn width
    worksheet_drivetime.getColumn('S').width = 15;
    worksheet_drivetime.getColumn('T').width = 15;
    worksheet_drivetime.getColumn('U').width = 15;
    worksheet_drivetime.getColumn('V').width = 15;
    worksheet_drivetime.getColumn('W').width = 15;
    worksheet_drivetime.getColumn('X').width = 15;

    //------------------------------------
    // Employment Occupation
    let eo = 0;
    exportData[0].EmploymentByOccupation.forEach((element) => {
      //AA - AK
      const cpname = (eo + 10).toString(36) + '1';
      var cell = worksheet_drivetime.getCell('A' + cpname.toUpperCase());
      cell.value = element.dataPointName;
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (eo + 10).toString(36) + '2';
      var cell = worksheet_drivetime.getCell('A' + cpvalue.toUpperCase());
      cell.value = element.nationalValue;
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (eo + 10).toString(36) + '3';
      var cell = worksheet_drivetime.getCell('A' + cpvalue.toUpperCase());
      cell.value = element.dataPointValue;
      cell.alignment = { horizontal: 'right' };

      eo++;
    });
    //Coloumn width
    worksheet_drivetime.getColumn('AA').width = 20;
    worksheet_drivetime.getColumn('AB').width = 30;
    worksheet_drivetime.getColumn('AC').width = 35;
    worksheet_drivetime.getColumn('AD').width = 20;
    worksheet_drivetime.getColumn('AE').width = 20;
    worksheet_drivetime.getColumn('AF').width = 35;
    worksheet_drivetime.getColumn('AG').width = 30;
    worksheet_drivetime.getColumn('AH').width = 25;
    worksheet_drivetime.getColumn('AI').width = 25;
    worksheet_drivetime.getColumn('AJ').width = 15;
    worksheet_drivetime.getColumn('AK').width = 25;

    worksheet_drivetime.spliceColumns(25, 2);

    //------------------------------------
    // Educations
    let e = 11;
    exportData[0].Educations.forEach((element) => {
      //AL - AQ
      const cpname = (e + 10).toString(36) + '1';
      var cell = worksheet_drivetime.getCell('A' + cpname.toUpperCase());
      cell.value = element.dataPointName;
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (e + 10).toString(36) + '2';
      var cell = worksheet_drivetime.getCell('A' + cpvalue.toUpperCase());
      cell.value = element.nationalValue;
      cell.alignment = { horizontal: 'right' };

      var cpvalue = (e + 10).toString(36) + '3';
      var cell = worksheet_drivetime.getCell('A' + cpvalue.toUpperCase());
      cell.value = element.dataPointValue;
      cell.alignment = { horizontal: 'right' };

      e++;
    });
    //Coloumn width
    worksheet_drivetime.getColumn('AL').width = 25;
    worksheet_drivetime.getColumn('AM').width = 15;
    worksheet_drivetime.getColumn('AN').width = 20;
    worksheet_drivetime.getColumn('AO').width = 20;
    worksheet_drivetime.getColumn('AP').width = 20;
    worksheet_drivetime.getColumn('AQ').width = 15;

    worksheet_drivetime.spliceColumns(36, 2);

    //Create Colleges worksheet
    const worksheet_Colleges = workbook.addWorksheet('Colleges');

    worksheet_Colleges.columns = [
      { header: 'Location Details', key: 'Loc', width: 20 },
      { header: 'College Name', key: 'CName', width: 35 },
      { header: 'College Type', key: 'CType', width: 25 },
      { header: 'Enrollment', key: 'Enrollment', width: 15 },
    ];

    exportData[0].postSecondarySchools.TopNSchools.forEach((element) => {
      worksheet_Colleges.addRow({
        Loc: latLongDisplay != '' ? latLongDisplay : sessionStorage.getItem('DTAddress'),
        CName: element.collegeName,
        CType: element.typeofSchool,
        Enrollment: element.enrollment,
      });
    });

    //Create Median Wages
    const worksheet_Wages = workbook.addWorksheet('Median Wages');

    worksheet_Wages.columns = [
      { header: 'Location Details', key: 'Loc', width: 20 },
      { header: 'Job Title', key: 'JobTitle', width: 35 },
      { header: 'Years of Experience', key: 'Exp', width: 30 },
      { header: 'Market', key: 'Market', width: 15 },
      { header: 'National', key: 'National', width: 15 },
    ];

    exportData[0].MedianWages.forEach((element) => {
      worksheet_Wages.addRow({
        Loc: latLongDisplay != '' ? latLongDisplay : sessionStorage.getItem('DTAddress'),
        JobTitle: element.jobTitle,
        Exp: element.experience,
        Market: element.marketWage,
        National: element.nationalWage,
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, fileName);
    });
  }

  override handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }
}
