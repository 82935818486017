import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  momentjs;

  constructor(private http: HttpClient) {}

  callApi<T>(
    method: string,
    url: string,
    data?: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: "body";
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const header = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
      ...options,
    };

    if (method.toLowerCase() === "get") {
      return this.http.get<T>(url, header);
    }

    if (method.toLowerCase() === "post") {
      return this.http.post<T>(url, data, header);
    }

    if (method.toLowerCase() === "put") {
      return this.http.put<T>(url, data, header);
    }

    if (method.toLowerCase() === "delete") {
      return this.http.delete<T>(url);
    }
  }

  setMoment(moment) {
    this.momentjs = moment;
  }

  generateGuid(): string {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  getDateString() {
    const date = new Date();
    // eslint-disable-next-line prefer-const
    let [month, day, year] = [
      "" + (date.getMonth() + 1),
      "" + date.getDate(),
      date.getFullYear(),
    ];
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "." + month + "." + day;
  }

  sortArray(arrayToBeSorted: Array<any>, key: string): Array<any> {
    if (!key) {
      return arrayToBeSorted;
    }

    return arrayToBeSorted?.sort((a, b) => {
      if (a[key]?.trim() < b[key]?.trim()) return -1;
      if (a[key]?.trim() > b[key]?.trim()) return 1;
      return 0;
    });
  }

  customSortKey(s) {
    const firstChar = s.charAt(0);
    const asciiValue = firstChar.charCodeAt(0);

    if (
      (asciiValue >= 33 && asciiValue <= 47) ||
      (asciiValue >= 58 && asciiValue <= 64) ||
      (asciiValue >= 91 && asciiValue <= 96) ||
      (asciiValue >= 123 && asciiValue <= 126)
    ) {
        return 0; // Symbols
    } else if (asciiValue >= 48 && asciiValue <= 57) {
        return 1; // Numbers
    } else if ((asciiValue >= 65 && asciiValue <= 90) || (asciiValue >= 97 && asciiValue <= 122)) {
        return 2; // Letters (A-Z, a-z)
    } else {
        return 3; // other characters;
    }
  }

  customSortComparator(a, b, key) {
    const keyA = this.customSortKey(a[key]);
    const keyB = this.customSortKey(b[key]);

    if (keyA !== keyB) {
        return keyA - keyB;
    } else {
        return a[key].localeCompare(b[key]);
    }
  }

  customSortAlphabeticallyArray(array, key) {
    return array.sort((a, b) => this.customSortComparator(a, b, key));
  }

  stringToDate(datestring: string): Date {
    if (!datestring) return null;
    const unixTime = Date.parse(datestring);
    if (Number.isNaN(unixTime)) return null;

    return new Date(unixTime);
  }

  dateToString(date: Date): string {
    if (!date) return null;
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
  numberWithCommas(number) {
    if (typeof number !== "number") return number;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  compactNumbers(
    number,
    config?: { minimumFractionDigits: number; maximumFractionDigits: number }
  ) {
    if (number === null || number === undefined) return number;
    const _config: Intl.NumberFormatOptions = {
      notation: "compact",
      ...config,
    };
    const formattedNumber = Intl.NumberFormat("en", _config).format(number);
    return formattedNumber === "isNaN" ? number : formattedNumber;
  }

  getDateTimeWithZone(): string {
    const date = new Date(Date.now());
    const zone = date
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString(
      "en-US"
    )} ${zone}`;
  }

  formatThousandSeparator(
    number: number | string,
    config?: Intl.NumberFormatOptions
  ): any {
    if (!number) return number;
    if (typeof number === "string") {
      const commalessNumber = number.replace(/,/g, "");
      if (Number.isNaN(Number.parseFloat(commalessNumber))) return number;
      return new Intl.NumberFormat("en-US", config).format(
        Number.parseFloat(number)
      );
    }
    if (typeof number === "number") {
      return new Intl.NumberFormat("en-US", config).format(number);
    }
    return number;
  }

  getCookie(name: string) {
    const cookies: Array<string> = document.cookie.split(";");
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < cookies.length; i += 1) {
      c = cookies[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  generateCookiePayload(): any {
    const OKTA_TOKEN_STORAGE_ACCESSTOKEN = "okta-token-storage_accessToken";
    const OKTA_TOKEN_STORAGE_IDTOKEN = "okta-token-storage_idToken";
    return {
      [OKTA_TOKEN_STORAGE_ACCESSTOKEN]: {
        name: OKTA_TOKEN_STORAGE_ACCESSTOKEN,
        value: this.getCookie(OKTA_TOKEN_STORAGE_ACCESSTOKEN),
      },
      [OKTA_TOKEN_STORAGE_IDTOKEN]: {
        name: OKTA_TOKEN_STORAGE_IDTOKEN,
        value: this.getCookie(OKTA_TOKEN_STORAGE_IDTOKEN),
      },
    };
  }

  removeHTMLTags(htmlString) {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, 'text/html');
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || "";
    return textContent.trim(); // Trim any leading or trailing whitespace
}
}
