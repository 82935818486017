<ng-container [ngSwitch]="field.buttonType">

    <button type="button" *ngSwitchCase="'primary'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" [disabled]="checkDisabled()" (click)="onPrimaryButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName" [iconColor]="field.iconColor"></lag-icon>
    </button>

    <button type="button" *ngSwitchCase="'secondary'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" (click)="onSecondaryButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName" [iconColor]="field.iconColor"></lag-icon>
    </button>

    <button type="button" *ngSwitchCase="'filter'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" [disabled]="checkAddFilterButtonDisabled()" (click)="onAddFilterButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>
    <button type="button" *ngSwitchCase="'deletefilter'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" [disabled]="checkDeleteFilterButtonDisabled()" (click)="onDeleteFilterButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>

    <button type="button" *ngSwitchCase="'save'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" [disabled]="checkSaveButtonDisabled()" (click)="onSaveButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>
    <button type="button" *ngSwitchCase="'apply'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" (click)="onApplyButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>
    <button type="button" *ngSwitchCase="'clear'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" (click)="onClearButtonClick($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>
    <button type="button" *ngSwitchCase="'leftSideDrawerClose'" [attr.id]="field.buttonId??null" [class]="'button '+field.className" (click)="leftSideDrawerClose()">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName"></lag-icon>
    </button>
    <button type="button" *ngSwitchDefault [attr.id]="field.buttonId??null" [class]="'button '+field.className" (click)="onClickButton($event)">
        {{field.text}}
        <lag-icon *ngIf="field.iconName" [iconName]="field.iconName" [iconColor]="field.iconColor"></lag-icon>
    </button>
</ng-container>


