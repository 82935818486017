<mat-drawer-container class="example-container" id="drawerContainer" autosize>
    <mat-drawer #drawer [opened]="isShowing" id="matDrawer" class="example-sidenav pl-0 pr-0 pt-0" mode="side" [disableClose]="true">
        <div class="footer">
            <div class="row m-0" style="
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: rgba(0, 63, 45, 0.1);
        ">
                <div class="col-10 m-0">
                    <p class="header1">Benchmark Settings</p>
                </div>
                <div class="col-1 m-0" style="justify-content: right">
                    <button mat-icon-button id="chevron_right" (click)="toggleFilters()">
              <mat-icon>chevron_left</mat-icon>
           </button>
                </div>
            </div>
            <div class="panelHeader" style="min-height: 9% !important;">
                <div>
                    <mat-accordion multi>
                        <!-- Company and Profile Block -->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="margin: 0px 0px; border-bottom: 1px solid rgba(0, 63, 45, 0.1);">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="navHeader">
                                    Company and Profile
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="mt-2" style="font-size: 12px;">
                                <mat-form-field appearance="outline" class="matFormField">
                                    <mat-label class="custom-mat-label companyText">Company</mat-label>
                                    <mat-select class="mat-option-text-style" [disabled]="true">
                                        <mat-option class="mat-option-text-style" *ngFor="let item of profiles" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="outline">
                    <mat-select  [(ngModel)]="benchMarkSelectionCriteria.profile">
                      <mat-option *ngFor="let item of profiles" [value]="item.id">
                        {{item.name}}
                      </mat-option>                  
                    </mat-select>
                    </mat-form-field>                               -->
                                <mat-form-field appearance="outline" class="matFormField" style="font-size: 12px !important; padding-bottom: 10px !important;">
                                    <mat-label class="custom-mat-label">Profile</mat-label>
                                    <input type="text" matInput id="benchmark" class="mat-option-text-style" [formControl]="profileControl" [(ngModel)]="benchMarkSelectionCriteria.profileName" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let item of filteredProfiles  | async" [value]="item.profileName" style="font-size: 12px;">
                                            {{item.profileName}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                        <!--Geographic Boundary-->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="margin: 0px 0px; border-bottom: 1px solid rgba(0, 63, 45, 0.1);">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="navHeader">
                                    Geographic Boundary
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="mt-1 ml-0" style="font-size: 12px; width: 80%; column-count: 2; -webkit-column-count: 2">
                                <mat-radio-group (change)="handleDriveTimeChange($event)" name="driveTimeGroup" aria-labelledby="radio-group-label" class="radio-group" style="margin-left: 10px;padding-left: 10px;" [(ngModel)]="benchMarkSelectionCriteria.driveTime">
                                    <mat-radio-button class="radio-button calibrefont radioText" *ngFor="let option of driveTimeOptions" [value]="option.value">
                                        {{ option.name }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </mat-expansion-panel>
                        <!--Filters-->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="margin: 0px 0px; border-bottom: 1px solid rgba(0, 63, 45, 0.1);">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="navHeader">
                                    Filters
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div style="font-size: 12px;">
                                <div class="mt-2">
                                    <mat-form-field appearance="outline" class="matFormField">
                                        <mat-label class="custom-mat-label">Select Markets</mat-label>
                                        <mat-select class="mat-option-text-style" (selectionChange)="setFilterSelection($event)" [value]="filterSelection">
                                            <mat-option class="mat-option-text-style" *ngFor="let item of filterOptions" [value]="item.value">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="filterSelection === 'selectCities'">
                                    <div style="font-size: 12px;">
                                        <mat-form-field appearance="outline" class="matFormField" style="padding-bottom: 10px !important;">
                                            <mat-label class="custom-mat-label">Select</mat-label>
                                            <mat-select class="mat-option-text-style" #allCitiesMarketsSelect (openedChange)="openedCitiesChange($event)" [formControl]="citiesSelectFormControl" multiple>
                                                <div class="select-container">
                                                    <mat-optgroup style="max-width: 70%">
                                                        <mat-form-field appearance="outline" class="matFormField">
                                                            <input #citiesMarkets autocomplete="off" aria-label="citiesMarkets" matInput (keydown)="handleInput($event)" [formControl]="citiesSearchTextboxControl">
                                                            <button [disableRipple]="true" *ngIf="citiesMarkets.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event,'cities')">
                                   <mat-icon >close</mat-icon>
                                   </button>
                                                            <button [disableRipple]="true" *ngIf="citiesMarkets.value===''" matSuffix mat-icon-button>
                                   <mat-icon >search</mat-icon>
                                   </button>
                                                        </mat-form-field>
                                                    </mat-optgroup>
                                                    <mat-optgroup *ngIf="(citiesFilteredOptions | async) !== null && (citiesFilteredOptions | async).length == 0">
                                                        <div>market not found!</div>
                                                    </mat-optgroup>
                                                    <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                                        <!-- <mat-checkbox *ngIf="citiesMarkets.value==''" [(ngModel)]="allCitiesMarketsSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllCitiesMarketsSelection()" style="padding-left: 16px!important;" color="primary">Select All</mat-checkbox> -->
                                                        <mat-option class="mat-option-text-style" (onSelectionChange)="handleCitiesMarketsChange($event)" *cdkVirtualFor="let option of citiesFilteredOptions | async" [value]="option.name">
                                                            {{option.name}}
                                                        </mat-option>
                                                    </cdk-virtual-scroll-viewport>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div *ngIf="filterSelection === 'selectMetros'">
                                    <div style="font-size: 12px;">
                                        <mat-form-field appearance="outline" class="matFormField" style="padding-bottom: 10px !important;">
                                            <mat-label class="custom-mat-label">Select</mat-label>
                                            <mat-select class="mat-option-text-style" #allMarketsFilterSelect (openedChange)="openedMetro($event)" [formControl]="filterSelectFormControl" multiple>
                                                <!-- <mat-select-trigger>
                             {{filterSelectFormControl.value ? filterSelectFormControl.value[0] : ''}}
                             <span *ngIf="filterSelectFormControl.value?.length > 1" class="additional-selection">
                               (+{{filterSelectFormControl.value.length - 1}} {{filterSelectFormControl.value?.length === 2 ? 'other' : 'others'}})
                             </span>
                             </mat-select-trigger> -->
                                                <div class="select-container">
                                                    <mat-optgroup style="max-width: 70%">
                                                        <mat-form-field appearance="outline" class="matFormField">
                                                            <input #filterSelectedMarketsTextBox autocomplete="off" aria-label="filterSelectedMarketsTextBox" matInput (keydown)="handleInput($event)" [formControl]="filterSearchTextboxControl">
                                                            <button [disableRipple]="true" *ngIf="filterSelectedMarketsTextBox.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event,'filter')">
                                   <mat-icon >close</mat-icon>
                                   </button>
                                                            <button [disableRipple]="true" *ngIf="filterSelectedMarketsTextBox.value===''" matSuffix mat-icon-button>
                                   <mat-icon >search</mat-icon>
                                   </button>
                                                        </mat-form-field>
                                                    </mat-optgroup>
                                                    <mat-optgroup *ngIf="(filterFilteredOptions | async) !== null && (filterFilteredOptions | async).length == 0">
                                                        <div>market not found!</div>
                                                    </mat-optgroup>
                                                    <!-- <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option.name">
                                {{option.name}}
                                </mat-option> -->
                                                    <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                                        <mat-checkbox *ngIf="filterSelectedMarketsTextBox.value===''" [(ngModel)]="allMarketsFilterSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllFilteredMarketsSelection()" class="calibrefont" style="padding-left: 16px!important;" color="primary">Select All</mat-checkbox>
                                                        <mat-option class="mat-option-text-style" (onSelectionChange)="handleFilterMarketsChange($event)" *cdkVirtualFor="let option of filterFilteredOptions | async" [value]="option.name">
                                                            {{option.name}}
                                                        </mat-option>
                                                    </cdk-virtual-scroll-viewport>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div *ngIf="filterSelection === 'states'">
                                    <mat-form-field appearance="outline" class="matFormField" style="padding-bottom: 10px !important;">
                                        <mat-label class="custom-mat-label">Select</mat-label>
                                        <mat-select class="mat-option-text-style" #statesSelect (openedChange)="filterStates($event, filterOptions[1].list)" multiple [(ngModel)]="filterOptions[1].list">
                                            <mat-checkbox [(ngModel)]="allStatesSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllStatesSelection()" class="calibrefont" style="padding-left: 16px!important; font-weight:600;" color="primary">Select All</mat-checkbox>
                                            <mat-option class="mat-option-text-style" (click)="optionClick()" *ngFor="let item of states.list" [value]="item.value">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="filterSelection === 'timezones'">
                                    <mat-form-field appearance="outline" class="matFormField" style="padding-bottom: 10px !important;">
                                        <mat-label class="custom-mat-label">Select</mat-label>
                                        <mat-select class="mat-option-text-style" multiple (openedChange)="filterTimeZones($event, filterOptions[0].list)" [(ngModel)]="filterOptions[0].list">
                                            <mat-option class="mat-option-text-style" *ngFor="let item of timeZones.list" [value]="item.value">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="filterSelection === 'laborforce'" style="padding-bottom: 10px !important;">
                                    <mat-form-field class="matFormField" appearance="outline" style="width: 33.33% !important;">
                                        <mat-label class="custom-mat-label">Value</mat-label>
                                        <input type="text" matInput appOnlynumber [maxLimit]=9 (change)="filterLaborForce()" style="font-size: 14px!important;" autocomplete="off" [(ngModel)]="filterOptions[2].value1">
                                    </mat-form-field>
                                    <mat-button-toggle-group (change)="filterLaborForce()" [(ngModel)]="filterOptions[2].operation" style="max-height: 4em; margin-top: 2px;">
                                        <mat-button-toggle value="inRange">
                                            <fa-icon [icon]="lessThanEqual"></fa-icon>
                                            <fa-icon [icon]="greaterThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="lessThan" style="margin-left: 12px;">
                                            <fa-icon [icon]="lessThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="moreThan" style="margin-left: 12px;">
                                            <fa-icon [icon]="greaterThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <mat-form-field class="matFormField" *ngIf="filterOptions[2].operation === 'inRange'" style="width: 33.33% !important;" appearance="outline">
                                        <mat-label class="custom-mat-label">Value</mat-label>
                                        <input matInput appOnlynumber appOnlynumber [maxLimit]=9 (change)="filterLaborForce()" [(ngModel)]="filterOptions[2].value2" autocomplete="off" style="font-size: 14px!important;">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="filterSelection === 'population'" style="padding-bottom: 10px !important;">
                                    <mat-form-field class="matFormField" appearance="outline" style="width: 33.33% !important;">
                                        <mat-label class="custom-mat-label">Value</mat-label>
                                        <input matInput appOnlynumber [maxLimit]=9 (change)="filterPopulation()" style="font-size: 14px!important;" [(ngModel)]="filterOptions[3].value1" autocomplete="off" style="font-size: 14px!important;">
                                    </mat-form-field>
                                    <mat-button-toggle-group (change)="filterPopulation()" [(ngModel)]="filterOptions[3].operation" style="max-height: 4em; margin-top: 2px;">
                                        <mat-button-toggle value="inRange">
                                            <fa-icon [icon]="lessThanEqual"></fa-icon>
                                            <fa-icon [icon]="greaterThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="lessThan" style="margin-left: 12px;">
                                            <fa-icon [icon]="lessThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="moreThan" style="margin-left: 12px;">
                                            <fa-icon [icon]="greaterThanEqual"></fa-icon>
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <mat-form-field class="matFormField" style="width: 33.33% !important;" *ngIf="filterOptions[3].operation === 'inRange'" appearance="outline">
                                        <mat-label class="custom-mat-label">Value</mat-label>
                                        <input matInput appOnlynumber [maxLimit]=9 (change)="filterPopulation()" [(ngModel)]="filterOptions[3].value2" autocomplete="off" style="font-size: 14px!important;">
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <!-- Additional Markets-->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="margin: 0px 0px; border-bottom: 1px solid rgba(0, 63, 45, 0.1);">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="navHeader">
                                    Additional Markets
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div style="font-size: 12px;">
                                <mat-form-field appearance="outline" class="matFormField">
                                    <mat-label class="custom-mat-label">Select Additional Markets</mat-label>
                                    <mat-select class="mat-option-text-style" (openedChange)="openedChange($event)" [formControl]="addSelectFormControl" multiple>
                                        <!-- <mat-select-trigger>
                       {{addSelectFormControl.value ? addSelectFormControl.value[0] : ''}}
                       <span *ngIf="addSelectFormControl.value?.length > 1" class="additional-selection">
                         (+{{addSelectFormControl.value.length - 1}} {{addSelectFormControl.value?.length === 2 ? 'other' : 'others'}})
                       </span>
                       </mat-select-trigger> -->
                                        <div class="select-container">
                                            <mat-optgroup style="max-width: 70%">
                                                <mat-form-field appearance="outline" class="matFormField">
                                                    <input #additionalMarkets autocomplete="off" aria-label="additionalMarkets" matInput (keydown)="handleInput($event)" [formControl]="addSearchTextboxControl">
                                                    <button [disableRipple]="true" *ngIf="additionalMarkets.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event,'')">
                             <mat-icon >close</mat-icon>
                             </button>
                                                    <button [disableRipple]="true" *ngIf="additionalMarkets.value===''" matSuffix mat-icon-button>
                             <mat-icon >search</mat-icon>
                             </button>
                                                </mat-form-field>
                                            </mat-optgroup>
                                            <mat-optgroup *ngIf="(addFilteredOptions | async) !== null && (addFilteredOptions | async).length == 0">
                                                <div>market not found!</div>
                                            </mat-optgroup>
                                            <!-- <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option.name">
                          {{option.name}}
                          </mat-option> -->
                                            <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                                <mat-option (onSelectionChange)="handleAddMarketsChange($event)" *cdkVirtualFor="let option of addFilteredOptions | async" [value]="option.name">
                                                    {{option.name}}
                                                </mat-option>
                                            </cdk-virtual-scroll-viewport>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                        <!-- Benchmark Baseline-->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="margin: 0px 0px; border-bottom: 1px solid rgba(0, 63, 45, 0.1);">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="navHeader">
                                    Benchmark Baseline
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div style="font-size: 12px;">
                                <mat-radio-group class="radio-group" style="margin-left: 10px;padding-left: 10px;" [(ngModel)]="benchMarkSelectionCriteria.baseLine" aria-labelledby="radio-group-label">
                                    <mat-radio-button class="radio-button calibrefont radioText" *ngFor="let baseLine of baseLineOptions" [value]="baseLine.value">
                                        {{baseLine.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-form-field class="matFormField" *ngIf="benchMarkSelectionCriteria.baseLine === 'SITEASBASELINE'" appearance="outline">
                                    <!-- <mat-select [(ngModel)] = "benchMarkSelectionCriteria.baseLineMarket">
                       <mat-option *ngFor="let market of siteSelectionMarkets" [value]="market.id">
                         {{market.name}}
                       </mat-option>                  
                       </mat-select> -->
                                    <mat-label class="custom-mat-label">Select Site</mat-label>
                                    <input type="text" id="benchmark" class="mat-option-text-style" matInput [formControl]="baseLineSiteControl" [(ngModel)]="benchMarkSelectionCriteria.baseLineMarketName" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <!-- <mat-option *ngFor="let market of marketFilteredOptions | async" [value]="market.name">
                          {{market.name}}
                          </mat-option> -->
                                        <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh;">
                                            <mat-option *cdkVirtualFor="let option of marketSearchResults | async" [value]="option.name" style="font-size: 12px;">
                                                {{option.name}}
                                            </mat-option>
                                        </cdk-virtual-scroll-viewport>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                        <div class="row ml-3 mr-1" style="display: block; height: 3em!important; font-size: 12px;">
                            <div id="criteriaSubHeader">
                                <div style="display: block; flex: 1 1 auto; font-size: 14px !important;" class="navHeader"> Filter Criteria ({{filteredMarkets.length + filterSelectedMarketIds.length + citiesSelectedMarkets.length}}) </div>
                                <button id="criteriaClearBtn" (click)="clearAllFilters()" mat-button>Clear</button>
                            </div>
                        </div>
                        <div class="row pl-3 pr-1 m-0" style="min-height:16%;">
                            <mat-chip-list class="mat-chip-list-stacked" #chipList aria-label="Market selection">
                                <mat-chip class="m-0" style="font-size: 12px!important; min-height: 2.5em;" *ngFor="let filter of filters.details" [selectable]="selectable" [removable]="removable" (removed)="clearFilterItem(filter.marketAttribute)">
                                    {{filter.displayText}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div class="row ml-3 mr-1" style="display: block; height: 3em!important; font-size: 12px;">
                            <div id="criteriaSubHeader">
                                <div style="display: block; flex: 1 1 auto; font-size: 14px !important;" class="navHeader"> Additional Markets ({{addSelectedMarkets.length}}) </div>
                                <button id="criteriaClearBtn" mat-button (click)="clearAdditionalMarkets()">Clear</button>
                            </div>
                        </div>
                        <div class="row pl-3 pr-1 m-0" style="height:15%; overflow-y: auto;">
                            <mat-chip-list #chipList aria-label="Market selection">
                                <mat-chip class="m-0" style="font-size: 12px!important;" *ngFor="let market of addSelectedMarkets" [selectable]="selectable" [removable]="removable" (removed)="removeMarket(market)">
                                    {{market}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </mat-accordion>
                </div>
            </div>
        </div>
        <div class="row m-0 justify-content-center">
            <button type="button" class="btn btn-primary submitButton resultsButton" style="width: 90%;" (click)="OnSubmit()" [disabled]="!userProfileService.canDownload() || benchMarkSelectionCriteria.profileName === '' || (addSelectedMarkets.length === 0 && (filteredMarkets.length + filterSelectedMarketIds.length + citiesFilterSelectedMarketIds.length == 0))">
     Download Report
     </button>
        </div>
        <div class="row mt-2 m-0 justify-content-center">
            <button type="button" class="btn btn-primary submitButton resultsButton" style="width: 90%;" [disabled]="true">
     Save Benchmark Query
     </button>
        </div>
    </mat-drawer>
    <div id="dataInfoSidebar" *ngIf="!isShowing">
        <div>
            <button mat-icon-button id="chevron_right" (click)="toggleFilters()">
           <mat-icon>chevron_right</mat-icon>
        </button>
        </div>
        <div id="verticalText" class="header1">Benchmark Settings</div>
    </div>
    <div class="example-sidenav-content">
        <!-- <button type="button" mat-button (click)="drawer.toggle()">
        Toggle sidenav
        </button> -->
        <div class="center-screen">
            <div style="width: 447px;
           height: 80px; background: #F5F7F7;
           border: 1px solid rgba(0, 63, 45, 0.15);
           border-radius: 4px;" class="row">
                <div style="padding: 20px" class="col-2">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M4 4H36V28H6.34L4 30.34V4ZM4 0C1.8 0 0.02 1.8 0.02 4L0 40L8 32H36C38.2 32 40 30.2 40 28V4C40 1.8 38.2 0 36 0H4ZM8 20H32V24H8V20ZM8 14H32V18H8V14ZM8 8H32V12H8V8Z" fill="#778F9C"/>
              </svg>
                </div>
                <div class="col-10" style="padding: 15px;">
                    <p class="reportDiv">Please select from the Benchmark menu to generate your report preview.</p>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>