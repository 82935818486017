import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { statusColorList } from './statusColorList';

@Directive({
  selector: '[Status]'
})
export class StatusDirective implements AfterViewInit {
  @Input() value:string;

  constructor(private el:ElementRef) {
   }

   ngAfterViewInit():void{
    this.el.nativeElement.firstChild.style.background=statusColorList[this.value];
   }

}
