import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { GeocodeRequest, GeocodeResponse } from 'src/app/core/models/geocode';
import { NotificationService } from 'src/app/core/notification.service';
import { GeocoderService } from 'src/app/core/services/geocoder.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ToasterService } from 'src/app/core/toaster.service';
import { Market } from 'src/app/models/market';
import { Profile } from 'src/app/models/profile';
import { ProjectInfoService } from 'src/app/services/projectInfo.service';
import { FormUtil } from 'src/app/util/FormUtil';
import { Util } from 'src/app/util/Util';

import { ReportParams } from '../../marketindex-report/marketindex.service';
import { DriveTimeService } from '../../services/drivetime/drivetime.service';

@Component({
  selector: 'app-params',
  templateUrl: './parameters.component.html',
  styleUrls: ['./params.component.scss'],
})
export class ParamsComponent implements OnInit {
  profileSelected: Profile;
  reportInputParams: ReportParams;
  driveTimeForm: FormGroup;
  driveTimeOptions: any[] = [
    { value: 15, text: '15 Minutes' },
    { value: 30, text: '30 Minutes' },
    { value: 45, text: '45 Minutes' },
    { value: 60, text: '1 Hour' },
  ];
  selectedDriveTimeOption;
  selectedMarket: string;
  profiles: Profile[] = [];
  markets: Market[] = [];
  selectedCity: string;
  selectedState: string;
  isGetAddress = false;
  address: any = {};
  Apierrors: any = {};
  warning;
  information;
  isAddressValidationFailure: boolean;
  isLatLongValidationFailure: boolean;
  projectName: string;

  constructor(
    private notificationService: NotificationService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private formUtil: FormUtil,
    private router: Router,
    private route: ActivatedRoute,
    private geocoderService: GeocoderService,
    private spinnerService: SpinnerService,
    private util: Util,
    private driveTimeService: DriveTimeService,
    private activatedrouter: ActivatedRoute,
    private loggingService: LoggingService,
    private projectInfoService: ProjectInfoService,
  ) {
    this.selectedDriveTimeOption = 30;
  }

  ngOnInit() {
    this.projectName = this.projectInfoService.projectName;

    // this.resolve()

    this.loadConfiguration();

    this.buildForm();

    this.loadAddressDiv();
  }

  loadConfiguration() {
    this.spinnerService.show('Loading Single Site...');

    this.getProfiles();

    this.getMarkets();
  }

  resolve() {
    this.activatedrouter.data.subscribe((data) => {
      console.log('TCL: ParamsComponent -> resolve -> data', data);
        this.spinnerService.show('Loading Single Site...');

        this.getProfiles();

        this.getMarkets();
        //this.router.navigate(['/SingleSite/Parameters']);
    });
  }

  private buildForm(): void {
    this.driveTimeForm = this.fb.group({
      profile: [null, Validators.required],
      driveTime: [this.selectedDriveTimeOption, Validators.required],
      address: [null, Validators.required],
      cityState: [null, Validators.required],
      zip: ['', Validators.required],
      lat: ['', [Validators.max(90), Validators.min(-90)]],
      lon: ['', [Validators.max(180), Validators.min(-180)]],
    });
  }

  getProfiles() {
    this.driveTimeService.getProfiles().subscribe(
      (res) => {
        console.log('Profiles fetch', res);

        this.profiles = this.util.sortArrayByPropertyName(res, 'profileName');

        const profile = new Profile(null, 'Choose a Profile');

        this.profiles = [profile, ...this.profiles];

        this.profiles.forEach((element) => {
          element.profileName = element.profileName.replace('GeoSkill - ', '');
        });

        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        console.log('TCL: ParamsComponent -> getProfiles -> error-comp', error);
        this.loggingService.logError(
          this.util.getLogObject(error.toString(), 'error while getting profiles', 'ParamsComponent.getProfiles'),
        );
        // this.toasterService.showErrorMessage('DriveTime Api service is not started, please contact system administrator.');
        if (error.status == 0) {
          //or whatever condition you like to put
          this.router.navigate(['/Error']);
        }
      },
    );
  }

  getMarkets() {
    this.driveTimeService.getMarkets().subscribe(
      (res) => {
        console.log('Markets fetch', res);
        this.markets = this.util.sortArrayByPropertyName(res, 'name');
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        console.log('TCL: ParamsComponent -> getMarkets -> error-comp', error);
        this.loggingService.logError(
          this.util.getLogObject(error.toString(), 'error while getting markets', 'ParamsComponent.getMarkets'),
        );
        // this.toasterService.showErrorMessage('DriveTime Api service is not started, please contact system administrator.');
        if (error.status == 0) {
          //or whatever condition you like to put
          this.router.navigate(['/Error']);
        }
      },
    );
  }

  typeaheadNoResults(event: any): void {
    this.driveTimeForm.controls['cityState'].setValue(null);
  }

  selectProfile(item) {
    const profile = new Profile(item.target.value, item.target.options[item.target.selectedIndex].text);
    this.profileSelected = profile;
  }

  isDriveTimeSelected(key) {
    return this.selectedDriveTimeOption === key;
  }

  selectDriveTime(key) {
    this.selectedDriveTimeOption = key;
    this.driveTimeForm.controls['driveTime'].setValue(key);
  }

  onSubmit() {
    console.log('Selected search criteria', this.driveTimeForm.value);

    if (this.validatform()) {
      if (this.driveTimeForm.value.address != null && this.driveTimeForm.value.address != '') {
        this.getGeocodes();
      } else if (this.driveTimeForm.value.lat != '' && this.driveTimeForm.value.lon != '') {
        this.getAddress();
      }
    }
  }

  private getGeocodes() {
    this.reportInputParams = new ReportParams();
    this.reportInputParams.profileID = this.profileSelected.profileID.toString();
    this.reportInputParams.profileName = this.profileSelected.profileName;
    this.reportInputParams.driveTimeInMinutes = this.selectedDriveTimeOption;
    this.reportInputParams.address =
      this.driveTimeForm.value.address == null ? '' : this.driveTimeForm.value.address.trim();
    this.reportInputParams.zipCode = this.driveTimeForm.value.zip == null ? '' : this.driveTimeForm.value.zip.trim();

    this.spinnerService.show('Searching for Geo Coordinates...');

    const geocodeRequest: GeocodeRequest = new GeocodeRequest();
    geocodeRequest.address =
      this.driveTimeForm.value.address +
      ' ' +
      this.driveTimeForm.value.cityState.name +
      ' ' +
      this.driveTimeForm.value.zip;

    this.geocoderService.geocode(geocodeRequest).subscribe((data) => {
      // Examine response.MatchStrength (0 low - 5 high).
      // We might want to show the user the response address to confirm that it is correct if MatchStrength is a 0 or 1.
      // this.spinnerService.hide();
      const geocodeResponse: GeocodeResponse = data as GeocodeResponse;
      console.log('Geocodes for selected profile and location', geocodeResponse);
      if (!geocodeResponse.addressWasFound) {
        this.loggingService.logError(
          this.util.getLogObject(
            'Addresses could not be converted into Lat/Long. Please change address and retry. . API Response: ' +
              JSON.stringify(geocodeResponse),
            JSON.stringify(geocodeRequest),
            'ParamsComponent.getGeocodes',
          ),
        );
        this.notificationService.error.next(
          'Addresses could not be converted into Lat/Long. Please change address and retry.',
        );
      } else {
        //this.reportInputParams.city = geocodeResponse.city;
        this.reportInputParams.city = this.selectedCity;
        this.reportInputParams.state = geocodeResponse.stateProv;
        this.reportInputParams.latitude = geocodeResponse.latitude.toString();
        this.reportInputParams.longitude = geocodeResponse.longitude.toString();

        const address =
          this.driveTimeForm.value.address +
          ', ' +
          this.reportInputParams.city +
          ', ' +
          this.reportInputParams.state +
          (this.driveTimeForm.value.zip != '' ? ', ' + this.driveTimeForm.value.zip : '');

        sessionStorage.setItem('DTAddress', address);
        sessionStorage.setItem('reportInputParams', JSON.stringify(this.reportInputParams));

        this.router.navigate(['/single-site/report'], {
          state: this.reportInputParams,
        });
      }
    });
  }

  onMarketSelect(event: TypeaheadMatch): void {
    this.driveTimeForm.controls['cityState'].setValue(event.item);
    this.selectedCity = event.item.name.split(',', 1)[0];
    console.log('ParamsComponent -> onMarketSelect -> this.selectedMarket', this.selectedMarket);
  }

  loadLatLongDiv() {
    var element = document.getElementById('div-latlong');
    element.classList.add('div-latlong');
    this.isGetAddress = true;

    var element = document.getElementById('div-address');
    element.classList.remove('div-address');
    this.isAddressValidationFailure = false;
    this.warning = '';

    //this.disableDiv();
  }

  loadAddressDiv() {
    var element = document.getElementById('div-address');
    element.classList.add('div-address');

    var element = document.getElementById('div-latlong');
    element.classList.remove('div-latlong');
    this.isGetAddress = false;
    this.isLatLongValidationFailure = false;
    this.warning = '';

    //this.disableDiv();
  }

  getAddress() {
    this.spinnerService.show('Searching for Address...');
    const reverseGeoCodeRequest = {
      inputId: 1,
      latitude: this.driveTimeForm.controls['lat'].value.trim(),
      longitude: this.driveTimeForm.controls['lon'].value.trim(),
    };

    this.geocoderService.getReverseGeoCodes(reverseGeoCodeRequest).subscribe((data) => {
      this.address = data;
      if (this.address.addressWasFound) {
        console.log('ParamsComponent -> getAddress -> addressWasFound', this.address);
        //this.loadAddress();
        let market: Market[] = [];
        if (this.address.city != '' && this.address.stateProv != '') {
          market = this._filter(this.address.city, this.address.stateProv);
        }
        console.log('ParamsComponent -> getAddress -> market', market);

        if (market.length > 0) {
          this.reportInputParams = new ReportParams();
          this.reportInputParams.profileID = this.profileSelected.profileID.toString();
          this.reportInputParams.profileName = this.profileSelected.profileName;
          this.reportInputParams.driveTimeInMinutes = this.selectedDriveTimeOption;
          this.reportInputParams.address = this.address.match_addr;
          this.reportInputParams.city = this.address.city;
          this.reportInputParams.state = this.address.stateProv;
          this.reportInputParams.zipCode = this.address.postalCode;
          this.reportInputParams.latitude = this.address.latitude;
          this.reportInputParams.longitude = this.address.longitude;
          this.reportInputParams.isReverseGeoCodeData = true;

          const address =
            this.driveTimeForm.value.address +
            ', ' +
            this.reportInputParams.city +
            ', ' +
            this.reportInputParams.state +
            ' ' +
            this.driveTimeForm.value.zip;

          sessionStorage.setItem(
            'DTAddress',
            this.address.match_addr +
              ' - ' +
              this.util.getFormatedLatLong(this.address.latitude, this.address.longitude),
          );
          sessionStorage.setItem('reportInputParams', JSON.stringify(this.reportInputParams));

          this.router.navigate(['/single-site/report'], {
            state: this.reportInputParams,
          });
        } else {
          this.spinnerService.hide();
          this.loggingService.logError(
            this.util.getLogObject(
              'We do not support this Lat/Long at this time. please try a different Lat/Long. API Response: ' +
                JSON.stringify(this.address),
              JSON.stringify(reverseGeoCodeRequest),
              'ParamsComponent.getAddress',
            ),
          );
          this.Apierrors.Addressnotfound =
            'We do not support this Lat/Long at this time. please try a different Lat/Long.';
        }
      } else {
        this.spinnerService.hide();
        this.loggingService.logError(
          this.util.getLogObject(
            'We do not support this Lat/Long at this time. please try a different Lat/Long. API Response: ' +
              JSON.stringify(this.address),
            JSON.stringify(reverseGeoCodeRequest),
            'ParamsComponent.getAddress',
          ),
        );
        this.Apierrors.Addressnotfound =
          'Lat/Long could not be converted into an address. Please change Lat/Long and retry.';
      }
    });
  }

  // loadAddress() {

  //     this.driveTimeForm.controls['address'].setValue(this.address.address);
  //     let market: Market[] = this._filter(this.address.city, this.address.stateProv);

  //     this.driveTimeForm.controls['cityState'].setValue(market[0].name);
  //     this.selectedMarket = market[0].name;
  //     this.selectedCity = market[0].city;
  //     this.selectedState = market[0].state;
  //     this.driveTimeForm.controls['zip'].setValue(this.address.postalCode);

  // }

  private _filter(city: string, state: string): Market[] {
    const cityValue = city.toLowerCase();
    const stateValue = state.toLowerCase();
    return this.markets.filter(
      (option) =>
        option.city.toLocaleLowerCase().includes(cityValue) && option.state.toLocaleLowerCase().includes(stateValue),
    );
  }

  private validatform(): boolean {
    let isvalid = true;
    const elementDivAdress = document.getElementById('div-address');
    const elementDivLatLong = document.getElementById('div-latlong');

    if (elementDivAdress.classList.contains('div-address')) {
      if (
        this.driveTimeForm.value.address == '' ||
        this.driveTimeForm.value.address == null ||
        this.selectedMarket == '' ||
        this.selectedMarket == null
      ) {
        this.isAddressValidationFailure = true;
      } else {
        this.isAddressValidationFailure = false;
      }
    } else if (elementDivLatLong.classList.contains('div-latlong')) {
      if (
        this.driveTimeForm.value.lat == '' ||
        this.driveTimeForm.value.lat == null ||
        this.driveTimeForm.value.lon == '' ||
        this.driveTimeForm.value.lon == null
      ) {
        this.isLatLongValidationFailure = true;
      } else {
        this.isLatLongValidationFailure = false;
      }
    }

    if (this.isAddressValidationFailure || this.isLatLongValidationFailure) {
      isvalid = false;
      this.warning = 'Please enter the correct address or lat/long and retry! ';
    }

    console.log('ParamsComponent -> validatform -> isvalid', isvalid);
    return isvalid;
  }

  disableDiv() {
    const elementDivAdress = document.getElementById('div-address');
    const elementDivLatLong = document.getElementById('div-latlong');

    if (elementDivAdress.classList.contains('div-address')) {
      if (
        (this.driveTimeForm.value.address != null && this.driveTimeForm.value.address != '') ||
        (this.driveTimeForm.value.zip != null && this.driveTimeForm.value.zip != '') ||
        (this.selectedMarket != null && this.selectedMarket != '')
      ) {
        this.driveTimeForm.controls['lat'].disable();
        this.driveTimeForm.controls['lon'].disable();
        this.information = 'Please clear address fields to use lat/long. ';
      } else {
        this.driveTimeForm.controls['lat'].enable();
        this.driveTimeForm.controls['lon'].enable();
        this.information = '';
      }
    } else if (elementDivLatLong.classList.contains('div-latlong')) {
      if (
        (this.driveTimeForm.value.lat != null && this.driveTimeForm.value.lat != '') ||
        (this.driveTimeForm.value.lon != null && this.driveTimeForm.value.lon != '')
      ) {
        this.driveTimeForm.controls['address'].disable();
        document.getElementById('citystateid').setAttribute('disabled', 'true');
        this.driveTimeForm.controls['zip'].disable();
        this.information = 'Please clear lat/long to use address fields. ';
      } else {
        this.driveTimeForm.controls['address'].enable();
        document.getElementById('citystateid').removeAttribute('disabled');
        this.driveTimeForm.controls['zip'].enable();
        this.information = '';
      }
    }
  }
}
