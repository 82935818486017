<div  style="display: flex; gap:4px; flex-direction: column;">
    <mat-form-field style="background:#fff;" appearance="outline" class="matFormField">
        <mat-label class="custom-mat-label">{{label?.label}}</mat-label>
        <mat-select  [formControl]="controlName" class="mat-option-text-style">

                <div class="select-container">
                    <mat-form-field appearance="outline">
                        <input #filterSearchTextBox autocomplete="off" matInput [(ngModel)]="value"
                            (keyup)="handleFilterOptions($event)" />
                        <button *ngIf="value" [disableRipple]="true" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button *ngIf="!value" [disableRipple]="true" matSuffix mat-icon-button>
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh">
                <mat-option class="mat-option-text-style" *cdkVirtualFor="let item of optionsData$ | async " [value]="item?.id">
                    {{ item?.name }} 
                </mat-option>
               </cdk-virtual-scroll-viewport>
        </mat-select>
    </mat-form-field>

    <mat-error *ngIf="controlName.hasError('required')">This field is required</mat-error>
</div>