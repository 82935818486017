<form [formGroup]="form" id="selectSearchForm">
  <mat-form-field style="width: 100%" appearance="outline">
    <div class="search-container">
      <input
        (click)="matSelect.toggle()"
        type="text"
        [placeholder]="field.label"
        [attr.arialabel]="field.label"
        matInput
        [formControl]="inputFilterControl"
        (blur)="onBlur()"
      />
      <div (click)="matSelect.toggle()">
        <mat-icon style="color: rgba(26, 26, 26, 0.6)"
          >arrow_drop_down</mat-icon
        >
      </div>
    </div>
    <mat-label class="mat-label-background">{{ field.label }}</mat-label>
    <mat-select
      #matSelectItem
      [formControlName]="field.control"
      class="mat-option-text-style"
      disableOptionCentering
    >
      <cdk-virtual-scroll-viewport
        *ngIf="isVirtualScrollEnabled"
        [itemSize]="10"
        style="height: 260px"
      >
        <ng-container *ngIf="withLabel">
          <mat-option
            class="mat-option-text-style itemLabelBlock"
            *cdkVirtualFor="let item of options"
            [value]="item?.id"
          >
            <div
              [ngClass]="
                item?.label ? 'itemLabelDetails' : 'mat-option-block itemDetail'
              "
            >
              <p class="name">{{ item?.name }}</p>
              <p class="label">{{ item?.label }}</p>
            </div>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!withLabel">
          <mat-option
            class="mat-option-text-style mat-option-block"
            *cdkVirtualFor="let item of options"
            [value]="item?.id"
          >
            {{ item.name }}
          </mat-option>
        </ng-container>
      </cdk-virtual-scroll-viewport>
      <div
        *ngIf="!isVirtualScrollEnabled"
        style="max-height: 260px; overflow-y: auto"
      >
        <ng-container *ngIf="withLabel">
          <mat-option
            class="mat-option-text-style itemLabelBlock"
            *ngFor="let item of options"
            [value]="item?.id"
          >
            <div
              [ngClass]="
                item?.label ? 'itemLabelDetails' : 'mat-option-block itemDetail'
              "
            >
              <p class="name">{{ item?.name }}</p>
              <p class="label">{{ item?.label }}</p>
            </div>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!withLabel">
          <mat-option
            class="mat-option-text-style mat-option-block"
            *ngFor="let item of options"
            [value]="item?.id"
          >
            {{ item.name }}
          </mat-option>
        </ng-container>
      </div>
    </mat-select>
  </mat-form-field>
  <div *ngIf="field.helperText" class="helper-text">{{ field.helperText }}</div>
</form>
