<form [formGroup]="copyForm">
    <h2 mat-dialog-title>{{data.headerText}}</h2>
    <mat-dialog-content class="mat-typography"
        style="font-family: Futura Md BT; font-size: 12px!important; overflow:hidden!important;">
        <div class="row pl-4">
            <span class="col-5 p-0 mb"> External Profile Name </span>
            <span class="col-5" *ngIf="!sameAsInternal.checked"> Internal Profile Name </span>
        </div>
        <div class="row pl-4">
            <input class="form-control col-5 mr-3" type="text" formControlName="externalProfileName"
            autocomplete="off">
            <!-- <input class="form-control col-5 mr-3" type="text" formControlName="externalProfileName"
                [matAutocomplete]="auto" autocomplete="off">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete> -->
            <input *ngIf="!sameAsInternal.checked" class="form-control col-5 " type="text"
                formControlName="internalProfileName" autocomplete="off">
        </div>

        <div class="row pl-4 text-left">
            <span class="text-danger pl-1 col-6"
                *ngIf="(registerFormControl.externalProfileName.touched || submitted) && registerFormControl.externalProfileName?.errors?.required">
                External name is required </span>
        </div>

        <div class="row pl-4">
            <div class="col-2 p-0 mt-1">
                <mat-checkbox #sameAsInternal class="form-control" formControlName="sameAsInternalName"
                    style="border: 0px; padding: 0px!important; display: flex; float: left; font-size: 12px!important;">
                    Same as internal name
                </mat-checkbox>
            </div>
        </div>

        <div class="row pl-4 mt-2"> <span> How would you like Score Assignment to populate? </span> </div>
        <div class="row pl-4">
            <mat-radio-group class="col-7 p-0 mt-2">
                <mat-radio-button value="auto" [checked]="true"> Automatically </mat-radio-button>
                <mat-radio-button value="manual" [disabled]="true"> Manually </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="row pl-4 mt-2"> <span> Select a country </span> </div>
        <div class="row pl-4">
            <mat-radio-group class="col-8 p-0 mt-2">
                <mat-radio-button value="USA" [checked]="true"> USA </mat-radio-button>
                <mat-radio-button value="CANADA" [disabled]="true"> Canada </mat-radio-button>
            </mat-radio-group>
        </div>
        <!-- <h3>{{data.bodyText}}</h3> -->
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="onCancel()">Cancel</button>
        <button mat-button cdkFocusInitial (click)="onAdd()">Add</button>
    </mat-dialog-actions>
</form>