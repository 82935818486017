import { Injectable } from '@angular/core';
import { filter, map, combineLatest, delay } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserProfileService } from '@services/userProfile.service';
import { UserProfileRoleAccesses } from '../models/userProfile.model';
import { MegamenuService } from '@services/megamenu.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private megamenuService: MegamenuService,
  ) {}

  forbiddenAccess() {
    this.router.navigate(['/']);
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pipe = combineLatest([
      this.userProfileService.userAccess$,
      this.megamenuService.megaMenuData$,
      this.userProfileService.mappedModules$,
    ]).pipe(
      filter((data: Array<any>) => data[0] !== null && data[1]?.length && data[2] !== null),
      map(([user, megamenuData]: [UserProfileRoleAccesses, any]) => {
        if (user.activeStatus === false) return this.forbiddenAccess();

        const currentTime = new Date(Date.now());
        const startAccessTime = new Date(user.accessStartDate);
        const endAccessTime = new Date(user?.accessEndDate);
        if (user.accessStartDate && startAccessTime > currentTime) return this.forbiddenAccess();
        if (user?.accessEndDate && currentTime > endAccessTime) return this.forbiddenAccess();

        const currentAccessItemName = this.userProfileService.getCurrentAccessItemName(state.url);

        if (!currentAccessItemName) return this.forbiddenAccess();

        const accessItem = user.accessItems?.find((item) => {
          return _.unescape(item?.itemName)?.toLowerCase()?.includes(currentAccessItemName);
        });

        if (accessItem === undefined) return this.forbiddenAccess();
        if (accessItem.viewAccessValue !== 'Enabled') return this.forbiddenAccess();

        return true;
      }),
    );
    return pipe;
  }
}
