import { Component, HostBinding, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from '@services/user.service';
import { CompetitionDataService, FormService } from 'core';
import { filter, Observable } from 'rxjs';

@Component({
  selector: 'app-searchcompetition',
  templateUrl: './searchcompetition.component.html',
  styleUrls: ['./searchcompetition.component.scss'],
})
export class SearchcompetitionComponent implements OnInit {
  @HostBinding('class.emeraldstandard') public emeraldstandard = true;
  isRightSidenavOpened$: Observable<boolean>;
  isLeftSidenavOpened$: Observable<boolean>;
  isAddRecordOpened$: Observable<boolean>;
  isFilterCompetitionOpened$: Observable<boolean>;
  constructor(
    private formService: FormService,
    private userService: UserService,
    private competitionDataService: CompetitionDataService,
  ) {}

  ngOnInit() {
    this.isRightSidenavOpened$ = this.formService.isRightSideDrawerOpened$;
    this.isLeftSidenavOpened$ = this.formService.isLeftSideDrawerOpened$;
    this.isAddRecordOpened$ = this.competitionDataService.isAddRecordDrawerOpened$;
    this.isFilterCompetitionOpened$ = this.competitionDataService.isFilterCompetitionDrawerOpened$;
    this.formService.setEnvironment(environment);
    this.formService.leftSideDrawerHandler(true);
    this.userService.getUser().pipe(filter(user=>!!user)).subscribe(user=>this.formService.setUser(user));
  }
}
