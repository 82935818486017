import { ThemeTypes } from "./themes.model";

export class UserProfileCompany {
  public companyId?: number;
  public name: string;
  public address: string;
  public city: string;
  public stateAbbr: string;
  public stateId: number;
  public postalCode: string;
  public region: string;
  public companySize: string;
  public countryId: number;
  public industryId: number;
}

export interface UserProfileInfo {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  title?: string;
  phoneNumber?: string;
  userImagePath?: string;
  userImagePathUrl?: string;
  clientAddressDetailsId?: number;
  company?: UserProfileCompany;
  clientId?: number;
  uiTheme?: ThemeTypes;
  newsLetter?:NewsLetterConfig;
}


export interface NewsLetterConfig {
    emailID: string;
    industries: number[]
    topics: number[]
  
}

type ItemPermissions = 'Module' | 'Reports' | string;
export enum AccessPermission {
  ADD_EDIT = 'addEditAccessValue',
  VIEW = 'viewAccessValue',
  DOWNLOAD_PRINT = 'downloadPrintAccessValue',
};
export type AccessItem = {
  itemId: number;
  itemName: string;
  itemParentId: number;
  itemPermissions: ItemPermissions;
  addEditAccessId?: AccessIdEnum;
  addEditAccessValue?: string;
  viewAccessId: AccessIdEnum;
  viewAccessValue: string;
  downloadPrintAccessId: AccessIdEnum;
  downloadPrintAccessValue: string;
};

export enum AccessIdEnum {
  DENIED = 2,
  ENABLED = 1,
  DISABLED = 0.
}


export interface UserProfileRoleAccesses extends UserProfileInfo {
  contactInformation: string;
  market: string;
  marketId: number;
  lagRoleId: number;
  roleType: string;
  lastLoginDate: string;
  accessStartDate: string;
  accessEndDate?: string;
  accessItems: AccessItem[];
  activeStatus: boolean;
  reportAccess: string;
  moduleAccess: string;
}
