import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, Observable, catchError, map } from 'rxjs';

import * as moment from 'moment';

import { BaseService } from './base.service';
import { FormService } from 'core';

@Injectable({
  providedIn: 'root',
})
export class CompetitionService extends BaseService {
  competitionFilterPayloads$$ = new BehaviorSubject(null);
  private spinnerLessHeader = new HttpHeaders().set('Content-Type', 'application/json').set('X-Skip-Interceptor', '');
  
  competitionItem$ = new BehaviorSubject(null);

  activeFilter = new BehaviorSubject({
    values: null,
    metadata: null,
    form: null,
    callback: null,
  });

  constructor(
    override http: HttpClient,
    private formService: FormService,
  ) {
    super(http);
  }

  updateFilter(data) {
    this.activeFilter.next({ ...this.activeFilter.getValue(), ...data });
  }

  get filter() {
    return this.activeFilter.asObservable();
  }

  get competitionItem() {
    return this.competitionItem$.asObservable();
  }

  updateCompetitionItem(item) {
    this.competitionItem$.next(item);
  }

  get competitionFilterPayload() {
    const payloads = this.competitionFilterPayloads$$.getValue();
    const defaultFilter = {
      locationIds: [],
      boundary: {
        value: null,
        type: '',
      },
      categoryIds: [],
      employerIds: [],
      industryIds: [],
      operationIds: [],
      startLastUpdatedDate: moment(
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
      ).format('MM/DD/YYYY'),
      endLastUpdatedDate: moment(new Date()).format('MM/DD/YYYY'),
    };
    return payloads?.length ? payloads : [defaultFilter];
  }

  fetchCompetitionData(showSkeleton = true, payloads = this.competitionFilterPayload) {
    if (showSkeleton) {
      this.formService.updateDataGrid({ data: [], isLoading: false, isError: false });
    }

    const observables: Observable<any>[] = payloads.map((payload) => {
      return this.http.post(`${environment.competitionApiUrl}/api/competition`, payload, {
        headers: this.spinnerLessHeader,
      });
    });

    combineLatest(observables)
      .pipe(
        map((res) => res),
        catchError((e) => Promise.reject(e)),
      )
      .subscribe(
        (data: Array<any>) => {
          const result = [];
          data?.forEach((competitionData) => competitionData?.forEach((i) => result.push(i)));

          this.formService.updateDataGrid({
            data: [],
            isLoading: false,
            isError: false,
          });
          this.formService.updateDataGrid({
            isLoading: true,
            data: result,
            isError: false,
          });
        },
        (err) => {
          console.log('error in data fetch - table', err);
          this.formService.updateDataGrid({
            isLoading: true,
            data: [],
            isError: true,
          });
        },
      );
  }
}
