import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { LoggingService } from './logging.service';
import { GeocodeRequest, GeocodeResponse } from '../models/geocode';
import { ReverseGeocodeRequest } from '../models/reverseGeoCode';

import { InterceptorSkipHeader } from 'src/app/httpInterceptor/TokenHttpInterceptor';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  private url = environment.baseUrl + 'api/geocoder/geocode';
  header = { 
    headers: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set(InterceptorSkipHeader, ''),
  };

  constructor(private httpClient: HttpClient, private loggingService: LoggingService) {}

  geocode(request: GeocodeRequest): Observable<string | GeocodeResponse> {
    return this.httpClient.post<GeocodeResponse>(this.url, request).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }
  getBatchGeoCodes(batchGeocodeRequest: GeocodeRequest[], skipHeader = false): Observable<any> {
    console.log('TCL: GeocoderService -> constructor -> batchGeocodeRequest', batchGeocodeRequest);
    const apiUrl = environment.adminUrl + '/api/geocoder/BatchGeocode';

    return this.httpClient.post(apiUrl, batchGeocodeRequest, skipHeader ? this.header : {}).pipe(
      map((res) => res),
      catchError(this.handleError),
    );

    // return this.httpClient.get(apiUrl)
    //   .pipe(
    //     map(res => res),
    //     catchError(this.handleError)
    //   );
  }

  getReverseBatchGeoCodes(reverseBatchGeocodeRequest: ReverseGeocodeRequest[]): Observable<any> {
    console.log('TCL: GeocoderService -> constructor -> reverseBatchGeocodeRequest', reverseBatchGeocodeRequest);
    const apiUrl = environment.adminUrl + '/api/geocoder/BatchReverseGeocode';

    return this.httpClient.post(apiUrl, reverseBatchGeocodeRequest).pipe(
      map((res) => res),
      catchError(this.handleError),
    );

    // return this.httpClient.get(apiUrl)
    //   .pipe(
    //     map(res => res),
    //     catchError(this.handleError)
    //   );
  }

  getReverseGeoCodes(reverseGeoCodeRequest: any): Observable<any> {
    const apiUrl = environment.baseUrl + 'api/geocoder/ReverseGeoCode';

    return this.httpClient.post(apiUrl, reverseGeoCodeRequest).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
