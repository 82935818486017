import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfoService {
  private _projectId: number;
  private _projectName: string;

  constructor() {}

  public get projectId(): number {
    return this._projectId;
  }
  public set projectId(value: number) {
    this._projectId = value;
  }

  public get projectName(): string {
    return this._projectName;
  }
  public set projectName(value: string) {
    this._projectName = value;
  }
}
