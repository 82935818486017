import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SpinnerService } from 'src/app/core/spinner.service';

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { DrivetimeFeature, ImageSet } from 'src/app/models/drivetimeFeature.model';
import { NationalAndMarketWages } from 'src/app/models/nationalAndMarketWages.model';
import { ProjectInfoService } from 'src/app/services/projectInfo.service';

import { MarketIndexService, ReportParams } from '../../marketindex-report/marketindex.service';
import { ORDEducationAttainment } from '../../models/educationAttainment.model';
import { MedianWage } from '../../models/medianWage.model';
import { NewDriveTimeReportData } from '../../models/newDriveTimeReportData.model';
import { PostSecondarySchools } from '../../models/postSecondarySchools.model';
import { DriveTimeService } from '../../services/drivetime/drivetime.service';

@Component({
  selector: 'app-new-dt-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class NewDTReportComponent implements OnInit {
  public auditId = 0;
  public profileId = '';
  public address = '';
  public city = '';
  public state = '';
  public latitude = '';
  public longitude = '';
  public driveTimeInMinutes = '';
  public selectedProfileName = '';
  public zipCode = '';
  public fullAddress = '';
  public totalPageCount = 6;
  public isReverseGeoCodeData = false;

  public ordData: NewDriveTimeReportData;
  public demographicsGrid: any = null;
  nationalDemographics: any[];
  nationalEducation: any[];
  nationalOccupation: any[];
  public educationAttainmentGrid: ORDEducationAttainment[] = null;
  public employmentByOccupationGrid: any = null;
  public postSecondarySchools: PostSecondarySchools = null;
  public nationalAndMarketWages: NationalAndMarketWages = null;
  public medianWages: Array<MedianWage> = null;
  public educationAttainmentGraph: any = null;
  public employmentByOccupationGraph: any = null;
  public medianWagesGraph: any = null;

  displayedWagesColumns: string[] = ['alphabetASCIISerial', 'jobTitleNExperienceDuration', 'market', 'national'];
  public methodCount = 0;
  // greenArrowLogo: any = require('../../../assets/images/Green-arrow-end logo.png');
  // public Editor = ClassicEditor;

  //Indexing properties
  marketIndexesGraph: any = null;
  costSkillSets: number;
  availabilityandQuality: number;
  totalIndex: any = '--';
  totalIndexColorCode = '#4285F4';
  marketIndexeslist: any = [];
  marketindex = {
    index: null,
    datapointname: null,
    colorcode: null,
  };
  Apierrors: any = {};
  exportedExcel = false;
  exportedPdf = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    private _marketIndexService: MarketIndexService,
    private driveTimeService: DriveTimeService,
    private projectNameService: ProjectInfoService,
  ) {
    this.methodCount = 0;
    this.spinnerService.show('Working on the Report...');
  }

  ngOnInit() {
    this.loadQueryParams();

    this.getMarketIndexes();

    this.getNewDriveTimeReport();

    this.getPostSecondarySchools();

    this.getMedianWages();

    this.SingleSiteAuditinfo();
  }

  loadQueryParams() {
    if (history.state.profileID) {
      this.loadParams(history.state);
    } else {
      const param = JSON.parse(sessionStorage.getItem('reportInputParams'));
      this.loadParams(param);
    }
  }

  loadParams(params) {
    this.profileId = params.profileID;
    this.selectedProfileName = params.profileName;
    this.latitude = params.latitude;
    this.longitude = params.longitude;
    this.isReverseGeoCodeData = params.isReverseGeoCodeData;
    this.driveTimeInMinutes = params.driveTimeInMinutes;
    this.address = params.address;
    this.city = params.city;
    this.state = params.state;
    this.zipCode = params.zipCode;
    this.fullAddress = params.address + ',' + params.city + ',' + params.state + ',' + params.zipCode;
  }

  private SingleSiteAuditinfo() {
    console.log('projectName' + this.projectNameService.projectName);
    const request = {
      // projectName: this.projectNameService.projectName,
      siteType: 'Single',
      profile: this.profileId + '-' + this.selectedProfileName,
      driveTime: this.driveTimeInMinutes,
      address: this.fullAddress,
      lat: this.latitude,
      lon: this.longitude,
    };

    this.driveTimeService.postSingleSiteAuditinfo(request).subscribe(
      (res) => {
        this.auditId = res.auditId;
        console.log('Drive-Time: AuditId for Report', this.auditId);
      },
      (error) => {},
    );
  }

  private updateSingleSiteAuditinfo() {
    this.driveTimeService.updateSingleSiteAuditinfo(this.auditId, this.exportedExcel, this.exportedPdf).subscribe(
      (res) => {
        this.auditId = res.auditId;
      },
      (error) => {},
    );
  }

  getNewDriveTimeReport() {
    const apiParams: string =
      'lat=' + this.latitude + '&long=' + this.longitude + '&driveTimeInMinutes=' + this.driveTimeInMinutes;
    this.methodCount++;
    this.driveTimeService.getDriveTimeReport(apiParams).subscribe(
      (data) => {
        this.ordData = data as NewDriveTimeReportData;
        console.log('Drive-Time: Report Data', this.ordData);
        this.plotEmploymentByOccupation();
        this.plotEducationAttainment();
        this.getDemographicsNationalvalues();
        this.methodCount--;
        this.hideSpinner();
      },
      (error) => {
        console.error('Drive-Time: NewDTReportComponent -> getMarketIndexes -> error', error);
        this.Apierrors.EmploymentByOccupation =
          'Error in fetching Employment By Occupation, please retry in few minutes.If error persists, please contact system administrator.';
        this.Apierrors.EducationAttainment =
          'Error in fetching Education Attainment, please retry in few minutes.If error persists, please contact system administrator.';
        this.spinnerService.hide();
      },
    );
  }

  getPostSecondarySchools() {
    this.methodCount++;
    const apiParams = 'city=' + this.city + '&state=' + this.state;
    this.driveTimeService.getPostSecondarySchools(apiParams).subscribe(
      (data) => {
        this.postSecondarySchools = data as PostSecondarySchools;
        console.log('Drive-Time: Secondary School Data', this.postSecondarySchools);
        this.methodCount--;
        this.hideSpinner();
      },
      (error) => {
        console.error('Drive-Time: NewDTReportComponent -> getDemographicsNationalvalues -> error', error);
        this.Apierrors.PostSecondarySchools =
          'Error in fetching Post Secondary Schools, please retry in few minutes.If error persists, please contact system administrator.';
        this.spinnerService.hide();
      },
    );
  }

  getMedianWages() {
    this.methodCount++;
    this.medianWages = new Array<MedianWage>();
    const apiParams = 'profileID=' + this.profileId + '&city=' + this.city + '&state=' + this.state;

    this.driveTimeService.getMedianWages(apiParams).subscribe(
      (data) => {
        this.nationalAndMarketWages = data as NationalAndMarketWages;
        console.log('Drive-Time: National and Market Wages Data', this.nationalAndMarketWages);
        this.plotMedianWages();
        this.methodCount--;
        this.hideSpinner();
      },
      (error) => {
        console.error('Drive-Time: NewDTReportComponent -> getDemographicsNationalvalues -> error', error);
        this.Apierrors.MedianWages =
          'Error in fetching Median Wages, please retry in few minutes.If error persists, please contact system administrator.';
        this.spinnerService.hide();
      },
    );
  }

  getDemographicsNationalvalues() {
    this.methodCount++;
    this.driveTimeService.getDemographicsNationalvalues().subscribe(
      (res) => {
        this.nationalDemographics = res.NationalDemographics;
        console.log('Drive-Time: National Demographics Data', this.nationalDemographics);
        this.plotDemographics();
        this.methodCount--;
        this.hideSpinner();
      },
      (error) => {
        console.error('Drive-Time: NewDTReportComponent -> getDemographicsNationalvalues -> error', error);
        this.Apierrors.Demographics =
          'Error in fetching Demographics, please retry in few minutes.If error persists, please contact system administrator.';
        this.spinnerService.hide();
      },
    );
  }

  getEducationNationalvalues() {
    this.driveTimeService.getEducationNationalvalues().subscribe((res) => {
      this.nationalEducation = [];
      this.ordData.EducationAttainment.forEach((element) => {
        const national = res.NationalEducationAttainment.filter((x) => x.dataPointName == element.dataPointName)[0];
        this.nationalEducation.push({
          dataPointName: element.dataPointName,
          dataPointValue: element.dataPointValue,
          nationalValue: national.dataPointValue,
        });
      });
      this.getOccupationsNationalvalues();
    });
  }

  getOccupationsNationalvalues() {
    this.driveTimeService.getOccupationsNationalvalues().subscribe((res) => {
      this.nationalOccupation = [];
      this.ordData.EmploymentByOccupation.forEach((element) => {
        const national = res.NationalEmploymentByOccupation.filter((x) => x.dataPointName == element.dataPointName)[0];
        this.nationalOccupation.push({
          dataPointName: element.dataPointName,
          dataPointValue: element.dataPointValue,
          nationalValue: national.dataPointValue,
        });
      });
      console.log(
        'Drive-Time: NewDTReportComponent -> getOccupationsNationalvalues -> this.nationalOccupation',
        this.nationalOccupation,
      );
      this.export();
    });
  }

  hideSpinner() {
    if (this.methodCount == 0) this.spinnerService.hide();
  }

  export() {
    const exportData: any = [
      {
        DriveTimeInMinutes: this.driveTimeInMinutes,
        LocationDetails: this.address,
        Latitude: this.latitude,
        Longitude: this.longitude,
        isReverseGeoCodeData: this.isReverseGeoCodeData,
        ZipCode: this.zipCode,
        State: this.state,
        TimeZone: this.getTimezoneName(),
        TotalIndex: this.totalIndex,
        Demographics: this.demographicsGrid,
        MarketIndex: this.marketIndexeslist,
        postSecondarySchools: this.postSecondarySchools,
        EmploymentByOccupation: this.nationalOccupation,
        MedianWages: this.medianWages,
        Educations: this.nationalEducation,
      },
    ];
    console.log('Drive-Time: NewDTReportComponent -> export -> this.ordData', this.ordData);
    this._marketIndexService.exportAsExcelFile(exportData);
    this.spinnerService.hide();
  }

  getTimezoneName() {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }

  getMarketIndexes() {
    this.methodCount++;
    const params = new ReportParams();
    params.profileID = this.profileId;
    params.city = this.city;
    params.state = this.state;
    params.latitude = this.latitude;
    params.longitude = this.longitude;
    params.driveTimeInMinutes = this.driveTimeInMinutes;

    this._marketIndexService.getMarketIndex(params).subscribe(
      (response) => {
        console.log('Drive-Time: Market Index Data', response);
        this.prepareMarketIndexes(response);
        this.plotGraph();
        this.methodCount--;
        this.hideSpinner();
      },
      (error) => {
        console.error('Drive-Time: NewDTReportComponent -> getMarketIndexes -> error', error);
        this.Apierrors.Indexing =
          'Error in fetching market scores, please retry in few minutes.If error persists, please contact system administrator.';
        this.Apierrors.OptimalBalance =
          'Error in fetching Optimal Balance, please retry in few minutes.If error persists, please contact system administrator.';
        this.spinnerService.hide();
      },
    );
  }

  prepareMarketIndexes(marketIndexes: any[]) {
    marketIndexes.forEach((element) => {
      switch (element.datapointname) {
        case 'Labor Supply':
          var marketindex: any = {};

          marketindex.position = 1;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.availabilityandQuality = marketindex.index;
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Availability and Quality':
          var marketindex: any = {};

          marketindex.position = 2;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Skill Set Density':
          var marketindex: any = {};

          marketindex.position = 3;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Labor Cost':
          var marketindex: any = {};

          marketindex.position = 4;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.costSkillSets = marketindex.index;
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Costs of Living':
          var marketindex: any = {};

          marketindex.position = 5;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Median Market Wages':
          var marketindex: any = {};

          marketindex.position = 6;
          marketindex.datapointname = element.datapointname;
          marketindex.index = Math.round(element.index.toFixed(2));
          marketindex.colorcode = this.getColorCoding(marketindex.index);
          this.marketIndexeslist.push(marketindex);
          break;
        case 'Total Index':
          this.totalIndex = Math.round(element.index.toFixed(2));
          this.totalIndexColorCode = this.getColorCoding(this.totalIndex);
          break;
        default:
          break;
      }
    });
    this.sortorder(this.marketIndexeslist);
    // this.spinnerService.hide();
  }

  getColorCoding(index: any): string {
    let colorcode = '';

    switch (true) {
      case index >= 115:
        colorcode = '#41B049';
        break;
      case index >= 105 && index <= 114:
        colorcode = '#A5CE3A';
        break;
      case index >= 95 && index <= 104:
        colorcode = '#FEBC15';
        break;
      case index >= 85 && index <= 94:
        colorcode = '#FF9900';
        break;
      case index <= 84:
        colorcode = '#E32726';
        break;
      default:
        colorcode = '';
        break;
    }

    return colorcode;
  }

  sortorder(arraylist: any) {
    // var sortorder = reasons.sort((a, b) => a.position - b.position);
    const sortorder = arraylist.sort(function (a, b) {
      const x = a['position'];
      const y = b['position'];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  plotGraph() {
    const xAxis = [];
    xAxis.push(this.costSkillSets);
    const yAxis = [];
    yAxis.push(this.availabilityandQuality);

    const trace1 = {
      x: xAxis,
      y: yAxis,
      type: 'scatter',
      mode: 'markers',
      name: this.city,
      marker: { size: 15 },
      showlegend: false,
    };

    // var trace2 = {
    //   x: [2],
    //   mode: 'lines',
    //   showlegend: false,
    //   line: { color: 'black' }
    // };

    // var trace3 = {
    //   y: [2],
    //   mode: 'lines',
    //   showlegend: false,
    //   line: { color: 'black' }
    // };
    const trace2 = {
      x: [89, 93, 98, 102, 107, 111],
      y: [100, 100, 100, 100, 100, 100],
      mode: 'lines',
      showlegend: false,
      line: { dash: 'dash', color: 'black' },
    };

    const trace3 = {
      y: [61, 77, 92, 108, 123, 139],
      x: [100, 100, 100, 100, 100, 100],
      mode: 'lines',
      showlegend: false,
      line: { dash: 'dash', color: 'black' },
    };

    const data = [trace1, trace2, trace3];

    const layout = {
      xaxis: {
        title: {
          text: 'Cost Comparison',
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      yaxis: {
        title: {
          text: 'Supply',
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      hovermode: 'closest',
    };

    this.marketIndexesGraph = {
      data: data,
      layout: layout,
    };
  }

  exportToExcel() {
    this.getEducationNationalvalues();
    this.exportedExcel = true;
    this.updateSingleSiteAuditinfo();
  }

  plotMedianWages() {
    let alphabetASCII = 65;
    this.nationalAndMarketWages.MarketWages.forEach((mrkt) => {
      const nationalWage = this.nationalAndMarketWages.NationalWages.find(
        (ntnl) =>
          ntnl.JobTitle == mrkt.JobTitle &&
          ntnl.Timeframe == mrkt.Timeframe &&
          ntnl.ExperienceDuration == mrkt.ExperienceDuration,
      );

      const medianWage: MedianWage = new MedianWage(
        String.fromCharCode(alphabetASCII++),
        mrkt.JobTitle,
        mrkt.Wages,
        nationalWage.Wages,
        nationalWage.ExperienceDuration + ' ' + nationalWage.Timeframe,
      );

      this.medianWages.push(medianWage);
    });

    // this.marketWages.forEach(el => {
    //   eaData.push( { x: [(el.dataPointValue / 100)], type: 'bar', name: el.dataPointName } );
    // });

    const xAxis = [];
    const yAxis = [];
    const labels = [];
    const yAxisCurrency = [];
    this.medianWages.forEach((el) => {
      xAxis.push(el.alphabetASCIISerial);
      labels.push(el.jobTitle + ' - ' + el.experience);
      yAxis.push(el.marketWage);
      yAxisCurrency.push('$' + el.marketWage.toLocaleString());
    });

    //hoverinfo: 'none'
    //, hovertext: labels
    this.medianWagesGraph = {
      //data: this.medianWages,
      data: [
        {
          x: xAxis,
          y: yAxis,
          type: 'bar',
          text: yAxisCurrency,
          textposition: 'outside',
          font: { color: '#000' },
          hoverinfo: 'text',
          hovertext: labels,
        },
      ],
      layout: {
        yaxis: { tickformat: '$,' },
        height: 500,
        legend: {
          //traceorder: 'normal',
          font: {
            family: 'sans-serif',
            size: 10,
            color: '#000',
          },
          bgcolor: '#E2E2E2',
          bordercolor: '#FFFFFF',
          borderwidth: 1,
        },
        // annotations: {
        //   text: yAxis
        // }
      },
    };
  }

  plotDemographics() {
    const demographicslist: any = [];

    if (this.ordData != null && this.ordData.Demographics != null) {
      //finding national value for each datapoint
      this.ordData.Demographics.forEach((element) => {
        const nationalValue = this.nationalDemographics.filter((x) => x.dataPointName == element.dataPointName)[0];
        demographicslist.push({
          dataPointName: element.dataPointName,
          dataPointValue: element.dataPointValue,
          national: nationalValue != null ? nationalValue.dataPointValue : 0,
        });
      });
      this.demographicsGrid = demographicslist;
    } else {
      this.Apierrors.Demographics =
        'Error in fetching Demographics, please retry in few minutes.If error persists, please contact system administrator.';
    }
  }

  plotEducationAttainment() {
    this.educationAttainmentGrid = [];
    this.ordData.EducationAttainment.forEach((e) => {
      if (e.dataPointName.includes('High School')) {
        this.educationAttainmentGrid.push({
          position: 1,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
      if (e.dataPointName.includes('Some College')) {
        this.educationAttainmentGrid.push({
          position: 2,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
      if (e.dataPointName.includes('Not Reported Education Attainment')) {
        this.educationAttainmentGrid.push({
          position: 3,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
      if (e.dataPointName.includes('Associate')) {
        this.educationAttainmentGrid.push({
          position: 4,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
      if (e.dataPointName.includes('Bachelor')) {
        this.educationAttainmentGrid.push({
          position: 5,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
      if (e.dataPointName.includes('Graduate Degree')) {
        this.educationAttainmentGrid.push({
          position: 6,
          dataPointName: e.dataPointName,
          dataPointValue: e.dataPointValue,
        });
      }
    });

    this.sortorder(this.educationAttainmentGrid);

    const eaData = [];
    this.educationAttainmentGrid.forEach((el) => {
      eaData.push({
        y: [el.dataPointValue / 100],
        type: 'bar',
        name: el.dataPointName,
        text: el.dataPointValue + '%',
        textposition: 'outside',
        font: { color: '#000' },
        hoverinfo: 'none',
      });
    });
    //console.log(eaData, ' - eaData');

    this.educationAttainmentGraph = {
      data: eaData,
      // data:
      // [
      //   { y: yAxis, type: 'bar', name: xAxis }
      // ],
      layout: {
        //autosize: false,
        margin: {
          l: 50,
          r: 50,
          b: 100,
          t: 100,
          pad: 0,
        },
        height: 500,
        showlegend: true,
        legend: { orientation: 'h' },
        xaxis: { showticklabels: false },
        yaxis: { range: [0, 0.5], tickformat: '%' },
      },
    };
  }

  plotEmploymentByOccupation() {
    this.employmentByOccupationGrid = this.ordData.EmploymentByOccupation.sort((x, y) =>
      x.dataPointValue > y.dataPointValue ? 1 : -1,
    );
    //For descending order, change the line as below -
    //this.employmentByOccupationGrid = this.ordData.EmploymentByOccupation.sort((x, y) => (x.dataPointValue > y.dataPointValue ? -1 : 1));

    const eboData = [];

    this.employmentByOccupationGrid.forEach((el) => {
      eboData.push({
        x: [el.dataPointValue / 100],
        type: 'bar',
        name: el.dataPointName,
        textposition: 'inside',
        hoverinfo: 'text',
        hovertext: el.dataPointValue + ' - ' + el.dataPointName,
        text: el.dataPointValue + ' - ' + el.dataPointName,
      });
    });

    this.employmentByOccupationGraph = {
      data: eboData,
      layout: {
        // colorscale: "sequential",
        // autocolorscale: true,
        //autosize: true,
        showlegend: true,
        hovermode: 'closest',
        //legend: { orientation: 'h' },
        legend: {
          x: 1,
          y: 1,
          traceorder: 'reversed',
          font: {
            family: 'sans-serif',
            size: 10,
            color: '#000',
          },
          bgcolor: '#E2E2E2',
          bordercolor: '#FFFFFF',
          borderwidth: 1,
        },
        margin: {
          l: 10,
          r: 10,
          b: 20,
          t: 0,
          pad: 0,
        },
        xaxis: { range: [0, 0.25], tickformat: '%' },
        yaxis: { showticklabels: false },
      },
    };
  }

  formatNumberToLocaleString(val: number): string {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  async exportToPDF() {
    this.spinnerService.show('Export to PDF is in progress...');

    this.exportedPdf = true;
    this.updateSingleSiteAuditinfo();

    const dtFeatures: DrivetimeFeature[] = new Array<DrivetimeFeature>();
    dtFeatures.push(new DrivetimeFeature(1, document.getElementById('marketIndexes')));
    dtFeatures.push(new DrivetimeFeature(2, document.getElementById('demographics')));
    dtFeatures.push(new DrivetimeFeature(3, document.getElementById('postSecondarySchools')));
    dtFeatures.push(new DrivetimeFeature(4, document.getElementById('medianWages')));
    dtFeatures.push(new DrivetimeFeature(5, document.getElementById('employmentByOccupation')));
    dtFeatures.push(new DrivetimeFeature(6, document.getElementById('educationAttainment')));

    const imagesData: ImageSet[] = [];
    await this.collectImages(dtFeatures, imagesData);

    html2canvas(document.body, { scale: 2 }).then((canvas) => {
      const doc: jsPDF = new jsPDF('l', 'mm', 'a4');
      for (let i = 0; i < imagesData.length - 1; i = i + 1) {
        doc.addPage('a4', 'l');
      }

      this.addPagesToPDF(doc, imagesData);

      this.spinnerService.hide();

      const dtAddress = sessionStorage.getItem('DTAddress');
      doc.save(this.selectedProfileName + '_' + dtAddress + '_' + this.driveTimeInMinutes + ' Minutes' + '.pdf');
    });
  }

  async collectImages(dtFeatures: DrivetimeFeature[], imagesData: ImageSet[]) {
    // console.log("Drive-Time: collectImages -> dtFeatures", dtFeatures);
    for (let index = 0; index < dtFeatures.length; index = index + 1) {
      const feature: HTMLElement = dtFeatures.find((x) => x.rank == index + 1).feature;
      await html2canvas(feature, { scale: 1 }).then((canvas) => {
        // console.log("Drive-Time: collectImages -> feature Rank", (index + 1).toString());
        // console.log("Drive-Time: collectImages -> feature", feature);

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        imagesData.push(new ImageSet(index + 1, imgData));
      });
    }
  }

  addPagesToPDF(doc: jsPDF, imagesData: ImageSet[]) {
    for (let index = 0; index < imagesData.length; index = index + 1) {
      const orderedImageData: ImageSet = imagesData.find((x) => x.id == index + 1);
      // console.log("Drive-Time: orderedImageData.id", orderedImageData.id);

      doc.setPage(index + 1);
      //Portrait - last params are width, height
      //doc.addImage(orderedImageData.img, 'JPEG', 5, 5, 200, 295);
      //Landscape - last params are width, height
      doc.addImage(orderedImageData.img, 'JPEG', 5, 5, 287, 206);
    }
  }
}
