import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SessionConfirmationDialogComponent } from './session-confirmation-dialog/session-confirmation-dialog.component';
import { DeleteUpdateDialogComponent } from './delete-update-dialog/delete-update-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { FileUploadnDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { MassUpdateDialogComponent } from './mass-update-dialog/mass-update-dialog.component';
import { QuickUpdateDialogComponent } from './quick-update-dialog/quick-update-dialog.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { MultipleRecordsDialogComponent } from './multiple-records-dialog/multiple-records-dialog.component';
import { ImageUploadDialogComponent } from './image-upload-dialog/image-upload-dialog.component';
@Injectable({
  providedIn: 'root',
})
export abstract class DialogService {
  constructor(private dialogService: MatDialog) {}

  show(message: string) {
    this.dialogService.open(DialogComponent, {
      disableClose: false,
      panelClass: 'dialogBox',
      data: { message: message },
    });
  }


  showSessionConfirmationDialog(conf?: any) {
    const dialogRef = this.dialogService.open(SessionConfirmationDialogComponent, {
      id: conf.data.headerText,
      ...conf,
    });

    return dialogRef;
  }

  showEmeraldConfirmationDialog(template: TemplateRef<any>, conf?: any) {
    const dialogRef = this.dialogService.open(template, {
      disableClose: true,
      ...conf,
    });

    return dialogRef;
  }

  showWarningDialog(headerText: string, bodyText: string) {
    this.dialogService.open(WarningDialogComponent, {
      disableClose: true,
      panelClass: 'dialogBox',
      data: { headerText: headerText, bodyText: bodyText },
    });
  }

  checkIfDialogBoxOpen(dialogID: string) {
    return this.dialogService.getDialogById(dialogID);
  }

  openQuickUpdateDialog(
    headerText: string,
    bodyText: string,
    okBtnName: string,
    cancelBtnName: string,
    selectedTableData: any,
    userData: any,
    staticData: any,
  ) {
    const dialogRef = this.dialogService.open(QuickUpdateDialogComponent, {
      disableClose: true,
      panelClass: 'dialogBox',
      data: {
        headerText: headerText,
        bodyText: bodyText,
        okBtnName: okBtnName,
        cancelBtnName: cancelBtnName,
        selectedTableData: selectedTableData,
        userData: userData,
        staticData: staticData,
      },
    });

    return dialogRef;
  }

  openMultipleRecordsDialog(
    headerText: string,
    bodyText: string[],
    okBtnName: string,
    cancelBtnName: string,
  ) {
    const dialogRef = this.dialogService.open(MultipleRecordsDialogComponent, {
      disableClose: true,
      panelClass: 'dialogBox',
      data: {
        headerText: headerText,
        bodyText: bodyText,
        okBtnName: okBtnName,
        cancelBtnName: cancelBtnName,
      },
    });

    return dialogRef;
  }

  openMassUpdateDialog(
    headerText: string,
    bodyText: string,
    okBtnName: string,
    cancelBtnName: string,
    selectedTableData: any,
    staticData: any,
  ) {
    const dialogRef = this.dialogService.open(MassUpdateDialogComponent, {
      disableClose: true,
      panelClass: 'dialogBox',
      data: {
        headerText: headerText,
        bodyText: bodyText,
        okBtnName: okBtnName,
        cancelBtnName: cancelBtnName,
        selectedTableData: selectedTableData,
        staticData: staticData,
      },
    });

    return dialogRef;
  }

  openDeleteDialog(
    headerText: string,
    bodyText: string,
    okBtnName: string,
    cancelBtnName: string,
    selectedTableData: any,
  ) {
    const dialogRef = this.dialogService.open(DeleteUpdateDialogComponent, {
      disableClose: true,
      panelClass: 'dialogBox',
      data: {
        headerText: headerText,
        bodyText: bodyText,
        okBtnName: okBtnName,
        cancelBtnName: cancelBtnName,
        selectedTableData: selectedTableData,
      },
    });
    return dialogRef;
  }

  openFileUploadDialog(headerText: string, empData: any) {
    const dialogRef = this.dialogService.open(FileUploadnDialogComponent, {
      disableClose: true,
      id: headerText,
      panelClass: 'dialogBox',
      data: { headerText: headerText, empData: empData },
    });

    return dialogRef;
  }

  openImageUploadDialog(headerText: string, data: any) {
    const dialogRef = this.dialogService.open(ImageUploadDialogComponent, {
      disableClose: true,
      id: headerText,
      panelClass: 'dialogBox',
      data: { headerText, empData: data }
    });

    return dialogRef;
  }
}
