import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, map, startWith, of} from 'rxjs';
import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-text-autocomplete',
    templateUrl: './text-autocomplete.component.html',
    styleUrls: ['./text-autocomplete.component.css'],
})
export class TextAutocompleteComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() form:FormGroup;
  @Input() field:any;
  @Input() type:string;
  @Input() hideRequiredMarker = false;
  @Input() hasChips = false;
  @Input() disabled = false;
  @Input() onChangeUpdateOptions: boolean = false;
  @Input() onOptionSelected:(event) => void;
  @Input() clearOnFocus = false;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  
  
  constructor(private formgroupDirective:FormGroupDirective, private formService: FormService) {
  }
  

  selectedOptions :Array<any> = [];

  myControl = new FormControl('');
  filteredOptions: Observable<string[]>;

  ngOnInit() {
      this.form = this.form ?? this.formgroupDirective.control;

      this.form.controls[this.field.control].valueChanges.pipe(
          startWith(''),
          distinctUntilChanged(),
          debounceTime(400),
          map(value => this._filter(value || '','name')),
      ).subscribe((data) => this.filteredOptions = of(data));
      this.form.controls[this.field.control].valueChanges.pipe(debounceTime(600)).subscribe((value) => {
        if (!value) return;

        this.formService.analytics('track', `Selected value in text-autocomplete`, { value, component: 'text-autocomplete', field: this.field.label });
      });
  }

    ngAfterViewInit() {
        this.trigger.panelClosingActions.subscribe((e) => {
            if (!(this.field.isNewValueAllowed)) {
                if (!(e && e.source)) {
                    if (!this.field.clearAfterClosing) {
                        this.form.controls[this.field.control].patchValue(null);
                    }
                    this.trigger.closePanel();
                }
            }
        });
    }

  ngOnChanges() {
      const control = this.form?.controls[this.field.control];
      if (control) {
          this.disabled ? control.disable() : control.enable();
      }

      if (this.onChangeUpdateOptions) {
        this.filteredOptions = of(this._filter('','name'));
      }
  }

  ngOnDestroy(): void {
      this.field.options.forEach((opt) => {
          if (opt.checked) opt.checked = false;});
  }

  getValue(){
  }

  private _filter(value: any, filterKey?:string): string[] {
      const filterValue = value?.name?.toLowerCase() || String(value).toLowerCase();
      return this.field?.options.filter(option => String(option[filterKey]).toLowerCase().includes(filterValue));
  }
  displayFn(value){
      if (Array.isArray(value)) {
          return value.map((val) => val?.name).join(', ');
      }
      return value?.name;
  }

  handleClick() {
    if (this.clearOnFocus) {
      this.form.controls[this.field.control].patchValue(null);
    }
  }

  onBlur(event){
    
  }
  onFocus() {
      if (this.type === 'multiple') {
          this.selectedOptions = this.form.controls[this.field.control].value || [];
          this.selectedOptions.forEach((opt) => { opt.checked = true; });
          this.form.controls[this.field.control].patchValue([]);
      }
  }
  removeChip(option) {
      option.selected = false;
      this.selectedOptions = this.selectedOptions.filter((opt) => opt.name !== option.name);
      this.form.controls[this.field.control].patchValue(this.selectedOptions);
  }
  multiSelectOnClose(): void {
      this.form.controls[this.field.control].patchValue(this.selectedOptions);
      this.selectedOptions = [];
  }
  onCheckboxSelect(option): void {
      option.checked = !option.checked;
      if (option.checked) {
          this.selectedOptions.push(option);
      } else {
          this.selectedOptions = this.selectedOptions.filter((opt) => opt.id !== option.id);
      }
  }
  onDropdownClick(e, trigger:MatAutocompleteTrigger): void {
      if (trigger && !this.disabled) {
          trigger.panelOpen ? trigger.closePanel() : trigger.openPanel();
      }
      e.stopPropagation();
  }
}
