<div class="dialog">
  <form>
    <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 20px">
      <h2 class="upload-header">
        {{ data.headerText }}
      </h2>
      <mat-icon style="color: #262626" class="close-icon" (click)="dialog.closeAll()" mat-dialog-close="true"
        >close</mat-icon
      >
    </div>
    <mat-dialog-content class="mat-typography">
      <div *ngIf="!isImportInProgress" class="container modalContainer" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" accept=".jpeg, .png" #inputFile id="inputFile" (change)="fileBrowseHandler($event)" />
        <h2 class="upload-h2" for="inputFile">Upload image</h2>
        <h4 class="upload-text">Drag and drop here or <span class="files-list template-link"> browse file</span></h4>
        <h4 class="upload-text" style="margin-top: 8px">Max file size: 5MB</h4>
        <h4 class="upload-text">Accepted file types: jpeg, png</h4>
      </div>
      <mat-progress-spinner
        *ngIf="isImportInProgress"
        style="margin: 0 auto"
        [diameter]="48"
        color="primary"
        mode="indeterminate"
        [value]="40"
      >
      </mat-progress-spinner>
      <div *ngIf="isImportInProgress" class="upload-h2" style="margin-top: 26px; color: #262626; text-align: center">
        Uploading file
      </div>
      <div class="files-list">
        <div class="button-container justify-content-end">
          <button
            class="button text"
            [ngClass]="!isCancelUpload ? 'cancel-import' : ''"
            [disabled]="!isCancelUpload"
            (click)="deleteFile()"
          >
            Cancel upload
          </button>
        </div>
      </div>
    </mat-dialog-content>
    <div class="warning" *ngIf="msgError">{{ msgError }}</div>
  </form>
</div>
