import { Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[lagNumberOnly]',
    host: {
        '(blur)': '_onBlur()',
        '(focus)': '_onFocus()',
    }
})
export class NumberOnlyDirective {
    navigationKeys = [ 'Delete', 'Backspace', 'Tab', 'Escape', 'Enter'];
    constructor(
    private _inputEl: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    ) { }

  @Input() isInteger = false;
  @Input() disableThousandSeparator = false;

  ngAfterViewInit(): void {
      if (!this.disableThousandSeparator) this.showNumberInThousandSeparator();
  }

  showNumberInThousandSeparator(): void  {
      if (this.disableThousandSeparator) return;
      if (!this._inputEl.nativeElement.value) return;
      // do not parse if the input is invalid (123...00, etc)
      if (this.ngControl.control.invalid) {
          this._inputEl.nativeElement.value = this.ngControl.control.value;
          return;
      }
      const commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');
      let toInt: number;
      let toLocale: string;
      if (commasRemoved.split('.').length > 1) {
          const decimal = isNaN(commasRemoved.split('.')[1])? '': commasRemoved.split('.')[1];
          toInt = parseInt(commasRemoved);
          toLocale = toInt.toLocaleString('en-US') + '.' + decimal;
      } else {
          toInt = parseInt(commasRemoved);
          toLocale = toInt.toLocaleString('en-US');
      }
      if (toLocale === 'NaN') {
          this._inputEl.nativeElement.value = '';
      } else {
          this._inputEl.nativeElement.value = toLocale;
      }
  }

  showNumberInThousandSeparatorOnInput(): void {
      if (this.disableThousandSeparator) return;
      const inputValue = this._inputEl.nativeElement.value;
      if (typeof inputValue === 'string' && inputValue !== '') {
          const formattednumber = inputValue.split(',').join('');
          this._inputEl.nativeElement.value = this.isInteger ?  Number.parseInt(formattednumber) : Number.parseFloat(formattednumber);
      }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
      if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
          (e.key === '-') ||
      (!this.isInteger && e.key === '.') ||
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
      ) {
          return;  // let it happen, don't do anything
      }
      // Ensure that it is a number and stop the keypress
      if (e.key === ' ' || isNaN(Number(e.key))) {
          e.preventDefault();
      }
  }
  
  _onFocus() {
      this.showNumberInThousandSeparatorOnInput();
  }

  _onBlur() {
      this.showNumberInThousandSeparator();
  }

}


// ToDo 
// show invalid value instead of hidden