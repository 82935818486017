<div class="dialog">
  <h2 mat-dialog-title class="modal-header">{{data.headerText}}</h2>
  
  <button mat-icon-button class="close-button" (click) = "dialog.closeAll()" mat-dialog-close="true">
    <mat-icon class="close-icon" >close</mat-icon>
  </button>

<!-- <mat-dialog-content *ngIf="!data.bodyText" class="mat-typography" style="width:100%">
  
</mat-dialog-content> -->
<div *ngIf="!data.bodyText" class="tableStyle">
  <table mat-table [dataSource]="dataSource">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
      <th mat-header-cell *matHeaderCellDef>
        {{ col.label }}
      </th>
      <td mat-cell *matCellDef="let i=index; let element">
        <div [ngSwitch]="col.type" *ngIf="!element.isedit && !element.isdelete">
          <ng-container *ngSwitchCase="'isEdit'">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="updateGridFilter(element.id,'edit-class',element)">
                <span>Edit</span>
              </button>
              <button mat-menu-item  (click)="updateGridFilter(element.id,'delete-class',element)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
          <span *ngSwitchCase="'date'">
            {{ element[col.key] | date: 'mediumDate' }}
          </span>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>

        <div [ngSwitch]="col.type" id="tempy" *ngIf="element.isdelete">
          <ng-container *ngSwitchCase="'isEdit'">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="resetState(element); removeGridFilter(element.id)">
                <span>Reset</span>
              </button>
            </mat-menu>
          </ng-container>
          <div *ngSwitchCase="'text_employer'" style="width: 301px;">
           <span> {{ element[col.key] }}</span><br>
           <div class="warning delete">
            <mat-icon class="material-icons-outlined">warning_amber</mat-icon>
           <span>Are you sure you want to delete this record</span>
           <div class="button-edit-delete-container">
           <button mat-icon-button (click)="removeRow(element); removeGridFilter(element.id)">
            <mat-icon class="doneMark">done</mat-icon>
          </button>
          <button mat-icon-button (click)="resetState(element); removeGridFilter(element.id)">
            <mat-icon class="closeMark">close</mat-icon>
          </button>
          </div>
        </div>
        </div>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        
        <div [ngSwitch]="col.type" *ngIf="element.isedit">
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <ng-container *ngSwitchCase="'isEdit'">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="resetState(element); removeGridFilter(element.id)">
                  <span>Reset</span>
                </button>
              </mat-menu>
            </ng-container>
          </div>
          <div *ngSwitchCase="'text_employer'" style="width: 301px;">
           <span> {{ element[col.key] }}</span><br>
        </div>
        <div *ngSwitchCase="'text_address'" style="width: 301px;">
          <span> {{ element[col.key] }}</span><br>
       </div>
       <div *ngSwitchCase="'text_market'" style="width: 301px;">
        <span> {{ element[col.key] }}</span><br>
     </div>
     <div *ngSwitchCase="'number_headcount'" style="width: 301px;">
      <div class="editabele-container" *ngIf="!element.editHeadcount">
      <input class="massUpdateInput" type="number" min="0" onkeypress="return event.charCode >= 48" matInput  [(ngModel)]="element[col.key]" (change)="numberValidator($event)" #massUp="ngModel" required />
      <div class="button-edit-delete-container">
      <button mat-icon-button (click)="doneVal(element)" [disabled]="disable">
        <mat-icon class="doneMark">done</mat-icon>
      </button>
      <button mat-icon-button (click)="cancelVal(element)">
        <mat-icon class="closeMark">close</mat-icon>
      </button>
    </div>
      <mat-error *ngIf="massUp.hasError('required')">required</mat-error>
      <mat-error *ngIf="!massUp.hasError('required') && massUp.hasError('isNumber')">please enter a valid value</mat-error>
      </div>
      <div *ngIf="element.editHeadcount">
        <span> {{ element[col.key] }}</span><br>
      </div>
   </div>
   <div *ngSwitchCase="'text_sourcename'" style="width: 301px;">
    <div class="editabele-container" *ngIf="!element.editSource">
    <mat-form-field appearance="outline" class="matFormField">
      <mat-select class="mat-option-text-style" (selectionChange)="setSourceSelection($event)"
      [(ngModel)]="element[col.key]">
        <mat-option class="mat-option-text-style" *ngFor="let item of sources" [value]="item.name">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-edit-delete-container">
    <button mat-icon-button (click)="donesource(element)">
      <mat-icon class="doneMark">done</mat-icon>
    </button>
    <button mat-icon-button (click)="cancelsource(element)">
      <mat-icon class="closeMark">close</mat-icon>
    </button>
  </div>
  </div>
    <div *ngIf="element.editSource">
      <span> {{ element[col.key] }}</span><br>
    </div>
 </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row  *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row  [ngClass]="row.class" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-dialog-actions align="end">
  <button class="button secondary" mat-button [mat-dialog-close]="true" >{{data.okBtnName}}</button>
  <button class="button primary" mat-button (click)="apply()" [disabled]="compareTwoArrayOfObjects(initialdata, dataSource) || disable">{{data.cancelBtnName}}</button>
</mat-dialog-actions>
</div>