export const createProfileMetadata =  {
    externalProfileName: {
        label: 'Profile name (required)',
        control: 'externalProfileName',
    },
    internalProfileName: {
        label: 'Internal profile name',
        control: 'internalProfileName',
    },
    client :{
    label: 'Client name (required)',
    control: 'client',
    options: [],
    requireSelection: true,
    }
}