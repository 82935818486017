import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, catchError, delay, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InterceptorSkipHeader } from '../httpInterceptor/TokenHttpInterceptor';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends BaseService {
  private url = environment.articleApiUrl;
  private imageUrl = environment.imageUrl;
  private header = { 
    headers: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set(InterceptorSkipHeader, ''),
};
  
  constructor(http: HttpClient) {
    super(http);
  }

  getArticleList(parameter = {}, email): Observable<any> {
    const user = email;
    const payload = {
      user,
      ...parameter,
    };
    return this.http.post(`${this.url}/api/article`, payload, this.header).pipe(
        map(res => res),
        catchError((err) => Promise.reject(err)),
    );
}
  getArticleById(id, email): Observable<any> {
    return this.http.get(`${this.url}/api/article/${id}`).pipe(
      map(res => res),
      catchError((err) => Promise.reject(err)),
    );
  }

  getThoughtLeadership(): Observable<any> {
    return this.http.get(`${this.url}/api/article/thought-leadership`, this.header).pipe(
      map(res => res),
      catchError((err) => Promise.reject(err)),
    );
  }
  
  subscribeNewsletter(payload): Observable<any> {
    return this.http.post(`${environment.adminUrl}/newsletter/subscribenewsletter`, payload).pipe(
      catchError((err) => Promise.reject(err)),
    );
  }

  postCreateArticle(formData): Observable<any> {
    return this.http.post(`${this.url}/api/article/createArticle`, formData).pipe(
      map(res => res),
      catchError((err) => Promise.reject(err)),
    );
  }

  postUpdateArticle(formData): Observable<any> {
    return this.http.post(`${this.url}/api/article/updateArticle`, formData).pipe(
      catchError((err) => Promise.reject(err)),
    );
  }

  saveImage(payload): Observable<any> {
    return this.http.post(`${this.imageUrl}/api/image/upload`, payload).pipe(
      map(res => res),
    );
  }  

  deleteArticle(articleId): Observable<any> {
    const url = this.url+'/api/article/'+ articleId;
    return this.http.delete(url,this.header).pipe(
      map(res => res),
    );
  }

  approveArticle(articleId): Observable<any> {
    const url = `${this.url}/api/article/review/${articleId}/approve`;
    return this.http.put(url, {}, { responseType: 'text' });
  }

  rejectArticle (articleId): Observable<any> {
    const url = `${this.url}/api/article/review/${articleId}/reject`;
    return this.http.put(url, {}, { responseType: 'text' });
  }

  rejectArticleSource(articleId): Observable<string> {
    const url = `${this.url}/api/article/review/${articleId}/reject_source`
    return this.http.put(url, null, { responseType: 'text' });
  }
}
