<div class="confirm-dialog">
    <div class="confirm-dialog__title">
        <h2>{{config.title}}</h2>
        <mat-icon  mat-dialog-close >close</mat-icon>
    </div>
    <mat-dialog-content>
        {{config.description}}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close color="primary">{{config.cancelBtnText}}</button>
        <button mat-raised-button [mat-dialog-close]="true" color="primary">{{config.confirmBtnText}}</button>
    </mat-dialog-actions>
</div>
