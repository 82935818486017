import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class baseLineMap {
  map = new Map();
  constructor() {
    this.map.set('NATIONAL', 'National Average');
    this.map.set('SITEAVERAGES', 'Data Set Average');
    this.map.set('SITEMEDIANS', 'Data Set Median');
    this.map.set('SITEASBASELINE', 'Selected Site');
  }
  public getBaseLineName(value) {
    return this.map.get(value);
  }
}
