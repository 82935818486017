<div class="row m-0 pt-2 pb-2">
    <div class="col-1 d-flex align-items-center">
        <fa-icon [icon]="errorIcon" style="color: #CE152C;"></fa-icon>
    </div>
    <div class="col-10 d-flex align-items-center">
        <ul class="mb-0">
            <li *ngFor="let item of data" style="color: black; margin-bottom: 0px;">{{item}}</li>
        </ul>
        <!-- <p *ngFor="let item of data" style="color: black; margin-bottom: 0px;">{{item}}</p> -->
    </div>
    <div class="col-1 d-flex align-items-center">
        <button mat-icon-button class="close-button" (click)="snackBarRef.dismiss()">
            <mat-icon style="color: black;">close</mat-icon>
        </button>
    </div>
</div>