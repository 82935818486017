import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snack-bar-warning',
  templateUrl: './snack-bar-warning.component.html',
  styleUrls: ['./snack-bar-warning.component.scss'],
})
export class SnackBarWarningComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, private snackBarRef:MatSnackBarRef<SnackBarWarningComponent>) {}

  explamationTriangle = faExclamationTriangle;

  ngOnInit() {}

  closeSnackbar(){
     this.snackBarRef.dismiss();
  }
}
