export class GeocodeRequest {
  // adding this ID to be able to identify and match with response
  public inputId: number;

  // Input street address OR full input address in a single line (required)
  public address: string;

  // Input city (optional)
  public city: string;

  // Input state or province (optional)
  public stateProv: string;

  // Input ZIP or postal code (optional)
  public postalCode: string;

  // Input country (optional); will default to "USA" if blank or null
  public country: string;
}

export class GeocodeResponse {
  // adding this ID to be able to identify and match with request
  public inputId: number;
  // We found something for the address
  public addressWasFound: boolean;
  // Matched address
  public address: string;
  // Matched city
  public city: string;
  // Matched State
  public stateProv: string;
  // Matched Postcode
  public postalCode: string;
  // Matched Country
  public country: string;
  // Match strength(int); 0 = low, 5 = high
  public matchStrength: number;
  public latitude: number;
  public longitude: number;
}
