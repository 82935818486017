<div class="dialog">
  <div class="header-content">
    <h2 mat-dialog-title class="header-title">Weekly newsletter subscription</h2>
    <button class="header-close-button" (click)="dialog.closeAll()" mat-dialog-close="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="body-content">
    <div class="body-content__subtitle">Subscribe to keep up with the latest news in the labor market.</div>
    <div class="body-content__email">
      <div class="content-email__text">Subscription email</div>
      <div class="content-email__user">{{ data?.userProfile.email }}</div>
    </div>
    <div class="body-content__interests">
      <div class="content-interests__title">Select interests</div>
      <form [formGroup]="form" class="content-interests__form">
        <lag-text-multiple-autocomplete
          class="matFormField"
          [field]="matFields.industries"
        ></lag-text-multiple-autocomplete>
        <lag-text-multiple-autocomplete
          class="matFormField"
          [field]="matFields.topics"
        ></lag-text-multiple-autocomplete>
      </form>
    </div>
  </div>
  <mat-dialog-actions style="justify-content: flex-end">
    <button class="button text" (click)="dialog.closeAll()">Cancel</button>
    <button class="button primary" (click)="getResponse()" [disabled]="isSubscribeBtnDisabled()">Subscribe</button>
  </mat-dialog-actions>
</div>
