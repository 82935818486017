import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService, FormService, SearchFilter } from 'core';
import { Observable, Subject, Subscription, filter, skip } from 'rxjs';

import { DynamicFieldComponent } from '../dynamic-field/dynamic-field.component';
import { validateProperty } from '../validators/propertyValidator';

@Component({
    selector: 'lag-simple-form',
    templateUrl: './simple-form.component.html',
    styleUrls: ['./simple-form.component.css'],
})
export class SimpleFormComponent implements OnInit, AfterViewInit {
  @HostBinding('class.form') public formStyle=true;
  @HostBinding('class.divider') @Input() divider=false;
  @Input() model: any;
  @Input() form!: FormGroup;
  @Input() formName:string;
  @ViewChild('nestedForm', { read: ViewContainerRef}) nestedFormContainer: ViewContainerRef;
  @ViewChild('value', { read: ViewContainerRef}) valueContainer: ViewContainerRef;
  @ViewChild('operator', { read: ViewContainerRef}) operatorContainer: ViewContainerRef;

  @Input() parent:FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();

  fields:Observable<any[]>;
  parentElements=[];
  subscriptions : Subscription[] = [];
  isFirstValidated = false;

  private dependency:any[]=[];
  private fields$$=new Subject<any[]>();
  fields$:Observable<any[]>;

  child_unique_key=0;
  componentsReferences: ComponentRef<SimpleFormComponent>[]=[];
  
  filters$:Observable<SearchFilter[]>;

  constructor(private formService:FormService, private commonService:CommonService, private cd:ChangeDetectorRef){
      this.fields$$.next(this.model);
      this.fields$=this.fields$$.asObservable();
      this.parent=this.form;
      this.filters$=this.formService.searchFilter$;
  }
  ngAfterViewInit(): void {
      if(this.nestedFormContainer){
          this.formService.formClear$.pipe().subscribe(val=>{
              this.registerDynamicField(undefined, true);
          });
          this.nestedFormContainer?.clear();
          this.registerDynamicField(undefined, true);
      }
  }

  ngOnInit() {
      this.buildForm();
      this.parentElements=this.model.filter(model=>model.parentId===undefined);
      this.formChange.emit(this.form);
  }



  private registerDynamicField(formData?, divider?) {
      const componentInstance = SimpleFormComponent;
      const dynamicComponent = this.nestedFormContainer.createComponent(componentInstance);
      dynamicComponent.instance['parent']=this.form;
      dynamicComponent.instance['model']= this.model.find(model=> model.type==='nested-form').metaData;
      dynamicComponent.instance['unique_key']= ++this.child_unique_key;
      dynamicComponent.instance['formName'] = 'filterForm'+ ++this.child_unique_key;
      dynamicComponent.instance['formData'] = formData;
      dynamicComponent.instance['divider'] = divider||false;
      this.componentsReferences.push(dynamicComponent);
      this.cd.detectChanges();
  }


  private registerDynamicField2(model?, formData?) {
      this.valueContainer?.clear();
      const componentInstance = DynamicFieldComponent;
      const dynamicComponent = this.valueContainer?.createComponent(componentInstance);
      dynamicComponent.instance['formGroup']= this.form;
      dynamicComponent.instance['field']= model;
      dynamicComponent.instance['unique_key']= ++this.child_unique_key;
      dynamicComponent.instance['formData'] = formData;
      this.form.updateValueAndValidity();
      this.cd.detectChanges();

  }

  private registerDynamicField3(model?, formData?) {
      this.operatorContainer?.clear();
      const componentInstance = DynamicFieldComponent;
      const dynamicComponent = this.operatorContainer?.createComponent(componentInstance);
      dynamicComponent.instance['formGroup']= this.form;
      dynamicComponent.instance['field']= model;
      dynamicComponent.instance['unique_key']= ++this.child_unique_key;
      dynamicComponent.instance['formData'] = formData;
      this.cd.detectChanges();
  }

  private buildForm() {
      const formGroupFields = this.getFormControlsFields();
      this.form = this.form ?? new FormGroup(formGroupFields);
      this.addDependency(this.dependency);
      if(this.parent){
          const parent= this.parent.controls['filters'] as FormArray;
          for (const key in this.form.controls) {
              // this.form.get(key).clearValidators();
              // this.form.get(key).updateValueAndValidity();
          }
          parent.push(this.form);
          if(this['formData']){
              setTimeout(()=>this.form.patchValue(this['formData']), 500);
              setTimeout(()=> this.form.controls['operator'].setValue(this['formData'].operator), 600);
          }
      }
      this.formService.formValue$.pipe().subscribe(data=>this.setFormValue(data));
      this.formService.resetForm$.pipe().subscribe((value)=>this.resetForm(value ?? {}));

      if (this.form.controls?.name) {
          this.form.controls?.name?.valueChanges.pipe().subscribe(() => {
              if (this.form.controls?.name?.dirty) {
                  this.form.controls?.operator?.patchValue('');
                  this.form.controls?.value?.patchValue('');
              }
          });
      }
  }

  private getFormControlsFields() {
      const formGroupFields = {};
      const formGroupFieldsfromModel=this.model.filter(item=>!!item.control);
    
      for (const field of formGroupFieldsfromModel) {
          const fieldProps = field;
          const validators = this.addValidator(fieldProps.rules);
          if(fieldProps?.dependsOn?.length){
              for(const depends of fieldProps.dependsOn){
                  const {field, conditions}=depends;
                  this.dependency.push({'field':field, 'dependency': {'field':fieldProps.control, 'conditions': conditions, fieldProps}});
              }
          }
          if(fieldProps?.control==='formId'){
              fieldProps.value=fieldProps.value??this.commonService.generateGuid();
          }
          formGroupFields[field.control] = field.controlType==='formfield'? new FormControl({value:fieldProps.value, disabled:fieldProps.disabled}, { updateOn: fieldProps.triggerOn || 'change', validators}):
              new FormArray([]);
      }
      return formGroupFields;
  }


  
  private addValidator(rules) {
      if (!rules) {
          return [];
      }

      const validators = Object.keys(rules).map((rule) => {
          switch (rule) {
          case 'required':
              return Validators.required;
          case 'propertyValidator':
              return validateProperty(rules['propertyValidator'], [Validators.required]);
          }
      });
      return validators;
  }
  private remove(key?: number) {
      if (this.nestedFormContainer?.length < 1) return;

      if(key){
          const componentRef = this.componentsReferences.filter(
              x => x.instance['unique_key'] == key
          )[0];

          const vcrIndex: number = this.nestedFormContainer?.indexOf(componentRef.hostView);


          // removing component from container
          this.nestedFormContainer.remove(vcrIndex);

          // removing component from the list
          this.componentsReferences = this.componentsReferences.filter(
              x => x.instance['unique_key'] !== key
          );
          const parent= this.form.controls['filters'] as FormArray;
          parent.removeAt(vcrIndex);
      }
      else {
          this.nestedFormContainer?.clear();
          const parent= this.form.controls['filters'] as FormArray;
          parent?.clear();
      }
    
  }

  private addDependency(dependencies){
      dependencies.map((dependency)=>{
          this.form.controls[dependency.field].valueChanges.pipe(filter(val=>!!val)).subscribe((value)=>{
              const {field:dependentField, conditions, fieldProps}=dependency.dependency;
        
              if(typeof value==='object'){
                  const dependencyIndex=this.model.findIndex(field=>field.control===dependentField);
                  const options= this.model[dependencyIndex].options;
                  const ruleName=conditions.find(condition=>condition.ruleName.toLowerCase()===value.type?.toLowerCase());
                  this.form.controls[dependentField].clearValidators();
                  this.form.controls[dependentField].updateValueAndValidity();
          
                  if(value.type==='City'){
                      this.form.controls[dependentField].addValidators(Validators.required);
                      this.form.controls[dependentField].addValidators(validateProperty('type', [Validators.required]));
                      this.form.controls[dependentField].updateValueAndValidity();
                  }
                  else{
                      this.form.controls[dependentField].patchValue('');
                  }
                  this.executeRule(ruleName?.options,options);
              }
              else{
                  conditions.map((condition=>{
                      if(condition.ruleType==='componentChange'){
                          const index=this.model.findIndex(field=>field.control===fieldProps.control);
                          const rule=condition?.rule.find(rule=> rule.name===value);
                          let compName=rule?.componentType;
                          if(this.form.controls['name']?.value==='address') {
                              compName='text';
                          }
                          if(compName){
                              this.model[index].type=compName;
                              //this.fields$$.next(this.model);
                              dependency.dependency.fieldProps.type= compName;
                              let optionsData;
                              const dependentedValues=fieldProps?.dependsOn?.map(f=>this.form.controls[f.field].value);
                              for (const key in dependency?.dependency?.fieldProps?.providedOptionsData){ 
                                  if(~key.toLowerCase().indexOf(dependentedValues[0])){
                                      optionsData=dependency?.dependency?.fieldProps?.providedOptionsData[key];
                                  }
                              }

                              const newModel={...this.model[index], options:optionsData};
                              this.registerDynamicField2(newModel, this.form.value);
                              this.form.updateValueAndValidity();
                              this.form.markAsUntouched();
                          }
                      }
                      if(condition.ruleType==='optionChange'){
                          //this.form.controls[dependentField].reset();
                          if(fieldProps.providedOptionsData){
                              const index=this.model.findIndex(field=>field.control===fieldProps.control);
                              const newModel={...this.model[index], options:fieldProps.providedOptionsData[value]??fieldProps.providedOptionsData.default};
                              this.registerDynamicField3(newModel, this.form.value);
                              fieldProps.options=fieldProps.providedOptionsData[value]??fieldProps.providedOptionsData.default;
                              this.form.updateValueAndValidity();
                          }
                      }
                      if(condition.ruleType==='setValue'){
                          this.form.get(dependentField).patchValue(value);
                      }

                      if(condition.ruleType==='validation'){
                          condition.rule.forEach(rule=>{
                              if(rule.required){
                                  this.form.controls[dependentField].addValidators(Validators.required);
                                  this.form.updateValueAndValidity();
                              }
                          });
                      }
                  }));
              }
          });
      });
  }

  private resetForm(value: Object){
      this.form.patchValue({'country': 'United States', 'formId':this.commonService.generateGuid(), cardName: '', location: '', geographicBoundary: '', ...value});
      if(this.nestedFormContainer){
          this.remove();
          this.registerDynamicField();
          this.cd.detectChanges();
      }
  }

  private setFormValue(formData){
      this.form.patchValue(formData);
      if(this.nestedFormContainer){
          this.remove();

          this.cd.detectChanges();
          if(formData['filters']?.length < 1){
              this.registerDynamicField();
          }
          else {
              for (const row of formData['filters']) {
                  this.registerDynamicField(row);
              }
          }
      }
  }

  private executeRule(ruleName, options){
      if(ruleName==='all enabled'){
          return options.map(option=> option.disabled=false);

      }
      if(ruleName==='metro enabled'){
          return options.map(option=> option.text?.toLowerCase().includes('metro')?option.disabled=false: option.disabled=true);
      }
      if(ruleName==='none'){
          return options.map(option=> option.disabled=true);
      }
      return options.map(option=> option.disabled=true);
  }
}
