import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mat-text-input',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextInputComponent {
  @Input() form:FormGroup;
  @Input() field:any;
}
