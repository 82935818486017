<div class="support-overview">
  <div class="card mb-3">
    <h3 class="card-header">What is GeoSkill?</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p>Did you know 83% of occupiers make their location strategy decisions based on labor? Understanding what
            is driving your labor market or a market of interest for your client helps set you apart from the crowd
            –
            and now it’s easier than ever. Tap into 20 years of industry-leading and award-winning analytics with
            GeoSkill. Select the profile that best reflects your client, your market, and the radii and download or
            email it direct! It’s as easy as 3 clicks.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/WhatIsGeoSkill.png" class="support-image" alt="What is GeoSkill?" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <h3 class="card-header">Know your score</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p class="mb-2">GeoSkill makes it easy to score a market based on several dozen variables and benchmark
            against the
            national average. This enables your client to quickly understand the strengths and weaknesses of a market
            and make faster decisions.</p>

          <u class="text-underline">Scoring methodology:</u>

          <p class="mt-2">The national average is set to 100. All scores above 100 indicate a positive attribute for
            an occupier
            client, such as strong supply or low cost. All scores below 100 indicate a negative attribute for an
            occupier, such as weak supply or higher costs. Methodology and sources are included in each report for
            easy interpretation.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/KnowYourScore.png" class="support-image" alt="Know your score" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <h3 class="card-header">Define your market</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p>Did you know GeoSkill tracks any city with a population of 5,000 or greater and can be reported at 10, 20
            or 30 mile radius in addition to the metropolitan? That means you can pull a report based on the city
            level and assess the labor force in a more accurate way based on typical commutes rather than the entire
            metro area. We recommend 20 mile radius for most markets; 10 miles for highly dense urban areas or 30
            miles for highly rural areas. Don’t see your city – it may not be large enough or may be a township.
            Search for the next closest city and run your analysis from there.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/DefineYourMarket.png" class="support-image" alt="Define your market!" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <h3 class="card-header">Customize your reporting</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p>Is your client asking about a specific skill set? CBRE GeoNavigator can help. With over 1,200 profiles in
            our database, we have the art down to a science. Contact us and we’ll work closely with you to identify
            the skills, education, supply and cost requirements that accurately reflect your client’s needs to ensure
            the most accurate lens to support their decision.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/CustomizeYourReport.png" class="support-image"
            alt="Customize your reporting!" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <h3 class="card-header">Is your client searching for their next successful market?</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p>Labor typically comprises up to 70% of an operation’s expense. Even more importantly, identifying a
            market with the right availability and supply of talent is what ensures their competitive advantage. We
            will customize a search, benchmarking over 7,000 cities to find the perfect balance of talent, cost,
            education and supply to ensure their success.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/IsYourClientSearching.png" class="support-image"
            alt="Is your client searching for the next successfule market?" />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <h3 class="card-header">Need to know more than just your GeoSkill score?</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <p>Market research and due diligence is just a phone call away. Harness the power of our database that
            tracks over 21,000 competitive operations across the Americas. Understanding competitive positioning
            (who’s there, how big are they, what functions do they employ and what are they paying) can make the
            difference in any market, regardless of score. Ensure your client is an employer of choice.</p>
        </div>
        <div class="col-md-12 col-lg-6">
          <img src="/assets/images/support/NeedToKnowMore.png" class="support-image"
            alt="Need to know more than just your GeoSkill score?" />
        </div>
      </div>
    </div>

  </div>