<div (clickOutside)="currentSelectedMenuItem = undefined" style="position: relative">
  <header class="row w-100 m-0 sticky-nav header">
    <div class="header__navigation">
      <a class="nav-link-logo" id="CBRE" [routerLink]="['/']">
        <img class="cbre-logo-navbar" src="assets/images/CBRE_white.svg"/>
        <span class="header__geonavigator">GeoNavigator &trade;</span>
      </a>
      <span class="separator" id="CBRE">
        <span>|</span>
      </span>
      <div class="header__menu">
        <div
          *ngFor="let menuItem of navigationMenu"
          class="subnav"
          (click)="selectCurrentRoute(menuItem.id)"
          [class.active]="currentSelectedMenuItem?.id === menuItem.id"
        >
          <span class="navbar-text navtext" style="color: #ffffff">
            <div [innerHtml]="menuItem.itemName"></div>
          </span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.41 8.29501L12 12.875L16.59 8.29501L18 9.70501L12 15.705L6 9.70501L7.41 8.29501Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="user-section">
      <div class="navbar-notifications__block" (click)="notificationService.setDrawerStatus(null)">
        <div class="navbar-notifications" *ngIf="unreadNotificationCount > 0">{{ unreadNotificationCount }}</div>
        <mat-icon>notifications</mat-icon>
      </div>
      <div class="navbarlogo">
        <div class="col-1 p-0" style="color: #ffffff">
        <div *ngIf="!user?.isAdmin" [matMenuTriggerFor]="menu" class="outer-avatar-block">
          <div class="inner-avatar-block">
            <div *ngIf="!isUserProfileImage" class="avatar-block">{{ userAvatarText }}</div>
            <img *ngIf="isUserProfileImage" [src]="userProfile.userImagePathUrl" class="avatar-block" />
          </div>
        </div>
        <div *ngIf="user?.isAdmin" (click)="onUserMenuSelect()" class="outer-avatar-block">
          <div class="inner-avatar-block">
            <div *ngIf="!isUserProfileImage" class="avatar-block">{{ userAvatarText }}</div>
            <img *ngIf="isUserProfileImage" [src]="userProfile.userImagePathUrl" class="avatar-block" />
          </div>
        </div>
        <!-- <i class="fa fa-user fa-lg"></i>                 -->
        <mat-menu #menu="matMenu" style="padding: 16px">
          <div class="d-flex gap-3" style="padding: 16px; align-items: center">
            <div *ngIf="!isUserProfileImage" class="avatar-block avatar-lg">{{ userAvatarText }}</div>
            <img *ngIf="isUserProfileImage" [src]="userProfile.userImagePathUrl" class="avatar-block avatar-lg" />
            <div class="username__section">
              <span class="username">{{ userProfile?.firstName }} {{ userProfile?.lastName }}</span>
              <span class="subtitle">{{ userProfile?.email }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center" style="padding: 0 16px" mat-menu-item [routerLink]="['/my-profile']">
            <mat-icon style="color: #262626">manage_accounts</mat-icon>
            <span style="font-size: 16px; font-weight: 400; line-height: 24px; color: #262626">My Profile</span>
          </div>
          <div class="d-flex align-items-center" style="padding: 0 16px" mat-menu-item (click)="logout()">
            <mat-icon style="color: #262626">logout</mat-icon>
            <span style="font-size: 16px; font-weight: 400; line-height: 24px; color: #262626">Log Out</span>
          </div>
        </mat-menu>
        </div>
      </div>
    </div>
  </header>
  <div *ngIf="!!currentSelectedMenuItem" class="mega-menu-block">
    <app-navigation-item
      *ngFor="let category of currentSelectedMenuItem.childItems; index as i"
      [maxItemLevel]="maxNagivationLevel"
      [category]="category"
      [thoughtLeadership]="thoughtLeadership"
      [index]="i"
      (handleClick)="currentSelectedMenuItem = undefined"
    ></app-navigation-item>
  </div>
</div>
<app-notification-drawer></app-notification-drawer>
