import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { GTMConfig } from '../interfaces/GTMConfig.interface';

export const GTMConfigService = new InjectionToken<GTMConfig>('gtm-config');

@Injectable({
  providedIn: 'root',
})
export class GTMConfigurationService {
  private _googleTagManagerConfig: GTMConfig = {
    id: null,
    gtm_auth: '',
    gtm_preview: '',
  };

  constructor(@Optional() @Inject(GTMConfigService) googleTagManagerConfig?: GTMConfig) {
    if (googleTagManagerConfig) {
      this.set(googleTagManagerConfig);
    }
  }

  public set(googleTagManagerConfig: GTMConfig): void {
    this._googleTagManagerConfig = googleTagManagerConfig;
  }

  public get(): GTMConfig {
    return this._googleTagManagerConfig;
  }
}
