import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormUtil {
  constructor() {}
  markFormGroupTouchedDirty(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();
      if (control.controls) {
        this.markFormGroupTouchedDirty(control);
      }
    });
  }

  numberRangeValidator = (minControlName: string, maxControlName: string): ValidatorFn => {
    return (form) => {
      const minControl = form.get(minControlName);
      const maxControl = form.get(maxControlName);
      if (minControl?.value === null || maxControl?.value === null) return null;
      const min = Number.parseInt(minControl?.value, 10);
      const max = Number.parseInt(maxControl?.value, 10);
      if (min > max) {
        minControl.setErrors({ rangeError: true });
        return;
      }
      minControl.updateValueAndValidity({onlySelf: true });
      return null;
    };
  };

  startDateEndDateValidator(startKey, endKey) {
    return (filter_form: FormGroup) => {
      const startDate = filter_form.controls[startKey].value;
      const endDate = filter_form.controls[endKey].value;
      if (startDate && endDate) {
        if (startDate > endDate) {
          filter_form.controls[startKey].setErrors({ dateError: true });
        } else {
          filter_form.controls[startKey].setErrors(null);
        }
      }
      return null;
    };
  }
}
