import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { GTMConfig } from '../../interfaces/GTMConfig.interface';
import { GTMConfigService } from '../../services/gtm-configuration.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class GoogleTagManagerModule {
  public static forRoot(config?: GTMConfig): ModuleWithProviders<GoogleTagManagerModule> {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [{ provide: GTMConfigService, useValue: config }],
      // providers:[{provide:, useValue:}]
    };
  }
}
