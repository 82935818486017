import { Injectable } from '@angular/core';
import { map, filter, catchError, of, from, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@services/user.service';
import { appRoutesMapping } from '../app-routing.module';
import { OktaAuthGuard } from '@okta/okta-angular';
import { MegamenuService } from '@services/megamenu.service';
import { UserProfileService } from '@services/userProfile.service';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {
  constructor(private router: Router,
    private userService: UserService,
    private megaMenuService: MegamenuService,
    private userProfileService: UserProfileService,
    private oktaAuthGuard: OktaAuthGuard) {}

  noAccess() {
    this.router.navigate([`/${appRoutesMapping.REQUEST_ACCESS}`]);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(this.oktaAuthGuard.canActivate(route, state)).pipe(
      switchMap((loggedIn) => {
          if (!loggedIn) {
            return  of(false)
          }
          return this.userService.getUser().pipe(
            map((user) => {
              if (user) {
                this.triggerInitalDataLoading();
              }
              return !!user
            }),
            catchError(() => {
              this.noAccess()
              return of(false)
            })
          )
      })
    );
  }

  private triggerInitalDataLoading(): void {
    this.megaMenuService.setInitialMegaMenu();
    this.userProfileService.fetchInitialUserProfile();
    this.userProfileService.fetchUserAccesses();
    this.userProfileService.getUserAccess().subscribe()
  }
}
