<mat-sidenav-container (backdropClick)="drawer(false)">
  <mat-sidenav
    [autoFocus]="false"
    mode="over"
    position="end"
    [fixedTopGap]="thoughtSpotFilterGap"
    [fixedInViewport]="true"
    [opened]="isFilterDrawerOpened$ | async"
    #sideNav
    [disableClose]="true"
    [style]="matSidenav"
  >
    <div class="sidepanel-header-container">
      <div>
        <p class="eds-h5">Filters</p>
      </div>
      <mat-icon (click)="drawer(false)">close</mat-icon>
    </div>
    <div class="sidepanel-content-paddingless-container" *ngIf="filterForm">
      <form *ngIf="isFilterShow" [formGroup]="filterForm">
        <lag-expansion-panel title="Geography" expanded>
          <div class="expansion-container">
            <lag-text-multiple-autocomplete
              class="matFormField"
              [hideRequiredMarker]="true"
              [form]="filterForm"
              [field]="filterFormMetadata.country"
            >
            </lag-text-multiple-autocomplete>
            <lag-text-autocomplete
              class="matFormField"
              [form]="filterForm"
              [field]="filterFormMetadata.geographyType"
              [onOptionSelected]="onGeographyTypeSelection"
            ></lag-text-autocomplete>
            <lag-text-multiple-autocomplete
              [form]="filterForm"
              class="matFormField"
              [field]="filterFormMetadata.locations"
            >
            </lag-text-multiple-autocomplete>
          </div>
        </lag-expansion-panel>
        <lag-expansion-panel title="Filters" expanded>
          <div class="expansion-container">
            <div class="d-flex" style="flex-direction: column; gap: 16px" *ngIf="dynamicComponents?.length">
              <div *ngFor="let component of dynamicComponents">
                <ng-container [ngSwitch]="component.componentType">
                  <ng-container *ngSwitchCase="'single-select'">
                    <lag-single-select
                      class="matFormField"
                      [form]="filterForm"
                      [field]="filterFormMetadata[component.columnNameForBI]"
                    ></lag-single-select>
                  </ng-container>
                  <ng-container *ngSwitchCase="'multi-select'">
                    <lag-text-multiple-autocomplete
                      class="matFormField"
                      [form]="filterForm"
                      [field]="filterFormMetadata[component.columnNameForBI]"
                    ></lag-text-multiple-autocomplete>
                  </ng-container>
                  <ng-container *ngSwitchCase="'date'">
                    <lag-date
                      class="matFormField"
                      [form]="filterForm"
                      [field]="filterFormMetadata[component.columnNameForBI]"
                    ></lag-date>
                  </ng-container>
                  <ng-container *ngSwitchCase="'text'"
                    ><lag-text [form]="filterForm" [field]="filterFormMetadata[component.columnNameForBI]"></lag-text
                  ></ng-container>
                </ng-container>
              </div>
            </div>
            <mat-radio-group
              class="filter-date-container"
              [value]="filterDateType"
              (change)="onSelectionChange($event)"
            >
              <mat-radio-button disableRipple [value]="filterDateTypes.RELATIVE">Relative dates</mat-radio-button>
              <ng-container *ngIf="filterDateType === filterDateTypes.RELATIVE">
                <mat-chip-list [formControlName]="'dateFilter'" selectable>
                  <mat-chip
                    class="mat-chip-thoughtspot"
                    (click)="onRelativeDateChange(chip)"
                    [value]="chip"
                    *ngFor="let chip of dateChipList"
                  >
                    <mat-icon class="check-icon" *ngIf="chip === filterForm.get('dateFilter').value">check</mat-icon>
                    {{ chip }}
                  </mat-chip>
                </mat-chip-list>
              </ng-container>
              <mat-radio-button disableRipple [value]="filterDateTypes.CUSTOM">Custom dates</mat-radio-button>
              <div *ngIf="filterDateType === filterDateTypes.CUSTOM" class="d-flex gap-3">
                <div style="flex: 1">
                  <lag-date
                    [form]="filterForm"
                    class="matFormField"
                    [field]="filterFormMetadata.customStartDate"
                  ></lag-date>
                  <mat-error *ngIf="filterForm.get('customStartDate').hasError('dateError')"
                    >Start date cannot be after end date</mat-error
                  >
                </div>
                <div style="flex: 1">
                  <lag-date
                    [form]="filterForm"
                    class="matFormField"
                    [field]="filterFormMetadata.customEndDate"
                  ></lag-date>
                </div>
              </div>
            </mat-radio-group></div
        ></lag-expansion-panel>
      </form>
    </div>

    <div class="sidepanel-footer-container" style="justify-content: space-between">
      <button
        class="button primary"
        type="button"
        style="flex: 1"
        [disabled]="!filterForm?.valid"
        (click)="applyFilter()"
      >
        Apply
      </button>
      <button class="button text" style="flex: 1" (click)="filterForm.reset()">Clear filter</button>
    </div>
  </mat-sidenav>
  <div class="d-flex justify-content-between align-items-end">
    <div class="title-container">
      <p class="eds-h5">{{ menuTitle }}</p>
      <p class="eds-body2">{{ menuDescription }}</p>
    </div>
    <div style="padding: 16px">
      <button class="button primary no-focus" (click)="drawer(true)">Filters</button>
    </div>
  </div>
  <div style="margin: 16px 16px 0 16px">
    <app-active-filters
      *ngIf="filterForm && isFilterShow"
      [formValues]="filterValues"
      [form]="filterForm"
      [formMetadata]="filterFormMetadata"
      (remove)="applyFilter()"
    ></app-active-filters>
  </div>
  <div #vizContainer style="min-height: 100vh"></div>
</mat-sidenav-container>
