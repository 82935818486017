import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { CommonService } from '../services/common.service';
import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-single-select-emerald',
    templateUrl: './single-select-emerald.component.html',
    styleUrls: ['./single-select-emerald.component.scss']
})
export class SingleSelectEmeraldComponent implements OnInit {
    @Input() form:FormGroup;
    @Input() field:any;
    
    constructor(@Optional() private formgroupDirective:FormGroupDirective, private commonService:CommonService, private formService: FormService) {
    }
  
    filteredOptions: Observable<string[]>;
  
    ngOnInit() {
        this.form = this.form??this.formgroupDirective.control;
  
        this.filteredOptions = this.form.controls[this.field.control].valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(400),
            filter((name) => !!name),
            map(value => this._filter(value || '','name')),
        );
        this.form.controls[this.field.control].valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(400),
            filter((name) => !!name),
        ).subscribe((value) => {
            this.formService.analytics('track', `Dropdown selected value`, { value, component: "single-select-emerald", field: this.field.label });
        });
    }
  
  
    private _filter(value: string, filterKey?:string): string[] {
        const filterValue = String(value).toLowerCase();
        const filteredOptions=this.field?.options.filter(option => String(option[filterKey]).toLowerCase().includes(filterValue));
        return this.commonService.sortArray(filteredOptions,'name');
    }
    displayFn(value){
        return value?.name??value;
    }
}
  