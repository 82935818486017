import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { GeocodeRequest, GeocodeResponse } from 'src/app/core/models/geocode';
import { NotificationService } from 'src/app/core/notification.service';
import { GeocoderService } from 'src/app/core/services/geocoder.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { Market } from 'src/app/models/market';
import { Profile } from 'src/app/models/profile';
import { FormUtil } from 'src/app/util/FormUtil';
import { Util } from 'src/app/util/Util';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { ReportParams } from '../../marketindex-report/marketindex.service';
import { DriveTimeService } from '../../services/drivetime/drivetime.service';

@Component({
  selector: 'app-new-dt-params',
  templateUrl: './params.component.html',
  styleUrls: ['./params.component.scss'],
})
export class NewDTParamsComponent implements OnInit {
  profileSelected: Profile;
  selectedMarket: Market;
  reportInputParams: ReportParams;
  spDriveTimeForm: FormGroup;
  driveTimeOptions: number[] = [15, 30, 45, 60];
  selectedDriveTimeOption;
  profiles: Profile[] = [];
  markets: Market[] = [];
  zip: string;
  errors = {
    profile: '',
    address: '',
    cityState: '',
    zip: '',
    lat: '',
    lon: '',
  };
  zipMask = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/] };
  zipPlusFourMask = { mask: [/\d/, /\d/, /\d/, /\d/] };
  latLonMask = {
    mask: createNumberMask({
      prefix: '', // Default is $,
      includeThousandsSeparator: false,
      allowDecimal: true,
      allowNegative: true,
      decimalLimit: 8,
      integerLimit: 3,
    }),
  };

  private validationMessages = {
    profile: {
      required: 'Profile is required.',
      number: 'Profile is required.',
    },
    address: {
      required: 'Please enter an address.',
    },
    cityState: {
      required: 'Please enter a city/state.',
    },
    zip: {
      required: 'Please enter a zip.',
    },
    lat: {
      required: 'Please enter a latitude.',
      max: 'Please enter a value between 90 and -90',
      min: 'Please enter a value between 90 and -90',
    },
    lon: {
      required: 'Please enter a longitude.',
      max: 'Please enter a value between 180 and -180',
      min: 'Please enter a value between 180 and -180',
    },
  };

  constructor(
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private formUtil: FormUtil,
    private router: Router,
    private route: ActivatedRoute,
    private geocoderService: GeocoderService,
    private spinnerService: SpinnerService,
    private util: Util,
    private driveTimeService: DriveTimeService,
  ) {
    this.selectedDriveTimeOption = 30;
    this.spinnerService.show('Loading Drive Time...');
  }

  ngOnInit() {
    this.buildForm();

    this.getProfiles();

    this.getMarkets();
  }

  getProfiles() {
    this.driveTimeService.getProfiles().subscribe((res) => {
      this.profiles = this.util.sortArrayByPropertyName(res, 'profileName');

      const profile = new Profile(null, 'Choose a Profile');

      this.profiles = [profile, ...this.profiles];

      this.spinnerService.hide();
    });
  }

  getMarkets() {
    this.driveTimeService.getMarkets().subscribe((res) => {
      this.markets = this.util.sortArrayByPropertyName(res, 'name');
      this.spinnerService.hide();
    });
  }

  typeaheadNoResults(event: any): void {
    this.spDriveTimeForm.controls['cityState'].setValue(null);
  }

  selectProfile(item: Profile) {
    this.profileSelected = item;
  }
  selectProfile_new(item) {
    const profile = new Profile(item.target.value, item.target.options[item.target.selectedIndex].text);
    this.profileSelected = profile;
  }

  isDriveTimeSelected(key) {
    return this.selectedDriveTimeOption === key;
  }

  selectDriveTime(key) {
    this.selectedDriveTimeOption = key;
  }

  onSubmit() {
    if (!this.spDriveTimeForm.valid) {
      this.showErrorMessages();
    } else {
      this.getGeocodedData();
    }
  }

  private getGeocodedData() {
    let latitudeValue: string = this.spDriveTimeForm.controls['lat'].value.trim();
    let longitudeValue: string = this.spDriveTimeForm.controls['lon'].value.trim();
    const addressText: string = this.spDriveTimeForm.controls['address'].value.trim();
    const cityStateText: string = this.spDriveTimeForm.controls['cityState'].value;
    const zipcodeText: string = this.spDriveTimeForm.controls['zip'].value.trim();

    this.reportInputParams = new ReportParams();
    this.reportInputParams.profileID = this.profileSelected.profileID.toString();
    this.reportInputParams.profileName = this.profileSelected.profileName;
    this.reportInputParams.driveTimeInMinutes = this.selectedDriveTimeOption;
    this.reportInputParams.address = this.spDriveTimeForm.controls['address'].value.trim();
    this.reportInputParams.zipCode = this.spDriveTimeForm.controls['zip'].value.trim();

    if (latitudeValue != '' && longitudeValue != '') {
      this.router.navigate(['/SingleSite/Report'], {
        relativeTo: this.route,
        queryParams: {
          lat: this.reportInputParams.latitude,
          long: this.reportInputParams.longitude,
          driveTimeInMins: this.selectedDriveTimeOption,
        },
      });
    } else if (addressText != '') {
      this.spinnerService.show('Searching for Geo Coordinates...');

      const geocodeRequest: GeocodeRequest = new GeocodeRequest();
      geocodeRequest.address = addressText + cityStateText + zipcodeText;

      this.geocoderService.geocode(geocodeRequest).subscribe((data) => {
        // console.log(data, ' is Geocode() response)!');
        // Examine response.MatchStrength (0 low - 5 high).
        // We might want to show the user the response address to confirm that it is correct if MatchStrength is a 0 or 1.

        this.spinnerService.hide();

        const geocodeResponse: GeocodeResponse = data as GeocodeResponse;
        if (!geocodeResponse.addressWasFound) {
          this.notificationService.error.next('Address was not found, please verify the address and try again.');
        } else {
          latitudeValue = geocodeResponse.latitude.toString();
          longitudeValue = geocodeResponse.longitude.toString();

          this.reportInputParams.city = geocodeResponse.city;
          this.reportInputParams.state = geocodeResponse.stateProv;
          this.reportInputParams.latitude = latitudeValue;
          this.reportInputParams.longitude = longitudeValue;

          sessionStorage.setItem(
            'DTAddress',
            addressText + ' ' + this.reportInputParams.city + ', ' + this.reportInputParams.state + ' ' + zipcodeText,
          );

          sessionStorage.setItem('reportInputParams', JSON.stringify(this.reportInputParams));
          // this.router.navigate(['/NewDriveTime/SPDTReport'], { relativeTo: this.route, queryParams: { profileId: this.profileSelected.id,profileName:this.profileSelected.name, lat: latitudeValue, long: longitudeValue, driveTimeInMins: this.selectedDriveTimeOption, city: cityParam, state: stateParam } });
          // this.reportInputParams
          this.router.navigate(['/single-site/report'], {
            state: this.reportInputParams,
          });
        }
      });
    }
  }

  private showErrorMessages() {
    this.formUtil.markFormGroupTouchedDirty(this.spDriveTimeForm);
    this.spDriveTimeForm.markAsTouched();
    this.spDriveTimeForm.markAsDirty();
    this.onValueChanged();
  }

  private buildForm(): void {
    this.spDriveTimeForm = this.fb.group({
      address: ['', Validators.required],
      cityState: ['', Validators.required],
      profile: [null, Validators.required],
      zip: ['', Validators.required],
      lat: ['', [Validators.max(90), Validators.min(-90)]],
      lon: ['', [Validators.max(180), Validators.min(-180)]],
    });

    this.spDriveTimeForm.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged(); // reset validation messages now
  }

  private updateAndValidateForm(lat, lon, address, cityState, zip) {
    lat.updateValueAndValidity({ emitEvent: false });
    lon.updateValueAndValidity({ emitEvent: false });
    address.updateValueAndValidity({ emitEvent: false });
    cityState.updateValueAndValidity({ emitEvent: false });
    zip.updateValueAndValidity({ emitEvent: false });
  }

  private onValueChanged(data?: any) {
    if (!this.spDriveTimeForm) {
      return;
    }
    const form = this.spDriveTimeForm;

    const lat = form.get('lat');
    const lon = form.get('lon');
    const address = form.get('address');
    const cityState = form.get('cityState');
    const zip = form.get('zip');

    //Set validation based on what was filled out.  Lat/Lon trumps address
    if ((lat.value || lon.value) && !lat.validator) {
      lat.setValidators([Validators.required, Validators.max(90), Validators.min(-90)]);
      lon.setValidators([Validators.required, Validators.max(180), Validators.min(-180)]);
      address.setValidators(null);
      cityState.setValidators(null);
      zip.setValidators(null);
      this.updateAndValidateForm(lat, lon, address, cityState, zip);
    } else if (!lat.value && !lon.value && lat.validator) {
      lat.setValidators(null);
      lon.setValidators(null);
      address.setValidators([Validators.required]);
      cityState.setValidators([Validators.required]);
      zip.setValidators([Validators.required]);
      this.updateAndValidateForm(lat, lon, address, cityState, zip);
    }

    for (const field in this.errors) {
      // clear previous error message (if any)
      this.errors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || control.touched) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.errors[field] += messages[key] + ' ';
          break;
        }
      }
    }
  }

  onMarketSelect(event: TypeaheadMatch): void {
    this.spDriveTimeForm.controls['cityState'].setValue(event.item);
  }

  private filterMarkets(val: any) {
    let filter;
    if (val.name) {
      filter = val.name.toLowerCase();
    } else {
      filter = val.toLowerCase();
    }
    return val
      ? this.markets.filter((market) => market.name.toLowerCase().indexOf(filter) !== -1)
      : this.markets.slice(0, 50);
  }
}
