import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { EnvConfig } from '../models/envConfig';
import { InterceptorSkipHeader } from '../httpInterceptor/TokenHttpInterceptor';

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService extends BaseService {
  public config: EnvConfig = null;
  private url = 'assets/settings/env.json';
  constructor(http: HttpClient) {
    super(http);
  }

  public async getEnvSettings(): Promise<any> {
    // The APP_INITIALIZER requires a promise; otherwise, we would use observable!
    const header = { 
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(InterceptorSkipHeader, ''),
  };
    return this.http.get(this.url, header).toPromise();
  }

  public init(data: any) {
    this.config = new EnvConfig(data);
  }
}
