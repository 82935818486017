import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Alignment, Fill, Font, Workbook, Worksheet } from 'exceljs';
import * as FileSaver from 'file-saver';
import { baseLineMap } from 'src/app/core/baselineMap';
import { DialogService } from 'src/app/core/dialog.service';
import { UserDataService } from 'src/app/core/services/userData.service';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { dataPointFormats } from 'src/app/core/util/dataPointFormats';
import { Market } from 'src/app/models/market';
import { Util } from 'src/app/util/Util';

import { BenchMarkQueryParams } from '../models/benchmark-query-params.model';

@Injectable({ providedIn: 'root' })
export class BenchmarkingExcelService {
  private canDownloadReport = true;

  excelFileName = 'Benchmarking_Report.xlsx';
  title = 'Benchmarking Details';

  titleFontStyle: Partial<Font> = {
    name: 'Calibre-Semibold',
    family: 4,
    size: 14,
    underline: 'none',
    bold: true,
    color: { argb: 'FFFFFF' },
  };
  fontstyle: Partial<Font> = {
    name: 'Calibre-Semibold',
    family: 4,
    size: 10,
    underline: 'none',
    bold: true,
    color: { argb: 'FFFFFF' },
  };
  headingstyle: Partial<Font> = {
    name: 'Calibre-Semibold',
    family: 4,
    size: 11,
    underline: 'none',
    bold: true,
    color: { argb: 'FFFFFF' },
  };
  calibrestyle: Partial<Font> = {
    name: 'Calibre',
    family: 4,
    size: 10,
    underline: 'none',
  };
  calibretablestyle: Partial<Font> = {
    name: 'Calibre',
    family: 4,
    size: 11,
    underline: 'none',
  };
  reportTitleRowFontStyle: Partial<Font> = {
    name: 'Futura lt BT',
    family: 4,
    size: 14,
    underline: 'none',
    bold: true,
    color: { argb: 'FFFFFF' },
  };
  reportTitleRowFill: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '808080' },
    bgColor: { argb: 'FFFFFF' },
  };

  colorLevels: any = [
    {
      level: 0,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '435254' },
        bgColor: { argb: '435254' },
      },
    },
    {
      level: 1,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '80BBAD' },
        bgColor: { argb: '80BBAD' },
      },
    },
    {
      level: 2,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '96B3B6' },
        bgColor: { argb: '96B3B6' },
      },
    },
    {
      level: 3,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'CAD1D3' },
        bgColor: { argb: 'CAD1D3' },
      },
    },
  ];

  fontLevels: any = [
    {
      level: 0,
      font: {
        name: 'Calibre',
        family: 4,
        size: 11,
        underline: 'none',
        color: { argb: 'FFFFFF' },
      },
    },
    {
      level: 1,
      font: {
        name: 'Calibre',
        family: 4,
        size: 14,
        underline: 'none',
        color: { argb: 'FFFFFF' },
      },
    },
    {
      level: 2,
      font: {
        name: 'Calibre',
        family: 4,
        size: 11,
        bold: true,
        underline: 'none',
        color: { argb: '435254' },
      },
    },
    {
      level: 3,
      font: {
        name: 'Calibre',
        family: 4,
        size: 11,
        underline: 'none',
        color: { argb: '435254' },
      },
    },
  ];

  color_level_0: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '28573C' },
    bgColor: { argb: '28573C' },
  };
  color_level_1: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '50AF78' },
    bgColor: { argb: '50AF78' },
  };
  color_level_2: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'E6F4EC' },
    bgColor: { argb: 'E6F4EC' },
  };
  color_level_3: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F5F7F7' },
    bgColor: { argb: 'F5F7F7' },
  };

  color_default: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F5F7F7' },
    bgColor: { argb: 'F5F7F7' },
  };
  color_fixed_header: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '435254' },
    bgColor: { argb: '435254' },
  };
  color_benchmarking_report: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '012a2d' },
    bgColor: { argb: '012a2d' },
  };
  color_generic_header_level0: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '879B9E' },
    bgColor: { argb: '879B9E' },
  };
  color_generic_header_all: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'CAD1D3' },
    bgColor: { argb: 'CAD1D3' },
  };

  fontstyle_nonBold: Partial<Font> = {
    name: 'Futura lt BT',
    family: 4,
    size: 10,
    underline: 'none',
    bold: false,
  };
  //, indent:0, readingOrder:'ltr', textRotation:0
  headerAlignment: Partial<Alignment> = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };

  leftValueAlignment: Partial<Alignment> = {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true,
  };

  marketValueAlignment: Partial<Alignment> = {
    vertical: 'middle',
    horizontal: 'right',
    wrapText: true,
  };

  marketDataAlignment: Partial<Alignment> = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: false,
  };

  profileWeightsPointer = 1;
  headerCellPointer = 2;
  nationalRowpointer = 1;
  execlCellPosition = 0; // to get AA cell
  baseLineType;

  headerCellPointerReset: number;

  profileDataPointWeights: any = [];

  dpFormatMap = new Map();

  constructor(
    private datePipe: DatePipe,
    private util: Util,
    private benchmarkSelectionCriteria: BenchMarkQueryParams,
    private userDataService: UserDataService,
    private baseLineMap: baseLineMap,
    private dataPointFormats: dataPointFormats,
    private dialogService: DialogService,
  ) {
    this.headerCellPointerReset = this.headerCellPointer;
  }

  createBenchMarkingReport(
    benchmarkScores: any,
    dpFormats: any,
    markets: Market[],
    benchMarkSelectionCriteria: BenchMarkQueryParams,
  ) {
    this.canDownloadReport = true;
    this.dpFormatMap = this.dataPointFormats.creatMap(dpFormats); // create a data point format map from indexing response object
    this.benchmarkSelectionCriteria = benchMarkSelectionCriteria;
    this.baseLineType = this.benchmarkSelectionCriteria.baseLine;
    const workbook = new Workbook();

    this.profileDataPointWeights = []; // reset this for every benchmark report run

    this.createMetaData(workbook, markets, benchmarkScores);

    this.nationalRowpointer = 1;

    benchmarkScores.sort((x, y) => {
      return parseFloat(y.score.index) - parseFloat(x.score.index);
    }); // sort benchmark score in descending order

    let interestedMarkets = [];

    if (this.benchmarkSelectionCriteria.additionalMarkets.length > 0) {
      interestedMarkets = benchmarkScores.filter((site) => {
        const temp = this.benchmarkSelectionCriteria.additionalMarkets.filter(
          // additional markets
          (market) => market.toLowerCase() === site.city.toLowerCase() + ', ' + site.state.toLowerCase(),
        );
        if (temp.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      // Remove duplicate additional markets from list if any...
      const uniqueAdditionalMarkets = interestedMarkets.reduce((unique, o) => {
        if (
          !unique.some(
            (market) =>
              market.city.toLowerCase() + ', ' + market.state.toLowerCase() ===
              o.city.toLowerCase() + ', ' + o.state.toLowerCase(),
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, []);

      interestedMarkets = uniqueAdditionalMarkets;

      const tempBenchmarkScores = benchmarkScores.filter((site) => {
        const temp = this.benchmarkSelectionCriteria.additionalMarkets.filter(
          // remaining markets leaving additional markets
          (market) =>
            market.toLowerCase() === site.city.toLowerCase() + ', ' + site.state.toLowerCase() &&
            this.benchmarkSelectionCriteria.filterMarkets.indexOf(Number(site.geographyId)) < 0,
        );
        if (temp.length > 0) {
          return false;
        } else {
          return true;
        }
      });

      benchmarkScores = tempBenchmarkScores;
    }

    if (this.benchmarkSelectionCriteria.baseLine == 'SITEASBASELINE') {
      /// remove base line site from showing up as a seperate row in scoring sheet
      const tempScores = benchmarkScores.filter((market) => market.useAsBaseline === false);
      benchmarkScores = tempScores;
    }

    this.createScoreComparison(benchmarkScores, interestedMarkets, markets, workbook);

    if (this.canDownloadReport) {
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, this.excelFileName);
      });
    }

    this.headerCellPointer = this.headerCellPointerReset; // reset header pointer cell to original value
  }

  createMetaData(workbook: Workbook, markets: Market[], benchmarkScores: any) {
    //Create Metadata worksheet
    const worksheet_metaData = workbook.addWorksheet('MetaData');

    //Add Row and formatting
    const titleRow = worksheet_metaData.addRow(['Benchmarking Report']);
    titleRow.font = this.titleFontStyle;
    worksheet_metaData.mergeCells('A1', 'C2');
    worksheet_metaData.getCell('C2').fill = this.color_benchmarking_report;

    const dateRow = worksheet_metaData.addRow(['Date:', this.datePipe.transform(new Date(), 'medium'), '']);
    dateRow.getCell(1).fill = this.color_benchmarking_report;
    dateRow.getCell(1).font = this.fontstyle;
    dateRow.getCell(2).font = this.calibrestyle;
    //dateRow.font = this.fontstyle;

    const profileRow = worksheet_metaData.addRow(['Profile:', this.benchmarkSelectionCriteria.profileName, '']);
    profileRow.getCell(1).fill = this.color_benchmarking_report;
    profileRow.getCell(1).font = this.fontstyle;
    profileRow.getCell(2).font = this.calibrestyle;
    profileRow.getCell(2).fill = this.color_level_3;
    profileRow.getCell(3).fill = this.color_level_3;
    //profileRow.font = this.fontstyle;
    worksheet_metaData.getColumn(1).width = 20;

    if (
      this.benchmarkSelectionCriteria.driveTime.includes('Miles') ||
      this.benchmarkSelectionCriteria.driveTime.includes('Metro')
    ) {
      const radiusRow = worksheet_metaData.addRow(['Radius:', this.benchmarkSelectionCriteria.driveTime, '']);
      radiusRow.getCell(1).fill = this.color_benchmarking_report;
      radiusRow.getCell(1).font = this.fontstyle;
      radiusRow.getCell(2).font = this.calibrestyle;
      //driveTimeRow.font = this.fontstyle;
    } else {
      const driveTimeRow = worksheet_metaData.addRow([
        'Drive Time(Minutes):',
        Number(this.benchmarkSelectionCriteria.driveTime),
        '',
      ]);
      driveTimeRow.getCell(1).fill = this.color_benchmarking_report;
      driveTimeRow.getCell(1).font = this.fontstyle;
      driveTimeRow.getCell(2).font = this.calibrestyle;
      driveTimeRow.getCell(2).alignment = this.leftValueAlignment;
      //driveTimeRow.font = this.fontstyle;
    }

    if (this.benchmarkSelectionCriteria.baseLine == 'SITEASBASELINE') {
      const baseLineMarket = markets.find((market) => market.id == this.benchmarkSelectionCriteria.baseLineMarket);
      const siteAddress = baseLineMarket.name;

      const baseLinesRow = worksheet_metaData.addRow(['Base Line:', 'SITEASBASELINE', '']);
      baseLinesRow.getCell(1).fill = this.color_benchmarking_report;
      baseLinesRow.getCell(1).font = this.fontstyle;
      baseLinesRow.getCell(2).font = this.calibrestyle;
      baseLinesRow.getCell(2).fill = this.color_level_3;
      baseLinesRow.getCell(3).fill = this.color_level_3;
      //baselineRow.font = this.fontstyle;

      const baseLineMarketRow = worksheet_metaData.addRow(['Base Line Market:', siteAddress, '']);
      baseLineMarketRow.getCell(1).fill = this.color_benchmarking_report;
      baseLineMarketRow.getCell(1).font = this.fontstyle;
      baseLineMarketRow.getCell(2).font = this.calibrestyle;
      baseLineMarketRow.getCell(2).fill = this.color_level_3;
      baseLineMarketRow.getCell(3).fill = this.color_level_3;
      //addressRow.font = this.fontstyle;
    } else {
      const baseLineName = this.baseLineMap.getBaseLineName(this.benchmarkSelectionCriteria.baseLine);
      const baseLineRow = worksheet_metaData.addRow(['Base Line:', baseLineName, '']);
      baseLineRow.getCell(1).fill = this.color_benchmarking_report;
      baseLineRow.getCell(1).font = this.fontstyle;
      baseLineRow.getCell(2).font = this.calibrestyle;
      baseLineRow.getCell(2).fill = this.color_level_3;
      baseLineRow.getCell(3).fill = this.color_level_3;
      //baselineRow.font = this.fontstyle;
    }

    worksheet_metaData.getColumn(2).width = 30;

    //worksheet_metaData.addRow([]);          // add a empty row

    if (
      this.benchmarkSelectionCriteria.filterCriteria != undefined &&
      this.benchmarkSelectionCriteria.filterCriteria.length > 0
    ) {
      let firstRowCriteria = true;
      this.benchmarkSelectionCriteria.filterCriteria.forEach((item) => {
        let filtersTextRow;
        if (firstRowCriteria) {
          filtersTextRow = worksheet_metaData.addRow(['Filter Criteria:', item.displayText, '']);
          firstRowCriteria = false;
        } else {
          filtersTextRow = worksheet_metaData.addRow(['', item.displayText, '']);
        }
        filtersTextRow.getCell(1).fill = this.color_benchmarking_report;
        filtersTextRow.getCell(1).font = this.fontstyle;
        filtersTextRow.getCell(2).font = this.calibrestyle;
        //filtersTextRow.font = this.fontstyle;
      });

      // worksheet_metaData.addRow([]);          // add a empty row
    }

    if (
      this.benchmarkSelectionCriteria.additionalMarkets != undefined &&
      this.benchmarkSelectionCriteria.additionalMarkets != null &&
      this.benchmarkSelectionCriteria.additionalMarkets.length > 0
    ) {
      const additionalMarkets = this.benchmarkSelectionCriteria.additionalMarkets.join('; ');
      const additionalMarketsRow = worksheet_metaData.addRow(['Additional Markets:', additionalMarkets, '']);
      additionalMarketsRow.getCell(1).fill = this.color_benchmarking_report;
      additionalMarketsRow.getCell(1).font = this.fontstyle;
      additionalMarketsRow.getCell(2).font = this.calibrestyle;
      additionalMarketsRow.getCell(2).fill = this.color_level_3;
      additionalMarketsRow.getCell(3).fill = this.color_level_3;
      // additionalMarketsRow.font = this.fontstyle;
      // worksheet_metaData.addRow([]);          // add a empty row
    }

    const downloadRow = worksheet_metaData.addRow([
      'Downloaded By:',
      this.userDataService.userInfo.firstName + ', ' + this.userDataService.userInfo.lastName,
      '',
    ]);
    downloadRow.getCell(1).fill = this.color_benchmarking_report;
    downloadRow.getCell(1).font = this.fontstyle;
    downloadRow.getCell(2).font = this.calibrestyle;
    //downloadedByRow.font = this.fontstyle;

    worksheet_metaData.addRow([]); // add a empty row

    const firstMarketScore = JSON.parse(JSON.stringify(benchmarkScores[0].score));

    this.writeProfileDataPoints(firstMarketScore, worksheet_metaData, this.headerAlignment);
  }

  writeProfileDataPoints(firstMarketScore: any, worksheet_metaData: Worksheet, headerAlignment: Partial<Alignment>) {

    this.getProfileWeights(firstMarketScore, 100, -1);

    // console.log(profileDataPointWeights);

    const profileHeaderRow = worksheet_metaData.addRow([, , , 'Total Effective Weight', 'Profile Weight']);
    profileHeaderRow.alignment = this.headerAlignment;
    profileHeaderRow.font = this.calibretablestyle;

    worksheet_metaData.getColumn(3).width = 20;
    worksheet_metaData.getColumn(4).width = 20;

    this.profileDataPointWeights.forEach((element) => {
      const currentRow = worksheet_metaData.addRow([, , element.datapointname, ,]);
      const levelHeadings = currentRow.getCell(2);
      levelHeadings.font = this.calibretablestyle;
      levelHeadings.alignment = this.leftValueAlignment;
      const effWeightCol = currentRow.getCell(3);
      effWeightCol.numFmt = '0.00%';
      effWeightCol.value = Number(element.effectiveWeight) / 100;
      effWeightCol.alignment = this.headerAlignment;
      effWeightCol.font = this.calibretablestyle;

      const weightCol = currentRow.getCell(4);
      weightCol.numFmt = '0.00%';
      weightCol.value = Number(element.weight) / 100;
      weightCol.alignment = this.headerAlignment;
      weightCol.font = this.calibretablestyle;
      //currentRow.alignment = this.headerAlignment;
      // add color
      const tempArr = this.colorLevels.filter((item) => item.level == element.level);
      const fontArr = this.fontLevels.filter((item) => item.level == element.level);
      if (tempArr.length != 0 && !element.leafNode) {
        // this means that it is a level that needs to be colored
        currentRow.eachCell({ includeEmpty: false }, function (cell, _colNumber) {
          cell.fill = tempArr[0].fill;
          cell.font = fontArr[0].font;
        });
      }
    });

    // adding border color to white
    worksheet_metaData.eachRow({ includeEmpty: true }, function (row, _rowNumber) {
      row.eachCell({ includeEmpty: false }, function (cell, _colNumber) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  }

  private getProfileWeights(firstMarketScore, parentWeight, level) {
    level++;
    // console.log(firstMarketScore.datapointname + ' level: ' + level + ' weight: ' + firstMarketScore.weight + ' effectiveWeight: ' +(firstMarketScore.weight * parentWeight) / 100);
    const effectiveWeight = (firstMarketScore.weight * parentWeight) / 100;
    if (
      firstMarketScore.children != undefined &&
      firstMarketScore.children != null &&
      firstMarketScore.children.length != 0
    ) {
      const row = {
        datapointname: firstMarketScore.datapointname,
        level: level,
        weight: firstMarketScore.weight,
        effectiveWeight: effectiveWeight,
        leafNode: false,
      };
      this.profileDataPointWeights.push(row);
      for (let i = 0; i < firstMarketScore.children.length; i++) {
        this.getProfileWeights(firstMarketScore.children[i], effectiveWeight, level);
      }
    } else {
      const row = {
        datapointname: firstMarketScore.datapointname,
        level: level,
        weight: firstMarketScore.weight,
        effectiveWeight: effectiveWeight,
        leafNode: true,
      };
      this.profileDataPointWeights.push(row);
    }
  }

  private createScoreComparison(benchmarkScores: any, interestedMarkets: any, markets: Market[], workbook: Workbook) {
    const __this = this;
    const worksheet_scoreComparison = workbook.addWorksheet('Benchmark Scores');

    // setting the default color to the cells
    worksheet_scoreComparison.eachRow({ includeEmpty: true }, function (row, _rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
        cell.style.font = { bold: false };
        __this.setCellBorderStyles(cell);
      });
    });


    this.addProfileWeightFields(worksheet_scoreComparison); 

    var cell = worksheet_scoreComparison.getCell('A' + this.headerCellPointer);
    cell.value = 'Market Name';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    worksheet_scoreComparison.getColumn('A').width = 25;

    let iaddresscount = this.headerCellPointer + 1;

    var cell = worksheet_scoreComparison.getCell('B' + this.headerCellPointer);
    cell.value = 'CBSA Principal City';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'B', 30);

    var cell = worksheet_scoreComparison.getCell('C' + this.headerCellPointer);
    cell.value = 'CBSA';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'C', 30);

    var cell = worksheet_scoreComparison.getCell('D' + this.headerCellPointer);
    cell.value = 'Metro/Micro';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'D', 20);

    var cell = worksheet_scoreComparison.getCell('E' + this.headerCellPointer);
    cell.value = 'State';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'E', 20);

    var cell = worksheet_scoreComparison.getCell('F' + this.headerCellPointer);
    cell.value = 'Fips Code';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'F', 20);

    var cell = worksheet_scoreComparison.getCell('G' + this.headerCellPointer);
    cell.value = 'Market of Interest';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'G', 20);

    var cell = worksheet_scoreComparison.getCell('H' + this.headerCellPointer);
    cell.value = 'TimeZone';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'H', 20);

    var cell = worksheet_scoreComparison.getCell('I' + this.headerCellPointer);
    cell.value = 'Union';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'I', 20);

    var cell = worksheet_scoreComparison.getCell('J' + this.headerCellPointer);
    cell.value = 'Right To Work';
    cell.alignment = this.leftValueAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'J', 20);

    var cell = worksheet_scoreComparison.getCell('K' + this.headerCellPointer);
    cell.value = 'Population Size';
    cell.alignment = this.headerAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'K', 20);

    var cell = worksheet_scoreComparison.getCell('L' + this.headerCellPointer);
    cell.value = 'Labor Force';
    cell.alignment = this.headerAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    this.setColumnWidth(worksheet_scoreComparison, 'L', 20);

    var cell = worksheet_scoreComparison.getCell('M' + this.headerCellPointer);
    cell.value = 'Total Index';
    //cell.fill = this.color_level_0;
    cell.alignment = this.headerAlignment;
    cell.fill = this.color_fixed_header;
    cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
    const column_level0 = worksheet_scoreComparison.getColumn('M');
    column_level0.width = 20;

    const additionalMarkettsLength = this.benchmarkSelectionCriteria.additionalMarkets.length;

    if (additionalMarkettsLength > 0) {
      if (benchmarkScores.length > 0) {
        this.nationalRowpointer = this.benchmarkSelectionCriteria.additionalMarkets.length + 1; // set the national row pointer to row after the end of interested markets
        this.addInterestedMarkets(interestedMarkets, markets, worksheet_scoreComparison, 1); // first add interested markets on top of the sheet.
      } else {
        this.addInterestedMarkets(interestedMarkets, markets, worksheet_scoreComparison, 2); // first add interested markets on top of the sheet.
      }
    }

    this.addBaselineMarketFields(worksheet_scoreComparison, markets);

    iaddresscount = this.headerCellPointer + this.nationalRowpointer + 1;

    benchmarkScores.forEach((element) => {
      // find the market for adding market information in the report
      const elementName = element.city + ', ' + element.state;
      const thisMarket = markets.find((market) => elementName.toLocaleLowerCase() === market.name.toLocaleLowerCase());

      const cellA = worksheet_scoreComparison.getCell('A' + iaddresscount);
      cellA.value = element.city;
      cellA.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('B' + iaddresscount);
      cell.value = thisMarket.mktLbl;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('C' + iaddresscount);
      cell.value = thisMarket.metroName;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('D' + iaddresscount);
      cell.value = thisMarket.msaType == 1 ? 'Metro' : 'Micro';;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('E' + iaddresscount);
      cell.value = element.state;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('F' + iaddresscount);
      cell.value = thisMarket.fipsCode;
      cell.alignment = this.leftValueAlignment;

      // TO Be Modified

      var cell = worksheet_scoreComparison.getCell('G' + iaddresscount);
      let cellValue = 'N';
      if (
        this.benchmarkSelectionCriteria.additionalMarkets != undefined &&
        this.benchmarkSelectionCriteria.additionalMarkets.length > 0
      ) {
        const tempArr = this.benchmarkSelectionCriteria.additionalMarkets.filter(
          (market) => market.toLowerCase() === element.city.toLowerCase() + ', ' + element.state.toLowerCase(),
        );

        if (tempArr.length > 0) {
          cellValue = 'Y';
        }
      }
      cell.value = cellValue;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('H' + iaddresscount);
      cell.value = this.validateAndSet(thisMarket.timeZone);
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('I' + iaddresscount);
      let tempUnion = this.validateAndSet(thisMarket.union);
      if (tempUnion != 'NA') {
        cell.numFmt = '0.00%';
        tempUnion = tempUnion / 100; // this is because excel mulitplies by 100 to display percetages
        cell.value = Number(tempUnion);
      } else {
        cell.value = tempUnion;
      }
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('J' + iaddresscount);
      let tempRTW = this.validateAndSet(thisMarket.rightToWork);
      if (tempRTW != 'NA') tempRTW = tempRTW == true ? 'Y' : 'N';
      cell.value = tempRTW;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('K' + iaddresscount);
      let tempPop: any;
      let tempLF: any;
      if (thisMarket.marketInfo !== undefined && thisMarket.marketInfo.length > 0) {
        const driveTimeRing = thisMarket.marketInfo.filter(
          (driveTime) => driveTime.driveTime.toString() == this.benchmarkSelectionCriteria.driveTime,
        );
        if (driveTimeRing.length == 1) {
          tempPop = driveTimeRing[0].population;
          tempLF = driveTimeRing[0].laborForce;
        } else {
          tempPop = 'NA';
          tempLF = 'NA';
        }
      } else {
        tempPop = 'NA';
        tempLF = 'NA';
      }
      this.setNumbersInCol(cell, tempPop);

      var cell = worksheet_scoreComparison.getCell('L' + iaddresscount);
      this.setNumbersInCol(cell, tempLF);

      var cell = worksheet_scoreComparison.getCell('M' + iaddresscount);
      cell.value = Math.round(element.score.index * 10) / 10;
      cell.alignment = this.headerAlignment;
      cell.fill = (+cell.row % 2) % 2 != 0 ? this.color_level_0 : this.color_level_0;

      this.execlCellPosition = 2; // starts from cell L
      element.score.children.forEach((level1) => {
        //Level 1
        if (level1.children != null) {
          this.parentDatarendering(
            worksheet_scoreComparison,
            level1,
            iaddresscount,
            this.color_generic_header_level0,
            this.color_generic_header_level0,
          );

          level1.children.forEach((level2) => {
            //Level 2
            if (level2.children != null) {
              this.parentDatarendering(
                worksheet_scoreComparison,
                level2,
                iaddresscount,
                this.color_generic_header_all,
                this.color_generic_header_all,
              );

              level2.children.forEach((level3) => {
                //Level 3
                if (level3.children != null) {
                  this.parentDatarendering(
                    worksheet_scoreComparison,
                    level3,
                    iaddresscount,
                    this.color_generic_header_all,
                    this.color_generic_header_all,
                  );

                  level3.children.forEach((level4) => {
                    this.lastLevelDatarendering(
                      worksheet_scoreComparison,
                      level4,
                      iaddresscount,
                      this.color_generic_header_all,
                    );
                  }); // end of level3.children.forEach
                } else {
                  this.lastLevelDatarendering(
                    worksheet_scoreComparison,
                    level3,
                    iaddresscount,
                    this.color_generic_header_all,
                  );
                }
              }); // end of level2.children.forEach
            } else {
              this.lastLevelDatarendering(
                worksheet_scoreComparison,
                level2,
                iaddresscount,
                this.color_generic_header_all,
              );
            }
          }); // end of level1.children.forEach
        } else {
          this.lastLevelDatarendering(worksheet_scoreComparison, level1, iaddresscount, this.color_generic_header_all);
        }
        // mi = mi + element1.children.length + 1;
      }); // end of level0.children.forEach
      iaddresscount++;
    }); // end of main loop

    worksheet_scoreComparison.eachRow({ includeEmpty: true }, function (row, _rowNumber) {
      if (_rowNumber > 2) {
        row.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
          cell.style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F5F7F7' },
            bgColor: { argb: 'F5F7F7' },
          };
        });
      }
    });

    // colorcoding the cells based on cell count
    this.setCellColorCoding(worksheet_scoreComparison);

    // add style
    worksheet_scoreComparison.eachRow({ includeEmpty: true }, function (row, _rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
        // console.log(cell.address); // <- to see I actullay go into the cells
        // changing cell border color to white
        __this.setCellBorderStyles(cell);
      });
    });

    const baselineRowPointer = worksheet_scoreComparison.getRow(this.headerCellPointer + this.nationalRowpointer);
    baselineRowPointer.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
        bgColor: { argb: 'D9D9D9' },
      };
      cell.font = {
        name: 'Calibre',
        family: 4,
        size: 11,
        underline: 'none',
        bold: true,
      };
    });
    const profileWeightsRow = worksheet_scoreComparison.getRow(this.profileWeightsPointer);
    __this.execlCellPosition = 1; // starts from cell L
    profileWeightsRow.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
      if (cell.value === 'Profile Weight' || cell.value === 'NA') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
          bgColor: { argb: 'D9D9D9' },
        };
        cell.font = {
          name: 'Calibre',
          family: 4,
          size: 11,
          underline: 'none',
          bold: true,
        };
       } else {
          const cell_pointer = __this.getCellPointer(__this.execlCellPosition);
          var cellHeader = worksheet_scoreComparison.getCell(cell_pointer + __this.headerCellPointer);
            __this.profileDataPointWeights.forEach((element) => {
              if (element.datapointname === cellHeader.value || element.datapointname + ' Index' === cellHeader.value) {
                cell.value = Number(element.weight) + '%';
                // add color
                const tempArr = __this.colorLevels.filter((item) => item.level == element.level);
                const fontArr = __this.fontLevels.filter((item) => item.level == element.level);
                if (tempArr.length != 0 && !element.leafNode) {
                    cell.fill = tempArr[0].fill;
                    cell.font = fontArr[0].font;
                }
              }
            });
            cell.alignment = __this.headerAlignment;
      }        
    });
    const headerRow = worksheet_scoreComparison.getRow(this.headerCellPointer);
    headerRow.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
            __this.profileDataPointWeights.forEach((element) => {
              if (element.datapointname === cell.value || element.datapointname + ' Index' === cell.value || element.datapointname + ' Value' === cell.value) {
                // add color
                const tempArr = __this.colorLevels.filter((item) => item.level == element.level);
                const fontArr = __this.fontLevels.filter((item) => item.level == element.level);
                if (tempArr.length != 0 && !element.leafNode) {
                    cell.fill = tempArr[0].fill;
                    cell.font = fontArr[0].font;
                }
              }
            });     
    });
  }

  private addInterestedMarkets(
    interestedMarkts: any,
    markets: Market[],
    worksheet_scoreComparison: Worksheet,
    startAtRow,
  ) {
    let iaddresscount = this.headerCellPointer + startAtRow;
    interestedMarkts.forEach((element) => {
      // find the market for adding market information in the report
      const elementName = element.city + ', ' + element.state;
      const thisMarket = markets.find((market) => elementName.toLocaleLowerCase() === market.name.toLocaleLowerCase());

      const cellA = worksheet_scoreComparison.getCell('A' + iaddresscount);
      cellA.value = element.city;
      cellA.alignment = this.leftValueAlignment;

      // To Bo modified after backend api changes

      var cell = worksheet_scoreComparison.getCell('B' + iaddresscount);
      cell.value = thisMarket.mktLbl;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('C' + iaddresscount);
      cell.value = thisMarket.metroName;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('D' + iaddresscount);
      cell.value = thisMarket.msaType == 1 ? 'Metro' : 'Micro';;
      cell.alignment = this.leftValueAlignment;      

      var cell = worksheet_scoreComparison.getCell('E' + iaddresscount);
      cell.value = element.state;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('F' + iaddresscount);
      cell.value = thisMarket.fipsCode;
      cell.alignment = this.leftValueAlignment;

      // TO Be Modified

      var cell = worksheet_scoreComparison.getCell('G' + iaddresscount);
      let cellValue = 'N';
      if (
        this.benchmarkSelectionCriteria.additionalMarkets != undefined &&
        this.benchmarkSelectionCriteria.additionalMarkets.length > 0
      ) {
        const tempArr = this.benchmarkSelectionCriteria.additionalMarkets.filter(
          (market) => market.toLowerCase() === element.city.toLowerCase() + ', ' + element.state.toLowerCase(),
        );

        if (tempArr.length > 0) {
          cellValue = 'Y';
        }
      }
      cell.value = cellValue;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('H' + iaddresscount);
      cell.value = this.validateAndSet(thisMarket.timeZone);
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('I' + iaddresscount);
      let tempUnion = this.validateAndSet(thisMarket.union);
      if (tempUnion != 'NA') {
        // tempUnion = tempUnion.toFixed(2) + ' %';
        cell.numFmt = '0.00%';
        tempUnion = tempUnion / 100;
        cell.value = Number(tempUnion);
      } else {
        cell.value = tempUnion;
      }
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('J' + iaddresscount);
      let tempRTW = this.validateAndSet(thisMarket.rightToWork);
      if (tempRTW != 'NA') tempRTW = tempRTW == true ? 'Y' : 'N';
      cell.value = tempRTW;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('K' + iaddresscount);
      let tempPop: any;
      let tempLF: any;
      if (thisMarket.marketInfo !== undefined && thisMarket.marketInfo.length > 0) {
        const driveTimeRing = thisMarket.marketInfo.filter(
          (driveTime) => driveTime.driveTime.toString() == this.benchmarkSelectionCriteria.driveTime,
        );
        if (driveTimeRing.length == 1) {
          tempPop = driveTimeRing[0].population;
          tempLF = driveTimeRing[0].laborForce;
        } else {
          tempPop = 'NA';
          tempLF = 'NA';
        }
      } else {
        tempPop = 'NA';
        tempLF = 'NA';
      }

      this.setNumbersInCol(cell, tempPop);

      var cell = worksheet_scoreComparison.getCell('L' + iaddresscount);
      this.setNumbersInCol(cell, tempLF);

      var cell = worksheet_scoreComparison.getCell('M' + iaddresscount);
      cell.value = Math.round(element.score.index * 10) / 10;
      cell.alignment = this.headerAlignment;

      cell.fill = (+cell.row % 2) % 2 != 0 ? this.color_level_0 : this.color_level_0;

      this.execlCellPosition = 2; // to get AA cell
      element.score.children.forEach((level1) => {
        //Level 1
        if (level1.children != null) {
          this.parentDatarendering(
            worksheet_scoreComparison,
            level1,
            iaddresscount,
            this.color_generic_header_level0,
            this.color_generic_header_level0,
          );

          level1.children.forEach((level2) => {
            //Level 2
            if (level2.children != null) {
              this.parentDatarendering(
                worksheet_scoreComparison,
                level2,
                iaddresscount,
                this.color_generic_header_all,
                this.color_generic_header_all,
              );

              level2.children.forEach((level3) => {
                //Level 3
                if (level3.children != null) {
                  this.parentDatarendering(
                    worksheet_scoreComparison,
                    level3,
                    iaddresscount,
                    this.color_generic_header_all,
                    this.color_generic_header_all,
                  );

                  level3.children.forEach((level4) => {
                    this.lastLevelDatarendering(worksheet_scoreComparison, level4, iaddresscount);
                  }); // end of level3.children.forEach
                } else {
                  this.lastLevelDatarendering(worksheet_scoreComparison, level3, iaddresscount);
                }
              }); // end of level2.children.forEach
            } else {
              this.lastLevelDatarendering(worksheet_scoreComparison, level2, iaddresscount);
            }
          }); // end of level1.children.forEach
        } else {
          this.lastLevelDatarendering(worksheet_scoreComparison, level1, iaddresscount);
        }
        // mi = mi + element1.children.length + 1;
      }); // end of level0.children.forEach
      iaddresscount++;
    }); // end of main loop
  }

  private getCellPointer(execlCell: number) {
    let cellPonterName = '';
    if (execlCell < 15) {
      // cpname1 = (mi + 15).toString(36) + '1';
      cellPonterName = (execlCell + 21).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 41) {
      cellPonterName = 'A' + (execlCell - 5).toString(36).toUpperCase(); // (0 + 10).toString(36) = a
      this.execlCellPosition++;
    } else if (execlCell < 67) {
      cellPonterName = 'B' + (execlCell - 31).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 93) {
      cellPonterName = 'C' + (execlCell - 57).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 119) {
      cellPonterName = 'D' + (execlCell - 83).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 145) {
      cellPonterName = 'E' + (execlCell - 109).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 171) {
      cellPonterName = 'F' + (execlCell - 135).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 197) {
      cellPonterName = 'G' + (execlCell - 161).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 223) {
      cellPonterName = 'H' + (execlCell - 187).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 249) {
      cellPonterName = 'I' + (execlCell - 213).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 275) {
      cellPonterName = 'J' + (execlCell - 239).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 301) {
      cellPonterName = 'K' + (execlCell - 265).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 327) {
      cellPonterName = 'L' + (execlCell - 291).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 353) {
      cellPonterName = 'M' + (execlCell - 317).toString(36).toUpperCase();
      this.execlCellPosition++;
    } else if (execlCell < 379) {
      cellPonterName = 'N' + (execlCell - 343).toString(36).toUpperCase();
      this.execlCellPosition++;
    }
    return cellPonterName;
  }

  private lastLevelDatarendering(worksheet_scoreComparison: Worksheet, lastLevel, iaddresscount: number, color?: any) {
    const cellPointerName = this.getCellPointer(this.execlCellPosition);
    const cellPointerName_Index = this.getCellPointer(this.execlCellPosition);

    var cell1 = worksheet_scoreComparison.getCell(cellPointerName + this.headerCellPointer);
    cell1.value = lastLevel.datapointname + ' Value';
    cell1.alignment = this.headerAlignment;

    var column1 = worksheet_scoreComparison.getColumn(cellPointerName);
    column1.width = 20;

    var cell1 = worksheet_scoreComparison.getCell(cellPointerName_Index + this.headerCellPointer);
    cell1.value = lastLevel.datapointname + ' Index';
    cell1.alignment = this.headerAlignment;

    var column1 = worksheet_scoreComparison.getColumn(cellPointerName_Index);
    column1.width = 20;

    // if (!forAdditionalMarket) {

    const cpindex1_value = cellPointerName + (this.headerCellPointer + this.nationalRowpointer);
    const cellindex1_value = worksheet_scoreComparison.getCell(cpindex1_value);

    this.dataPointFormats.setValueAndformatCell(
      cellindex1_value,
      lastLevel.datapointname,
      lastLevel.baselineValue,
      this.headerAlignment,
      this.dpFormatMap,
    );

    const cpindex1_Index = cellPointerName_Index + (this.headerCellPointer + this.nationalRowpointer);
    const cellindex1_Index = worksheet_scoreComparison.getCell(cpindex1_Index);
    cellindex1_Index.value = 100;
    // }

    const cpname2 = cellPointerName + iaddresscount;
    var cell2 = worksheet_scoreComparison.getCell(cpname2);

    this.dataPointFormats.setValueAndformatCell(
      cell2,
      lastLevel.datapointname,
      lastLevel.marketValue,
      this.headerAlignment,
      this.dpFormatMap,
    );

    const cpname2_index = cellPointerName_Index + iaddresscount;
    var cell2 = worksheet_scoreComparison.getCell(cpname2_index);
    cell2.value = Math.round(lastLevel.index);
  }

  private parentDatarendering(
    worksheet_scoreComparison: Worksheet,
    parentLevel,
    iaddresscount: number,
    color_level,
    color_level_AlternatingColor,
  ) {
    const cellPointerName = this.getCellPointer(this.execlCellPosition);
    const cell1 = worksheet_scoreComparison.getCell(cellPointerName + this.headerCellPointer);

    // check if parent is Wages Growth and if it is then activate custom data format for leafLevelDataRendering.

    if (parentLevel.datapointname.includes('Wage Growth')) {
      // modify the dfMap so that the wages growth format is %
      this.setWagesGrowthDataPoints(parentLevel, 8);
    } else if (parentLevel.datapointname.includes('Market Wages')) {
      // restore the markets wages data point format to $
      this.setWagesGrowthDataPoints(parentLevel, 1);
    }

    cell1.value = parentLevel.datapointname + ' Index';
    cell1.alignment = this.headerAlignment;

    const column = worksheet_scoreComparison.getColumn(cellPointerName);
    column.width = 20;

    let cellindex1 = null;

    // if (!forAdditionalMarket) {
    const cpindex1 = cellPointerName + (this.headerCellPointer + this.nationalRowpointer);
    cellindex1 = worksheet_scoreComparison.getCell(cpindex1);
    cellindex1.value = 100;
    cellindex1.alignment = this.headerAlignment;
    // }

    const cpname2 = cellPointerName + iaddresscount;
    const cell2_level = worksheet_scoreComparison.getCell(cpname2);
    cell2_level.value = Math.round(parentLevel.index);
    cell2_level.alignment = this.headerAlignment;

    //header
    cell1.fill = +cell1.row % 2 != 0 ? color_level_AlternatingColor : color_level;
    //cell values
    cell2_level.fill = +cell2_level.row % 2 != 0 ? color_level_AlternatingColor : color_level;
  }

  // setValueAndFormat(cell, dataPointName, value, dpfMap){
  //     dpfMap.get
  // }

  setColumnWidth(workSheet: Worksheet, colum: string, width: number) {
    const col = workSheet.getColumn(colum);
    col.width = width;
  }

  private validateAndSet(value: any) {
    if (value == undefined || value == null) {
      return 'NA';
    } else {
      return value;
    }
  }

  addProfileWeightFields(worksheet_scoreComparison: Worksheet) {
      var cell = worksheet_scoreComparison.getCell('A' + (this.profileWeightsPointer));
      cell.value = 'Profile Weight';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('B' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('C' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('D' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('E' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('F' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('G' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('H' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('I' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('J' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;

      var cell = worksheet_scoreComparison.getCell('K' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;

      var cell = worksheet_scoreComparison.getCell('L' + (this.profileWeightsPointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;
      
      var cell = worksheet_scoreComparison.getCell('M' + (this.profileWeightsPointer));
      cell.value = 100;
      cell.alignment = this.headerAlignment;
      cell.fill = +cell.row % 2 != 0 ? this.color_level_0 : this.color_level_0;
  }


  addBaselineMarketFields(worksheet_scoreComparison: Worksheet, markets: Market[]) {
    if (this.baseLineType != 'SITEASBASELINE') {
      const baseLineName = this.baseLineMap.getBaseLineName(this.baseLineType);
      var cell = worksheet_scoreComparison.getCell('A' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineName;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('B' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('C' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('D' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('E' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('F' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('G' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('H' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('I' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('J' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;

      var cell = worksheet_scoreComparison.getCell('K' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;

      var cell = worksheet_scoreComparison.getCell('L' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.headerAlignment;
      
      var cell = worksheet_scoreComparison.getCell('M' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 100;
      cell.alignment = this.headerAlignment;
      cell.fill = +cell.row % 2 != 0 ? this.color_level_0 : this.color_level_0;
    } else {
      const baseLineMarket = markets.find(
        (market) =>
          this.benchmarkSelectionCriteria.baseLineMarketName.toLocaleLowerCase() === market.name.toLocaleLowerCase(),
      );
      var cell = worksheet_scoreComparison.getCell('A' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineMarket.name.split(',')[0];
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('B' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineMarket.mktLbl;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('C' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineMarket.metroName;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('D' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineMarket.msaType == 1 ? 'Metro' : 'Micro';;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('E' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = this.benchmarkSelectionCriteria.baseLineMarketName.substr(-2);
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('F' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = baseLineMarket.fipsCode;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('G' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 'NA';
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('H' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = this.validateAndSet(baseLineMarket.timeZone);
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('I' + (this.headerCellPointer + this.nationalRowpointer));
      let tempUnion = this.validateAndSet(baseLineMarket.union);

      if (tempUnion != 'NA') {
        cell.numFmt = '0.00%';
        tempUnion = tempUnion / 100;
        cell.value = Number(tempUnion);
      } else {
        cell.value = tempUnion;
      }
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('J' + (this.headerCellPointer + this.nationalRowpointer));
      let tempRTW = this.validateAndSet(baseLineMarket.rightToWork);
      if (tempRTW != 'NA') tempRTW = tempRTW == true ? 'Y' : 'N';
      cell.value = tempRTW;
      cell.alignment = this.leftValueAlignment;

      var cell = worksheet_scoreComparison.getCell('K' + (this.headerCellPointer + this.nationalRowpointer));
      let tempPop: any;
      let tempLF: any;
      if (baseLineMarket.marketInfo !== undefined && baseLineMarket.marketInfo.length > 0) {
        const driveTimeRing = baseLineMarket.marketInfo.filter(
          (driveTime) => driveTime.driveTime.toString() == this.benchmarkSelectionCriteria.driveTime,
        );
        if (driveTimeRing.length == 1) {
          tempPop = driveTimeRing[0].population;
          tempLF = driveTimeRing[0].laborForce;
        } else {
          tempPop = 'NA';
          tempLF = 'NA';
        }
      } else {
        tempPop = 'NA';
        tempLF = 'NA';
      }
      this.setNumbersInCol(cell, tempPop);

      var cell = worksheet_scoreComparison.getCell('L' + (this.headerCellPointer + this.nationalRowpointer));
      this.setNumbersInCol(cell, tempLF);

      var cell = worksheet_scoreComparison.getCell('M' + (this.headerCellPointer + this.nationalRowpointer));
      cell.value = 100;
      cell.alignment = this.headerAlignment;
      cell.fill = this.color_level_0;
    }
  }

  private setCellColorCoding(worksheet_scoreComparison) {
    const __this = this;

    worksheet_scoreComparison.eachRow({ includeEmpty: true }, function (row, _rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, _colNumber) {
        // color coding the cells for index column values based on range.
        let columnHeaderName: any;
        if (cell && cell._column && cell._column.values) {
          // fetching the column header to identify the label contains index or not.
          columnHeaderName = cell._column.values[2];
        }
        if (columnHeaderName) {
          if (columnHeaderName.toLowerCase().trim().endsWith('index')) {
            if (cell.value >= 0) {
              if (cell.value >= 117) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '003D30' },
                  bgColor: { argb: '003D30' },
                };
                cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
              } else if (cell.value >= 106 && cell.value < 117) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '80BBAD' },
                  bgColor: { argb: '80BBAD' },
                };
                cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
              } else if (cell.value >= 95 && cell.value < 106) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'DBD99A' },
                  bgColor: { argb: 'DBD99A' },
                };
                cell.style.font = { bold: true, color: { argb: '000000' } };
              } else if (cell.value >= 85 && cell.value < 95) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'D2785A' },
                  bgColor: { argb: 'D2785A' },
                };
                cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
              } else if (cell.value >= 0 && cell.value < 85) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'AD2A2A' },
                  bgColor: { argb: 'AD2A2A' },
                };
                cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
              } else if (!cell.value) {
                cell.style.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'AD2A2A' },
                  bgColor: { argb: 'AD2A2A' },
                };
                cell.style.font = { bold: true, color: { argb: 'FFFFFF' } };
              }
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true,
              };
            }
          } else if (cell.value >= 0) {
            __this.setCellDefaultBackgroundColor(cell);
          }
        }
      });
    });
  }

  private setNumbersInCol(cell, value) {
    if (value !== 'NA') {
      cell.numFmt = '#,##0';
      cell.value = Number(value);
    } else {
      cell.value = value;
    }
    cell.alignment = this.headerAlignment;
  }

  private setWagesGrowthDataPoints(parentNode, format) {
    // get the leaf level data points. skip one level to get leaf nodes
    if (parentNode && parentNode.children && parentNode.children.length > 0) {
      this.canDownloadReport = true;
      parentNode.children.forEach((wageCategory) => {
        if (wageCategory && wageCategory.children && wageCategory.children.length > 0) {
          wageCategory.children.forEach((element) => {
            this.dpFormatMap.set(element.datapointname, format);
          });
        }
      });
    } else {
      this.canDownloadReport = false;
      this.showWarning('Unable to run benchmark report. Please contact application support team.');
    }
  }

  // setting default background color to the cells.
  private setCellDefaultBackgroundColor(cell) {
    cell.style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F5F7F7' },
      bgColor: { argb: 'F5F7F7' },
    };
  }

  // set cell borders to color white.
  private setCellBorderStyles(cell: any) {
    return (cell.border = {
      top: { style: 'thin', color: { argb: 'FFFFFF' } },
      left: { style: 'thin', color: { argb: 'FFFFFF' } },
      bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
      right: { style: 'thin', color: { argb: 'FFFFFF' } },
    });
  }

  // show warning message id data is not available
  showWarning(message: string) {
    this.dialogService.show(message);
  }
}
