import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'table-date',
  templateUrl: './table-date.component.html',
  styleUrls: ['./table-date.component.css'],
})
export class TableDateComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() field:any;

  constructor(@Optional() private formgroupDirective: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.form??this.formgroupDirective.control;
  }

}
