import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { LogModel } from '../core/models/logModel';
import { RelativeDateOptions } from '../drive-time/research/model/article-info';

@Injectable()
export class Util {
  constructor() {}
  each = _.each;
  isUndefined = _.isUndefined;
  isArray = _.isArray;
  isObject = _.isObject;
  sortArrayByPropertyName(items, propertyName) {
    return items.sort(function (a, b) {
      const nameA = a[propertyName].toUpperCase(); // ignore upper and lowercase
      const nameB = b[propertyName].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  getFormatedLatLong(reslat: string, reslong: string): string {
    let lat = null;
    let long = null;

    const temp1 = reslat.split('.');

    if (temp1.length == 2 && temp1[1].length > 4) {
      lat = temp1[0] + '.' + temp1[1].substring(0, 4);
    } else {
      lat = reslat;
    }

    const temp2 = reslong.split('.');

    if (temp2.length == 2 && temp2[1].length > 4) {
      long = temp2[0] + '.' + temp2[1].substring(0, 4);
    } else {
      long = reslong;
    }
    return 'lat(' + lat + ')/ long(' + long + ')';
  }

  getLogObject(message: string, request: string, methodName: string) {
    const logmodel: LogModel = {
      message: message,
      application: 'DriveTime',
      request: request,
      methodName: methodName
    };

    return logmodel;
  }

  compareTwoJsonObects(obj1: any, obj2: any) {
    let flag = true;

    if (Object.keys(obj1).length == Object.keys(obj2).length) {
      for (const key in obj1) {
        if (obj1[key] == obj2[key]) {
          continue;
        } else {
          flag = false;
          break;
        }
      }
    } else {
      flag = false;
    }
    return flag;
  }

  transformMarketValue(value: any) {
    if (value == null) {
      return value;
    } else if (value.toString().includes(',')) {
      return value;
    } else {
      const tempValue = value.toString();
      if (tempValue.split('.').length == 1) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
  }

  containsAlphaNumeric(str) {
    let code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        (code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return true;
      }
    }
    return false;
  }

  getLocations(staticData, countryISO = false) {
    let locIndex = 0;
    let locationList = [];
    const locationTypes = ['State', 'county', 'City', 'CBSA'];

    staticData.reduce((acc, curr) => {
      const currDataType = curr.dataType;

      if (locationTypes.includes(currDataType)) {
        const formattedOpt = curr.data.map(({ id, name, countryId, countryISO2 }, index) => ({
          id: index + locIndex,
          name: countryISO === false ? name : `${name}, ${countryISO2}`,
          type: currDataType,
          locationID: id,
          countryId,
        }));
        locIndex = locIndex + formattedOpt.length;
        locationList = locationList.concat(formattedOpt);
      }
    }, {});
    return locationList;
  }

  dateConverter = (value: string) => {
    let targetDate;
    switch (value) {
      case RelativeDateOptions.week:
        targetDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
        break;
      case RelativeDateOptions.month:
        targetDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        break;
      case RelativeDateOptions.halfyear:
        targetDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
        break;
      case RelativeDateOptions.year:
        targetDate = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
        break;
      case RelativeDateOptions.twoyears:
        targetDate = new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate());
        break;
      default:
        targetDate = new Date();
    }
    return targetDate?.toLocaleDateString('en-US');
  };
}
