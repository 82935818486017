import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormService } from "core";
import { skip } from "rxjs";

@Component({
  selector: "lag-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.css"],
})
export class ExpansionPanelComponent implements OnInit {
  @HostBinding("class.dynamic") public dynamic = true;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() expanded: boolean;
  @Input() styles: string;
  @Output() open = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.formService.setExpanded$
      .pipe(skip(1))
      .subscribe((val) => (this.expanded = val));
  }

  onOpen() {
    if (this.expanded === undefined) return;
    if (!this.expanded) {
      this.open.emit();
    }
  }
  onClose() {
    if (this.expanded === undefined) return;
    if (this.expanded) {
      this.close.emit();
    }
  }
}
