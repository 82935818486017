<form [formGroup]="form" class="example-form">
  <mat-form-field appearance="outline" class="matFormField text-autocomplete" panelWidth="auto" width="100%" [hideRequiredMarker]="hideRequiredMarker">
      <div class="search-container">
        <input matInput type="text" (focus)="onFocus()" [matAutocomplete]="auto" [formControl]="searchTermControl" (input)="handleOnChange($event)" #trigger="matAutocompleteTrigger">
        <input [hidden]="true" [type]="field.controlType" [formControlName]="field.control">
        <mat-icon class="dropdown-icon" matSuffix (click)="onDropdownClick($event, trigger)">arrow_drop_down</mat-icon>
      </div>
      <mat-label class="">{{field.label}}</mat-label>
  </mat-form-field>
  <mat-autocomplete class="mat-autocomplete-multiple" #auto="matAutocomplete" (closed)="multiSelectOnClose()" (opened)="onOpened()"  [panelWidth]="panelWidth">
    <div class="main-virtual-scroll-block">
        <ng-container *ngIf="filteredOptions | async as obj">
            <cdk-virtual-scroll-viewport [itemSize]="30" class="single-select-input-virtual-scroll" [style.height.px]="selectedHeight" #cdkVirtualScrollSelected>
            <mat-option
            class="mat-option-text-style mat-option-block mat-option-right clear-option"
            [style]="panelWidth && { width: panelWidth + 'px' }"
            >
              <a class="eds-nav-link w-100" (click)="unselectAll($event)">Clear all</a>
            </mat-option>
            <mat-option *cdkVirtualFor="let opt of obj[0]" class="mat-option-text-style mat-option-block" [value]="opt">
              <mat-checkbox checked disableRipple (click)="onSelectCheckbox($event, opt)">
               {{opt.name}}
              </mat-checkbox>
            </mat-option>
          </cdk-virtual-scroll-viewport>
          <hr class="divider" />
          <cdk-virtual-scroll-viewport [itemSize]="30" class="single-select-input-virtual-scroll" [style.height.px]="unselectedHeight" #cdkVirtualScrollUnselected>
            <mat-option class="mat-option-text-style mat-option-block" *ngIf="showSelectAll">
              <mat-checkbox (click)="toggleSelectAll($event)" [checked]="isAllSelected" disableRipple> Select all </mat-checkbox>
            </mat-option>
          <mat-option *cdkVirtualFor="let option of obj[1]" class="mat-option-text-style mat-option-block" [value]="option">
          <mat-checkbox [checked]="false"  disableRipple (click)="onSelectCheckbox($event, option)">
            {{option.name}}
          </mat-checkbox>
          </mat-option>
        </cdk-virtual-scroll-viewport>
        </ng-container>
      </div>
  </mat-autocomplete>
  <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</form>