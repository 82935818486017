<div class="dialog">
  <div class="header-content">
    <h2 mat-dialog-title class="header-title">Pending approval</h2>
    <button class="header-close-button" (click)="close()" mat-dialog-close="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="body-content">
    <div class="body-content__subtitle">
      Thank you for your patience as we review your request.<br><br>Once our administrators have completed their review, you
      will receive an email notification regarding access.<br><br>In the meantime, you will be redirected to our service
      website.
    </div>
  </div>
</div>
