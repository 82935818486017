import { Component, Input, OnInit, OnDestroy, ViewChild, ViewEncapsulation, HostBinding } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'mat-select-emerald',
  templateUrl: './mat-select-emerald.component.html',
  styleUrls: ['./mat-select-emerald.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MatSelectEmeraldComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() field:any;
  @Input() isTypeahead = false;
  @Input() withLabel: boolean = true;
  @Input() fullWidth: boolean = false;

  @ViewChild('matSelectItem') matSelect;
  @HostBinding('style.width') hostWidth;

  options;
  isVirtualScrollEnabled = false;
  inputValue = '';
  inputFilterControl = new FormControl();

  ngOnInit() {
    this.options = this.sortAsc(this.field.options, 'name');
    this.inputFilterControl.patchValue(this.getValueById(this.form.value[this.field.control]));
    this.setVirtualScrollStatus();

    if (this.fullWidth) {
      this.hostWidth = '100%';
    } else {
      this.hostWidth = 'calc(100% - 72px)';
    }
    
    this.form.controls[this.field.control].valueChanges.subscribe((value) => {
      this.inputFilterControl.patchValue(this.getValueById(value));
    });
    this.inputFilterControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(100),
    ).subscribe((value) => {
      this.options = this._filter(value || '','name');
      this.setVirtualScrollStatus();
      this.inputValue = value;
    });
  }

  onBlur() {
    if (this.isTypeahead) {
      const dropdownField = this.form.controls[this.field.control];
      const dropdownValue = dropdownField?.value;
      const inputValue = this.inputValue;

      if (!dropdownValue) dropdownField?.patchValue(inputValue);
      if (this.getValueById(dropdownValue)?.toLowerCase() !== inputValue?.toLowerCase()) dropdownField?.patchValue(inputValue);
    }
  }

  setVirtualScrollStatus() {
    const minItems = 100;

    if (this.options?.length > minItems) {
      this.isVirtualScrollEnabled = true;
    } else {
      this.isVirtualScrollEnabled = false;
    }
  }

  getValueById(id) {
    return this.field.options.find((opt) => opt.id === id)?.name ?? id;
  }

  private _filter(value: string, filterKey?:string): string[] {
    const filterValue = String(value).toLowerCase();

    if (!value) {
      return this.sortAsc(this.field.options, 'name')
    }

    return this.field.options?.filter(option => String(option[filterKey])?.toLowerCase()?.includes(filterValue));
  }

  sortAsc(arrayOfObjects, Key) {
    return arrayOfObjects.sort((a, b) => (a[Key] < b[Key] ? -1 : 1));
  }
}
