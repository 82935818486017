import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/core/dialog/dialog.component';

@Component({
  selector: 'app-copy-profile-dialog',
  templateUrl: './copy-profile-dialog.component.html',
  styleUrls: ['./copy-profile-dialog.component.scss'],
})
export class CopyProfileDialogComponent {
  copyForm: FormGroup;

  submitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.copyForm = this.fb.group({
      externalProfileName: [null, Validators.required],
      internalProfileName: [null, null],
      sameAsInternalName: [null, null],
    });
  }

  onAdd() {
    this.submitted = true;

    if (this.copyForm.status === 'INVALID') {
      // display error in your form
    } else {
      console.log(this.copyForm.value);
      this.dialogRef.close(this.copyForm.value);
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  get registerFormControl() {
    return this.copyForm.controls;
  }
}
