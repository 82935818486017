<form [formGroup]="form" class="example-form">
    <mat-form-field appearance="outline" class="matFormField text-autocomplete" panelWidth="auto" width="100%">
        <mat-label class="">{{field.label}}</mat-label>
        <input [type]="field.controlType" [placeholder]="field.label" [attr.arialabel]="field.label" matInput [formControlName]="field.control" [matAutocomplete]="auto">
        <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
              {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</form>