import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'session-confirmation-dialog',
  templateUrl: './session-confirmation-dialog.component.html',
  styleUrls: ['./session-confirmation-dialog.component.scss'],
})
export class SessionConfirmationDialogComponent {
  explamationTriangle = faExclamationTriangle;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
