import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { SpinnerComponent } from './spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  constructor(private modalService: BsModalService) {}
  bsProgressRef: BsModalRef;

  show(message = 'Loading...') {
    this.bsProgressRef = this.modalService.show(SpinnerComponent, {
      class: 'modal-dialog-centered modal-sm',
      backdrop: 'static',
      initialState: { message: message },
    });
  }

  hide() {
    // this.bsProgressRef.hide();
    this.modalService.hide(1);
  }
}
