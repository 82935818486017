export class Project {
  public id: number;
  public projectName: string;
  public projectType: string;
  public primaryBroker: string;
  public brokerTitle: string;
  public brokerOffice: string;
  public source: string;
  public operationType: string;
  public representation: string;
  public LAGFee: number;
  public potCBREGrossFee: number;
  public estPaymentDt: Date;
  public createdBy: string;
  public updatedBy: string;
}
