import { Component, Input, OnChanges, OnInit, Optional, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";

import { CommonService } from "../services/common.service";
import { FormService } from "../services/form.service";

@Component({
  selector: "lag-single-select",
  templateUrl: "./single-select.component.html",
  styleUrls: ["./single-select.component.css"],
})
export class SingleSelectComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() field: any;
  @Input() disabled = false;
  @Input() type: string;
  @Input() hideRequiredMarker = false;
  @Input() isMultiple = false;
  @Output() customSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Optional() private formgroupDirective: FormGroupDirective,
    private commonService: CommonService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.form = this.form ?? this.formgroupDirective.control;
    this.field.options = this.field.optionsNotSorted
      ? this.field.options
      : this.commonService.sortArray(this.field.options, "name");

    this.form.controls[this.field.control]?.valueChanges.subscribe((value) => {
      if (value === undefined || value === null) return;

      let options;
      if (Array.isArray(value)) {
        options = value.map((id) =>
          this.field.options?.find((opt) => opt.id === id)
        );
      } else if (Number.isInteger(Number.parseInt(value))) {
        options = this.field.options?.find((opt) => opt.id === value);
      } else {
        options = value;
      }

      if (!value) return;

      this.formService.analytics("track", "Dropdown selected value", {
        value: options ?? value,
        field: this.field.label,
        component: 'single-select',
      });
    });
  }
  changedValue(value: string) {
    this.customSelectionChange.emit();
  }
  ngOnChanges(): void {
    const control = this.form?.controls[this.field.control];
    if (control) {
      this.disabled ? control.disable() : control.enable();
    }
  }
  selectedValue(option1, option2) {
    return option1?.type === option2?.type && option1?.value === option2?.value;
  }
}
