import { Component, HostListener, OnInit} from '@angular/core';
import { TokenService } from '@services/token.service';
import { environment } from '@env/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-carto',
  templateUrl: './carto.component.html',
  styleUrls: ['./carto.component.scss'],
})
export class CartoComponent implements OnInit{
  oktaToken = '';
  cartoIframeUrl: SafeResourceUrl;
  iframe: HTMLIFrameElement;
  constructor(
    private tokenService: TokenService,
    private sanitizer: DomSanitizer
  ) {
    this.cartoIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.cartoIframeUrl);
  }

  ngOnInit(): void {
    this.iframe = document.getElementById('myIframe') as HTMLIFrameElement;    
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.oktaToken = this.tokenService.getAccessToken();

    const data = {
      token: this.oktaToken
    };
    this.iframe.contentWindow.postMessage(data, '*');
  }

}
