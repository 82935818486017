import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Observable, Subject, debounceTime} from 'rxjs';
import { statusColorList } from './util/statusColorList';

interface matSort {
    active?: string,
    direction?: string,
}

export interface columnObj {
    label: string | ElementRef;
    key: string;
    dataLocator?: string;
    cellTemplate?: ElementRef;
    columnType?:string;
    editable?: boolean;
    sortable?: boolean;
    required?:boolean;
    style?: object;
    isSticky?: boolean;
    valueGetter?: (row) => string;
    validator?: () => string | undefined;
}
@Component({
  selector: 'lib-table-dataOnly',
  templateUrl: './table-dataOnly.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection:ChangeDetectionStrategy.Default,
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500, hideDelay: 500 }}]
})
export class TableDataOnlyComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Output() onSaveCell = new EventEmitter();
  @Output() onDragReorder = new EventEmitter();
  @Output() onFilterChange = new EventEmitter();
  @Output() selectedFilterList = new EventEmitter();
  @Output() selectOptionCHange = new EventEmitter();
  @Output() onPageChange = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dragHandle') dragHandle: ElementRef;
  @ViewChild('nestedExpand') nestedExpand: ElementRef;

  @Input() formGroupSort;
  @Input() customFormSort;
  @Input() pageSize = 5;
  @Input() subTitleStyle: any;
  @Input() tableHeight: any='580px';
  @Input() pageSizeOptions: number[] = [5, 10, 15];
  @Input() title: string;
  @Input() preserveHeaderFilter = false;
  @Input() subTitle: string;
  @Input() stickyHeader = false;
  @Input() dragHandleColumnStyle: object = { width: '52px' };
  @Input() expandColumnIndex = 0;
  @Input() pagination = false;
  @Input() sortingDataAccessor;
  @Input() set dataLoading(value){
    if (value === true){
        setTimeout(() => {
            this.dataLoaded = value;
          }, 500);
    }else{
        this.dataLoaded = value;
    }
  }
  @Input() setRowStyle = (event) => ({});
  // required for nested rows
  @Input() rowKey: string;
  @Input() searchableFilter: boolean = false;
  @Input() searchableFunc = (searchTerm: string) => {};
  @Input() set filters(value: { key: string, label: string, options: { value: string, label: string, color?: string }[] }[]) {
      this._filters = value;
  }
  @Input() set dragToReorder(value: boolean) {
      if (value === true) {
          this._columns = this.addDragHandleToColumns(this._columns);
      } else if (this._columns) {
          this._columns = this._columns.filter(column => column.key !== this.dragHandleKey);
      }
      this._dragToReorder = value;
  }
  _data=new MatTableDataSource();
    @Input() set data(value: any[]) {
        this._data.data= value;
        this.addPaginatorAndSortToData();
        this.reExpandRows(value);
        if (this._expandColumnKey !== undefined) {
            this.addSortingDataAccessor();
        }
        this.cd.detectChanges();
    }

    @Input() set filterInputs(value: any[]) {
        this.selectDropdownOptions = value.filter(obj => {
            return obj.inputType === 'select';
        });
        this.searchInputOptions = value.filter(obj => {
            return obj.inputType === 'search';
        });
    }

  @Input() set columns(value: columnObj[]) {
      let columns = value;
      if (this._dragToReorder) {
          columns = this.addDragHandleToColumns(columns);
      }
      if (this._expandColumnKey !== undefined) {
          columns = this.addExpandIconToColumns(this._expandColumnKey, columns);
      }
      if (!this.dataLoaded) {
          const skeletonRow = value?.reduce((acc, curr) => curr.dataLocator ? { ...acc, [curr.dataLocator]: 'skeleton' } : acc);
          const skeletonData = Array(this.pageSize).fill(skeletonRow);
          this._data.data=skeletonData;
      }
      this._columns = columns;
      this.headers = columns?.map(header => header.key);
  }

  @Input() set expandColumnKey(value: string) {
      this._columns = this.addExpandIconToColumns(value, this._columns);
      this._expandColumnKey = value;
      this.addSortingDataAccessor();
  }

  @Input() totalRecordsCount = 0;
  @Input() SearchString$:Observable<string>;

  @Input() sortByField:string;
  @Input() sortOrder:SortDirection = 'desc';
  activeHeaderFilter?: matSort;
  _expandColumnKey: string;
 
  _columns: columnObj[];
  _dragToReorder = false;
  _filters = [];
  selectedFilters = {};
  destroy$: Subject<boolean> = new Subject<boolean>();
  cellsBeingEdited: object = {};
  headers: string[];
  dragHandleKey = 'dragHandle';
  dragDisabled = true;
  expandedRows: any[] = [];
  dataLoaded = false;
  selectDropdownOptions = {};
  searchInputOptions = {};

  gridFilterRemovable = true;
  rowFormGroup!:FormGroup;
  searchFormGroup: FormGroup = new FormGroup({
    search: new FormControl(''),
  });
  statusColorList=statusColorList;

  ngOnInit(): void {
    this.searchableFilter && this.searchFormGroup.controls.search.valueChanges.pipe(debounceTime(500)).subscribe((searchTerm) => {
        this.searchableFunc(searchTerm);
    });
  }

  ngOnChanges(): void{
  }

  ngAfterViewInit(): void {
      if (this.paginator) {
          this.paginator._intl.itemsPerPageLabel = 'Rows Per Page:';
      }
      this.addPaginatorAndSortToData();
      this.SearchString$?.pipe().subscribe(searchStr=> this.clearGridSearch(searchStr));
      this.paginator.firstPage();
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  constructor(private cd:ChangeDetectorRef){}

  getCellStyle(columnStyle, rowIndex, row, column) {
      const rowStyle = this.setRowStyle({ rowIndex, rowData: row });
      let cellStyle = {};
      if (column?.required && !(row?.[column.key]) && this.dataLoaded) {
          cellStyle = {'background-color': '#FBEEEE'};
      }
      return { ...columnStyle, ...rowStyle, ...cellStyle };
  }

  addSortingDataAccessor() {
      this._data.sortingDataAccessor = this.sortingDataAccessor ? this.sortingDataAccessor : (data:any, sortHeaderId) => {
          const dataLoacator = this._columns.find(column => column.key === sortHeaderId).dataLocator;
          if (data.parentData) {
              return `${data.parentData[dataLoacator]}${data.parentData[this.rowKey]}${((() => {
                  switch (this._data.sort.direction) {
                      case 'asc': return '~~~~~';
                      case 'desc': return '     ';
                      default: return '';
                  }
              })())}${data[dataLoacator]}`;
          } else if (data[this._expandColumnKey]) {
              return `${data[dataLoacator]}${data[this.rowKey]}${((() => {
                  switch (this._data.sort.direction) {
                      case 'asc': return '     ';
                      case 'desc': return '~~~~~';
                      default: return '';
                  }
              })())}`;
          }
          return data[dataLoacator] + data[this.rowKey];
      };
  }

  addDragHandleToColumns(columns) {
      if (columns.find(column => column.key === this.dragHandleKey) === undefined) {
          return [
              {
                  label: this.dragHandle,
                  key: this.dragHandleKey,
                  cellTemplate: this.dragHandle,
                  style: this.dragHandleColumnStyle
              },
              ...columns
          ];
      }
      return columns;
  }

  addExpandIconToColumns(expandColumnKey, columns) {
      if (columns.find(column => column.key === expandColumnKey) === undefined) {
          columns.splice(this.expandColumnIndex, 0, {
              label: this.nestedExpand,
              key: expandColumnKey,
              cellTemplate: this.nestedExpand,
              style: { width: '52px' }
          });
      }
      return columns;
  }

  sortTableData(data: FormGroup[], sort: MatSort) {
    const factor =
      sort.direction == 'asc' ? 1 : sort.direction == 'desc' ? -1 : 0;
    if (factor) {
      data = data.sort((a: FormGroup, b: FormGroup) => {
        const aValue = a.get(sort.active) ? a.get(sort.active).value : null;
        const bValue = a.get(sort.active) ? b.get(sort.active).value : null;
        return aValue > bValue ? factor : aValue < bValue ? -factor : 0;
      });
    }
    return data;
  }
  addPaginatorAndSortToData() {
      if (this._data) {
          this._data.sort = this.sort;
          
          
          this._data.sortingDataAccessor = (item:any, property) =>
          {
            const currentItem = item?.value?.[property] || item[property];
            
            switch (property) {
              case 'datePublished': return new Date(currentItem);
              case 'reportedDate':  return new Date(item[property]?item[property].replace('/', '/01/'):null);
              case 'lastUpdatedDate': 
              case 'interviewDate':
                return new Date(currentItem);
              case 'lastLoginDate':
              case 'accessStartDate':
              case 'accessEndDate':
                // condition to sort null items to the end of the list
                if (null === currentItem) {
                  // returns Jan 01 1970
                  if ('desc' === this.sort.direction) return new Date(null);
                  // Feb, 01, current year + 100
                  return new Date().setFullYear(new Date().getFullYear() + 100, 1, 1);
                }

                return new Date(currentItem);
              case 'zip':
              case 'headcount':  
              case 'longitude':
              case 'latitude':  
                return currentItem?parseInt(currentItem,10):0;
              case 'activeStatus':
                return currentItem;
              case 'contactInformation':
                return currentItem?.email?.toLowerCase() && currentItem?.phoneNumber?.toLowerCase();
              default: 
              {  if(typeof currentItem!=='number'){
                   return currentItem?.toLowerCase();
                }
                else{
                   return currentItem;
                }
              }
              
            }
          };
          this._data.sort = this.sort;
        if (this.formGroupSort){
            this._data.sortData = this.sortTableData;
        }

        if (this.customFormSort) {
            this._data.sortData = this.customFormSort;
        }
        
    }
    
    if (this.preserveHeaderFilter && this.activeHeaderFilter && this.activeHeaderFilter?.active && this.activeHeaderFilter?.direction) {
        this.sort.sort({ id: this.activeHeaderFilter.active, start: this.activeHeaderFilter.direction as any, disableClear: false });
        this._data.sort = this.sort;
    }

    this._data.paginator = this.paginator;
  }

  handleEditIconClick(columnKey, rowIndex, value, row) {
      this.rowFormGroup=null;
      this.cellsBeingEdited={};
      this.cellsBeingEdited = {
          [columnKey]: { ...(this.cellsBeingEdited[columnKey] ? this.cellsBeingEdited[columnKey] : {}), [rowIndex]: { value } }
      };
      this.createForm(row);
  }

  saveCellValue(columnKey, rowIndex, oldRowData, newRowData) {
      this.onSaveCell.emit({
          columnKey,
          oldRowData: this.removeParentDataFromRow(oldRowData),
          newRowData: this.removeParentDataFromRow(newRowData),
          parentData: oldRowData.parentData
      });
      this.cellsBeingEdited[columnKey][rowIndex] = null;
    //   const colIndex=this._columns.findIndex(f=>f?.columnType==='text-autocomplete-multiple');
    //   const modifiedData={...newRowData};
    //   if(~colIndex){
    //     modifiedData[this._columns[colIndex].key]=newRowData[this._columns[colIndex].key]?.map(x=>x.name).join(',');
    //   }

    //   this._data.data[rowIndex]= modifiedData;
    //   this._data.connect().next(this._data.data);
    //   this.addPaginatorAndSortToData();
  }

  cancelCellEdit(columnKey, rowIndex) {
      this.cellsBeingEdited[columnKey][rowIndex] = null;
  }

  handlePageEvent(event) {
    this.onPageChange.emit(event);
  }

  announceSortChange(event: matSort) {
    if (!event?.active || !event?.direction) return;

    this.activeHeaderFilter = event;
  }

  onListDrop(event) {
      this.onDragReorder.emit(event);
  }

  change(event) {
   this.selectOptionCHange.emit(event);
  }

  expandRow(rowData) {
      if (this.expandedRows.indexOf(rowData[this.rowKey]) > -1) {
          this.expandedRows = this.expandedRows.filter(rowId => rowId !== rowData[this.rowKey]);

          this._data.data = this._data.data.filter((data:any) => (data.parentData === undefined || data.parentData[this.rowKey] !== rowData[this.rowKey]));
      } else {
          this.expandedRows = [...this.expandedRows, rowData[this.rowKey]];

          const rowIndex = this._data.data.findIndex(row => row[this.rowKey] === rowData[this.rowKey]);
          const expandedData = [...this._data.data];
          if (rowData[this._expandColumnKey]) {
              rowData[this._expandColumnKey].forEach((child, index) => {
                  expandedData.splice(rowIndex + index + 1, 0, { ...child, parentData: rowData });
              });
          }
          this._data.data = expandedData;
      }
  }

  reExpandRows(data) {
      if (data) {
          const expandedData = data.filter(row => row.parentData === undefined);
          this.expandedRows.forEach(rowId => {
              const rowIndex = this._data.data.findIndex(row => row[this.rowKey] === rowId);
              const rowData = this._data.data[rowIndex];
              if (rowData[this._expandColumnKey]) {
                  rowData[this._expandColumnKey].forEach((child, index) => {
                      expandedData.splice(rowIndex + index + 1, 0, { ...child, parentData: rowData });
                  });
              }
              this._data.data = expandedData;
          });
      }
  }

  removeParentDataFromRow(rowData) {
      if (rowData && rowData.parentData) {
          const { parentData: undefined, ...cleanRowData } = rowData;
          return cleanRowData;
      }
      return rowData;
  }

  handleFilterClick(filter, option) {
      if (this.selectedFilters[filter]) {
          this.selectedFilters = {
              ...this.selectedFilters,
              [filter]: this.selectedFilters[filter].find(value => value.value === option.value) ?
                  this.selectedFilters[filter].filter(value => value.value !== option.value) : [
                      ...this.selectedFilters[filter],
                      option
                  ]
          };
      } else {
          this.selectedFilters[filter] = [option];
      }
      this.onFilterChange.emit({ filter, option });
      this.selectedFilterList.emit(this.selectedFilters[filter]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._data.filter = filterValue.trim().toLowerCase();
    if (this._data.paginator) {
      this._data.paginator.firstPage();
    }
  }

  clearGridSearch(event) {
    this._data.filter = typeof event === 'string'? event: (event.target as HTMLInputElement).value;
    if (this._data.paginator) {
      this._data.paginator.firstPage();
    }
  }
  createForm(rowData){
    const formControlFields={};
    for(const column of this._columns){
        if(column.columnType==='text-autocomplete-multiple'){
            const value=rowData[column.key]?.split(',').map(x=>({id:x?.trim(), name:x?.trim()}));
            formControlFields[column.key]=new FormControl(value, column.required?Validators.required:null);
        }
        else{
            formControlFields[column.key]=new FormControl(rowData[column.key], column.required?Validators.required:null);
        }
    }
    this.rowFormGroup=new FormGroup(formControlFields);
  }

}
