<table style="width: 100%; overflow-x: auto">
  <ng-container *ngFor="let column of columns">
    <tr
      *ngIf="column.label"
      [class]="'column-level-' + column.columnLevel"
      [style.display]="isOpened(column)"
    >
      <th [class]="'horizontal-table-empty-space'">
        <ng-container
          *ngIf="column.cellTemplate"
          [ngTemplateOutlet]="column.cellTemplate"
          [ngTemplateOutletContext]="{ column: column }"
        ></ng-container>
      </th>
      <th
        [class]="
          'horizontal-table-header ' + 'column-level-' + column.columnLevel
        "
      >
        {{ column.label }}
      </th>
      <td
        [class]="'horizontal-table-row ' + 'column-level-' + column.columnLevel"
        *ngFor="let row of data"
        [style.width]="rowWidth"
      >
        <ng-container
          *ngIf="column.dataTemplate"
          [ngTemplateOutlet]="column.dataTemplate"
          [ngTemplateOutletContext]="{ column: column, row: row }"
        ></ng-container>
        <ng-container *ngIf="!column.dataTemplate">
          <ng-container [ngSwitch]="row[column.key] | typeof">
            <ng-container *ngSwitchCase="'number'">{{ row[column.key] | number }}</ng-container>
            <ng-container *ngSwitchDefault>{{ row[column.key] }}</ng-container>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-container>
</table>
