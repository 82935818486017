import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "lib-horizontal-table",
  templateUrl: "./horizontal-table.component.html",
  styleUrls: ["./horizontal-table.component.css"],
})
export class HorizontalTableComponent implements OnInit {
  @Input() data;
  @Input() columns;

  constructor() {}

  ngOnInit(): void {}

  isOpened(column) {
    if (column?.isChild === undefined || column?.isChild === null) return;
    if (column?.isOpened === undefined || column?.isOpened === null) return;
    if (column.isOpened) return;

    return "none";
  }

  public get rowWidth() {
    const totalWidthInPercent = 100;

    return `${totalWidthInPercent / this.data?.length}%`;
  }
}
