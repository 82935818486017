import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CommonService } from 'core';
import { Subject, debounceTime } from 'rxjs';

interface matSort {
    active?: string,
    direction?: string,
}

interface columnObj {
    label: string | ElementRef;
    key: string;
    dataLocator?: string;
    cellTemplate?: ElementRef;
    editable?: boolean;
    sortable?: boolean;
    style?: object;
    isSticky?: boolean;
    validator?: () => string | undefined;
}
@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500, hideDelay: 500 }}]
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Output() onSaveCell = new EventEmitter();
  @Output() onDragReorder = new EventEmitter();
  @Output() onFilterChange = new EventEmitter();
  @Output() selectedFilterList = new EventEmitter();
  @Output() selectOptionCHange = new EventEmitter();
  @Output() onPageChange = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dragHandle') dragHandle: ElementRef;
  @ViewChild('nestedExpand') nestedExpand: ElementRef;

  @Input() formGroupSort;
  @Input() pageSize = 5;
  @Input() subTitleStyle: any;
  @Input() tableHeight: any;
  @Input() pageSizeOptions: number[] = [5, 10, 15];
  @Input() title: string;
  @Input() preserveHeaderFilter = false;
  @Input() subTitle: string;
  @Input() stickyHeader = false;
  @Input() dragHandleColumnStyle: object = { width: '52px' };
  @Input() expandColumnIndex = 0;
  @Input() pagination = false;
  @Input() sortingDataAccessor;
  timeout: any;
  @Input() set dataLoading(value){
    clearTimeout(this.timeout);
    if (value === true){
        this.timeout = setTimeout(() => {
            this.dataLoaded = value;
          }, 500);
    }else{
        this.dataLoaded = value;
    }
  }
  @Input() setRowStyle = (event) => ({});
  // required for nested rows
  @Input() rowKey: string;
  @Input() searchableFilter: boolean = false;
  @Input() searchableFunc = (searchTerm: string) => {};
  @Input() set filters(value: { key: string, label: string, options: { value: string, label: string, color?: string }[] }[]) {
      this._filters = value;
  }
  @Input() set dragToReorder(value: boolean) {
      if (value === true) {
          this._columns = this.addDragHandleToColumns(this._columns);
      } else if (this._columns) {
          this._columns = this._columns.filter(column => column.key !== this.dragHandleKey);
      }
      this._dragToReorder = value;
  }

    @Input() set data(value: any[]) {
        this._data = new MatTableDataSource<any>(value);
        this.addPaginatorAndSortToData();
        this.reExpandRows(value);
        if (this._expandColumnKey !== undefined) {
            this.addSortingDataAccessor();
        }
    }

    @Input() set filterInputs(value: any[]) {
        this.selectDropdownOptions = value.filter(obj => {
            return obj.inputType === 'select';
        });
        this.searchInputOptions = value.filter(obj => {
            return obj.inputType === 'search';
        });
    }

  @Input() set columns(value: columnObj[]) {
      let columns = value;
      if (this._dragToReorder) {
          columns = this.addDragHandleToColumns(columns);
      }
      if (this._expandColumnKey !== undefined) {
          columns = this.addExpandIconToColumns(this._expandColumnKey, columns);
      }
      if (!this.dataLoaded) {
          const skeletonRow = value.reduce((acc, curr) => curr.dataLocator ? { ...acc, [curr.dataLocator]: 'skeleton' } : acc);
          const skeletonData = Array(this.pageSize).fill(skeletonRow);
          this._data = new MatTableDataSource<any>(skeletonData);
      }
      this._columns = columns;
      this.headers = columns.map(header => header.key);
  }

  @Input() set expandColumnKey(value: string) {
      this._columns = this.addExpandIconToColumns(value, this._columns);
      this._expandColumnKey = value;
      this.addSortingDataAccessor();
  }

  @Input() totalRecordsCount = 0;
  @Input() sortByField:string;
  @Input() sortOrder:SortDirection = 'desc';
  activeHeaderFilter?: matSort;
  _expandColumnKey: string;
  _data: MatTableDataSource<any>;
  _columns: columnObj[];
  _dragToReorder = false;
  _filters = [];
  selectedFilters = {};
  destroy$: Subject<boolean> = new Subject<boolean>();
  cellsBeingEdited: object = {};
  headers: string[];
  dragHandleKey = 'dragHandle';
  dragDisabled = true;
  expandedRows: any[] = [];
  dataLoaded = true;
  selectDropdownOptions = {};
  searchInputOptions = {};

  gridFilterRemovable = true;
  searchFormGroup: FormGroup = new FormGroup({
    search: new FormControl(''),
  });

  constructor(private commonService: CommonService) {}

  trackByIndex(index) {
    return index;
  }

  ngOnInit(): void {
    this.searchableFilter && this.searchFormGroup.controls.search.valueChanges.pipe(debounceTime(500)).subscribe((searchTerm) => {
        this.searchableFunc(searchTerm);
    });
  }

  ngOnChanges(): void{
  }

  ngAfterViewInit(): void {
      if (this.paginator) {
          this.paginator._intl.itemsPerPageLabel = 'Rows Per Page:';
      }
      this.addPaginatorAndSortToData();
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  getCellStyle(columnStyle, rowIndex, row, column) {
      const rowStyle = this.setRowStyle({ rowIndex, rowData: row });
      let cellStyle = {};
      if (column?.required && !(row?.value?.[column.key]) && this.dataLoaded) {
          cellStyle = {'background-color': '#FBEEEE'};
      }
      return { ...columnStyle, ...rowStyle, ...cellStyle };
  }

  addSortingDataAccessor() {
      this._data.sortingDataAccessor = this.sortingDataAccessor ? this.sortingDataAccessor : (data, sortHeaderId) => {
          const dataLoacator = this._columns.find(column => column.key === sortHeaderId).dataLocator;
          if (data.parentData) {
              return `${data.parentData[dataLoacator]}${data.parentData[this.rowKey]}${((() => {
                  switch (this._data.sort.direction) {
                      case 'asc': return '~~~~~';
                      case 'desc': return '     ';
                      default: return '';
                  }
              })())}${data[dataLoacator]}`;
          } else if (data[this._expandColumnKey]) {
              return `${data[dataLoacator]}${data[this.rowKey]}${((() => {
                  switch (this._data.sort.direction) {
                      case 'asc': return '     ';
                      case 'desc': return '~~~~~';
                      default: return '';
                  }
              })())}`;
          }
          return data[dataLoacator] + data[this.rowKey];
      };
  }

  addDragHandleToColumns(columns) {
      if (columns.find(column => column.key === this.dragHandleKey) === undefined) {
          return [
              {
                  label: this.dragHandle,
                  key: this.dragHandleKey,
                  cellTemplate: this.dragHandle,
                  style: this.dragHandleColumnStyle
              },
              ...columns
          ];
      }
      return columns;
  }

  addExpandIconToColumns(expandColumnKey, columns) {
      if (columns.find(column => column.key === expandColumnKey) === undefined) {
          columns.splice(this.expandColumnIndex, 0, {
              label: this.nestedExpand,
              key: expandColumnKey,
              cellTemplate: this.nestedExpand,
              style: { width: '52px' }
          });
      }
      return columns;
  }

  sortTableData(data: FormGroup[], sort: MatSort) {
    const factor =
      sort.direction == 'asc' ? 1 : sort.direction == 'desc' ? -1 : 0;
    if (factor) {
      data = data.sort((a: FormGroup, b: FormGroup) => {
        const aValue = a.get(sort.active) ? a.get(sort.active).value : null;
        const bValue = a.get(sort.active) ? b.get(sort.active).value : null;
        return aValue > bValue ? factor : aValue < bValue ? -factor : 0;
      });
    }
    return data;
  }
  addPaginatorAndSortToData() {
      if (this._data) {
          this._data.paginator = this.paginator;
          this._data.sortingDataAccessor = (item, property) =>
          {
            const currentItem = item?.value?.[property] || item[property];
            
            switch (property) {
              case 'reportedDate':  return new Date(item[property]?item[property].replace('/', '/01/'):null);
              case 'datePublished': 
              case 'createdDate': return new Date(currentItem);
              case 'lastUpdatedDate': {
                // lastUpdatedDate property has next format -
                // "MM/DD/YYYY hh:mm am-pm timezone", neither moment.js nor javascript's Date class can't parse that date.
                // so we use only first two parts of date
                const newItem = currentItem?.split(' ');
                return newItem?.length > 1 ? new Date(`${newItem?.at(0)} ${newItem?.at(1)}`) : new Date(currentItem);
              }
              case 'touchPointDate': return new Date(currentItem);
              case 'zip':
              case 'headcount':
              case 'numberOfJobs':  
              case 'longitude':
              case 'latitude':  
                return currentItem?parseInt(currentItem,10):0;
              default: return currentItem?.toLowerCase();
            }
          };
        if (this.formGroupSort){
            this._data.sortData = this.sortTableData;
        }
        this._data.sort = this.sort;
    }
    
    if (this.preserveHeaderFilter && this.activeHeaderFilter && this.activeHeaderFilter?.active && this.activeHeaderFilter?.direction) {
        this.sort.sort({ id: this.activeHeaderFilter.active, start: this.activeHeaderFilter.direction as any, disableClear: false });
        this._data.sort = this.sort;
    }
  }

  handleEditIconClick(columnKey, rowIndex, value) {
      this.cellsBeingEdited = {
          ...this.cellsBeingEdited,
          [columnKey]: { ...(this.cellsBeingEdited[columnKey] ? this.cellsBeingEdited[columnKey] : {}), [rowIndex]: { value } }
      };
  }

  saveCellValue(columnKey, rowIndex, rowData) {
      this.onSaveCell.emit({
          columnKey,
          rowData: this.removeParentDataFromRow(rowData),
          newValue: this.cellsBeingEdited[columnKey][rowIndex].value,
          parentData: rowData.parentData
      });
      this.cellsBeingEdited[columnKey][rowIndex] = null;
  }

  cancelCellEdit(columnKey, rowIndex) {
      this.cellsBeingEdited[columnKey][rowIndex] = null;
  }

  handlePageEvent(event) {
    this.onPageChange.emit(event);
  }

  announceSortChange(event: matSort) {
    if (!event?.active || !event?.direction) return;

    this.activeHeaderFilter = event;
  }

  onListDrop(event) {
      this.onDragReorder.emit(event);
  }

  change(event) {
   this.selectOptionCHange.emit(event);
  }

  expandRow(rowData) {
      if (this.expandedRows.indexOf(rowData[this.rowKey]) > -1) {
          this.expandedRows = this.expandedRows.filter(rowId => rowId !== rowData[this.rowKey]);

          this._data.data = this._data.data.filter(data => (data.parentData === undefined || data.parentData[this.rowKey] !== rowData[this.rowKey]));
      } else {
          this.expandedRows = [...this.expandedRows, rowData[this.rowKey]];

          const rowIndex = this._data.data.findIndex(row => row[this.rowKey] === rowData[this.rowKey]);
          const expandedData = [...this._data.data];
          if (rowData[this._expandColumnKey]) {
              rowData[this._expandColumnKey].forEach((child, index) => {
                  expandedData.splice(rowIndex + index + 1, 0, { ...child, parentData: rowData });
              });
          }
          this._data.data = expandedData;
      }
  }

  reExpandRows(data) {
      if (data) {
          const expandedData = data.filter(row => row.parentData === undefined);
          this.expandedRows.forEach(rowId => {
              const rowIndex = this._data.data.findIndex(row => row[this.rowKey] === rowId);
              const rowData = this._data.data[rowIndex];
              if (rowData[this._expandColumnKey]) {
                  rowData[this._expandColumnKey].forEach((child, index) => {
                      expandedData.splice(rowIndex + index + 1, 0, { ...child, parentData: rowData });
                  });
              }
              this._data.data = expandedData;
          });
      }
  }

  removeParentDataFromRow(rowData) {
      if (rowData && rowData.parentData) {
          const { parentData: undefined, ...cleanRowData } = rowData;
          return cleanRowData;
      }
      return rowData;
  }

  handleFilterClick(filter, option) {
      if (this.selectedFilters[filter]) {
          this.selectedFilters = {
              ...this.selectedFilters,
              [filter]: this.selectedFilters[filter].find(value => value.value === option.value) ?
                  this.selectedFilters[filter].filter(value => value.value !== option.value) : [
                      ...this.selectedFilters[filter],
                      option
                  ]
          };
      } else {
          this.selectedFilters[filter] = [option];
      }
      this.onFilterChange.emit({ filter, option });
      this.selectedFilterList.emit(this.selectedFilters[filter]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._data.filter = filterValue.trim().toLowerCase();
    if (this._data.paginator) {
      this._data.paginator.firstPage();
    }
  }

  clearGridSearch(event: Event) {
    this._data.filter = (event.target as HTMLInputElement).value;
    if (this._data.paginator) {
      this._data.paginator.firstPage();
    }
  }
}
