import { Component, Input, OnChanges, OnInit, Optional } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { CommonService } from "../services/common.service";

@Component({
  selector: "lag-multiple-select",
  templateUrl: "./multiple-select.component.html",
  styleUrls: ["./multiple-select.component.css"],
})
export class MultipleSelectComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() field: any;
  @Input() disabled = false;
  @Input() type: string;
  @Input() hideRequiredMarker = false;
  constructor(
    @Optional() private formgroupDirective: FormGroupDirective,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.form = this.form ?? this.formgroupDirective.control;
    this.field.options = this.commonService.sortArray(
      this.field.options,
      "name"
    );
  }

  changedValue(value: string) {}

  ngOnChanges(): void {
    const control = this.form?.controls[this.field.control];
    if (control) {
      this.disabled ? control.disable() : control.enable();
    }
  }
  selectedValue(option1, option2) {
    return option1?.type === option2?.type && option1?.value === option2?.value;
  }
}
