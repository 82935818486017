<div class="dialog">
<h2 mat-dialog-title class="modal-header">{{data.headerText}}</h2>

<button mat-icon-button class="close-button" (click) = "dialog.closeAll()" mat-dialog-close="true">
  <mat-icon class="close-icon" >close</mat-icon>
</button>

<mat-dialog-content *ngIf="!data.bodyText" class="row mat-typography" style="width:700px">
    <div class="row">
      <div class="m-0 col-6">
        <mat-form-field appearance="outline" class="matFormField" style="width:100%">
          <mat-label class="custom-mat-label">Headcount Source*</mat-label>
          <mat-select class="mat-option-text-style" (selectionChange)="performAction($event)"  [(ngModel)]="source_level">
            <mat-option class="mat-option-text-style" *ngFor="let action of sortSources" [value]="action.name">
              {{action.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    <div class="m-0 col-6">
      <mat-form-field appearance="outline" class="matFormField" style="width:100%">
        <mat-label class="custom-mat-label">Touchpoint date*</mat-label>
        <input  matInput type="date" max="9999-12-31" 
              [(ngModel)]="date_level"  #dateCtrl="ngModel">
      </mat-form-field>
    </div>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="data.bodyText" class="row mat-typography" style="width:700px">
  <h3>{{data.bodyText}}</h3>
</mat-dialog-content>

<mat-form-field appearance="outline" class="matFormField mt-2">
  <mat-label class="custom-mat-label">Headcount Status</mat-label>
  <mat-select class="mat-option-text-style" [disabled]="true"
  [(ngModel)]="status_level">
    <mat-option class="mat-option-text-style" *ngFor="let item of statuses" [value]="item.name">
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>


<mat-dialog-actions align="end" class="button-container">
  <button class="button secondary" mat-button [mat-dialog-close]="true" >{{data.okBtnName}}</button>
  <button class="button primary" mat-button (click)="apply()" [disabled]="!(source_level !== undefined && date_level !== undefined)">{{data.cancelBtnName}}</button>
</mat-dialog-actions>
</div>