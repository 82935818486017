import { Injectable } from '@angular/core';

@Injectable()
export class GeoLocation {
  constructor() {}

  hasGeoLocation(): boolean {
    if (navigator.geolocation) {
      return true;
    }

    return false;
  }

  getGeoLocation(): Promise<any> {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve(position);
        },
        function (error) {
          reject(error);
        },
      );
    });
  }
}
