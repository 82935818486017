<ng-container [ngSwitch]="graphType">
  <ng-container *ngSwitchCase="loaderGraph.LINE_1">
    <div class="loader-wrapper" [style.height.px]="height">
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 796 294" fill="currentColor">
        <path d="M1 233.333V293.333H795.5V245.333H732.5L703.956 255H658.016L621.009 240H592L561.5 233.333H522.5L505.5 215.833L497 210.833L484.5 208.333H451.287L405.347 185H381.5L363 173.833L340.5 159.333L315 156.333L280.289 171.667H239.453L209.5 139.833L184.5 98.3333L176.5 70.3333L170.5 37.3333L161 17L143.5 1.5L120.5 5L106 24L96 59L81 178.5L60.5 215.833L1 233.333Z" fill="#E6EAEA" stroke="#E6EAEA"/>
      </svg>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loaderGraph.LINE_2">
    <div class="loader-wrapper" [style.height.px]="height">
      <svg viewBox="0 0 796 245" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 94.9997L1.00024 244.333H795.5V1H740H684.5L627 22.5H546L480.5 43.5L419.5 22.5H319.5L252.5 49.5L161 94.9997L114 90.8331L90.5 94.9997L55.5 77.4997H1.00024L1 94.9997Z" fill="#E6EAEA" stroke="#E6EAEA"/>
        </svg>        
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loaderGraph.LINE_3">
    <div class="loader-wrapper" [style.height.px]="height">
      <svg viewBox="0 0 289 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M288 67H1V44L25.5 26.7279L35.5 25L47 26.7279L57.5 32.2653L71 43L78 44L82.5 42.5L91 34.5L99 30.5L106.5 31.5L117.5 34.5L133.5 43L145 47H150.5L160.5 45.5L179 30.5L184.5 27L191.5 25L201.5 26L212.5 30.5L219 32.5L227 31L233.5 27L244 11.5L251.5 4.00002L261.5 0.500015L272 1.50002L281.5 8.00002L288 13V34.9082V67Z" fill="#E6EAEA" stroke="#E6EAEA"/>
        </svg>
            
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loaderGraph.COLUMN_1">
    <div class="loader-wrapper" [style.height.px]="height">
      <svg viewBox="0 0 284 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 17C2 15.8954 2.89543 15 4 15H19C20.1046 15 21 15.8954 21 17V55H2V17Z" fill="#E6EAEA"/>
        <path d="M25 40C25 38.8954 25.8954 38 27 38H40C41.1046 38 42 38.8954 42 40V55H25V40Z" fill="#E6EAEA"/>
        <path d="M46 17C46 15.8954 46.8954 15 48 15H61C62.1046 15 63 15.8954 63 17V55H46V17Z" fill="#E6EAEA"/>
        <path d="M67 33C67 31.8954 67.8954 31 69 31H84C85.1046 31 86 31.8954 86 33V55H67V33Z" fill="#E6EAEA"/>
        <path d="M88 26C88 24.8954 88.8954 24 90 24H105C106.105 24 107 24.8954 107 26V55H88V26Z" fill="#E6EAEA"/>
        <path d="M111 2.00002C111 0.895447 111.895 1.52588e-05 113 1.52588e-05H128C129.105 1.52588e-05 130 0.895446 130 2.00002V55H111V2.00002Z" fill="#E6EAEA"/>
        <path d="M134 17C134 15.8954 134.895 15 136 15H149C150.105 15 151 15.8954 151 17V55H134V17Z" fill="#E6EAEA"/>
        <path d="M155 33C155 31.8954 155.895 31 157 31H171C172.105 31 173 31.8954 173 33V55H155V33Z" fill="#E6EAEA"/>
        <path d="M177 17C177 15.8954 177.895 15 179 15H193C194.105 15 195 15.8954 195 17V55H177V17Z" fill="#E6EAEA"/>
        <path d="M197 33C197 31.8954 197.895 31 199 31H214C215.105 31 216 31.8954 216 33V55H197V33Z" fill="#E6EAEA"/>
        <path d="M220 21C220 19.8954 220.895 19 222 19H235C236.105 19 237 19.8954 237 21V55H220V21Z" fill="#E6EAEA"/>
        <path d="M241 33C241 31.8954 241.895 31 243 31H256C257.105 31 258 31.8954 258 33V55H241V33Z" fill="#E6EAEA"/>
        <path d="M262 12C262 10.8954 262.895 10 264 10H279C280.105 10 281 10.8954 281 12V55H262V12Z" fill="#E6EAEA"/>
        <mask id="path-14-inside-1_39_2230" fill="white">
        <path d="M0 55L284 55L0 55Z"/>
        </mask>
        <path d="M1.62726e-07 54C-0.552285 54 -1 54.4477 -1 55C-1 55.5523 -0.552285 56 -1.62726e-07 56L1.62726e-07 54ZM284 56C284.552 56 285 55.5523 285 55C285 54.4477 284.552 54 284 54L284 56ZM-1.62726e-07 56L284 56L284 54L1.62726e-07 54L-1.62726e-07 56Z" fill="#E6EAEA" mask="url(#path-14-inside-1_39_2230)"/>
        </svg>
           
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loaderGraph.SEMICIRCLE">
    <div class="loader-wrapper" [style.height.px]="height">
      <div class="semi-circle"></div>
    </div>
  </ng-container>
</ng-container>