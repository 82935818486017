export interface MenuItem {
    id: number,
    itemName: string,
    itemDescription?: string,
    itemURL?: string,
    itemOrder: number,
    itemLevel: number,
    itemPromotionalTag: string | null,
    itemPermission: string,
    itemDraft: boolean,
    itemFeatured: boolean | null,
    itemImage: string | null,
    itemChildLimit?: number,
    childItems?: MenuItem[],
};

export enum PromotionalTags {
    FEATURE = 'featured',
    NEW = 'new',
    POPULAR = 'popular',
    COMING_SOON = 'coming soon',
};