import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { CompetitionDataService } from 'core';
import { UserDataService } from 'src/app/core/services/userData.service';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/core/spinner.service';
import { TableExport } from 'src/app/drive-time/research/competition/searchcompetition/tableExport';
import { FormService } from 'core';
import { CompetitionService } from '@services/competition.service';
@Component({
  selector: 'file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadnDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('deleteRowBtn') deleteRowBtn: ElementRef;
  @ViewChild('tableFormGroup') tableFormGroup: ElementRef;
  // @ViewChild('dropdown') dropdown: ElementRef;

  dataSource;
  isUploadButtonDisabled = true;
  operationTypes = [];
  glvIndustryTypes = [];
  blsIndustryTypes = [];
  countryData = [];
  statesData = [];
  EmployersData = [];
  sourceTypes = [];
  statusTypes = [];

  requiredFields = {
    Employer: true,
    Address: true,
    City: true,
    GLVCity: true,
    STATE: true,
    ZIP: true,
    GLVIndustry: true,
    OperationType: true,
    EmployeesHeadcount: false,
    Longitude: true,
    Latitude: true,
    HeadcountSource: true,
    HeadcountStatus: true,
    TouchPointLastAction: true,
    TouchPointDate: true,
  };

  nonEditableColumns = {
    MetroMarket: true,
    County: true,
  };

  cellStyles = {
    EmployeesHeadcount: 'text-right',
  };
  extraFieldsWithData = {
    TouchPointDate: {
      type: 'date',
    },
    Employer: {
      type: 'typeahead',
    },
  };

  columns: any[] = [];

  tableOptions = [
    { label: 'Employer', key: 'Employer', type: 'text', required: true },
    { label: 'Address', key: 'Address', type: 'text', required: true },
    { label: 'City', key: 'City', type: 'text', required: true },
    { label: 'Latitude', key: 'Latitude', type: 'number', required: true },
    { label: 'Longitude', key: 'Longitude', type: 'number', required: true },
    { label: 'Market (GLV City)', key: 'GLVCity', type: 'text', required: true },
    {
      label: 'Headcount',
    },
    { label: 'State', key: 'STATE', type: 'text', required: true },
    { label: 'ZIP', key: 'ZIP', type: 'number', required: true },
    { label: 'Industry', key: 'GLVIndustry', type: 'text', required: true },
    {
      label: 'Operation type',
      key: 'OperationType',
      type: 'text',
      required: true,
    },
    {
      label: 'Touch Point Last Action',
      key: 'TouchPointLastAction',
      required: true,
    },
    {
      label: 'Touchpoint date',
      key: 'TouchPointDate',
      type: 'text',
      required: true,
    },
    {
      label: 'Touch Point URL',
    },
    { label: 'Headcount Status' },
    { label: 'Headcount Source' }
  ];

  filters = [
    {
      key: 'filterBy',
      label: '',
      options: [
        {
          value: 'dataErrors',
          label: 'Data errors',
          color: '#FBEEEE',
        },
        {
          value: 'duplicates',
          label: 'Duplicates',
          color: '#FFEDDB',
        },
      ],
    },
  ];

  menuItems = [{ label: 'Edit' }, { label: 'Delete' }];
  city = [];
  county = [];
  columnOptions: any = {};
  isSubmitFailed = false;
  isImportInProgress = false;
  isDupicatesFound = false;
  excelError: boolean = false;
  files: any[] = [];
  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild(MatSort) matSort: MatSort;
  isExcelFile: boolean;
  isCSVFile: boolean;
  spinnerEnabled = false;
  isGridAvailable = false;
  isCancelUpload = false;
  keys = [];
  ELEMENT_DATA: any[];
  form: FormGroup;
  formArray = new FormArray([]);
  isInavlidFile = false;
  lastActionTypes: any;

  setRowStyle = (event) => {
    let style = {};
    if (event.rowIndex % 2 === 0) {
      style = { 'background-color': '#f6f6f6' };
    }

    if (event?.rowData && event?.rowData?.value && event?.rowData?.value?.isEditable === true) {
      style = { 'background-color': '#E6F4EC' };
    }


    return style;
  };
  errorCount: any = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private driveTimeService: DriveTimeService,
    private userDataService: UserDataService,
    private competitionDataService: CompetitionDataService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FileUploadnDialogComponent>,
    private spinnerService: SpinnerService,
    private http: HttpClient,
    private formService: FormService,
    private competitionService: CompetitionService,
  ) {}

  ngOnInit() {
    this.competitionDataService.employers$.subscribe(res => {
      if (res?.size === 0) return;

      this.EmployersData = this.getEmployers(res);
      this.columnOptions = { ...this.columnOptions, Employer: this.EmployersData };
    });
    this.driveTimeService.getCompetitionStaticData().pipe(filter((data) => data !== null)).subscribe((res) => this.setStaticData(res));
    this.form = this._formBuilder.group({
      Actions: [''],
      Employer: ['', Validators.required],
      Address: ['', Validators.required],
      Latitude: ['', Validators.pattern(/^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/)],
      Longitude: ['', Validators.pattern(/^-?((\d|[1-9]\d|1[0-7]\d)(\.\d+)?|180(\.0+)?)$/)],
      City: ['', Validators.required],
      STATE: ['', Validators.required],
      GLVCity: ['', Validators.required],
      ZIP: ['', Validators.required],
      EmployeesHeadcount: ['', Validators.required],
      HeadcountStatus: ['', Validators.required],
      HeadcountSource: ['', Validators.required],
      OperationType: ['', Validators.required],
      GLVIndustry: ['', Validators.required],
      TouchPointLastAction: ['', Validators.required],
      TouchPointDate: ['', Validators.required],
      TouchPointURL: ['', Validators.required],
      NAICSCodeBLSIndustry: [''],
      country: [''],
      user: [''],
    });
  }

  ngAfterViewInit() {
    this.columns = [
      {
        label: 'Employer',
        key: 'Employer',
        dataLocator: 'Employer',
        sortable: true,
        style: { 'min-width': '214px', 'max-width': '215px' },
        editable: true,
        required: true,
      },
      {
        label: 'Address',
        dataLocator: 'Address',
        key: 'Address',
        sortable: true,
        style: { 'min-width': '256px', 'max-width': '257px' },
        editable: true,
        required: true,
      },
      {
        label: 'Market (GLV city)',
        key: 'GLVCity',
        dataLocator: 'GLVCity',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true,
      },
      {
        label: 'Headcount',
        key: 'EmployeesHeadcount',
        dataLocator: 'EmployeesHeadcount',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px', },
        editable: true,
        class: 'text-right',
      },
      {
        label: 'Headcount status',
        key: 'HeadcountStatus',
        dataLocator:'HeadcountStatus',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        required: true,
      },
      {
        label: 'Headcount source',
        key: 'HeadcountSource',
        dataLocator:'HeadcountSource',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        required: true,
      },
      {
        label: 'Operation type',
        key: 'OperationType',
        dataLocator: 'OperationType',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true,
      },
      {
        label: 'GLV industry',
        key: 'GLVIndustry',
        dataLocator: 'GLVIndustry',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true,
      },
      {
        label: 'City',
        key: 'City',
        dataLocator: 'City',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true,
      },
      {
        label: 'State',
        key: 'STATE',
        dataLocator: 'STATE',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true,
      },
      {
        label: 'Zip code',
        key: 'ZIP',
        dataLocator: 'ZIP',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        editable: true,
        required: true
      },
      {
        label: 'Latitude',
        key: 'Latitude',
        dataLocator:'Latitude',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        required: true,
      },
      {
        label: 'Longitude',
        key: 'Longitude',
        dataLocator:'Longitude',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        required: true,
      },
      {
        label: 'Touchpoint last action',
        key: 'TouchPointLastAction',
        dataLocator:'TouchPointLastAction',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
        required: true,
      },
      {
        label: 'Touchpoint date',
        key: 'TouchPointDate',
        dataLocator:'TouchPointDate',
        sortable: true,
        style: { 'min-width': '270px', 'max-width': '271px' },
        required: true,
      },
      {
        label: 'Touchpoint URL',
        key: 'TouchPointURL',
        dataLocator:'TouchPointURL',
        sortable: true,
        style: { 'min-width': '200px', 'max-width': '201px' },
      },
    ];
    this.columns = this.columns.map((column) => ({ ...column, cellTemplate: this.tableFormGroup }));
    this.columns = [
      ...this.columns,
      {
        label: '',
        key: 'menu',
        cellTemplate: this.deleteRowBtn,
        style: { maxWidth: '52px', width: '52px' },
      },
    ];
  }

  createForm(data: any) {
    {
      return new FormGroup({
        Actions: new FormControl(data.Actions),
        Employer: new FormControl(data.Employer, Validators.required),
        Address: new FormControl(data.Address, Validators.required),
        City: new FormControl(data.City, Validators.required),
        GLVCity: new FormControl(
          this.filterOptions(this.city, data?.['Market(GLVCity)']),
          Validators.required,
        ),
        STATE: new FormControl(this.filterOptions(this.statesData, data.State), Validators.required),
        ZIP: new FormControl(data.ZIP, [ Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]),
        EmployeesHeadcount: new FormControl(data.Headcount, Validators.pattern(/^\d+$/)),
        Latitude: new FormControl(data.Latitude, [Validators.required, Validators.pattern(/^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/)]),
        Longitude: new FormControl(data.Longitude, [Validators.required, Validators.pattern(/^-?((\d|[1-9]\d|1[0-7]\d)(\.\d+)?|180(\.0+)?)$/)]),
        HeadcountSource: new FormControl(this.filterOptions(this.sourceTypes, data.HeadcountSource), Validators.required),
        HeadcountStatus: new FormControl(this.filterOptions(this.statusTypes, data.HeadcountStatus), Validators.required),
        TouchPointLastAction: new FormControl(this.filterOptions(this.lastActionTypes, data.TouchPointLastAction), Validators.required),
        TouchPointDate: new FormControl(moment(data.TouchPointDate).format('MM/DD/YYYY'), Validators.required),
        TouchPointURL: new FormControl(data.TouchPointURL),
        OperationType: new FormControl(
          this.filterOptions(this.operationTypes, data.OperationType),
          Validators.required,
        ),
        GLVIndustry: new FormControl(this.filterOptions(this.glvIndustryTypes, data.Industry), Validators.required),
        NAICSCodeBLSIndustry: new FormControl(data.NAICS),
        country: new FormControl('United States'),
        user: new FormControl(this.userDataService.userInfo.email, Validators.required),
        isEditable: new FormControl(false),
        editableCell: new FormControl(),
        MetroMarket: new FormControl(data.MetroMarket),
        County: new FormControl(data.County),
      });
    }
  }

  filterOptions(datasource: unknown[], data: string | number | unknown[], type?: string, states?: any[]) {
    if (type && type === 'Market(GLVCity)') {
      const stateAbbr = states?.find((state) => state.name === data['State'])?.stateAbbr;
      const cityName = `${data[type]}, ${stateAbbr}`;
      return datasource.find((x) => x['name']?.toUpperCase() == cityName?.toUpperCase())?.['id'];
    }

    if (typeof data === 'string') {
      const obj = datasource.find((x) => x['name']?.toUpperCase() == data?.toUpperCase());
      if (obj && obj['id']) return obj['id'];
    } else {
      const obj = datasource.find((x) => x['id'] === data);
      if (obj && obj['id']) return obj['name'];
    }
    return undefined;
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
    this.onFileUpload($event);
  }

  fileBrowseHandler($event) {
    this.prepareFilesList($event?.target?.files);
    this.onFileUpload($event);
  }

  deleteFile() {
    const options = { user: this.userDataService.userInfo.email };
    this.driveTimeService.importCompCancel(options).subscribe();
    this.snackBarService.showInformation('Your file import has been canceled');
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index] && this.files[index]?.progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else if (this.files[index] && this.files[index].progress) {
            this.files[index].progress += 5;
          }
        }, 500);
      }
    }, 1000);
  }

  private prepareFilesList(files: Array<any>) {
    if (files?.length > 1) {
      return;
    }
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  public getEmployers(data: any) {
    return [...data.values()].map((item) => {
      return {
        name: item.employer,
        industry: item.industry,
        id: item.employerID,
        industryid: item.industryID,
      };
    });
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  extractHeader(ws) {
    const header = [];
    const columnCount = XLSX?.utils?.decode_range(ws['!ref'])?.e.c + 1;
    for (let i = 0; i < columnCount; i++) {
      header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
    }
    return header;
  }

  private async onFileUpload(evt) {
    let data: any;
    const target: DataTransfer = <DataTransfer>evt.target;
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    this.isCSVFile = !!target.files[0].name.match(/(.csv)/);
    // fileName = !!target.files[0].name.match(/(Copy of GLV list updates)/);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }

    if (this.isExcelFile || this.isCSVFile) {
      this.isInavlidFile = false;
      let header;
      this.spinnerEnabled = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
        const options = {
          raw: false,
          dateNF: 'YYYY-MM-DD',
        };
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        header = this.extractHeader(ws);
        /* save data */
        data = XLSX.utils.sheet_to_json(ws, options);
      };
      reader.readAsBinaryString(target.files[0]);
      reader.onloadend = (e) => {
        // this.displayedColumns = ['Actions'].concat(header);
        const tableHeaders = this.tableOptions
          .map((a) => a.label)
          .map(function (x) {
            return x.toUpperCase();
          });
        header = header.map(function (x) {
          return x.toUpperCase();
        });
        const hasAllElems = this.findCommonElements(header, tableHeaders);
        if (hasAllElems) {
          this.ELEMENT_DATA = this.formatData(data);
          this.ELEMENT_DATA = this.ELEMENT_DATA.map(function (obj) {
            obj['Employer'] = obj['Employer*'];
            obj['Address'] = obj['Address*'];
            obj['Latitude'] = obj['Latitude*'];
            obj['Longitude'] = obj['Longitude*'];
            obj['City'] = obj['City*'];
            obj['State'] = obj['State*'];
            obj['ZIP'] = obj['ZIP*'];
            obj['Market(GLVCity)'] = obj['Market(GLVCity)*'];
            obj['Industry'] = obj['Industry*'];
            obj['OperationType'] = obj['OperationType*'];
            obj['TouchPointLastAction'] = obj['TouchPointLastAction*'];
            obj['TouchPointDate'] = obj['TouchPointDate'];
            obj['HeadcountStatus'] = obj['HeadcountStatus*'];
            obj['HeadcountSource'] = obj['HeadcountSource*'];

            return obj;
          });
          const UNIQUE_ELEMENT_DATA = [];
          
          this.ELEMENT_DATA.forEach((item) => {
            const isDuplicateExist = UNIQUE_ELEMENT_DATA.some((newItem) => {
              if (
                item?.Employer?.toLowerCase() === newItem?.Employer?.toLowerCase() &&
                item?.Address?.toLowerCase() === newItem?.Address?.toLowerCase() &&
                item?.Latitude?.toLowerCase() === newItem?.Latitude?.toLowerCase() &&
                item?.Longitude?.toLowerCase() === newItem?.Longitude?.toLowerCase() &&
                item?.City?.toLowerCase() === newItem?.City?.toLowerCase() &&
                item?.State?.toLowerCase() === newItem?.State?.toLowerCase() &&
                item?.ZIP?.toLowerCase() === newItem?.ZIP?.toLowerCase() &&
                item?.['Market(GLVCity)']?.toLowerCase() === newItem?.['Market(GLVCity)']?.toLowerCase() &&
                item?.Industry?.toLowerCase() === newItem?.Industry?.toLowerCase() &&
                item?.OperationType?.toLowerCase() === newItem?.OperationType?.toLowerCase() &&
                item?.TouchPointLastAction?.toLowerCase() === newItem?.TouchPointLastAction?.toLowerCase() &&
                item?.TouchPointDate?.toLowerCase() === newItem?.TouchPointDate?.toLowerCase() &&
                item?.HeadcountStatus?.toLowerCase() === newItem?.HeadcountStatus?.toLowerCase() &&
                item?.HeadcountSource?.toLowerCase() === newItem?.HeadcountSource?.toLowerCase() &&
                item?.Headcount?.toLowerCase() === newItem?.Headcount?.toLowerCase() &&
                item?.TouchPointURL?.toLowerCase() === newItem?.TouchPointURL?.toLowerCase()
              ) {
                return true;
              }

              return false;
            });

            if (!isDuplicateExist) {
              UNIQUE_ELEMENT_DATA.push(item);
            }
          })

          this.ELEMENT_DATA = UNIQUE_ELEMENT_DATA;
          this.formArray = new FormArray(this.ELEMENT_DATA.map((x) => {
            return this.createForm(x);
          }));
          
          this.isSubmitFailed = this.checkValidations(this.ELEMENT_DATA);

          const filteredForm=this.formArray.controls.reduce((prev, control)=> {
            prev['valid']=prev['valid']||[];
            prev['invalid']=prev['invalid']||[];

            if(control.valid){
              prev['valid'].push(control);
            }
            else{
              prev['invalid'].push(control);
            }

            return prev;
          },{});

          this.dataSource=filteredForm['invalid'];

          if (filteredForm['valid']?.length) {
            this.uploadFileData(new FormArray(filteredForm['valid']));
          } else {
            this.isGridAvailable = true;
            this.getErrorCount();
          }
        } else {
          this.isInavlidFile = true;
        }
      };
    } else {
      this.isInavlidFile = true;
    }
  }

  findCommonElements(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }

  private checkValidations(array) {
    const __this = this;
    return array
      .map(function (value) {
        return value.Employer + value.Address + value.OperationType;
      })
      .some(function (value, index, array) {
        if (array.indexOf(value) !== array.lastIndexOf(value)) {
          __this.formArray.controls[index]['class'] = 'delete-class';
        }
        return array.indexOf(value) !== array.lastIndexOf(value);
      });
  }

  private getConcatValue(value) {
    return value.Employer + value.Address + value.OperationType;
  }

  private formatData(data) {
    data.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        const replacedKey = key.trim().replace(/\s/g, '');
        if (key !== replacedKey) {
          obj[replacedKey] = obj[key];
          delete obj[key];
        }
      });
    });
    return data;
  }

  uploadValidData() {
    this.isGridAvailable = false;
    const formArray = new FormArray(this.dataSource);
    this.dataSource = [];
    this.uploadFileData(formArray);
    this.dialogRef.close();
  }

  uploadFileData(uploadData?: FormArray) {
    const formArray = uploadData;
    const __this = this;
    if (formArray.valid) {
      const options = formArray.value.map((row) => {
        return {
          employer: row.Employer,
          user: this.userDataService.userInfo.email,
          country: 'United States',
          street: row.Address,
          city: row.City,
          stateID: row.STATE,
          postalCode: row.ZIP,
          headCount: row.EmployeesHeadcount,
          operationTypeId: row.OperationType,
          latitude: Number.parseFloat(row.Latitude),
          longitude: Number.parseFloat(row.Longitude),
          industryID: row.GLVIndustry,
          naicsCode: 0,
          geographyID: row.GLVCity,
          organizationTypeID: 2,
          metroMarket: row.MetroMarket,
          county: row.County,
          headcountStatus: this.filterOptions(this.statusTypes, Number(row.HeadcountStatus)),
          headcountSource: this.filterOptions(this.sourceTypes, Number(row.HeadcountSource)),
          touchPointLastAction: this.filterOptions(this.lastActionTypes, Number(row.TouchPointLastAction)),
          touchPointDate: moment(row.TouchPointDate).format('MM/DD/YYYY'),
          touchPointURL: row.TouchPointURL,
        };
      });

      this.isImportInProgress = true;

      this.driveTimeService.importComp(options).subscribe((res) => {
        if (res.responses?.length) {
          this.competitionService.fetchCompetitionData(false);
          this.competitionDataService.setIsEmployersUpdated(true);
          this.snackBarService.showSuccess(`You've successfully imported ${res.responses?.length} records.`);
          if (this.dataSource.length) {
            this.isGridAvailable = true;
            this.getErrorCount();
          } else {
            this.dialogRef.close();
          }

          this.isImportInProgress = false;
        } else {
          this.snackBarService.showInformation('Your file import has been canceled');
          this.dialogRef.close();
          this.isImportInProgress = false;
        }
      });
    }
  }

  getFormControl(element: AbstractControl, column: string) {
    return (element as FormGroup).get(column) as FormControl;
  }

  getFormControlName(element: AbstractControl, column: string, data) {
    const res = data?.find((obj) => {
      return obj.id === element.value[column];
    });
    return res?.name;
  }

  private setStaticData(data: Array<any>) {
    if (data.length > 0) {
      this.countryData = data.find((i) => i.dataType === 'Country')?.data;
      this.statesData = data.find((i) => i.dataType === 'State')?.data;
      this.operationTypes = data.find((i) => i.dataType === 'OperationTypes')?.data;
      this.glvIndustryTypes = data.find((i) => i.dataType === 'IndustryTypes')?.data;
      this.blsIndustryTypes = data.find((i) => i.dataType === 'NaicsCodeTypes')?.data;
      this.city = data.find((i) => i.dataType === 'City')?.data;
      this.county = data.find((i) => i.dataType === 'county')?.data;
      this.lastActionTypes = data.find((i) => i.dataType === 'LastActionTypes')?.data;
      this.sourceTypes = data.find((i) => i.dataType === 'SourceTypes')?.data;
      this.statusTypes = data.find((i) => i.dataType === 'StatusTypes')?.data;

      this.columnOptions = {
        ...this.columnOptions,
        GLVCity: this.city,
        STATE: this.statesData,
        GLVIndustry: this.glvIndustryTypes,
        OperationType: this.operationTypes,
        TouchPointLastAction: this.lastActionTypes,
        HeadcountSource: this.sourceTypes,
        HeadcountStatus: this.statusTypes,
      };
    }
  }

  // Unused Method
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editVal(event, key) {
    const { rowData } = event;
    rowData.get('isEditable').patchValue(true);
    rowData.get('editableCell').patchValue(key);
  }

  doneVal(event) {
    const { rowData, key } = event;

    if (key === 'TouchPointDate') {
      rowData.get(key)?.patchValue(moment(rowData.get(key)?.value).format('MM/DD/YYYY'));
    }

    if (key === 'Employer') {
      const id = Number(rowData.get(key)?.value);

      // if it is employer's id
      if (!Number.isNaN(id)) {
        rowData.get(key)?.patchValue(this.filterOptions(this.EmployersData, Number(rowData.get(key)?.value)));
      }
    }

    rowData.get('isEditable').patchValue(false);
    rowData.get('editableCell').patchValue('');
    this.getErrorCount()
  }

  cancelVal(event) {
    const { rowData, key } = event;
    const index = this.formArray.controls.findIndex((x) => x.touched === true);
    const eventKey = event.key === 'GLVCity' ? 'GLVCity' : event.key;
    let previousValue: any;
    if (this.columnOptions[event.key] && index !== -1) {
      previousValue = this.filterOptions(this.columnOptions[event.key], this.ELEMENT_DATA[index][eventKey]);
      rowData.get(event.key).patchValue(previousValue);
    } else if (index !== -1) {
      previousValue = this.ELEMENT_DATA[index][eventKey];
      rowData.get(event.key).patchValue(previousValue);
    }
    rowData.get('isEditable').patchValue(false);
    rowData.get('editableCell').patchValue('');
    this.getErrorCount()
  }

  deleteRow(rowData) {
    if (rowData?.value) {
      this.dataSource = this.dataSource.filter((row) => row !== rowData);
      this.getErrorCount()

      if (this.dataSource?.length === 0) {
        this.dialogRef.close();
      }
    }
  }

  getColumnLabel(key) {
    return this.columns?.find((object) => {
      return object.key === key;
    });
  }

  getErrorCount(){
    this.errorCount = 0;
    this.formArray.controls.forEach(x => {
      if(x.invalid){
        this.errorCount++
      }
    })
  }

  downloadTemplate() {
    this.http.get('assets/templates/CompImportTemplate.xlsx', {
      responseType: 'blob',
    }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const timeSpan = new Date().toISOString();

      link.download = `CompetitionImport-${timeSpan}.xlsx`;
      link.href = url;
      link.click();
      link.remove();
    });
  }

  exportErrorsTemplateTable(arg) {
    this.spinnerService.show('Exporting data...');
    const type = arg === 'temp';
    const exportTableData = type
      ? [
          {
            Employer: '',
            Address: '',
            Latitude:'',
            Longitude:'',
            City: '',
            State: '',
            ZIP: '',
            'Market(GLV City)': '',   
            Industry: '',
            'Operation type': '',
            'Touchpoint last action':'',
            'Touchpoint date':'',
            'Touchpoint URL':'',
            Headcount: '',
            'Headcount status':'',
            'Headcount source':''            
          },
        ]
      : this.dataSource.map((f) => ({
          "Employer*": f.value.Employer,
          "Address*": f.value.Address,
          'Market(GLV City)*': this.filterOptions(this.city, Number(f.value.GLVCity)),
          Headcount: f.value.EmployeesHeadcount, 
          'Headcount Status*':  this.filterOptions(this.statusTypes, Number(f.value.HeadcountStatus)),
          'Headcount Source*': this.filterOptions(this.sourceTypes, Number(f.value.HeadcountSource)),
          'Operation Type*': this.filterOptions(this.operationTypes, Number(f.value.OperationType)),
          "Industry*": this.filterOptions(this.glvIndustryTypes, Number(f.value.GLVIndustry)), 
          "City*": f.value.City,
          "State*": this.filterOptions(this.statesData, Number(f.value.STATE)),
          "ZIP*": f.value.ZIP,
          "Latitude*":f.value.Latitude,
          "Longitude*":f.value.Longitude,
          'Touch Point Last Action*': this.filterOptions(this.lastActionTypes, Number(f.value.TouchPointLastAction)),
          'Touch Point Date': f.value.TouchPointDate,
          'Touch Point URL': f.value.TouchPointURL,
        }));
    TableExport.exportArrayToExcel(exportTableData, type ? 'CompetitionImport' : 'CompetitionImportErrorsExport');
    this.spinnerService.hide();
  }
}
