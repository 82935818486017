import { Component, OnInit, HostBinding } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NotificationTypes, REROUTING_ACCORD_TO_TYPE, notificationText } from '../models/model';
import { MatDialog } from '@angular/material/dialog';
import { ApproveAccessDialogComponent } from '@core/noaccess/approve-access-dialog/approve-access-dialog.component';
import { UserProfileService } from '@services/userProfile.service';

@Component({
  selector: 'app-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.scss'],
})
export class NotificationDrawerComponent implements OnInit {
  @HostBinding('class.emeraldstandard') public emeraldstandard = true;
  notifications;
  public NotificationTypes = NotificationTypes;

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: MatDialog,
    private userProfileService: UserProfileService,
  ) {}

  openAccessRequest(item) {
    const payload = { requestId: item?.notificationTypeId };
    this.userProfileService.getAccessRequestMessage(payload)
      .subscribe((data) => {
        const dialogRef = this.dialogService.open(ApproveAccessDialogComponent, {
          data: { email: item?.notificationText, message: data?.requestMessage, ...payload },
          panelClass: 'request-access-dialog',
        });
      });
  }

  removeItem(id) {
    this.notificationService.deleteNotification(id);
  }

  markAllItemsAsRead() {
    this.notificationService.markAllNotificationsAsRead();
  }

  markItemAsRead(item) {
    this.notificationService.setDrawerStatus(false);

    if (!item.notificationRead) {
      this.notificationService.markSingleNotificationAsRead(item.id);
    }
    if (NotificationTypes.ACCESS_REQUEST === item?.notificationTypeName) {
      this.openAccessRequest(item);
      return;
    }

    this.router.navigate([REROUTING_ACCORD_TO_TYPE?.[item?.notificationTypeName]], {
      relativeTo: this.route,
      queryParams: { id: item?.notificationTypeId },
    });
  }

  isAllMarkedAsRead() {
    return this.notifications?.userNotifications?.every((item) => item.notificationRead);
  }

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe((data) => {
      this.notifications = {
        ...data,
        userNotifications: data?.userNotifications
          ?.map((item) => ({
            ...item,
            dateCreated: moment(item?.dateCreated).format('MM/DD/YYYY'),
            ...notificationText[item.notificationTypeName],
          }))
          ?.sort((a, b) => Number(new Date(a?.dateCreated)) - Number(new Date(b?.dateCreated)))
          ?.reverse(),
      };
    });
  }
}
