<div class="top-margin" style="padding-right: 5%; padding-left: 5%;">
  <div class="row mt-5 mb-2">
    <div class="col-4"></div>
    <div class="col-4">
      <h3 class="mx-auto">Single Site Labor Report</h3>
    </div>
    <div class="col-4" style="margin-top: -1.5%;">
      <div class="row justify-content-end">
        <button type="button" class="btn btn-primary" (click)="exportToExcel();">
          Export To Excel
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-primary" (click)="exportToPDF();">
          Export to PDF
        </button>
      </div>
    </div>
  </div>

  <!-- Market Indexes -->
  <div id="marketIndexes">
    <div>
      <app-dt-report-header selectedProfileNameInput={{selectedProfileName}}
        driveTimeInMinutesInput={{driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-3 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Labor Market Scores
        </h3>
      </div>
    </div>
    <br>
    <div class="mt-2 ml-4" *ngFor="let marketIndex of marketIndexeslist">
      <div class="row mt-2">
        <div class="col-md-11">
          <div class="progress" *ngIf="marketIndex.index>=100">
            <div class="progress-bar" role="progressbar" [style.width.%]="50"
              [style.background-color]="marketIndex.colorcode" style="text-align: left;">
              {{marketIndex.datapointname}}
            </div>
            <div class="progress-bar" role="progressbar" [style.width.px]="2" [style.background-color]="'white'">
            </div>
            <div class="progress-bar" role="progressbar" [style.width.%]="marketIndex.index/2-50"
              [style.background-color]="marketIndex.colorcode">
            </div>
          </div>
          <div class="progress" *ngIf="marketIndex.index<100">
            <div class="progress" [style.width.%]="50">
              <div class="progress-bar" role="progressbar" [style.width.%]="marketIndex.index"
                [style.background-color]="marketIndex.colorcode" style="text-align: left;">{{marketIndex.datapointname}}
              </div>
            </div>
            <div class="progress-bar" role="progressbar" [style.width.px]="2" [style.background-color]="'white'">
            </div>
          </div>
        </div>
        <div class="col-md-1" style="margin-top: -5px;">
          <div [style.background-color]="marketIndex.colorcode" style="text-align: center">
            {{marketIndex.index}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="Apierrors.Indexing" class="alert alert-danger" role="alert">
      {{Apierrors.Indexing}}
    </div>


    <!-- OptimalBalance -->
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Optimal&nbsp;Balance
        </h3>
      </div>
    </div>
    <div class="row" *ngIf="marketIndexesGraph">
      <plotly-plot [data]="marketIndexesGraph.data" [layout]="marketIndexesGraph.layout" displayModeBar="false">
      </plotly-plot>
    </div>
    <div *ngIf="Apierrors.OptimalBalance" class="alert alert-danger" role="alert">
      {{Apierrors.OptimalBalance}}
    </div>
    <br>
    <div>
      <app-dt-report-footer pageNumber='1' totalPages='6'></app-dt-report-footer>
    </div>
  </div>
  <br>
  <!-- Demographics -->
  <div id="demographics">
    <div>
      <app-dt-report-header selectedProfileNameInput={{this.selectedProfileName}}
        driveTimeInMinutesInput={{this.driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Demographics
        </h3>
      </div>
    </div>

    <div *ngIf="Apierrors.Demographics" class="alert alert-danger" role="alert">
      {{Apierrors.Demographics}}
    </div>
    <div class="row" *ngIf="this.demographicsGrid != null">
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center">
          <table class="table">
            <thead style="background-color:#00a657">
              <tr>
                <th scope="col">Key Metrics</th>
                <th scope="col">Market</th>
                <th scope="col">National</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let demographic of demographicsGrid">
                <td>
                  {{demographic.dataPointName}}
                </td>
                <td>
                  {{demographic.dataPointValue.toLocaleString()}}
                </td>
                <td>{{demographic.national| number}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <h4 style="color: grey;">Comments</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <ckeditor [editor]="Editor" (ready)="onReady($event)"></ckeditor> -->
      </div>
    </div>

    <br>
    <div>
      <app-dt-report-footer pageNumber=2 totalPages={{this.totalPageCount}}></app-dt-report-footer>
    </div>
  </div>
  <br>

  <!-- PostSecondarySchools -->
  <div id="postSecondarySchools">
    <div>
      <app-dt-report-header selectedProfileNameInput={{this.selectedProfileName}}
        driveTimeInMinutesInput={{this.driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Post&nbsp;Secondary&nbsp;Schools
        </h3>
      </div>
    </div>
    <div *ngIf="Apierrors.PostSecondarySchools" class="alert alert-danger" role="alert">
      {{Apierrors.PostSecondarySchools}}
    </div>
    <div class="row" *ngIf="postSecondarySchools != null">
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center">
          <table class="table">
            <thead style="background-color:#00a657">
              <tr>
                <th scope="col">Colleges & Universities</th>
                <th scope="col">Type</th>
                <th scope="col">Enrollment</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let schools of postSecondarySchools.TopNSchools">
                <td style="width: 80vh">
                  {{schools.collegeName}}
                </td>
                <td style="width: 80vh">
                  {{schools.typeofSchool}}
                </td>
                <td>{{schools.enrollment.toLocaleString()}}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="row">
                    <div class="col-md-4" style="max-width:80vh"><b>2-Yr Colleges:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.TwoYearColleges) }} </div>
                    <div class="col-md-4" style="max-width: 80vh"><b>2-Yr Enrollment:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.TwoYearEnrollment) }} </div>
                    <div class="col-md-4"><b>Total # of Colleges:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.TotalColleges) }} </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4" style="max-width: 80vh"><b>4-Yr Colleges:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.FourYearColleges) }} </div>
                    <div class="col-md-4" style="max-width: 80vh"><b>4-Yr Enrollment:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.FourYearEnrollment) }} </div>
                    <div class="col-md-4"><b>Total Enrollment:&nbsp;&nbsp;</b>
                      {{ this.formatNumberToLocaleString(this.postSecondarySchools.TotalEnrollment) }} </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <h4 style="color: grey;">Comments</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <ckeditor [editor]="Editor" (ready)="onReady($event)"></ckeditor> -->
      </div>
    </div>
    <br>
    <div>
      <app-dt-report-footer pageNumber=3 totalPages={{this.totalPageCount}}></app-dt-report-footer>
    </div>
  </div>
  <br>

  <!-- Median Wages -->
  <div id="medianWages">
    <div>
      <app-dt-report-header selectedProfileNameInput={{this.selectedProfileName}}
        driveTimeInMinutesInput={{this.driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Median&nbsp;Wages&nbsp;By&nbsp;Market&nbsp;(USD)
        </h3>
      </div>
    </div>
    <br>
    <div *ngIf="Apierrors.MedianWages" class="alert alert-danger" role="alert">
      {{Apierrors.MedianWages}}
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="this.medianWagesGraph != null">
        <plotly-plot [data]="this.medianWagesGraph.data" [layout]="this.medianWagesGraph.layout">
        </plotly-plot>
        <div class="full k-widget k-grid">
          <div class="k-grid-aria-root">
            <div class="k-grid-container">
              <div class="k-grid-content k-virtual-content">
                <div class="k-grid-table-wrap">
                  <table mat-table class="k-grid-table" [dataSource]="this.medianWages" matSort>
                    <tbody>
                      <!-- collegeName Column -->
                      <ng-container matColumnDef="alphabetASCIISerial">
                        <th mat-header-cell *matHeaderCellDef
                          style="background-color:#00a657; color:white; font-size: medium;"></th>
                        <td mat-cell *matCellDef="let element" style="font-size: small;">
                          {{element.alphabetASCIISerial}}</td>
                      </ng-container>

                      <!-- collegeName Column -->
                      <ng-container matColumnDef="jobTitleNExperienceDuration">
                        <th mat-header-cell *matHeaderCellDef
                          style="background-color:#00a657; color:white; font-size: medium;"> Job Title - Year of
                          Experience </th>
                        <td mat-cell *matCellDef="let element" style="font-size: small;">
                          {{element.jobTitle}} - {{element.experience}}</td>
                      </ng-container>

                      <!-- typeofSchool Column -->
                      <ng-container matColumnDef="market">
                        <th mat-header-cell *matHeaderCellDef
                          style="background-color:#00a657; color:white; font-size: medium;"> Market </th>
                        <td mat-cell *matCellDef="let element" style="font-size: small;">
                          {{element.marketWage | currency:'USD':'symbol-narrow':'1.0'}} </td>
                      </ng-container>

                      <!-- enrollment Column -->
                      <ng-container matColumnDef="national">
                        <th mat-header-cell *matHeaderCellDef
                          style="background-color:#00a657; color:white; font-size: medium;"> National </th>
                        <td mat-cell *matCellDef="let element" style="font-size: small;">
                          {{element.nationalWage | currency:'USD':'symbol-narrow':'1.0'}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedWagesColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedWagesColumns;"></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <h4 style="color: grey;">Comments</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <ckeditor [editor]="Editor" (ready)="onReady($event)"></ckeditor> -->
      </div>
    </div>

    <br>
    <div>
      <app-dt-report-footer pageNumber=4 totalPages={{this.totalPageCount}}></app-dt-report-footer>
    </div>
  </div>
  <br>

  <!-- EmploymentByOccupation -->
  <div id="employmentByOccupation">
    <div>
      <app-dt-report-header selectedProfileNameInput={{this.selectedProfileName}}
        driveTimeInMinutesInput={{this.driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Employment&nbsp;By&nbsp;Occupation
        </h3>
      </div>
    </div>
    <div *ngIf="Apierrors.EmploymentByOccupation" class="alert alert-danger" role="alert">
      {{Apierrors.EmploymentByOccupation}}
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="this.employmentByOccupationGrid != null">
        <plotly-plot [data]="this.employmentByOccupationGraph.data" [layout]="this.employmentByOccupationGraph.layout">
        </plotly-plot>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h4 style="color: grey;">Comments</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <ckeditor [editor]="Editor" (ready)="onReady($event)"></ckeditor> -->
      </div>
    </div>
    <br>
    <div>
      <app-dt-report-footer pageNumber=5 totalPages={{this.totalPageCount}}></app-dt-report-footer>
    </div>
  </div>
  <br>

  <!-- EducationAttainment -->
  <div id="educationAttainment">
    <div>
      <app-dt-report-header selectedProfileNameInput={{this.selectedProfileName}}
        driveTimeInMinutesInput={{this.driveTimeInMinutes}} totalIndex={{totalIndex}}
        totalIndexColorCode={{totalIndexColorCode}}>
      </app-dt-report-header>
    </div>
    <br>
    <div class="row mt-5 justify-content-start">
      <div>
        <!-- <img src="{{greenArrowLogo}}" height="35px" width="45px" /> -->
      </div>
      <div class="col-sm-6">
        <h3 style="color:#7BC043; font-weight: bold;">
          Educational&nbsp;Attainment
        </h3>
      </div>
    </div>
    <div *ngIf="Apierrors.EducationAttainment" class="alert alert-danger" role="alert">
      {{Apierrors.EducationAttainment}}
    </div>
    <br>
    <div class="row">
      <div class="col-md-12" *ngIf="this.educationAttainmentGrid != null">
        <plotly-plot [data]="this.educationAttainmentGraph.data" [layout]="this.educationAttainmentGraph.layout">
        </plotly-plot>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <h4 style="color: grey;">Comments</h4>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <ckeditor [editor]="Editor" (ready)="onReady($event)"></ckeditor> -->
      </div>
    </div>

    <br>
    <div>
      <app-dt-report-footer pageNumber=6 totalPages={{this.totalPageCount}}></app-dt-report-footer>
    </div>
  </div>

</div>