import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'lag-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.css']
})
export class NumberComponent implements OnInit {

  @Input() form:FormGroup;
  @Input() field:any;

  constructor(@Optional() private formgroupDirective: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.form??this.formgroupDirective.control;
  }
}
