import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';

import { LogModel } from '../models/logModel';
import { LoggingService } from '../services/logging.service';

export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService) {}

  handleError(error) {
    let errorMsg = '';

    if (error instanceof HttpErrorResponse) {
      console.error('HTTP error. ', error);
      errorMsg = 'Status code:' + (<HttpErrorResponse>error).status + '. ' + error.message;
    } else if (error instanceof TypeError) {
      console.error('Type error. ', error);
      errorMsg = error.message + '. Stack Trace : ' + error.stack;
    } else if (error instanceof Error) {
      console.error('General error. ', error);
      errorMsg = error.message + '. Stack Trace : ' + error.stack;
    } else {
      console.error('Error Occurred. ', error);
      errorMsg = error.message + '. Stack Trace : ' + error.stack;
    }

    const errorObject: LogModel = {
      message: errorMsg,
      application: 'DriveTime',
      request: '',
      methodName: ''
    };
    this.loggingService.logError(errorObject);
  }
}
