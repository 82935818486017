import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, map, startWith, takeUntil} from 'rxjs';
import { FormService } from '../services/form.service';
import { CommonService } from '../services/common.service';


@Component({
    selector: 'lag-text-multiple-autocomplete',
    templateUrl: './text-multiple-autocomplete.component.html',
    styleUrls: ['./text-multiple-autocomplete.component.css']
})
export class TextMultipleAutocompleteComponent implements OnInit, OnChanges {
  @Input() form:FormGroup;
  @Input() disabled: boolean;
  @Input() hideRequiredMarker = false;
  @Input() isSorted = true;
  @Input() customSortFunc = false;
  @Input() panelWidth: number | string;
  @Input() showSelectAll: boolean = true;
  @Input() set field(field) {
    this._field = field;
    this._options = [...field.options];
  }
  public get field() {
    return this._field;
  }
  private _field: any;
  private _options: { id: string | number, name: string}[];
  selectedHeight = 40;
  unselectedHeight = 40;
  filteredOptions: Observable<any[]>;
  filterString = new BehaviorSubject<string>('');
  filterString$ = this.filterString.asObservable();
  selectedOptions$ = new BehaviorSubject<Array<any>>([]);
  searchResults$ = new BehaviorSubject<Array<any>>([]);
  searchTermControl = new FormControl('');
  private destroy$ = new Subject<void>();

  @ViewChild('cdkVirtualScrollSelected') virtualScrollSelected: CdkVirtualScrollViewport;
  @ViewChild('cdkVirtualScrollUnselected') virtualScrollUnselected: CdkVirtualScrollViewport;

  constructor(private formgroupDirective:FormGroupDirective, private formService: FormService, private commonService: CommonService) { }


  ngOnInit(): void {
      // on initialize
      this.form = this.form ?? this.formgroupDirective.control;
      const initValues = this.form?.controls[this.field.control]?.value || [];
      const control = this.form?.controls[this.field.control];
      if (control) {
          control.statusChanges
          .pipe(
            startWith(control.status),
            takeUntil(this.destroy$))
          .subscribe(status => {
              if (status === 'INVALID') {
                  this.searchTermControl.setErrors({ 'hiddenError': true });
                } 
                else {
                    this.searchTermControl.setErrors(null);
                }
          });

          this.searchTermControl.valueChanges.pipe(debounceTime(600), takeUntil(this.destroy$)).subscribe((value) => {
            if (!value) return;
            
            this.formService.analytics('track',
                `Selected value in multiple autocomplete component`,
                { value, field: this.field.label, component: 'text-multiple-autocomplete' }
            );
          });
      }
      if (initValues.length > 0) {
        this.selectedOptions$.next(initValues);
        this.showExistingSelectedOpts();
      }
    //  on form control reset etc..
    this.form?.controls[this.field.control].valueChanges.pipe( 
        map((selectedOpts) => {
          if(selectedOpts?.length > 0) {
              this.selectedOptions$.next(selectedOpts);
              this.showExistingSelectedOpts();
          }else {
              this.selectedOptions$.next([]);
              this.searchTermControl.patchValue('');
          }
        }),
          takeUntil(this.destroy$),
    ).subscribe();

      const searchChange$ = this.filterString.pipe(
          startWith(''),
          debounceTime(400),
          map(value => this._filter(value || '','name')),
      );

      this.filteredOptions = combineLatest([this.selectedOptions$, searchChange$]).pipe(
          map(([selectedOptions, filteredOptionList]) => {
            this.searchResults$.next(filteredOptionList);
              const filterState = [[], []];
              const selectedIds = selectedOptions.map((opt) => opt.id);
              
              filteredOptionList.reduce((acc, opt) => {
                selectedIds.includes(opt?.id) ? acc[0].push(opt) : acc[1].push(opt);
                return acc;
                  }, filterState);


              this.selectedHeight = this.heightCalculator(filterState[0].length);
              this.unselectedHeight = this.heightCalculator(filterState[1].length);
              return filterState;
          })
      );
  }

  ngOnChanges() {
      const control = this.form?.controls[this.field.control];
      if (this.disabled) {
          control?.disable();
          this.searchTermControl.disable();
      } else {
          control?.enable();
          this.searchTermControl.enable();
      }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  onOpened() {
      this.virtualScrollSelected.checkViewportSize();
      this.virtualScrollUnselected.checkViewportSize();
    }

  updateValueWithoutRemovingError(val) {
      const errors = this.searchTermControl.errors;
      this.searchTermControl.patchValue(val);
      this.searchTermControl.setErrors(errors);
  }

  heightCalculator(count): number {
      // 40 is fixed option height
      if (count >= 3) return 160;
      if (count === 0) return 40;
      if (count > 0 && count < 3) return (count + 1) * 40;
      return 40;
  }

  handleOnChange(e): void {
      const { value } = e.target;
      this.filterString.next(value);
  }

  _filter(value: any, filterKey?:string): any[] {
    if (this.customSortFunc) {
      this.commonService.customSortAlphabeticallyArray(this._options, 'name');
    } else if (this.isSorted) {
      this._options.sort((opt1, opt2) => new Intl.Collator('en', { caseFirst: 'upper' }).compare(opt1.name, opt2.name));
    }

    if (!value) return this._options;
    const filterValue = value?.name?.toLowerCase() || String(value)?.toLowerCase()?.trim();
    const results = this._options.filter(option => String(option[filterKey]).toLowerCase().includes(filterValue));
    return results;
  }

  onFocus() {
      const control = this.form.controls[this.field.control];
      if (control) (<EventEmitter<any>> control.statusChanges).emit(control.status);
      this.updateValueWithoutRemovingError('');
      this.filterString.next('');
  }

  multiSelectOnClose(): void {
      this.showExistingSelectedOpts();
  }

  showExistingSelectedOpts(): void {
      const nameList =  Array.from(this.selectedOptions$.value, (opts) => (opts.name));
      const displayValue = nameList.slice(0, 10).map((name) => name).join(', ');
      this.updateValueWithoutRemovingError(displayValue);
  }

  onSelectCheckbox(e, option): void {
    e.stopPropagation();
    e.preventDefault();
    const selectedOptions = this.selectedOptions$.value;
    const selectedOptionIds = selectedOptions.map((opt) => opt.id);
    if (selectedOptionIds.includes(option?.id)) {
        this.selectedOptions$.next(selectedOptions.filter((opt) => opt?.id !== option?.id));
    } else {
        this.selectedOptions$.next([...selectedOptions, option]);
    }
    const control = this.form.controls[this.field.control];
    control.patchValue(this.selectedOptions$.value, {emitEvent: true});
  }

  onDropdownClick(e, trigger:MatAutocompleteTrigger): void {
      e.stopPropagation();
      if (trigger) {
          if (!trigger.panelOpen) {
              trigger.openPanel();
              return this.onFocus();
          }
          if (trigger.panelOpen) {
              trigger.closePanel();
              return trigger.closePanel();
          }
      }
  }

  selectAll() {
    const filteredOptions = this.searchResults$.value;
    this.selectedOptions$.next(filteredOptions);
    this.form.controls[this.field.control].setValue(filteredOptions);
  }

  get isAllSelected(): boolean {
    if (this._options.length === 0) return false;
    return this._options.length === this.selectedOptions$.value.length;
  }

  unselectAll(e) {
    e.preventDefault();
    e.stopPropagation();
    this.selectedOptions$.next([]);
    this.form.controls[this.field.control].patchValue([]);
  }

  toggleSelectAll(e) { 
      e.preventDefault();
      e.stopPropagation();
      this.isAllSelected ? this.unselectAll(e) : this.selectAll();
  }
}
