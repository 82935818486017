<div class="multiple-slider-block">
  <span *ngIf="defaultValue" class="multiple-slider-title"
    >{{ title }}: {{ formatValue(defaultValue) }}</span
  >
  <span *ngIf="!defaultValue" class="multiple-slider-title">{{ title }}</span>
  <div #sliderRangeLine class="multiple-slider-range">
    <div class="multiple-slider-range__line"></div>
    <div
      *ngFor="let item of positionOfItems; let i = index"
      class="multiple-slider-default__value"
      cdkDrag
      cdkDragBoundary=".multiple-slider-range"
      (cdkDragMoved)="dragMoved($event, i)"
      (cdkDragEnded)="dragEnded($event, i)"
      [cdkDragLockAxis]="x"
      [cdkDragFreeDragPosition]="{ x: item.initialXPosition, y: 0 }"
      [cdkDragDisabled]="item.isDisabled"
      [style.background]="item.color"
      [ngClass]="{ 'multiple-slider-default__unactive': !item.isDisabled }"
    >
      <div class="multiple-slider-value" [style.color]="item.color">
        {{ formatValue(item.value) }}
      </div>
    </div>
  </div>
  <div class="multiple-slider-values">
    <span class="multiple-slider-text">{{ min }}</span>
    <span class="multiple-slider-text">{{ max }}</span>
  </div>
</div>
