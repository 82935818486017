import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItem } from '@core/models/navbar.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, Subject, map, shareReplay, startWith, switchMap } from 'rxjs';
import * as _ from 'lodash';

type MegaMenu = Array<any>;
@Injectable({
  providedIn: 'root',
})
export class MegamenuService {

  private megaMenuDataV2$: Observable<MegaMenu>;
  private refresh$ = new Subject<void>();


  private megaMenuData$$: any = new BehaviorSubject<Array<any>>([]);
  megaMenuData$ = this.megaMenuData$$.asObservable();
  header = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  urlLinks: any[] = [];

    private megaMenuURLData$$: any = new BehaviorSubject([]);
    megaMenuURLData$ = this.megaMenuURLData$$.asObservable();
    
    constructor(
        private http: HttpClient,
    ) {}

    unescapeMegamenuItemName(data): void {
      // Loop through data recursively and unescape itemname and copy back old data
      if (Array.isArray(data)) {
        data.forEach((nav) => {
          nav.itemName = _.unescape(nav.itemName);
          if (nav.childItems?.length) {
            this.unescapeMegamenuItemName(nav.childItems);
          }
        });
      }
    }

    setMegamenuData(data): void {
      this.unescapeMegamenuItemName(data);      

        this.megaMenuData$$.next(data);

    if (Array.isArray(data)) {
      data.forEach((nav) => this.urlNavLinks(nav, this.urlLinks));
    }

    this.megaMenuURLData$$.next(this.urlLinks);
  }

  getFlatMegamenuItems(data) {
    const result = [];
    if (Array.isArray(data)) {
      data.forEach((item) => {
        result.push(item);

        if (item.childItems?.length) {
          result.push(...this.getFlatMegamenuItems(item.childItems));
        }
      });
    } else {
      result.push(data);
    }

    return result;
  }

  getSortedMegamenuItems(data) {
    if (Array.isArray(data)) {
      const newData = data.map((item) => {
        if (item.childItems?.length) return { ...item, childItems: this.getSortedMegamenuItems(item.childItems) };
        return item;
      });

      return newData?.sort((a: MenuItem, b: MenuItem) => {
        return a?.itemOrder - b?.itemOrder;
      });
    }

    return data;
  }

  getMegaMenu(): Observable<MegaMenu> {
    if (!this.megaMenuDataV2$) {
      this.megaMenuDataV2$ = this.refresh$.pipe(
        startWith(null),
        switchMap(() => this.http.get<MegaMenu>(`${environment.competitionApiUrl}/api/megamenu`)),
        map((menu) => {
          const sorted = this.getSortedMegamenuItems(menu);
          this.unescapeMegamenuItemName(sorted);
          return sorted
        }),
        shareReplay(1)
      ) 
    }
    return this.megaMenuDataV2$;
  }

  setInitialMegaMenu() {
    const initialMegamenuUri = `${environment.competitionApiUrl}/api/megamenu`;

    this.http
      .get(initialMegamenuUri, this.header)
      .subscribe((initialData) => this.setMegamenuData(this.getSortedMegamenuItems(initialData)));
  }

  getMegamenuSpecificLevel(id: number) {
    const specificMegamenuLevelUri = `${environment.competitionApiUrl}/api/megamenu/${id}`;
    return this.http.get(specificMegamenuLevelUri, this.header);
  }

  urlNavLinks(links: any, urlLinks: any[]): any[] {
    if (Array.isArray(links.childItems) && links.childItems.length > 0) {
      links.childItems.map((item) => this.urlNavLinks(item, urlLinks));
      return urlLinks;
    }
    if (links.itemURL.startsWith('/') || links.itemURL.startsWith('https')) {
      urlLinks.push({
        name: links.itemName,
        description: links.itemDescription,
        url: links.itemURL,
        id: links.id,
        megaMenuFilters: links.megaMenuFilters,
      });
    }
    return urlLinks;
  }
}
