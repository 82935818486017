<div class="table-form-group-cell-container" (mouseenter)="showEditByCellIcon= true"
    (mouseleave)="showEditByCellIcon= false">
    <ng-container *ngIf="editable && disabled !== true; else formControlData">
        <ng-container [formGroup]="rowData">
            <input matInput type="text" [formControlName]="key">
            <mat-icon class="doneMark" (click)="handleSubmit(rowData, key)">done</mat-icon>
            <mat-icon class="closeMark" (click)="handleCancel(rowData, key)">close</mat-icon>
        </ng-container>
    </ng-container>
    <ng-template #formControlData>
        {{rowData.value[key]}}
        <mat-icon 
            *ngIf="editByCell && disabled !== true" 
            class="editMark" 
            style="font-size: 18px"
            [ngStyle]="{fontSize: '18px', visibility: showEditByCellIcon ? 'visible' : 'hidden'}"
            (click)="handleEdit(rowData)"
        >edit</mat-icon>
    </ng-template>
</div>