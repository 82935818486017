import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';

import { DialogService } from '../core/dialog.service';

export function tokenGetter() {
  return localStorage.getItem('msal.idtoken');
}

@Injectable({
  providedIn: 'root',
})
export class TokenService implements OnDestroy {
  // Token monitoring
  private pollingIntervalInMilliseconds = 60 * 1000; // Every 1 minute
  private userMustBeActiveWithinTheLastXSeconds = 60 * 30; // 30 minutes
  private logUserOutWhenTheTokenHasLessThanXSeconds = 60 * 5; // 5 minutes
  private extendTokenWhenItHasLessThanXSeconds = 60 * 10; // 10 minutes
  private tokenMonitoringTimer: Subscription;
  private tokenRefreshNeededSubject = new BehaviorSubject(false);
  public tokenRefreshNeeded = this.tokenRefreshNeededSubject.asObservable();
  // Misc
  private LocalStorageKey = 'okta-token-storage';
  private userLastActiveDate: Date = new Date(0);

  constructor(private router: Router, private dialogService: DialogService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
    const timer = interval(this.pollingIntervalInMilliseconds);
    this.tokenMonitoringTimer = timer.subscribe(() => {
      this.monitorToken();
    });
  }

  ngOnDestroy(): void {
    this.tokenMonitoringTimer.unsubscribe();
  }

  public getAccessToken(): string {
    const accessToken = this._oktaAuth.getAccessToken();
    if (accessToken && this.isValidToken(accessToken)) {
      return accessToken;
    }

    return null;
  }

  public updateUserLastActiveTime(): void {
    this.userLastActiveDate = new Date();
  }

  private isValidToken(token: string) {
    return token && token.length > 0;
  }

  private async monitorToken(): Promise<void> {
    console.log('MethodName : monitorToken(), monitorToken executed !!!');

    const tokens = await this._oktaAuth.tokenManager.getTokens();
    const expirationTime = this._oktaAuth.tokenManager.getExpireTime(tokens.accessToken);
    const currentDateInSeconds = Number((new Date().getTime() / 1000).toFixed(0));
    const differenceInSeconds = expirationTime - currentDateInSeconds;
    const userWasLastActiveInSeconds = currentDateInSeconds - Number((this.userLastActiveDate.getTime() / 1000).toFixed(0));

    // if user ignored timeout warning and the token is about to expire user will be auto-logged out
    if (differenceInSeconds < this.logUserOutWhenTheTokenHasLessThanXSeconds) {
      await this.logoutUser();
      return;
    }

    if (userWasLastActiveInSeconds > this.userMustBeActiveWithinTheLastXSeconds) {
      this.showTimeoutConfirmation();
    } else if (differenceInSeconds < this.extendTokenWhenItHasLessThanXSeconds){
      console.log('MethodName : monitorToken() : Auto Token refresh request !!!' + new Date().toUTCString());
      this.extendSession();   
    }
  }

  private showTimeoutConfirmation(): void {
    const headerText = 'Timeout warning';
    console.log('MethodName : showTimeoutConfirmation() : UTC Time : ' + new Date().toUTCString());
    const dialogExist = this.dialogService.checkIfDialogBoxOpen(headerText);

    if (!dialogExist) {
      const conf = {
        data: {
          headerText: headerText,
          logoutFn: this.logoutUser.bind(this),
          extendSessionFn: this.extendSession.bind(this),
        }
      };      
      this.dialogService.showSessionConfirmationDialog(conf);

    } else {
      console.log('MethodName : showTimeoutConfirmation() : dialog already open with ID: ' + dialogExist.id);
    }
  }

  logoutUser(): void {
    this._oktaAuth?.signOut();
  }

  extendSession(): void {
    this.updateUserLastActiveTime();
    this._oktaAuth.tokenManager.renew('accessToken');
  }
}
