<form [formGroup]="spDriveTimeForm" (ngSubmit)="onSubmit()">
  <div class="container top-margin">
    <div class="row">
      <label class="mx-auto control-width">Profile</label>
    </div>
    <div class="row">
      <div class="form-group has-danger mx-auto">
        <select class=" form-control mx-auto control-width" [class.is-invalid]="errors.profile"
          (change)="selectProfile_new($event)" formControlName="profile">
          <option *ngFor="let item of profiles" [value]="item.id">{{item.name}}</option>
        </select>
        <div class="invalid-feedback" *ngIf="errors.profile">{{errors.profile}}</div>
      </div>
    </div>

    <div class="row mt-2">
      <label class="mx-auto control-width">Drive Time</label>
    </div>
    <div class="row">
      <div class="radius-list mx-auto">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label style="width:95px" class="btn btn-primary" [class.active]="isDriveTimeSelected(item)"
            *ngFor="let item of driveTimeOptions">
            <input type="radio" autocomplete="off" (click)="selectDriveTime(item)" checked=""> {{item}}
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-3" style="margin-right: 3%"></div>
      <div class="col">
        <div class="row mt-2">
          <h5>Single Address</h5>
        </div>
        <div class="row mt-2">
          <label class="">Address</label>
        </div>
        <div class="row">
          <div class="has-danger form-group ">
            <input class="form-control mx-auto " type="text" formControlName="address"
              [class.is-invalid]="errors.address" />
            <div class="invalid-feedback" *ngIf="errors.address">{{errors.address}}</div>
          </div>

        </div>
        <div class="row mt-1">
          <div class="col-7" style="padding: 0px"><label class="">City, State</label></div>
          <div class="col-5"><label class="">Zip</label></div>

        </div>
        <div class="row">
          <div class="col-7" style="padding: 0px">
            <div class="form-group has-danger">
              <input [(ngModel)]="selectedMarket" [ngModelOptions]="{standalone: true}" class="form-control required"
                [typeahead]="markets" [class.is-invalid]="errors.cityState" [typeaheadOptionsLimit]="7"
                typeaheadOptionField="name" (typeaheadNoResults)="typeaheadNoResults($event)" placeholder=""
                (typeaheadOnSelect)="onMarketSelect($event)">

              <input formControlName="cityState" type="hidden" class="mx-auto control-width form-control">
              <div class="invalid-feedback" *ngIf="errors.cityState">{{errors.cityState}}</div>
            </div>
          </div>
          <div class="col-5" style="padding: 0px;">

            <div class="has-danger form-group ">
              <input class="form-control" style="width:80px; margin-left: 10px;" type="text" formControlName="zip"
                [textMask]="zipMask" formControlName="zip" [class.is-invalid]="errors.zip" />
              <div class="invalid-feedback" style="margin-left: 10px;" *ngIf="errors.zip">{{errors.zip}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1">
        <h5 style="margin-top: 100px;">OR</h5>
      </div>
      <div class="col">
        <div class="row mt-2">
          <h5>Coordinates</h5>
        </div>
        <div class="row mt-2">
          <label class="">Latitude(y)</label>
        </div>
        <div class="row">

          <div class="has-danger form-group ">
            <input class="form-control" style="width:130px" type="number" formControlName="lat" [textMask]="latLonMask"
              formControlName="lat" [class.is-invalid]="errors.lat" />
            <div class="invalid-feedback" style="margin-left: 10px;" *ngIf="errors.lat">{{errors.lat}}</div>
          </div>
        </div>
        <div class="row mt-1">
          <label class="">Longitude(x)</label>
        </div>
        <div class="row">
          <input class="form-control" style="width:130px" type="text" formControlName="lon" [textMask]="latLonMask"
            formControlName="lon" [class.is-invalid]="errors.lon" />
          <div class="invalid-feedback" style="margin-left: 10px;" *ngIf="errors.lon">{{errors.lon}}</div>
        </div>

      </div>
      <div class="col-sm-2"></div>
    </div>

    <div class="row">
      <button type="submit" class="btn btn-primary mx-auto" style="width: 100px;">
        Submit
      </button>
    </div>
  </div>
</form>