import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BaseService } from './base.service';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getProjects(type: string): Observable<any> {
    const apiuri = '/projects?projectType=' + type;
    const url = environment.adminUrl + apiuri;

    const header = {};

    return this.http.get(url, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  createProject(project: Project): Observable<any> {
    const apiuri = '/project';
    const url = environment.adminUrl + apiuri;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post(url, project, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  updateProject(project: Project) {
    const apiuri = '/project';
    const url = environment.adminUrl + apiuri;

    const header = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.put(url, project, header).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e)),
    );
  }

  override handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
