import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateProfileComponent } from './create-profile/create-profile.component';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { AllProfilesResolver } from './services/allProfiles.Resolver';
import { ProfileMetadataResolver } from './services/profileMetadata.resolver';

export const profileWizardRoutesMapping = {
  CREATE_PROFILE: 'createprofile',
  MANAGE_PROFILES: 'manageprofiles',
  EDIT_PROFILES: 'editprofile',
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
  },
  {
    path: profileWizardRoutesMapping.CREATE_PROFILE,
    component: CreateProfileComponent,
    data: { key: 'profile/createprofile', shouldDetach: false },
    resolve: { profileMetaData: ProfileMetadataResolver },
  },
  {
    path: profileWizardRoutesMapping.MANAGE_PROFILES,
    component: ManageProfileComponent,
    data: { key: 'profile/manageprofiles', shouldDetach: false },
    resolve: { profiles: AllProfilesResolver },
  },
  {
    path: `${profileWizardRoutesMapping.EDIT_PROFILES}/:id`,
    component: CreateProfileComponent,
    data: { key: 'profile/editprofile/:id', shouldDetach: false },
    resolve: { profileMetaData: ProfileMetadataResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileWizardRoutingModule {}
