import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ButtonComponent } from "./button/button.component";
import { CoreComponent } from "./core.component";
import { DateComponent } from "./date/date.component";
import { HighlightDirective } from "./directives/highlight.directive";
import { OnlyNumbersDirective } from "./directives/onlyNumbers.directive";
import { ClickOutsideDirective } from "./directives/outsideclick.directive";
import { IconComponent } from "./icon/icon.component";
import { MaterialModule } from "./material.module";
import { NumberComponent } from "./number/number.component";
import { SingleSelectComponent } from "./single-select/single-select.component";
import { MultipleSelectComponent } from "./multiple-select/multiple-select.component";
import { SingleSelectEmeraldComponent } from "./single-select-emerald/single-select-emerald.component";
import { SingleSelectInputComponent } from "./single-select-input/single-select-input.component";
import { SingleSelectSearchComponent } from "./single-select-search/single-select-search.component";
import { TextComponent } from "./text/text.component";
import { TextAutocompleteComponent } from "./text-autocomplete/text-autocomplete.component";
import { TextMultipleAutocompleteComponent } from "./text-multiple-autocomplete/text-multiple-autocomplete.component";
import { MultipleSliderComponent } from "./multiple-slider/multiple-slider.component";
import { SingleTypeaheadComponent } from './single-typeahead/single-typeahead.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { GraphLoaderComponent } from './graph-loader/graph-loader.component';

@NgModule({
  declarations: [
    CoreComponent,
    TextComponent,
    NumberComponent,
    DateComponent,
    SingleSelectComponent,
    MultipleSelectComponent,
    IconComponent,
    SingleSelectSearchComponent,
    SingleSelectEmeraldComponent,
    TextAutocompleteComponent,
    ButtonComponent,
    OnlyNumbersDirective,
    SingleSelectInputComponent,
    ClickOutsideDirective,
    HighlightDirective,
    TextMultipleAutocompleteComponent,
    MultipleSliderComponent,
    SingleTypeaheadComponent,
    NumberOnlyDirective,
    GraphLoaderComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [
    TextMultipleAutocompleteComponent,
    CoreComponent,
    TextComponent,
    NumberComponent,
    DateComponent,
    SingleSelectComponent,
    MultipleSelectComponent,
    SingleSelectSearchComponent,
    SingleSelectEmeraldComponent,
    TextAutocompleteComponent,
    IconComponent,
    ButtonComponent,
    SingleSelectInputComponent,
    MultipleSliderComponent,
    ClickOutsideDirective,
    HighlightDirective,
    NumberOnlyDirective,
    SingleTypeaheadComponent,
    GraphLoaderComponent
  ],
})
export class LibCoreModule {}