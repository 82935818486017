<div *ngIf="!deleteDialog">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>

    <h1 mat-dialog-title>Market Score</h1>
    <div mat-dialog-content>
        <div class="row">
            <div class="flex-items col-6" *ngFor="let item of tilesList">
                <p class="example-card">{{item.count}}</p>
                <span class="pClass">{{item.title}}</span>
            </div>
        </div>


        <form [formGroup]="addProjectForm" (ngSubmit)="onSubmit()" style="border-top:1px solid rgba(0, 63, 45, 0.1)">
            <div style="margin-top: 15px;">

                <div class="form-group row" *ngFor="let obj of data">
                    <label class="custom-mat-label col-8">{{obj.name}}</label>
                    <input class="inputStyle col-2" type="number" min="1" max="10" formControlName="{{obj.id}}" (change)="inputChanged()" />
                </div>

                <div class="form-group row">
                    <label class="custom-mat-label col-8 textColor" for="marketScore"><h1 mat-dialog-title>Market Score</h1></label>
                    <span class="inputStyle col-2 textColor">{{marketScore}}</span>
                </div>
            </div>
        </form>

    </div>
    <div mat-dialog-actions class="mat-dialog-actions">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="save()">Save</button>
    </div>
</div>


<div *ngIf="deleteDialog">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>

    <h1 mat-dialog-title>Delete Question</h1>
    <div mat-dialog-content>
        You are about to delete a question. This action will not delete this question from your interviews or other question sets. Are you sure you want to delete this question?
    </div>

    <div mat-dialog-actions class="mat-dialog-actions">
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-button (click)="yes()">Yes, Delete</button>
    </div>
</div>