import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { CommonService } from '../services/common.service';
import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @HostBinding('class.button-component') public dynamic=true;
  @Input() form:FormGroup;
  @Input() field:any;
  @Input() parent:FormGroup;
  @Output() onClick = new EventEmitter<any>();

  isDisabled:Observable<boolean>;

  constructor(private formgroupDirective: FormGroupDirective, private formService:FormService, private commonService: CommonService) { }

  ngOnInit(): void {
      this.form = this.formgroupDirective.control;
  }

  leftSideDrawerClose() {
    this.formService.leftSideDrawerHandler(false);
  }

  checkDisabled(){
      return !(this.form.touched && this.form.dirty && this.form.valid);
  }

  checkDeleteFilterButtonDisabled(){
      //return !(this.form.dirty);
  }
  checkSaveButtonDisabled(){
      let isDisabled=true;
      this.formService.searchFilter$.pipe().subscribe(data=> { isDisabled=data.length?true:false;});
      return !(this.form.valid && isDisabled);
  }

  onClickButton(event) {
      if(this.field.resetForm){
          this.form.reset();
          this.formService.resetForm();
      }
      this.formService.rightSideDrawerHandler(false);
  }
  
  onPrimaryButtonClick($event){
      if(this.form.valid){
          this.formService.setExpanded(true);
          this.form.reset();
          this.formService.resetForm();
      }

  }

  onSecondaryButtonClick($event){

  }

  checkAddFilterButtonDisabled(){
      let isDisabled=true;
      (this.form.controls['filters'] as FormArray).controls.map(control=> {
          isDisabled=(control.valid && control.value.name)?true:false;
      } );

      return !(this.form.controls['filters']?.value[0]?.name && isDisabled);
  }

  onAddFilterButtonClick(){
      this.formService.formClear(this['unique_key']);
  }

  onCreateQueryButtonClick(){
      this.formService.resetForm();
      this.formService.rightSideDrawerHandler(true);
  }

  onApplyButtonClick($event){
      if(this.form.valid){
          this.formService.applyButtonClick(this.form.value);
      }
  }
  onClearButtonClick($event){
      this.form.reset();
      this.formService.clearButtonClick(this.form.value);
  }
}
