<div class="header-container">
  <div>
    <h2 mat-dialog-title>{{ data.headerText }}</h2>
  </div>
  <mat-icon [mat-dialog-close]="true" (click)="data.extendSessionFn()">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <h3 class="eds-body1">Your session is about to expire.</h3>
  <h3 class="eds-body1">Would you like to extend your session?</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [mat-dialog-close]="true" type="button" (click)="data.logoutFn()" class="button secondary">Log out</button>
  <button [mat-dialog-close]="true" (click)="data.extendSessionFn()" class="button primary">Extend session</button>
</mat-dialog-actions>