import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ButtonComponent,
    DateComponent,
    NumberComponent,
    SingleSelectComponent,
    SingleSelectEmeraldComponent,
    SingleSelectInputComponent,
    SingleSelectSearchComponent,
    TextAutocompleteComponent,
    TextComponent,
    TextMultipleAutocompleteComponent,
    MultipleSelectComponent
} from 'core';

import { CustomDateComponent } from '../custom-date/custom-date.component';
@Component({
    selector: 'lag-dynamic-field',
    templateUrl: './dynamic-field.component.html',
    styleUrls: ['./dynamic-field.component.css']
})
export class DynamicFieldComponent implements OnInit, AfterViewInit {
  @HostBinding('class.dynamic') public dynamic=true;
  @ViewChild('dynamicInputContainer', { read: ViewContainerRef}) dynamicInputContainer!: ViewContainerRef;
  @Input() field: any;
  @Input() formName: FormGroup;
  // @Input() parent:FormGroup;
  @Input() unique_key:number;

  supportedDynamicComponents = [
      {
          name: 'button',
          component: ButtonComponent
      },
      {
          name: 'text',
          component: TextComponent
      },
      {
          name: 'number',
          component: NumberComponent
      },
      {
          name: 'select',
          component: SingleSelectComponent
      },
      {
          name: 'multiple-select',
          component: MultipleSelectComponent,
      },
      {
          name: 'single-select-search',
          component: SingleSelectSearchComponent
      },
      {
          name: 'single-select-input',
          component: SingleSelectInputComponent,
      },
      {
          name: 'single-select-emerald',
          component: SingleSelectEmeraldComponent,
      },
      {
          name: 'date',
          component: DateComponent
      },
      {
          name: 'custom-date',
          component: CustomDateComponent
      },
      {
          name: 'text-autocomplete',
          component: TextAutocompleteComponent
      },
      {
          name: 'text-autocomplete-multiple',
          component: TextMultipleAutocompleteComponent
      }
      
  ];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      this.registerDynamicField();
  }

  private registerDynamicField() {
      this.dynamicInputContainer.clear();
      const componentInstance = this.getComponentByType(this.field.type);
      const dynamicComponent = this.dynamicInputContainer.createComponent(componentInstance);
      dynamicComponent.instance['field']=this.field;
      dynamicComponent.instance['formName']=this.formName;
      dynamicComponent.instance['unique_key']=this.unique_key;
      dynamicComponent.instance['form']=this['formGroup'];
      dynamicComponent.instance['parent']=this['parent'];
      // dynamicComponent.setInput('field', this.field);
      this.cd.detectChanges();
  }

  getComponentByType(type: string): any {
      const componentDynamic = this.supportedDynamicComponents.find(c => c.name === type);
      return componentDynamic.component || TextComponent;
  }

}
