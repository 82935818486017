
<form [formGroup]="projectInfoForm">
    <div class="row" style="height: 100%;width: 100%;">
        <!-- <div class="col-6" style="background: #F9F9FC;min-height: 87vh;max-height: 100vh;"> -->
        <div class="col-6" style="background: #F9F9FC;min-height: 87vh;">
            <div class="row div-img-center">
                <img src="../../../assets/images/singlesite_logo.png"/>
            </div>
        </div>
        <div class="col-6 mt-4">
            <div class="div-params-center ml-3 pl-3">
                <div class="row mt-3 mb-1" style="font-weight: bold;">
                    Project Name:
                </div>
                <div class="row">
                    <button type="submit" class="fa fa-search" style="background-color: #00a657 !important; color: white; border-style:hidden"></button>
                    <input class="col-4" type="text"
                    aria-label="projectName"
                    #projectName
                    matInput
                    [formControl]="projectNameControl"
                    [matAutocomplete]="auto"
                    name = 'search'
                    style="width:-webkit-fill-available;" (change) = 'setNewProjectName(projectName.value)' >
                        <mat-autocomplete #auto="matAutocomplete" (keyup.enter)='setProjectName($event.option)' (optionSelected)='setProjectName($event.option)'>
                            <mat-option *ngFor="let option of projectFilteredOptions | async" [value]="option.projectName">
                                {{option.projectName}}
                            </mat-option>
                        </mat-autocomplete>   
                </div>
                <div class="row mt-5" style="font-weight: bold;">
                    Broker Information:
                </div>

                <div class="row mt-3">
                    <div class="col-3 pl-0">
                        Primary Broker:
                    </div>
                    <div class="col-3">
                        Broker Title:
                    </div>
                    <div class="col-3">
                        Broker office:
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="col-3 pl-0">
                        <input id="primaryBroker" class="form-control" type="text" formControlName="primaryBroker" autocomplete="off">                        
                    </div>                                
                    <div class="col-3">
                        <input id="brokerTitle" class="form-control" type="text" formControlName="brokerTitle" autocomplete="off" />                        
                    </div>                                
                    <div class="col-3">
                        <input id="brokerOffice" class="form-control" type="text" formControlName="brokerOffice" autocomplete="off" />                        
                    </div>                                                    
                </div>

                <div class="row mt-5">
                    <div class="col-3 pl-0">
                        Source:
                    </div>
                    <div class="col-3">
                        Operation Type:
                    </div>
                    <div class="col-3">
                        Represenation:
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="col-3 pl-0">
                        <input id="source" class="form-control" type="text" formControlName="source" autocomplete="off" />                        
                    </div>                                                    
                    <div class="col-3">
                        <select class="form-control" formControlName="operationType" [value]="projectInfo.operationType">
                            <option value='office'>Office</option>
                            <option value='industrial'>Industrial</option>
                        </select>
                    </div>                                
                    <div class="col-3">
                        <select class="form-control" formControlName="representation" [value]="projectInfo.representation">
                            <option value='investor'>Investor</option>
                            <option value='occupier'>Occupier</option>
                        </select>
                    </div>                                
                </div>
                
                
                <div class="row mt-5" style="font-weight: bold;">
                    Financial Information:
                </div>

                <div class="row mt-3">
                    <div class="col-3 pl-0">
                        LAG Fee ($):
                    </div>
                    <div class="col-3">
                        Potential CBRE Gross fee ($):
                    </div>
                    <div class="col-3">
                        Estimated Date of Payment:
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="col-3 pl-0">
                        <input id="lagFee" class="form-control" type="text" appOnlyAmount formControlName="lagFee" autocomplete="off" />                                                
                    </div>                                
                    <div class="col-3">
                        <input id="cbreGrossFee" class="form-control" type="text" appOnlyAmount formControlName="cbreGrossFee" autocomplete="off" />                                                
                    </div>                                
                    <div class="col-3">
                        <input id="estPaymentDate" class="form-control" type="date" max="2999-12-31" formControlName="estPaymentDate" />                        
                    </div>                                
                </div> 

                <div *ngIf="Apierrors.message" class="row alert alert-danger mt-3" role="alert">
                    {{Apierrors.message}}
                    <br>                                    
                </div>

                <div class="row mt-5 ml-2">
                    <div class="p-2 col-9 float-right">
                        <!-- <button type="button" class="btn btn-primary float-left" style="width: 120px;"
                        [disabled]=" !isProjSelected || !projectInfoForm.valid"
                        (click)="onClear()">
                        Cear
                        </button>                         -->

                        <button type="button" class="btn btn-primary float-right" style="width: 120px;"
                            [disabled]=" !isProjSelected || !projectInfoForm.valid"
                            (click)="onSubmit()">
                            Save & Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row w-50" style="margin-top: -6vh;">
        <div class="col-md-7" style="text-align: left;">
            <p>Powered by : CBRE Talent & Location Navigator</p>
        </div>
        <div class="col-md-5 text-md-right">
            <a href="#" [routerLink]="['/Disclaimer']" class="text-dark">Disclaimer</a>
            <span class="text-muted mx-2">|</span>
            <a href="#" [routerLink]="['/Logout']" class="text-dark">Log Out</a>
        </div>
    </div>
</form>