import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CoreModule } from './core.module';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: CoreModule,
})
export class ToasterService {
  constructor(private toastr: ToastrService, private notificationService: NotificationService) {
    this.notificationService.success.subscribe((message) => {
      this.showSuccessMessage(message);
    });

    this.notificationService.error.subscribe((message) => {
      this.showErrorMessage(message);
    });
  }

  showSuccessMessage(message): void {
    //Toastr Documentation/Github - https://scttcper.github.io/ngx-toastr/
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-full-width',
      closeButton: true,
    });
  }
  showErrorMessage(message): void {
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-full-width',
      closeButton: true,
    });
  }
}
