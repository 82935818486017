export const wageMetadata = {
  industry: {
    label: 'Industry',
    control: 'industry',
    options: [],
  },
  experience: {
    label: 'level of experience',
    control: 'experience',
    options: [
    {
      name: 'Entry level',
      value: 1,
    },
    {
      name: 'Junior level',
      value: 3,
    },
    {
      name: 'Mid level',
      value: 5,
    },
    {
      name: 'Senior level',
      value: 10,
    },
  ],
  }
};

export const unemploymentMetadata = {
  cbsa: {
    label: 'CBSA',
    control: 'cbsa',
    options: [],
  },
  state: {
    label: 'State',
    control: 'state',
    options: [],
  }
};