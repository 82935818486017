<form [formGroup]="form" class="example-form">
    <lag-icon iconName="search" iconColor="#767676" class="icon-prefix" *ngIf="field.isSearchIcon"></lag-icon>
    <mat-form-field appearance="outline" class="matFormField" [ngClass]="{'search-icon': field.isSearchIcon}" width="100%" *ngIf="!istextarea"
        [hideRequiredMarker]="hideRequiredMarker">
        <mat-label class="mat-label-background">{{field.label}}</mat-label>
        <ng-container matPrefix *ngIf="field.iconPrefix">
            <ng-container [ngSwitch]="field.iconPrefix">
                <span matPrefix *ngSwitchCase="'#'">#</span>
                <span matPrefix *ngSwitchCase="'$'">$</span>
                <lag-icon *ngSwitchDefault matPrefix [iconName]="field.iconPrefix" iconColor="#767676"></lag-icon>
            </ng-container>
        </ng-container>
        <ng-container matSuffix *ngIf="field.iconSuffix">
            <ng-container [ngSwitch]="field.iconSuffix">
                <span matSuffix *ngSwitchCase="'%'">%</span>
                <lag-icon *ngSwitchDefault matSuffix [iconName]="field.iconSuffix" iconColor="#767676"></lag-icon>
            </ng-container>
        </ng-container>
        <ng-container [ngSwitch]="field.controlType">
            <input [trackInput]="false" *ngSwitchCase="'numberOnly'" [readonly]="readonly" (focus)="handleTouchAndBlur('focus')" (blur)="handleTouchAndBlur('blur')"
                [type]="field.controlType" [placeholder]="field.label" [attr.arialabel]="field.label" matInput [formControlName]="field.control"
                lagNumberOnly [isInteger]="field.isInteger" [disableThousandSeparator]="field.disableThousandSeparator">
            <input [trackInput]="false" *ngSwitchDefault [readonly]="readonly" (focus)="handleTouchAndBlur('focus')" (blur)="handleTouchAndBlur('blur')"
            [type]="field.controlType" [placeholder]="field.label" [attr.arialabel]="field.label" matInput [formControlName]="field.control">
        </ng-container>
        <div *ngIf="field.helperText" class="helper-text"> {{field.helperText}} </div>
    </mat-form-field>
    <ng-container *ngIf="istextarea">
        <mat-form-field appearance="outline" class="matFormField text-area" width="100%"
            [hideRequiredMarker]="hideRequiredMarker">
            <mat-label class="mat-label-background">{{field.label}}</mat-label>
            <textarea #textareaRef type="text" matInput [formControlName]="field.control"
                [maxlength]="maxLength > 0 ? maxLength : null"></textarea>
        </mat-form-field>
        <div class="helper-text d-flex justify-content-between">
            <span *ngIf="field.helperText">
                {{field.helperText}}
            </span>
            <span *ngIf="maxLength > 0" style="margin-left: auto">
                {{textareaRef.value.length}}/{{maxLength}}
            </span>
        </div>
    </ng-container>
</form>