<mat-accordion>
  <mat-expansion-panel
    [expanded]="expanded"
    (opened)="onOpen()"
    (closed)="onClose()"
  >
    <mat-expansion-panel-header [ngStyle]="styles">
      <mat-panel-title [ngClass]="{'panel-subtitle': subtitle}">
        <div>{{ title }}</div>
        <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-content></ng-content>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
