import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, filter, map, of, startWith } from 'rxjs';

import { FormService } from '../services/form.service';

@Component({
    selector: 'lag-single-select-input',
    templateUrl: './single-select-input.component.html',
    styleUrls: ['./single-select-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi:true,
            useExisting: SingleSelectInputComponent
        }
    ],
})
export class SingleSelectInputComponent implements OnInit, ControlValueAccessor {
  @Input() form:FormGroup;
  @Input() field:any;
  
  constructor(@Optional() private formgroupDirective:FormGroupDirective, private formService: FormService) {
  }
  onChange = (quantity) => {console.log(quantity);};

  onTouched = () => {};

  touched = false;

  disabled = false;
  writeValue(obj: any): void {
      throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
      throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
      throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
      throw new Error('Method not implemented.');
  }

  isOpened = false;
  myControl = new FormControl('');
  filteredOptions;

  ngOnInit() {
      this.form = this.form??this.formgroupDirective.control;

      this.filteredOptions = of(this.field.options);
      this.filteredOptions = this.form.controls[this.field.control].valueChanges.pipe(
          startWith(''),
          distinctUntilChanged(),
          debounceTime(100),
          map(value => this._filter(value || '','name')),
      );
      this.form.controls[this.field.control].valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        filter((s) => !!s)
      ).subscribe((value) => {
        this.formService.analytics('track',
            `Dropdown selected value`,
            {value, component: "single-select-input", field: this.field.label}
        );
      })
  }

  handleIconClick() {
      this.isOpened = !this.isOpened;
  }

  handleSelect(event) {
      this.isOpened = false;
      const { name, value } = event.source.value;
      this.form.controls[this.field.control].setValue(name);
  }

  private _filter(value: string, filterKey?:string): string[] {
      const filterValue = String(value).toLowerCase();

      if (!value) {
          return this.field?.options;
      }

      return this.field?.options.filter(option => String(option[filterKey]).toLowerCase().includes(filterValue));
  }
}
