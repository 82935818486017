import { Component, Input, OnChanges, OnInit, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'lag-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css'],
})
export class DateComponent implements OnInit, OnChanges {
  @Input() form:FormGroup;
  @Input() field:any;
  @Input() filter: any;
  @Input() disabled = false;
  @Input() hideRequiredMarker = false;

  constructor(@Optional() private formgroupDirective: FormGroupDirective) { }

  ngOnInit(): void {
      this.form = this.form??this.formgroupDirective.control;
  }

  ngOnChanges() {
      const control = this.form?.controls[this.field.control];
      if (control) {
          this.disabled ? control.disable() : control.enable();
      }
  }

}
