import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InterceptorSkipHeader } from '../httpInterceptor/TokenHttpInterceptor';

type Notification = {
  userID?: number;
  notificationTypeId?: number;
  notificationTypeName?: string;
  notificationRead?: boolean;
  dateCreated?: string;
  id?: number;
};

interface Notifications {
  unreadNotificationCount?: number;
  userNotifications?: Notification[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  private url = environment.adminUrl;
  private header = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').set(InterceptorSkipHeader, ''),
  };

  cacheEmail: string;
  notificationDrawer$$ = new BehaviorSubject<boolean>(false);
  notificationDrawer$ = this.notificationDrawer$$.asObservable();
  notifications$$ = new BehaviorSubject<Notifications>({
    unreadNotificationCount: 0,
    userNotifications: [],
  });
  notifications$ = this.notifications$$.asObservable();

  notificationQueue$ = new Subject<void>();

  constructor(http: HttpClient, private router: Router) {
    super(http);
  }

  getNotificationQueue(): Subject<void> {
    return this.notificationQueue$;
  }

  triggerNotificationQueue() {
    this.notificationQueue$.next();
  }

  deleteQueryParams() {
    this.router.navigate([], { queryParams: {} });
  }

  setNotifications(notifications: Notifications) {
    this.notifications$$.next(notifications);
  }

  setDrawerStatus(status: boolean | null) {
    if (status === null) {
      this.notificationDrawer$$.next(!this.notificationDrawer$$.value);
      return;
    }
    this.notificationDrawer$$.next(status);
  }

  handleParsingError(error) {
    if (error.status === 200) {
      this.getAllNotifications();
    }
  }

  deleteNotification(notificationId): void {
    this.http.delete(`${this.url}/user/notification/${notificationId}`, this.header).subscribe(
      () => this.getAllNotifications(),
      (error) => this.handleParsingError(error),
    );
  }

  markSingleNotificationAsRead(notificationId): void {
    this.http.put(`${this.url}/user/notification/${notificationId}`, this.header).subscribe(
      () => this.getAllNotifications(),
      (error) => this.handleParsingError(error),
    );
  }

  markAllNotificationsAsRead(userEmail?: string): void {
    this.http.put(`${this.url}/user/notification`, this.header).subscribe(
      () => this.getAllNotifications(),
      (error) => this.handleParsingError(error),
    );
  }

  getAllNotifications(userEmail?: string): void {
    if (!this.cacheEmail) {
      this.cacheEmail = userEmail;
    }

    this.http
      .get<Notifications>(`${this.url}/user/notification`, this.header)
      .subscribe((data: Notifications) => {
        this.setNotifications(data);
      });
  }
}
