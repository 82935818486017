import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestAccessDialogComponent } from './request-access-dialog/request-access-dialog.component';
import { MegamenuService } from '@services/megamenu.service';
import { PendingRequestDialogComponent } from './pending-request-dialog/pending-request-dialog.component';
import { CONSTANT_URLS } from '@core/models/urls.model';

@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.css'],
})
export class NoAccessComponent implements OnInit, OnDestroy {
  constructor(private dialogService: MatDialog, private megamenuService: MegamenuService) {}

  ngOnInit() {
    const dialogRef = this.dialogService.open(RequestAccessDialogComponent, {
      data: {},
      panelClass: 'request-access-dialog',
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val === 'request access') {
        this.dialogService
          .open(PendingRequestDialogComponent, {
            data: {},
            panelClass: 'request-access-dialog',
          })
          .afterClosed()
          .subscribe(() => {
            window.location.href = CONSTANT_URLS.LABOR_ANALYTICS;
          });
        return;
      }
      // when user clicks outside of modal window or "cancel" button we redirect him to "about us" page.
      window.location.href = CONSTANT_URLS.OCCUPIER;
    });
  }

  ngOnDestroy(): void {}
}
