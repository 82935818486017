import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SearchcompetitionComponent } from './searchcompetition/searchcompetition.component';

export const competitionRoutesMapping = {
  SEARCH_COMPETITION: 'searchcompetition',
};

const routes: Routes = [
  {
    path: competitionRoutesMapping.SEARCH_COMPETITION,
    redirectTo: competitionRoutesMapping.SEARCH_COMPETITION,
  },
  {
    path: competitionRoutesMapping.SEARCH_COMPETITION,
    component: SearchcompetitionComponent,
    data: { key: `${competitionRoutesMapping.SEARCH_COMPETITION}/home`, shouldDetach: false },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompetitionRoutingModule {}
