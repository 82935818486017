import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';

@Component({
  selector: 'multiple-records-dialog',
  templateUrl: './multiple-records-dialog.component.html',
  styleUrls: ['./multiple-records-dialog.component.scss'],
})
export class MultipleRecordsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MultipleRecordsDialogComponent>,
    public dialog: MatDialog
    ) {}

  ngOnInit() {
  }

  applyOne() {
    this.dialogRef.close('updateOne');
  }

  applyAll() {
    this.dialogRef.close('updateAll');
  }
}
