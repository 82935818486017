import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snack-bar-information',
  templateUrl: './snack-bar-information.component.html',
  styleUrls: ['./snack-bar-information.component.scss'],
})
export class SnackBarInformationComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, private snackBarRef:MatSnackBarRef<SnackBarInformationComponent>) {}

  checkCircle = faCheckCircle;

  ngOnInit() {}

  closeSnackbar(){
     this.snackBarRef.dismiss();
  }
}
