import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any[] {
    if (!args[0]) {
      return value;
    } else if (value) {
      return value.filter(item =>  args[1]?(!!item[args[0]] && item[args[0]]===args[1]):!item[args[0]]);
    }
}
}
