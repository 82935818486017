class Employer {
  public ID: number;
  public Name: string;
}
class Category {
  public ID: number;
  public CategoryName: string; 
}
class Operation {
  public ID: number;
  public OperationType: string;
}
class Location {
  public ID: number;
  public GeographyName: string;
}
class Industry {
  public ID: number;
  public IndustryName: string;
}
export class Article {
  public id: number;
  public title: string;
  public employer: string;
  public categoryName: string;
  public operationType: string;
  public industry: string;
  public source: string;
  public text: string;
  public noteTypeID: number;
  public datePublished: string;
  public dateExpired: string;
  public summary: string;
  public numberOfJobs: number;
  public wage: number;
  public currencyId: number;
  public wageTermId : number;
  public rssvisible:boolean;
  public url: string;
  public email: string;
  public requiresReview?: boolean;
}

class LocationFilter {
  public locationID: number;
  public locationType: string;
}
export class FilterPayload {
  public countryIds?: number[];
  public locations?: LocationFilter[];
  public categoryIds: number[];
  public operationIds?: number[];
  public employerNames?: string[];
  public industryIds?: number[];
  public publishedStartDate?: string;
  public publishedEndDate?: string;
  public minimumWage?: number;
  public maximumWage?: number;
  public minimumNumberOfJobs?: number;
  public maximumNumberOfJobs?: number;
  public wageTermId?: number;
}

class ArticleLocationPayload {
    public locationName: string;
    public locationType: string;
    public locationID: number;
}
export class ArticlePayload {
  public id?: number;
  public title: string;
  public url: string;
  public notes: string;
  public articleFollowup: boolean;
  public articleImagePath: string;
  public employer: string;
  public employerID?: number;
  public operationTypeID: number;
  public industryID?: number;
  public source: string;
  public categoryID: number;
  public datePublished: string;
  public numberOfJobs?: number;
  public wage?: number;
  public wageTermId: number;
  public competitionID?: number;
  public sourceID?: number;
  public user: string;
  public locations?: ArticleLocationPayload[];
  public summary?: string;
  public addedWeeklyNewsletter?: boolean;
  public newsletterStartDate?: string;
}

export enum RelativeDateOptions {
  week = 'Last 7 days',
  month = 'Last 30 days',
  halfyear = 'Last 6 months',
  year = 'Last year',
  twoyears ='Last 2 years',
}

export const dateChipList = [
  RelativeDateOptions.week,
  RelativeDateOptions.month,
  RelativeDateOptions.halfyear,
  RelativeDateOptions.year,
  RelativeDateOptions.twoyears,
];

export enum ArticleRouteActions {
  add_article = 'add_article',
}

export enum ArticlePanelState {
  ADD = 'add',
  EDIT = 'edit',
  FILTER = 'filter',
  VIEW = 'view',
}

export enum ThoughtLeadership {
  SOURCE = "CBRE",
  INDUSTRY = "INSIGHTS"
}