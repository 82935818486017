import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-dt-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss'],
})
export class DTReportHeaderComponent implements OnInit {
  @Input() selectedProfileNameInput: number;
  @Input() driveTimeInMinutesInput: number;
  @Input() totalIndex: string;
  @Input() totalIndexColorCode: string;
  public address: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit() {
    this.address = sessionStorage.getItem('DTAddress');
  }
}
