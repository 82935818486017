import { isArray } from 'util';

import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from 'src/app/core/services/userData.service';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['dialog.component.scss'],
})
export class InterviewDialogComponent {
  tilesList = [
    { count: 1, title: 'Very Unfavorable' },
    { count: 3, title: 'Unfavorable' },
    { count: 5, title: 'Neutral' },
    { count: 8, title: 'Favorable' },
    { count: 10, title: 'Very Favorable' },
  ];

  addProjectForm: FormGroup;

  marketScore = 0;

  deleteDialog = true;

  constructor(
    public dialogRef: MatDialogRef<InterviewDialogComponent>,
    public driveTimeService: DriveTimeService,
    private userDataService: UserDataService,
    private snackBarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (Array.isArray(this.data)) {
      this.deleteDialog = false;
      const group = {};
      this.data.forEach((element) => {
        group[element.id] = new FormControl('');
      });
      this.addProjectForm = new FormGroup(group);
    }
  }

  inputChanged() {
    const formValue = this.addProjectForm.value;
    const sum: any = Object.values(formValue).reduce((a: any, b: any) => a + b, 0);
    this.marketScore = sum / this.data.length;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    const formValue = this.addProjectForm.value;
    const keys = Object.keys(formValue);
    const payload = [];
    keys.forEach((key) => {
      payload.push({
        interviewId: '',
        interviewScoreCategoryId: key,
        score: formValue[key],
        user: this.userDataService.userInfo.email,
      });
    });
    this.driveTimeService.postMarketScore(payload).subscribe(
      (res) => {
        this.snackBarService.showInformation('Market score saved successfully!');
      },
      (error) => {
        this.snackBarService.showError(error.message);
      },
    );
  }

  onSubmit() {
    // TODO validations if required
  }

  cancel() {
    this.dialogRef.close('No');
  }

  yes() {
    this.dialogRef.close('yes');
  }
}
