<div class="snackbar slideInUp">
    <div class="d-flex align-items-center icon material-icons-outlined">
    </div>
    <div class="d-flex align-items-center message">
        <p>{{data}}</p>
    </div>
    <div class="d-flex align-items-center">
        <button mat-icon-button class="close" (click)="closeSnackbar()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
</div>