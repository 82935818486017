export type FilterType = 'employername' | 'industryname' | 'operation' | 'address' | 'headcount' | 'headcountUpdateDate';
export type OperationType='include'|'exclude'|'contains';
export const filters: FilterType[] = ['employername', 'industryname', 'operation', 'address', 'headcount', 'headcountUpdateDate'];
export const filtersWithType: FilterType[] = ['industryname', 'operation'];
export const filterByOptions = [
    {'text': 'Headcount', value: 'headcount' },
    {'text': 'Operation', value: 'operation' },
    {'text': 'Employer', value: 'employername' },
    {'text': 'Industry', value: 'industryname' },
    {'text': 'Address', value: 'address' },
    {'text': 'Headcount Update Date', value: 'headcountUpdateDate' },
];
export const filterByOperationTypes:OperationType[]=['include', 'exclude', 'contains'];