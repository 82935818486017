export enum UserRoles {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  GENERAL = 3,
  TRIAL = 4,
}

export const roles = {
  [UserRoles.SUPER_ADMIN]: 'Super Admin',
  [UserRoles.ADMIN]: 'Admin',
  [UserRoles.GENERAL]: 'General/Viewer',
  [UserRoles.TRIAL]: 'Trial',
};

export enum Modules {
  ARTICLES = 'Articles',
  COMPETITION = 'Competition',
  INTERVIEWS = 'Interviews',
  MULTISITE = 'Multisite',
  BENCHMARK = 'Benchmark',
  ALL = 'All',
}