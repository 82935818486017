<form [formGroup]="form">
  <mat-form-field appearance="outline" class="matFormField" panelWidth="auto" width="100%"
    [hideRequiredMarker]="field?.hideRequiredMarker">
    <div class="search-container">
      <input matInput [placeholder]="field.label" [attr.arialabel]="field.label" [formControlName]="field.control" [matAutocomplete]="auto">
      <ng-content select="[suffixOnEmpty]" *ngIf="(filteredOptions)?.length === 0"></ng-content>
    </div>
    <mat-label class="">{{field.label}}</mat-label>
  </mat-form-field>
  <mat-autocomplete
    (opened)="onOpened()"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected ? onOptionSelected($event) : null"
    >
    <cdk-virtual-scroll-viewport 
      class="test-virtual-viewport"
      #cdkVirtualScroll
      [itemSize]="itemSize"
      [minBufferPx]="itemSize * 6"
      [maxBufferPx]="itemSize * 12"
      [style.height]="(filteredOptions.length < 6 ? filteredOptions.length * itemSize : 6 * itemSize) + 'px'"
      >
      <mat-option
        *cdkVirtualFor="let option of filteredOptions"
        [value]="option"
        [disabled]="option.disabled"
        [style.height]="itemSize + 'px'"
        [style.maxHeight]="itemSize + 'px'"
      >
      <ng-container [ngSwitch]="field.optionType">
        <ng-container *ngSwitchCase="'twoLine'">
          <ng-container *ngTemplateOutlet="twoLineOption; context: {option: option}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="regularOption; context: {option: option}"></ng-container>
          </ng-container>
        </ng-container>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
    <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</form>


<ng-template #regularOption let-option="option">
  <div class="single-option">
    <p> {{ option?.name }} </p>
  </div>
</ng-template>

<ng-template #twoLineOption let-option="option">
  <div class="twoLine-option">
    <p class="name"> {{ option?.name }} </p>
    <p class="label"> {{option?.label}} </p>
    </div> 
</ng-template>