<form [formGroup]="form" class="example-form">
  <div class="select-container">
    <mat-form-field
      appearance="outline"
      class="matFormField"
      panelWidth="auto"
      width="100%"
      [hideRequiredMarker]="hideRequiredMarker"
    >
      <mat-label class="">{{ field.label }}</mat-label>
      <ng-container>
        <mat-select
          [disableOptionCentering]="true"
          class="mat-option-text-style"
          #dynamicSelect
          [formControlName]="field.control"
          [panelClass]="'panelMultipleClass'"
          [multiple]="true"
          (selectionChange)="changedValue(dynamicSelect)"
        >
          <mat-option
            class="mat-option-text-style"
            *ngFor="let option of field.options"
            [value]="option.value ?? option.id"
            [disabled]="option?.disabled"
          >
            {{ option.text ?? option.name }}
          </mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>
    <div *ngIf="field.helperText" class="helper-text">
      {{ field.helperText }}
    </div>
  </div>
</form>
