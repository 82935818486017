import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '@services/analytics.service';

@Directive({
  selector: 'input, mat-select, mat-autocomplete, mat-tab-group',
})
export class TrackInputDirective {
  constructor(public el: ElementRef, private analyticService: AnalyticsService) {}

  debounceFunc;
  @Input()
  trackInputDelay: number = 600;
  @Input()
  trackInput: boolean = true;

  // on input change listener
  @HostListener('input', ['$event']) onInputChange(event) {
    if (!this.trackInput) return;
    if (this.debounceFunc) clearTimeout(this.debounceFunc);

    const isSearch = event.target.className?.includes('search-query-input');
    const title = isSearch ? 'Search query event' : 'On input change event';

    const payload: any = {
      value: this.el.nativeElement.value,
      component: 'input',
    };

    if (isSearch) {
      payload.search_query = this.el.nativeElement.value;
    }

    this.debounceFunc = setTimeout(() => {
      this.analyticService.on('track', title, payload);
    }, this.trackInputDelay);
  }

  // mat-select components
  @HostListener('selectionChange', ['$event']) onSelectionChange(event) {
    const source = event.source;
    const { triggerValue, value } = source;

    this.analyticService.on('track', `Selected value in dropdown`, { triggerValue, value, component: 'mat-select' });
  }

  // mat-autocomplete components
  @HostListener('optionSelected', ['$event']) onOptionSelected(event) {
    this.analyticService.on('track', `Selected value in autocomplete component`, {
      value: event?.option?.value,
      component: 'mat-autocomplete',
    });
  }

  // mat-tab-group components
  @HostListener('selectedTabChange', ['$event']) onTabChange(event) {
    this.analyticService.on('track', `Tab change event`, {
      value: { name: event?.tab?.textLabel, id: event?.index },
      tabName: event?.tab?.textLabel,
      index: event?.index,
      component: 'mat-tab-group',
    });
  }
}
