import { Component, OnInit, Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {}

  async logout(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  ngOnInit(): void {
    this.logout();
  }
}
