import { Injectable } from '@angular/core';
import { Profile } from 'src/app/models/profile';

import { FilterDetails } from './filterDetails';

@Injectable({ providedIn: 'root' })
export class BenchMarkQueryParams {
  public profile: number;
  public profileName: string;
  public company: string;
  public driveTime: string;
  public filterMarkets: any;
  public additionalMarkets: any;
  public baseLine: string;
  public baseLineMarket: number;
  public baseLineMarketName: string;
  public filterCriteria: FilterDetails[];
}
