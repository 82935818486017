<div class="table-component">
    <div class="table-head" *ngIf="title || subTitle || (_filters && _filters.length > 0)">
        <div>
            <div class="table-head__title">
                {{title}}
            </div>
            <div class="table-head__subtitle" [style]="subTitleStyle">
                {{subTitle}}
            </div>
        </div>

        <div>
            <mat-form-field class="table-head__filterInputs" *ngIf="selectDropdownOptions[0]?.datasource?.length > 0" appearance="outline">
                <mat-label class="custom-mat-label">{{selectDropdownOptions[0].label}}</mat-label>
                <mat-select class="mat-option-text-style">
                    <mat-option class="mat-option-text-style" (onSelectionChange)="change($event)"
                        *ngFor="let option of selectDropdownOptions[0].datasource" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="table-head__filterInputs" *ngIf="selectDropdownOptions[0]?.datasource?.length > 0" appearance="outline">
                <input placeholder="Search" matInput (keyup)="applyFilter($event)" autocomplete="off" #search>
                <mat-icon *ngIf="search.value" matSuffix mat-icon-button (click)="clearGridSearch($event); search.value=''">close</mat-icon>
                <mat-icon *ngIf="search.value === ''" matSuffix mat-icon-button>search</mat-icon>
            </mat-form-field>
        </div>

        <div class="table-head__filters">
            <ng-container *ngFor="let filter of _filters">
                <mat-chip class="chip" *ngFor="let badge of selectedFilters[filter.key]" 
                    selected [ngStyle]="{'background-color':badge.color}"
                    (removed)="handleFilterClick(filter.key, badge)">
                    {{badge.label}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-icon [matMenuTriggerFor]="filterMenu">
                    filter_list
                </mat-icon>

                <mat-menu #filterMenu="matMenu" class="table-filter-menu">
                    <div style="font-size: 20px; padding: 16px; font-weight: 500;font-family: 'Calibre-R';">
                        Filter by {{filter.label}}
                    </div>
                    <button *ngFor="let option of filter.options" mat-menu-item class="menuButton"
                        (click)="handleFilterClick(filter.key, option)">
                        <mat-icon *ngIf="option.color" [ngStyle]="{'color': option.color}">lens</mat-icon>
                        {{option.label}}
                    </button>
                </mat-menu>
            </ng-container>
            <ng-container *ngIf="searchableFilter" [formGroup]="searchFormGroup">
                <lag-text [field]="{label: 'Search', control: 'search', isSearchIcon: true}" ></lag-text>
            </ng-container>
        </div>
    </div>
    <div class="table-container" [ngStyle]="{'height':tableHeight}">
        <table matSort mat-table cdkDropList class="table" [dataSource]="_data"
            [cdkDropListData]="_data ? _data.data : undefined" (cdkDropListDropped)="onListDrop($event)"
            (matSortChange)="announceSortChange($event)"
            [matSortActive] = "sortByField"
            [matSortDirection] = "sortOrder">
            <ng-container *ngFor="let column of _columns; let i=index; trackBy: trackByIndex">
                
                <ng-container [matColumnDef]="column.key" class="column" [sticky]="column?.isSticky">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.sortable !== true"
                        [ngStyle]="column.style" [ngClass]="column?.class">
                        <ng-container [ngTemplateOutlet]="stringOrTemplate" [ngTemplateOutletContext]="{
                                content: column.label,
                                key: column.dataLocator,
                                cellType: 'header'
                            }"></ng-container>
                    </th>
                    <td mat-cell *matCellDef="let row; let rowIndex = index" 
                    [ngStyle]="getCellStyle(column.style, rowIndex, row, column)">
                        <ng-container *ngIf="dataLoaded; else skeletonCell">
                            <div class="table__cell">
                                <ng-container 
                                    [ngTemplateOutlet]="(cellsBeingEdited[column.key] && cellsBeingEdited[column.key][rowIndex] !== undefined) ? dataInput : stringOrTemplate"
                                    [ngTemplateOutletContext]="{
                                    content: column.cellTemplate !== undefined ? column.cellTemplate : row[column.dataLocator],
                                    key: column.dataLocator,
                                    rowData: row,
                                    cellType: 'body',
                                    rowIndex: rowIndex,
                                    columnKey: column.key,
                                    columnLabel: column.label,
                                    columnField: column?.field !== undefined ? column.field : undefined
                                }"></ng-container>
                                <div
                                    *ngIf="column.editable && cellsBeingEdited[column.key] && cellsBeingEdited[column.key][rowIndex] === null">
                                    <mat-icon
                                        (click)="handleEditIconClick(column.key, rowIndex, row[column.dataLocator])"
                                        class="edit-icon">edit</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="headers; sticky: stickyHeader"></tr>
            <tr cdkDrag mat-row *matRowDef="let row; columns: headers;" [cdkDragData]="row"
                [cdkDragDisabled]="dragDisabled || row.parentData !== undefined"
                (cdkDragReleased)="dragDisabled = true">
            </tr>
        </table>
    </div>
    <div class="table-footer" *ngIf="pagination">
        <mat-paginator class="paginator" #paginator [length]="totalRecordsCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
            (page)="handlePageEvent($event)"></mat-paginator>
    </div>
</div>

<ng-template #stringOrTemplate let-content="content" let-key="key" let-rowData="rowData" let-cellType="cellType" let-columnField="columnField">
    <ng-container [ngSwitch]="content | typeof">
        <ng-container *ngSwitchCase="'object'" [ngTemplateOutlet]="content"
            [ngTemplateOutletContext]="{key: key, rowData: removeParentDataFromRow(rowData) , cellType: cellType, parentData: rowData?.parentData, columnField: columnField}">
        </ng-container>
        <ng-container *ngSwitchCase="'string'">
            <div
                class="truncate"
                [libCellTooltip]="content"
                [matTooltip]="''"
                >
                {{content}}
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{content}}
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #dataInput let-columnKey="columnKey" let-rowIndex="rowIndex" let-columnLabel="columnLabel"
    let-rowData="rowData">
    <div class="table-input">
        <div class="table-input__input">
            <input matInput [(value)]="cellsBeingEdited[columnKey][rowIndex].value">
        </div>
        <div class="table-input__icon">
            <mat-icon (click)="saveCellValue(columnKey, rowIndex, rowData)">
                done
            </mat-icon>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="table-input__icon">
            <mat-icon (click)="cancelCellEdit(columnKey, rowIndex)">
                close
            </mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #dragHandle let-rowData="rowData" let-cellType="cellType" let-parentData="parentData">
    <mat-icon (mousedown)="dragDisabled = false" (mouseup)="dragDisabled = true">drag_indicator</mat-icon>
</ng-template>

<ng-template #nestedExpand let-rowData="rowData" let-cellType="cellType" let-parentData="parentData">
    <mat-icon
        *ngIf="rowData && rowData[_expandColumnKey] && rowData[_expandColumnKey].length > 0 || cellType === 'header'"
        (click)="cellType === 'body' && cellType === 'body' && expandRow(rowData)">
        {{rowData && expandedRows.includes(rowData.id) ? 'arrow_drop_down' : 'arrow_right'}}
    </mat-icon>
</ng-template>

<ng-template #skeletonCell>
    <div class="skeleton-cell">
        <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
    </div>
</ng-template>