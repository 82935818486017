import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '@services/userProfile.service';

@Component({
  selector: 'app-request-access-dialog',
  templateUrl: './request-access-dialog.component.html',
  styleUrls: ['./request-access-dialog.component.scss'],
})
export class RequestAccessDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RequestAccessDialogComponent>,
    public dialog: MatDialog,
    private userprofileService: UserProfileService,
  ) {}

  messageField = {
    label: 'Message (Optional)',
    control: 'message',
  };
  form = new FormGroup({
    message: new FormControl(),
  });

  mailTo = 'cbre-laborconsulting-inquiries@cbre.onmicrosoft.com';
  subject = 'Request for Help for CBRE GeoNavigator Access';
  body =
    "Hello, %0D%0AI'm reaching out about my access to the CBRE GeoNavigator application. I am having difficulty in accessing the application:%0D%0A[Fill in issue]%0D%0ACan you please help me? Looking forward to your response.%0D%0A%0D%0ABest,";
  mailToHref = `mailto:${this.mailTo}?subject=${this.subject}&body=${this.body}`;

  ngOnInit() {}

  getResponse() {
    const payload = {
      requestMessage: this.form.value.message,
    };
    this.userprofileService.requestAccess(payload)
    .subscribe(() => {
      this.dialogRef.close('request access');
    });
  }
}
