import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'table-cell-edit',
    templateUrl: './table-cell-edit.component.html',
    styleUrls: ['./table-cell-edit.component.scss'],
    changeDetection:ChangeDetectionStrategy.OnPush,
})
export class TableCellEditComponent {
    showEditByCellIcon = false;

    @Input() rowData: any;
    @Input() key: string;
    @Input() required: any;
    @Input() columnOptions: any;
    @Input() columnLabel: any;
    @Input() nonEditableColumns: any;
    @Input() cellStyle: any;
    @Input() isEmerald = false;
    @Input() isValidationError = false;
    @Input() cellType: Object;

    value: string;

    @Output() onEditSubmit = new EventEmitter();
    @Output() onEditCancel = new EventEmitter();
    @Output() onEditStart = new EventEmitter();

    @ViewChild('filterSearchTextBox') seachTextbox: ElementRef;

    constructor() {
    }

    isTextInput() {
        return !this.columnOptions[this.key] && this.cellType?.[this.key]?.type !== 'date';
    }

    isDropdown() {
        return this.columnOptions[this.key] && this.cellType?.[this.key]?.type !== 'date';
    }

    isTypeahead() {
        return this.columnOptions[this.key] && this.cellType?.[this.key]?.type === 'typeahead';
    }

    handleSubmit(rowData, key) {
        this.onEditSubmit.emit({ rowData: rowData, key: key });
    }

    handleCancel(rowData, key) {
        this.onEditCancel.emit({ rowData: rowData, key: key });
    }

    handleEdit(rowData) {
        this.onEditStart.emit({ rowData: rowData });
    }

    getSelectedOptionName(element, data){
        const res = data?.find((obj) => {
          return obj.id === element;
        });
        return res?.name ?? element;
    }

    getColumOptions(key:string){
        if(key.toLowerCase() === 'employer'){
            this.columnOptions[key].forEach(item => item.label = item.industry);
        }
        return this.columnOptions[key];        
    }
}
