import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnvConfigService } from './env-config.service';
import { TokenService } from './token.service';

import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';

// Read: https://www.npmjs.com/package/msal
// Wiki: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL.js-1.0.0-api-release#configuration-options

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loginStatus: Observable<boolean>;


  isAuthenticated$!: Observable<boolean>;
  isAuthenticated: boolean = false;
  authState: any;

  constructor(
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false),
    );
    this._oktaStateService.authState$.subscribe((user) => {
      this.isAuthenticated = user.isAuthenticated ?? false;
    });
  }

  async logout() {
    await this._oktaAuth?.signOut();
  }

  async login() {
    await this._oktaAuth.signInWithRedirect();
  }
}
