import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { faBan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss'],
})
export class SnackBarErrorComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, private snackbarRef:MatSnackBarRef<SnackBarErrorComponent>) {}

  errorIcon = faBan;

  ngOnInit() {}

  closeSnackbar(){
    this.snackbarRef.dismiss();

  }
}
