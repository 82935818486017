// Angular
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { SpinnerService } from '@core/spinner.service';
import { EmeraldNgMaterialModule } from '@emerald/ngmaterial';
import { PlotlyViaCDNModule } from 'angular-plotly.js';
import { TextMaskModule } from 'angular2-text-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { environment } from '@env/environment';

import { DataLoader } from './app-initialize/data-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.material.module';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { SupportComponent } from './components/support/support.component';
import { CoreModule } from './core/core.module';
import { CommonService } from './core/services/common.service';
import { LoggingService } from './core/services/logging.service';
import { GlobalErrorHandler } from './core/util/global-error-handler';
import { CacheRouteReuseStrategy } from './drive-time/multi-site/component.reuse.strategy';
import { ParamsComponent } from './drive-time/params/parameters.component';
import { NewDTParamsComponent } from './drive-time/params/params.component';
import { SingleProjectInfoComponent } from './drive-time/params/single-project-info/single-project-info.component';
import { NewDTReportComponent } from './drive-time/report/report.component';
import { DTReportFooterComponent } from './drive-time/report-footer/report-footer.component';
import { DTReportHeaderComponent } from './drive-time/report-header/report-header.component';
import { TokenHttpInterceptor } from './httpInterceptor/TokenHttpInterceptor';
import { MarketIndexComponent } from './marketindex-report/marketindex-report.component';
import { EnvConfigService } from './services/env-config.service';
import { TokenService, tokenGetter } from './services/token.service';
// GeoSkill
import { CustomValidators } from './util/CustomValidators';
import { FormUtil } from './util/FormUtil';
import { GeoLocation } from './util/GeoLocation';
import { Util } from './util/Util';

// Okta angular
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth, SimpleStorage } from '@okta/okta-auth-js';
import { GlobalHTTPErrorHandler } from '@core/global-http-error-handler/GlobalHTTPErrorHandler';
import { MainComponent } from './drive-time/main/main.component';
import { ConfirmDialogModule } from './shared/components/confirm-dialog/confirm-dialog.module';

PlotlyViaCDNModule.plotlyVersion = '1.49.4'; // can be `latest` or any version number (i.e.: '1.4.3')
PlotlyViaCDNModule.plotlyBundle = 'basic';

let storage = {};
const storageProvider: CustomSimpleStorage = {
  getItem: function(key) {
    return storage[key];
  },
  setItem: function(key, val) {
    storage[key] = val;
  },
  removeItem: function(key) {
    delete storage[key];
  },
  clear: function() {
    storage = {};
  },
  getOktaStorageToken: function(): string {
    return storage['okta-token-storage'];
  },
  setOktaStorageToken: function(value: string): void {
    storage['okta-token-storage'] = value;
  }
};
export interface CustomSimpleStorage extends SimpleStorage {
  clear?: () => void;
  getOktaStorageToken(): string;
  setOktaStorageToken(value): void;
}
export const OKTA_STORAGE_PROVIDER = new InjectionToken<CustomSimpleStorage>('storageProvider');
export const SKIP_DEFAULT_ERROR_HANDLER = 'SKIP_DEFAULT_ERROR_HANDLER';
export const SKIP_DEFAULT_ERROR_TOKEN = new InjectionToken<HttpHeaders>(SKIP_DEFAULT_ERROR_HANDLER);

const oktaAuth = new OktaAuth({
  issuer: environment.OKTA_ISSUER_URL,
  clientId: environment.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  services: {
    // default is true, we do not need to make it auto renewal, as we do that in token.service.ts when we need it
    autoRenew: false,
  },
  tokenManager: {
    autoRenew: false,
  },
  storageManager: {
    token: {
      storageType: 'custom',
      storageProvider,
    }
  }
});

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DisclaimerComponent,
    NewDTParamsComponent,
    ParamsComponent,
    NewDTReportComponent,
    DTReportHeaderComponent,
    DTReportFooterComponent,
    SupportComponent,
    MarketIndexComponent,
    SingleProjectInfoComponent,
    MainComponent,
  ],
  imports: [
    OktaAuthModule.forRoot({ oktaAuth }),
    BrowserModule,
    BrowserAnimationsModule,
    EmeraldNgMaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TextMaskModule,
    AppRoutingModule,
    CoreModule,
    ConfirmDialogModule,
    CommonModule,
    PlotlyViaCDNModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:5000'],
        blacklistedRoutes: ['localhost:5000/api/auth'], // Ignore URLs ...not used by GeoSKill
      },
    }),
    ModalModule.forRoot(),
    AppMaterialModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: DataLoader,
      deps: [EnvConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true,
      deps: [TokenService, SpinnerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHTTPErrorHandler,
      multi: true,
    },
    { provide: 'ORIGIN_URL', useValue: location.origin },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [LoggingService],
    },
    { provide: 'gTMId', useValue: 'GTM-NLTPHTG' },
    CustomValidators,
    FormUtil,
    Util,
    GeoLocation,
    DatePipe,
    CommonService,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    { 
      provide: OKTA_STORAGE_PROVIDER,
      useValue: storageProvider
    },
    {
      provide: SKIP_DEFAULT_ERROR_TOKEN,
      useValue: new HttpHeaders().set(SKIP_DEFAULT_ERROR_HANDLER, ''),
    }
  ],
})
export class AppModule {}