import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/models/user-info';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

import { UserDataService } from '../services/userData.service';
import { MenuItem } from '@core/models/navbar.model';
import { DriveTimeService } from '@services/drivetime/drivetime.service';
import { MegamenuService } from '@services/megamenu.service';
import { BehaviorSubject, Subject, concatMap, delay, map, of,repeatWhen, startWith, switchMap, take, takeUntil, takeWhile } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { UserProfileService } from '@services/userProfile.service';
import { UserProfileInfo } from 'src/app/models/userProfile.model';

import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { SessionStorageService } from '@services/session-storage.service';
import { NotificationTypes } from './models/model';
import { ThemeService } from '@services/theme.service';
import { ArticleService } from '@services/article.service';
import { ArticlePayload } from 'src/app/drive-time/research/model/article-info';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  user: UserInfo;
  isUserLoaded = false;
  isResearch: boolean = environment.isResearch;
  isInterviews: boolean = environment.isInterviews;

  currentSelectedMenuItem?: MenuItem;
  maxNagivationLevel?: number;
  navigationMenu?: MenuItem[];
  adminMenu?: MenuItem;
  unreadNotificationCount: number | string;
  isUserProfileImage: boolean = false;
  userProfile: UserProfileInfo;
  userAvatarText: string;
  thoughtLeadership: Array<ArticlePayload>;

  destroy$ = new Subject<void>();

  constructor(
    private userSevice: UserService,
    private themeService: ThemeService,
    private userDataSerice: UserDataService,
    private articleService: ArticleService,
    private megamenuService: MegamenuService,
    private userProfileService: UserProfileService,
    private sessionStorageService: SessionStorageService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    public notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.loginStatusChanged();
    this.notificationService.notifications$.subscribe(
      (data) =>
        (this.unreadNotificationCount = data.unreadNotificationCount > 99 ? `99+` : data.unreadNotificationCount),
    );
    this.megamenuService.megaMenuData$.subscribe((data) => {
      this.updateNavigationMenu(data);
    });
    this.userProfileService.userData$.subscribe((data) => {
      this.isUserProfileImage = !!data?.userImagePath && !!data.userImagePathUrl;
      this.userProfile = data;
      this.userAvatarText = this.getTextToAvatar(data);
    });
    this.articleService.getThoughtLeadership().subscribe((data) => {
      this.thoughtLeadership = data;
    });

    this.notificationService.getNotificationQueue()
    .pipe(
      startWith(''),
      map(() => this.sessionStorageService.getStorage(NotificationTypes.LABORPLAN)),
      switchMap((array) => {
          return of(array)
          .pipe(
            concatMap(() => {
              if (this.user?.email) this.notificationService.getAllNotifications(this.user?.email);
              return this.notificationService.notifications$.pipe(take(1));}),
            map(({ userNotifications }) => {
              const exisingLaborPlanIds = userNotifications.filter((n) => (n.notificationTypeName === NotificationTypes.LABORPLAN && !n.notificationRead)).map((n) => n.notificationTypeId);
              const pendingNotificationIds = JSON.parse(this.sessionStorageService.getStorage(NotificationTypes.LABORPLAN));
              if (pendingNotificationIds?.length > 0) {
                const filteredPendingNotificationIds = pendingNotificationIds.filter((id) => !exisingLaborPlanIds.includes(id));
                this.sessionStorageService.setStorage(NotificationTypes.LABORPLAN, JSON.stringify(filteredPendingNotificationIds));
                return this.sessionStorageService.getStorage(NotificationTypes.LABORPLAN);
              }
              return [];
            }),
            repeatWhen(delay(60000)),
            takeWhile((pendingNotificationIds) =>  pendingNotificationIds?.length > 0),
            takeUntil(this.destroy$)
          );
      })
    )
    .subscribe();
  }

  getTextToAvatar(user) {
    return `${user?.firstName?.[0] ?? ''}${user?.lastName?.[0] ?? ''}`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAllChildren(item) {
    const children = [];

    if (Array.isArray(item)) {
      children.push(...item);

      item.forEach((i) => {
        if (i?.childItems) {
          children.push(...this.getAllChildren(i?.childItems));
        }
      });
    } else {
      children.push(item);
    }

    if (item?.childItems?.length) {
      children.push(...this.getAllChildren(item?.childItems));
    }

    return children;
  }

  getSortedChildrenByItemLevel(item: MenuItem) {
    return this.getAllChildren(item)?.sort((a: MenuItem, b: MenuItem) => a?.itemLevel - b?.itemLevel);
  }

  setCurrentRoute(currentItem, id) {
    const sortedChildren = this.getSortedChildrenByItemLevel(currentItem);

    if (id === this.currentSelectedMenuItem?.id) {
      this.currentSelectedMenuItem = undefined;
      this.maxNagivationLevel = undefined;
    } else if (id === this.adminMenu.id) {
      this.maxNagivationLevel = 4;
      this.currentSelectedMenuItem = currentItem;
    } else {
      this.maxNagivationLevel = sortedChildren?.at(-1)?.itemLevel;
      this.currentSelectedMenuItem = currentItem;
    }
  }

  getUpdatedMegamenu(data, id) {
    const childItems = this.megamenuService.getSortedMegamenuItems(data?.at(0)?.childItems);
    const updatedMegamenu = this.navigationMenu?.map((item) => {
      if (item.id === id) return { ...item, childItems };
      return item;
    });

    this.megamenuService.setMegamenuData(updatedMegamenu);

    return updatedMegamenu;
  }

  updateNavigationMenu(menu): void {
    this.navigationMenu = menu.filter((m) => m.itemLevel !== 0 && m.childItems?.length > 0);
    this.adminMenu = menu.find((m) => m.itemLevel === 0);
  }
  onUserMenuSelect() {
    if (this.adminMenu) { this.setCurrentRoute(this.adminMenu, this.adminMenu.id);}
  }
  selectCurrentRoute(id: number) {
    let currentSelectedItem = this.navigationMenu.find((item) => {
      if (item.itemLevel === 1 && item.id === id) return true;
      return false;
    });

    if (!currentSelectedItem?.childItems?.length) {
      this.megamenuService.getMegamenuSpecificLevel(id).subscribe((response) => {
        const data = this.getUpdatedMegamenu(response, id);

        this.navigationMenu = data;

        currentSelectedItem = this.navigationMenu.find((item) => {
          if (item.itemLevel === 1 && item.id === id) return true;
          return false;
        });

        this.setCurrentRoute(currentSelectedItem, id);
      });
      return;
    }

    this.setCurrentRoute(currentSelectedItem, id);
  }

  loginStatusChanged() {
    this.userSevice
      .getUser()
      .pipe()
      .subscribe((geoskillUser) => {
        this.user = geoskillUser;
        this.userDataSerice.userInfo = this.user;
        this.isUserLoaded = true;

        if (geoskillUser?.email) {
          this.notificationService.getAllNotifications(geoskillUser?.email);
        }
        this.themeService.updateTheme(geoskillUser?.uiTheme);
      });
  }

  // notificationQueue(): Observable<any> {
  //   const laborPlanPendingNotifications = this.sessionStorageService.getStorage(NotificationTypes.LABORPLAN);
    
  //   if (laborPlanPendingNotifications?.length > 0 && this.user?.email) {
  //     this.notificationService.getAllNotifications(this.user.email);
  //   }
  // }

  async logout(): Promise<void> {
    await this._oktaAuth.signOut();
  }
}
