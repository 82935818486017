import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ArticlesComponent } from './articles/articles.component';
import { CompetitionComponent } from './competition/competition.component';
import { InterviewsComponent } from './interviews/interviews.component';
import { ThoughtSpotComponent } from './thoughtspot/thoughtspot.component';

export const visualizeRoutesMapping = {
  THOUGHT_SPOT: 'thought-spot',
};

const routes: Routes = [
  {
    path: 'competition',
    redirectTo: 'competition',
  },
  {
    path: 'competition',
    component: CompetitionComponent,
    data: { key: 'competition/home', shouldDetach: false },
  },
  {
    path: 'articles',
    redirectTo: 'articles',
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    data: { key: 'articles/home', shouldDetach: false },
  },
  {
    path: 'interviews',
    redirectTo: 'interviews',
  },
  {
    path: 'interviews',
    component: InterviewsComponent,
    data: { key: 'interviews/home', shouldDetach: false },
  },
  {
    path: `${visualizeRoutesMapping.THOUGHT_SPOT}/:megamenuItemId/:id`,
    component: ThoughtSpotComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VisualizeRoutingModule {}
