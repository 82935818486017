import { Component, Input, OnInit } from '@angular/core';
import { LOADER_GRAPH, LOADER_GRAPH_TYPES } from './graph-loader.model';

@Component({
  selector: 'lag-graph-loader',
  templateUrl: './graph-loader.component.html',
  styleUrls: ['./graph-loader.component.css']
})
export class GraphLoaderComponent implements OnInit {
  @Input() graphType: LOADER_GRAPH_TYPES;
  @Input() height: number;
  public loaderGraph = LOADER_GRAPH;

  constructor() { }

  ngOnInit(): void {
  }

}
