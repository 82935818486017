import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[lagComponentChange]'
})
export class ComponentChangeDirective {

  constructor(private el:ElementRef) {
    console.log(this);
   }

}
