import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { faBan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snack-bar-profile-errors',
  templateUrl: './snack-bar-profile-errors.component.html',
  styleUrls: ['./snack-bar-profile-errors.component.scss'],
})
export class SnackBarProfileErrorsComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarProfileErrorsComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
  ) {}

  errorIcon = faBan;

  ngOnInit() {}
}
