import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';

const COLUMNS_SCHEMA = [
  {
    key: 'isEdit',
    type: 'isEdit',
    label: 'Actions',
  },
  {
    key: 'employer',
    type: 'text_employer',
    label: 'Employer',
  },
  {
    key: 'address',
    type: 'text_address',
    label: 'Address',
  },
  {
    key: 'market',
    type: 'text_market',
    label: 'Market',
  },
  {
    key: 'headcount',
    type: 'number_headcount',
    label: 'Headcount',
  },
  {
    key: 'sourceName',
    type: 'text_sourcename',
    label: 'Headcount source',
  },
];

@Component({
  selector: 'mass-update-dialog',
  templateUrl: './mass-update-dialog.component.html',
  styleUrls: ['./mass-update-dialog.component.scss'],
})
export class MassUpdateDialogComponent implements OnInit {
  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource: any;
  firstdata: any;
  initialdata: any;
  staticData;
  sourceData;
  sources;
  updateHeadcount;
  status_level: any = 'Baseline Adj.';
  statusTypes;
  statuses;
  disable = false;
  deleteSource;
  columnsSchema: any = COLUMNS_SCHEMA;
  gridFilters = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) matTable: MatTable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MassUpdateDialogComponent>,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    public driveTimeService: DriveTimeService,
  ) {
    this.dataSource = data.selectedTableData;
    this.staticData = data.staticData;
    this.firstdata = this.dataSource.map((i) => {
      i.oldValues = Object.assign({}, i);
      return i;
    });
    this.initialdata = JSON.parse(JSON.stringify(this.dataSource));
  }

  ngOnInit() {
    this.sourceData = this.staticData.find((x) => x.dataType == 'SourceTypes');
    this.sources = this.sourceData.data;
    this.statusTypes = this.staticData.find((x) => x.dataType == 'StatusTypes');
    this.statuses = this.statusTypes.data;
  }

  performAction(event) {
    //console.log(event);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  events: string[] = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  removeRow(element) {
    //console.log("Hell", employer);
    this.dataSource = this.dataSource.filter((u) => u.id !== element.id);
    console.log('Filter ', this.dataSource);
    this.resetState(element);
  }

  updateGridFilter(value: string, className: string, label: string) {
    this.gridFilters.push({ label: label, color: className });
    this.gridFilters = [...new Map(this.gridFilters.map((item) => [item.label, item])).values()];
    this.dataSource.forEach((element) => {
      if (element.id === value) {
        element.class = className;
        if (className == 'edit-class') {
          this.toggleEdit(element);
        } else {
          this.toggleDelete(element);
        }
      }
    });
  }

  removeGridFilter(value: string) {
    //console.log("vall ", value)
    this.dataSource.forEach((element) => {
      if (element.id === value) {
        element.class = '';
      }
    });
  }

  doneVal(element) {
    const el = this.dataSource.map((val) => val.id).indexOf(element.id);
    const first = this.firstdata.map((val) => val.id).indexOf(element.id);

    this.firstdata[first].oldValues['headcount'] = this.dataSource[el].headcount;
    element.editHeadcount = true;
    this.editToggles(element);
  }

  cancelVal(element) {
    const el = this.dataSource.map((val) => val.id).indexOf(element.id);
    const first = this.firstdata.map((val) => val.id).indexOf(element.id);

    this.dataSource[el].headcount = this.firstdata[first].oldValues['headcount'];
    element.editHeadcount = true;
    this.editToggles(element);
  }

  cancelsource(element) {
    const el = this.dataSource.map((val) => val.id).indexOf(element.id);
    const first = this.firstdata.map((val) => val.id).indexOf(element.id);

    // console.log("hello", this.firstdata[first].sourceName);

    this.dataSource[el].sourceName = this.firstdata[first].oldValues['sourceName'];
    element.editSource = true;
    this.editToggles(element);
  }

  donesource(element) {
    const el = this.dataSource.map((val) => val.id).indexOf(element.id);
    const first = this.firstdata.map((val) => val.id).indexOf(element.id);

    //console.log("hello", this.firstdata[first].sourceName);

    this.firstdata[first].oldValues['sourceName'] = this.dataSource[el].sourceName;
    element.editSource = true;
    this.editToggles(element);
  }

  editToggles(element) {
    if (element.editHeadcount == true && element.editSource == true) {
      this.removeGridFilter(element.id);
      this.resetState(element);
    }
  }

  toggleEdit(element) {
    element.isedit = true;
  }

  toggleDelete(element) {
    element.isdelete = true;
  }

  resetState(element) {
    element.isedit = false;
    element.isdelete = false;
    element.editHeadcount = false;
    element.editSource = false;
  }

  setSourceSelection(event: any) {
    this.disable = false;
    //console.log("selected source", event);
  }

  apply() {
    if (this.firstdata.length !== this.dataSource.length) {
      const deletedData = this.firstdata.filter(({ id: id1 }) => !this.dataSource.some(({ id: id2 }) => id2 === id1));
      const payload = deletedData.map((item) => {
        return {
          competitionID: item['id'],
          operationTypeID: item['operationTypeId'],
        };
      });
      console.log('delete payload', payload);
      this.driveTimeService.deleteMassUpdate(payload).subscribe(
        (res) => {
          this.snackBarService.show('upadted successfully!', 'success');
        },
        (error) => {
          this.snackBarService.showError('quick update failed, Please contact system admin.');
          console.log('Deleting Record Error -> error', error);
        },
      );
    }
    //console.log("update data source", this.dataSource)
    if (!this.compareTwoArrayOfObjects(this.initialdata, this.dataSource)) {
      const payload = this.dataSource.map((item) => {
        return {
          id: item['id'],
          employer: item['employer'],
          employerId: item['employerId'],
          operation: item['operation'],
          operationTypeId: item['operationTypeId'],
          headcount: item['headcount'],
          sourceId: this.getSourceId(item['sourceName']),
          statusId: this.getStatusId(this.status_level),
          user: item['user'],
          touchPointDate: this.getCurrentDateandTime(),
        };
      });
      console.log('updating payload', payload);
      this.driveTimeService.updateMassUpdate(payload).subscribe(
        (res) => {
          this.snackBarService.show('upadted successfully!', 'success');
          this.getResponse(res);
        },
        (error) => {
          this.snackBarService.showError('quick update failed, Please contact system admin.');
          this.getResponse(error);
        },
      );
    } else {
      this.dialogRef.close('massUpdate');
    }
  }

  numberValidator(event) {
    if (event.currentTarget.value) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }

  getStatusId(name) {
    const status = this.statuses.find((x) => x.name == name);
    if (status) {
      return status.id;
    } else {
      return name;
    }
  }

  getResponse(resp) {
    this.dialogRef.close(resp);
  }

  compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) => element_1.headcount === element_2.headcount && element_1.sourceName === element_2.sourceName,
        ),
      )
    );
  };

  getSourceId(name) {
    const sourceObj = this.sources.find((x) => x.name == name);
    if (sourceObj) {
      return sourceObj.id;
    } else {
      return name;
    }
  }
  // getTime(time) {
  //   if (time) {
  //     let refined = time.replace("T", " ");
  //     return refined.split(".")[0];
  //   } else {
  //     return time;
  //   }
  // }

  getCurrentDateandTime() {
    const current = new Date();
    const cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    const cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
    const dateTime = cDate + ' ' + cTime;
    return dateTime;
  }
}
