<div *ngIf="marketIndexes!=null">
    <div class="row">
        <app-dt-report-header selectedProfileNameInput={{profileName}} driveTimeInMinutesInput={{driveTime}}
            totalIndex={{marketIndexes.TotalIndex}}>
        </app-dt-report-header>
    </div>
    <div class="row mt-2 reportlabel">
        <img src="assets/images/Green-arrow-end logo.png" height="45px" width="45px" />
        Market Indexes
    </div>
    <div class="mt-2 ml-4">
        <div class="row mt-2">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.LaborSupply"
                        [style.background-color]="'#41B049'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                        style="text-align: center;">
                        Labor
                        Supply
                    </div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #41B049;text-align: center">{{marketIndexes.LaborSupply}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.AvailabilityandQuality"
                        [style.background-color]="'#41B049'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                        style="text-align: center;">
                        Availability
                        and Quality</div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #41B049;text-align: center">{{marketIndexes.AvailabilityandQuality}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.SkillSetEmployers"
                        [style.background-color]="'#41B049'" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"
                        style="text-align: center;">
                        Skill
                        Set
                        Density</div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #41B049;text-align: center">{{marketIndexes.SkillSetEmployers}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.LaborCost"
                        [style.background-color]="'#FEBC15'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        Labor
                        Cost
                    </div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #FEBC15;text-align: center">{{marketIndexes.LaborCost}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.MacroLaborCosts"
                        [style.background-color]="'#A5CE3A'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        Costs 
                        of 
                        Living</div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #A5CE3A;text-align: center">{{marketIndexes.MacroLaborCosts}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-11">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="marketIndexes.CostSkillSets"
                        [style.background-color]="'#FEBC15'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        Median 
                        Market 
                        Wages</div>
                </div>
            </div>
            <div class="col-md-1" style="margin-top: -5px;">
                <div style="background-color: #FEBC15;text-align: center">{{marketIndexes.CostSkillSets}}</div>
            </div>
        </div>
    </div>
    <div class="row mt-5 reportlabel">
        <img src="assets/images/Green-arrow-end logo.png" height="45px" width="45px" />
        OPTIMAL BALANCE
    </div>
    <div class="row">
        <plotly-plot [data]="marketIndexesGraph.data" [layout]="marketIndexesGraph.layout">
        </plotly-plot>
    </div>
    <div class="row">
        <app-dt-report-footer pageNumber='1' totalPages='6'></app-dt-report-footer>
    </div>
</div>