<mat-sidenav-container class="example-container" id="drawerContainer" hasBackdrop="false" autosize end>
  <mat-sidenav
    class="right-sidenav"
    hasBackdrop="false"
    autosize
    mode="over"
    position="end"
    [opened]="notificationService.notificationDrawer$ | async"
    [autoFocus]="false"
  >
    <div class="notifications-title__block">
      <div class="notifications__title">Notifications</div>
      <button class="button text" [disabled]="isAllMarkedAsRead()" (click)="markAllItemsAsRead()">
        Mark all as read
      </button>
    </div>
    <div class="notifications-container__list" *ngIf="notifications?.userNotifications?.length">
      <div
        *ngFor="let item of notifications?.userNotifications"
        class="notification-item"
        [ngClass]="{ 'notification-item-unactive': !item.notificationRead }"
      >
        <div class="notification-item__header">
          <div class="notification-item__date">{{ item.dateCreated }}</div>
          <div class="notification-item__remove">
            <mat-icon style="cursor: pointer; color: #003f2d" (click)="removeItem(item.id)">delete_outline</mat-icon>
          </div>
        </div>
        <div class="notification-item__wrapper">
          <div class="navbar-notifications__block">
            <div class="notification-item__circle" *ngIf="!item.notificationRead"></div>
            <mat-icon style="color: #8585e0">notifications</mat-icon>
          </div>
          <div class="notification-item__content">
            <ng-container [ngSwitch]="item.notificationTypeName">
              <ng-container *ngSwitchCase="NotificationTypes.LABORPLAN">
                <span class="notification-content__title">LaborPlan follow up</span>
                <span class="notification-content__subtitle" >You have marked "{{ item.notificationText | slice: 0 : 27}}" for follow up. Follow the link below to return to your report.</span>
              </ng-container>
              <ng-container *ngSwitchCase="NotificationTypes.ARTICLE">
                <span class="notification-content__title">Articles follow up</span>
                <span class="notification-content__subtitle" *ngSwitchCase="NotificationTypes.ARTICLE">You have marked "{{ item.notificationText | slice: 0 : 27}}" for follow up. Follow the link below to return to your article.</span>
              </ng-container>
              <ng-container *ngSwitchCase="NotificationTypes.INTERVIEW">
                <span class="notification-content__title">Interview follow up</span>
                <span class="notification-content__subtitle" *ngSwitchCase="NotificationTypes.INTERVIEW">
                  You have marked "{{ item.notificationText | slice: 0 : 27}}"'s interview for follow up. Follow the link below to return to your interview.
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="NotificationTypes.COMPETITION">
                <span class="notification-content__title">Competition follow up</span>
                <span class="notification-content__subtitle" *ngSwitchCase="NotificationTypes.COMPETITION">
                  You have marked {{ item.notificationText | slice: 0 : 27}} for follow up.
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="NotificationTypes.ACCESS_REQUEST">
                <span class="notification-content__title">Access request</span>
                <span class="notification-content__subtitle" *ngSwitchCase="NotificationTypes.ACCESS_REQUEST">
                  {{ item.notificationText }} is requesting for access
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span class="notification-content__title">{{ item.title }}</span>
                <span class="notification-content__subtitle">{{ item.subtitle }}</span>
                </ng-container>
            </ng-container>
            <button class="button secondary open-button-margin" (click)="markItemAsRead(item)">
              Open {{ item.notificationTypeName }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="notification-content__title no-notifications-block"
      *ngIf="notifications?.userNotifications?.length === 0"
    >
      You have no notifications.
    </div>
  </mat-sidenav>
</mat-sidenav-container>
