<div class="snackbar-info"> 
    <div class="d-flex align-items-center">
        <fa-icon [icon]="checkCircle"  style="color: #00a657;"></fa-icon>
    </div>
    <div class="d-flex align-items-center">
        <p style="color: #28573C; font-size: 16px;  margin-bottom: 0px;">{{data}}</p>
    </div>
    <div class="d-flex align-items-center">
        <button mat-icon-button class="close" (click)="closeSnackbar()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
</div>
