<form [formGroup]="form" class="example-form">
    <div class="select-search-container">
        <mat-form-field appearance="outline" class="matFormField" [hideRequiredMarker]="field.hideRequiredMarker">
            <mat-label class="">{{field.label}}</mat-label>
            <mat-select [formControlName]="field.control" class="mat-option-text-style">
                <div class="select-container">
                    <mat-form-field appearance="outline">
                        <input #filterSearchTextBox  autocomplete="off" matInput (keyup)="handleFilterOptions($event)" (keydown)="$event.stopPropagation()" />
                        <button *ngIf="filterSearchTextBox.value" [disableRipple]="true" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch()">
                            <lag-icon iconName="close"></lag-icon>
                        </button>
                        <button *ngIf="!filterSearchTextBox.value" [disableRipple]="true" matSuffix mat-icon-button>
                            <lag-icon iconName="search"></lag-icon>
                        </button>
                    </mat-form-field>
                </div>

                <cdk-virtual-scroll-viewport [itemSize]="10" style="height: 38vh">
                    <mat-option class="mat-option-text-style" *cdkVirtualFor="let item of optionsData$ | async"
                        [value]="(item?.id === 0 ? 0 : (item?.id || item?.name))">
                        {{ item?.name }}
                    </mat-option>
                </cdk-virtual-scroll-viewport>
            </mat-select>
        </mat-form-field>
    </div>
</form>