import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { FilterType, filterByOptions, filtersWithType } from '../model/competition-data.model';


@Injectable({providedIn: 'root'})
export class CompetitionDataService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    driveTimeOptions:unknown[];
    private staticData$$ = new BehaviorSubject(null);
    private employers$$ = new BehaviorSubject(new Map());
    private locationData$$ = new BehaviorSubject(new Map());
    private isEmployersUpdated$$ = new Subject();
    isEmployersUpdated$ = this.isEmployersUpdated$$.asObservable();
    staticData$ = this.staticData$$.asObservable();
    employers$ = this.employers$$.asObservable();
    locationData$ = this.locationData$$.asObservable();
    filtersBy: unknown[];
    filterByOptions: any;

    private isAddRecordDrawerOpened$$ = new BehaviorSubject(false);
    isAddRecordDrawerOpened$ = this.isAddRecordDrawerOpened$$.asObservable();
    private isFilterCompetitionDrawerOpened$$ = new BehaviorSubject(false);
    isFilterCompetitionDrawerOpened$ = this.isFilterCompetitionDrawerOpened$$.asObservable();

    constructor() {
        this.driveTimeOptions = this.setDrivetimeOptions();
        this.filterByOptions = filterByOptions;
    }

    setAddRecordDrawerStatus(v: boolean) {
        this.isAddRecordDrawerOpened$$.next(v);
    }

    setFilterCompetitionDrawerStatus(v: boolean) {
        this.isFilterCompetitionDrawerOpened$$.next(v);
    }

    handleCompetitionDrawers(action: 'addDrawer' | 'filterDrawer' | 'closeDrawers') {
        if (action === 'addDrawer') {
            this.isAddRecordDrawerOpened$$.next(true);
            this.isFilterCompetitionDrawerOpened$$.next(false);
        } else if (action === 'filterDrawer') {
            this.isAddRecordDrawerOpened$$.next(false);
            this.isFilterCompetitionDrawerOpened$$.next(true);
        } else if (action === 'closeDrawers') {
            this.isAddRecordDrawerOpened$$.next(false);
            this.isFilterCompetitionDrawerOpened$$.next(false);
        }
    }

    getDrivetimeOptions() {
        return this.driveTimeOptions;
    }

    getActualTextOfFilterBy(value) {
        return this.filterByOptions.find((option) => {
            if (option.value === value) return true;
            return false;
        })?.text;
    }

    isFilterByHasType(filterBy: FilterType) {
        return filtersWithType.includes(filterBy);
    }

    getFilterData(filterBy: FilterType, key) {
        if (filterBy === 'industryname') {
            return key;
        }

        if (filterBy === 'operation') {
            const operationTypes = this.staticData$$.getValue()?.find((i) => i.dataType === 'OperationTypes')?.data;

            if (operationTypes?.length) {
                return operationTypes?.find((i) => i.id === key)?.name;
            }

            return '';
        }

        return this.getEmployer(key)?.employer;
    }

    setIsEmployersUpdated(value: boolean) {
        this.isEmployersUpdated$$.next(value);
    }

    setDrivetimeOptions(){
        return [
            { value: '10', type:'Miles', text: '10 mile radius' , disabled: true},
            { value: '20', type:'Miles', text: '20 mile radius' , disabled: true},
            { value: '30', type:'Miles', text: '30 mile radius' , disabled: true},
            { value: null, type:'Metro', text: 'Metro' , disabled: true},
            { value: '15', type:'Drivetime', text: '15 min drive time' , disabled: true},
            { value: '30', type:'Drivetime', text: '30 min drive time' , disabled: true},
            { value: '45', type:'Drivetime', text: '45 min drive time' , disabled: true},
            { value: '60', type:'Drivetime', text: '60 min drive time' , disabled: true}
        ];
    }

    getEmployer(employerID) {
        return this.employers$$.value?.get(employerID);
    }

    setEmployers(employers){
        const data = new Map();

        employers.forEach((emp) => {
            if (!data.has(emp.employerID)) {
                data.set(emp.employerID, emp);
            }
        });

        this.employers$$.next(data);
    }

    setStaticData(staticData){
        this.staticData$$.next(staticData);
    }

    getLocationData(type, id) {
        return this.locationData$$.value?.get(`${id}-${type}`);
    }

    setlocationData(locationData){
        const data = new Map();

        locationData.forEach((location) => {
            const key = `${location.id}-${location.type}`;
            if (!data.has(key)) {
                data.set(key, location);
            }
        });
    
        this.locationData$$.next(data);
    }


}