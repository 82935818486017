import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mat-singleselect',
  templateUrl: './mat-singleselect.component.html',
  styleUrls: ['./mat-singleselect.component.scss']
})
export class MatSingleselectComponent implements OnInit {

  @Input() optionsData: any[];
  @Input() controlName;
  @Input() labelName: string;
  @Input() label: any;

  @ViewChild('filterSearchTextBox') seachTextbox: ElementRef;

  value: string;

  optionsData$: Observable<any[]>;

  constructor() {

  }

  ngOnInit(): void {
    const filteredListAsc = this.sortAsc(this.optionsData, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

  handleFilterOptions(event){
    const filterValue = event.target.value.toLowerCase();
    const filteredList = this.optionsData?.filter((option) => option?.name?.toLowerCase()?.indexOf(filterValue) !== -1);
    const filteredListAsc = this.sortAsc(filteredList, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

  clearSearch(){
    this.value = '';
    const filteredListAsc = this.sortAsc(this.optionsData, 'name');
    this.optionsData$ = of(filteredListAsc);
  }

  sortAsc(arrayOfObjects, Key) {
    return arrayOfObjects.sort((a, b) => (a[Key] < b[Key] ? -1 : 1));
  }

}
