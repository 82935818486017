import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GTMService } from '@core/services/gtm.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, Subject, of} from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';


import { UserInfo, UserInfoDetailed } from '../models/user-info';
import { InterceptorSkipHeader } from '../httpInterceptor/TokenHttpInterceptor';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = environment.adminUrl + '/user'

  private refresh$ = new Subject<void>();
  private user$: Observable<UserInfo>;
  private userSnapshot: UserInfo;

  constructor(private http: HttpClient) {}

  get user(): UserInfo {
    return this.userSnapshot;
  }

  getUser(): Observable<UserInfo> {
    if (!this.user$) {
      this.user$ = this.refresh$.pipe(
        startWith(null),
        switchMap(() => this.getUserInfo()),
        tap((user) => this.userSnapshot = user),
        shareReplay(1)
      ) 
    }
    return this.user$;
  }

  triggerUserReload(): void {
    this.refresh$.next();
  }

  private getUserInfo(): Observable<UserInfo> {
    return this.http.post<UserInfo>(this.url + '/userInfo', null);
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

}
