import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';

@Injectable()
export class CustomValidators {
  number(c: FormControl) {
    const value = c.value,
      isValid = !isNaN(value);
    if (!isValid) {
      return {
        number: isValid,
      };
    }
    return null;
  }

  id(c: FormControl) {
    const value = c.value;

    if (Array.isArray(value)) {
      if (value.some((v) => v.id || v.id == 0)) return null;
    }

    if (value?.id) {
      return null;
    }

    return { id: 'No selected option' };
  }

  static max(max: number, seperator: string = ' '): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Check that the control has a value and if that value is a string.
      if (control.value && typeof control.value === 'string') {
        // Remove any leading and trailing whitespace.
        const value = control.value.trim();
        const words = value.split(seperator);
        const actual = words.length;
        if (actual > max) {
          return { maxword: { max, actual } };
        }
      }
      return null;
    };
  }

  nullEmptyOrNumber(c: FormControl) {
    const value = c.value;
    let isValid = false;
    if (!value || value === '' || value === null) {
      isValid = true;
    } else {
      isValid = !isNaN(value);
    }
    if (!isValid) {
      return {
        nullEmptyOrNumber: isValid,
      };
    }
    return null;
  }

  idNumber(c: FormControl): any {
    const value = c.value ? c.value.id : NaN,
      isValid = !isNaN(value);
    if (!isValid) {
      return {
        idNumber: isValid,
      };
    }
    return null;
  }


  static greaterOrEqual(otherControlName: string) {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null
      }
      const contolValue = control.value;
      const otherValue = control.parent.get(otherControlName).value;
      if (!otherValue || contolValue >= otherValue) {
        return null
      }
      return {
        'greatedOrEqual': true
      }
    }
  }

  emailAddresses(emails: FormControl) {
    const emailRegex = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i; // http://www.regular-expressions.info/email.html
    const split = emails.value.split(/[\s;]+/); // split on any combination of whitespace, comma, or semi-colon
    const invalidEmails = [];

    for (const i in split) {
      const email = split[i];
      if (email !== '' && !emailRegex.test(email)) {
        invalidEmails.push(email);
      }
    }

    if (invalidEmails.length > 0) {
      const msg = invalidEmails.length === 1 ? 'Email address is not valid.' : 'Email addresses are not valid.';
      return {
        emailAddresses: msg,
      };
      // Not adding invalid emails in error msg because of space issue on smaller devices
      // "Email addresses \'" + invalidEmails.join(',') + "\' are not valid."
    }
    return null;
  }
}
