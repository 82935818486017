import { Injectable } from '@angular/core';
import { UserInfo } from 'src/app/models/user-info';

@Injectable({
  providedIn: 'root',
})
export class dataPointFormats {
  public creatMap(dpFormatObject: any) {
    const map = new Map();
    for (const key in dpFormatObject) {
      map.set(key, dpFormatObject[key]);
    }
    return map;
  }

  public setValueAndformatCell(cell: any, dataPointName, value, alignment, dpFormatMap) {
    let format = 7;

    if (dpFormatMap.has(dataPointName)) {
      format = dpFormatMap.get(dataPointName);
    }

    if (format == 1) {
      cell.numFmt = '$#,##0';
      cell.value = Number(value);
    } else if (format == 2) {
      cell.numFmt = '#,##0';
      cell.value = Number(value);
    } else if (format == 3) {
      cell.numFmt = '0.0%';
      cell.value = Number(value / 100);
    } else if (format == 4 || format == 8) {
      cell.numFmt = '0.00%';
      cell.value = Number(value / 100);
    } else if (format == 5) {
      cell.numFmt = '$#,##0.00';
      cell.value = Number(value);
    } else if (format == 9) {
      cell.numFmt = '0.0%';
      cell.value = Number(value / 100);
    } else {
      // 7 and the rest of the formats are defaulted to Number-two decimals
      cell.numFmt = '#,##0.00';
      cell.value = Number(value);
    }

    cell.alignment = alignment;
  }
}
