import { profileIndicator } from './profileIndicator.model';
import { ProfileNode } from './profileNode';

export class ProfileFlatNode {
  name: string;
  id: number;
  weight: any;
  effectiveWeight: any;
  level: number;
  children: ProfileNode[];
  expandable: boolean;
  dropdownOptions: profileIndicator[];
  filteredOptions: profileIndicator[];
  isSupplyGroup: boolean;
  isCostGroup: boolean;
  isSkillsGroup: boolean;
  isDegreeCompletionGroup: boolean;
  isCompetitionResearchGroup: boolean;
  degrees: any;
  filteredDegrees: any;
  secondDropdownOptions: profileIndicator[];
  secondDropdownFilteredOptions: profileIndicator[];
  secondName: string;
  groupCustomName: string;
  validationFailure: boolean;
  nodeId: string;
  indicatorType: string;
  isLeafNode: boolean;
  isOccupationGroup: boolean;
}
