import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DriveTimeService } from '../../services/drivetime/drivetime.service';
import { UserService } from '../../services/user.service';
import { SnackbarService } from '../snackBar.notification.service';

@Component({
  selector: 'quick-update-dialog',
  templateUrl: './quick-update-dialog.component.html',
  styleUrls: ['./quick-update-dialog.component.scss'],
})
export class QuickUpdateDialogComponent implements OnInit {
  dataSource: any;
  sourceData: any;
  sources: any;
  staticData: any;
  statusTypes: any;
  baseline: any;
  statuses: any;
  source_level: any;
  status_level: any = 'Baseline Adj.';
  date_level: any;
  userData: any;
  sortSources: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<QuickUpdateDialogComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    public driveTimeService: DriveTimeService,
  ) {
    this.dataSource = data.selectedTableData;
    this.staticData = data.staticData;
    this.userData = data.userData;
    console.log('data source', this.dataSource);
  }

  ngOnInit() {
    this.sourceData = this.staticData.find((x) => x.dataType == 'SourceTypes');
    this.sources = this.sourceData.data;
    this.statusTypes = this.staticData.find((x) => x.dataType == 'StatusTypes');
    this.statuses = this.statusTypes.data;

    this.sortSources = this.sources.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });
  }

  performAction(event) {
    // console.log(event);
  }

  events: string[] = [];
  // addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  //   this.events.push(`${type}: ${event.value}`);
  // }

  apply() {
    console.log('ss', this.dataSource);
    console.log('date', this.date_level);
    const quickOptions = this.dataSource.map((item) => {
      return {
        competitionId: item['competitionID'],
        operationTypeId: this.getOperationId(item['operation']),
      };
    });
    const payload = {
      quickUpdateCompetitionList: quickOptions,
      sourceId: this.getSourceId(this.source_level),
      statusId: this.getStatusId(this.status_level),
      user: this.userData,
      touchPointDate: this.date_level,
    };
    console.log('payyloo', payload);
    this.driveTimeService.postQuickUpdate(payload).subscribe(
      (res) => {
        console.log('Quick Update Record => res', res);
        this.snackBarService.show('upadted successfully!', 'success');
        this.getResponse(res);
      },
      (error) => {
        this.snackBarService.showError('quick update failed, Please contact system admin.');
        console.log('Create New Record Error -> error', error);
        this.getResponse(error);
      },
    );
  }

  getResponse(resp) {
    this.dialogRef.close(resp);
  }

  getSourceId(name) {
    const sourceObj = this.sources.find((x) => x.name == name);
    if (sourceObj) {
      return sourceObj.id;
    } else {
      return name;
    }
  }

  getOperationId(name) {
    const operationData = this.staticData.find((x) => x.dataType == 'OperationTypes');
    const operations = operationData.data;
    const operation = operations.find((x) => x.name == name);
    if (operation) {
      return operation.id;
    } else {
      return name;
    }
  }

  getStatusId(name) {
    const status = this.statuses.find((x) => x.name == name);
    if (status) {
      return status.id;
    } else {
      return name;
    }
  }
}
