import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { SpinnerService } from '@core/spinner.service';
import { Observable, filter, finalize } from 'rxjs';

import { TokenService } from '../services/token.service';

import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';
import { AuthService } from '@services/auth.service';

// See Example here: https://angular.io/guide/http#set-default-headers
@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    public tokenService: TokenService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithSpinner = !request.headers.has(InterceptorSkipHeader);
    if (requestWithSpinner) {
      // only increase when spinner is needed
      this.totalRequests++;
      this.spinnerService.show();
    }

    const ESRISuggest = request.url.includes('GeocodeServer/suggest?');
    const BAMRequest = request.url.includes('/UserProfiles/');
    const accessToken = this.tokenService.getAccessToken();
    if (accessToken !== undefined && accessToken !== null && accessToken.length !== 0 && !ESRISuggest && !BAMRequest) {
      this.tokenService.updateUserLastActiveTime();
      // Place the token in the header
      const reqWithToken = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + accessToken).delete(InterceptorSkipHeader),
      });
      return requestWithSpinner
        ? next.handle(reqWithToken).pipe(finalize(this.stopLoaderOnFinalize))
        : next.handle(reqWithToken);
    }

    // DO NOT put token in header
    if (requestWithSpinner) {
      return next.handle(request).pipe(finalize(this.stopLoaderOnFinalize));
    } else {
      const cloneReq = request.clone({
        headers: request.headers.delete(InterceptorSkipHeader),
      });
      return next.handle(cloneReq);
    }
  }

  stopLoaderOnFinalize = () => {
    this.totalRequests--;
    if (this.totalRequests <= 0) {
      this.spinnerService.hide();
    }
  };
}
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const spinnerlessheader = { 
  headers: new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set(InterceptorSkipHeader, ''),
};