import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/core/spinner.service';

import { MarketIndexService, ReportParams } from './marketindex.service';

@Component({
  selector: 'marketindex-report',
  templateUrl: './marketindex-report.component.html',
  styleUrls: ['./marketindex-report.component.css'],
})
export class MarketIndexComponent implements OnInit {
  @Input() profileId: string;
  @Input() profileName: string;
  @Input() driveTime: string;
  @Input() city: string;
  @Input() state: string;
  @Input() lat: string;
  @Input() long: string;
  reportParams: ReportParams;
  marketIndexes = {
    MacroLaborCosts: null,
    LaborCost: null,
    SkillSetEmployers: null,
    CostSkillSets: null,
    LaborSupply: null,
    TotalIndex: null,
    AvailabilityandQuality: null,
  };
  marketIndexesGraph: any = null;

  constructor(private _marketIndexService: MarketIndexService, private spinnerService: SpinnerService) {
    this.marketIndexes = null;
  }

  ngOnInit() {
    this.getMarketIndexes();
    // this.plotGraph();
  }

  getMarketIndexes() {
    const params = new ReportParams();
    params.profileID = this.profileId;
    params.city = this.city;
    params.state = this.state;
    params.latitude = this.lat;
    params.longitude = this.long;
    params.driveTimeInMinutes = this.driveTime;

    this._marketIndexService.getMarketIndex(params).subscribe((response) => {
      this.marketIndexes = response;

      this.marketIndexes.MacroLaborCosts = Math.round(response['Macro Labor Costs'].toFixed(2));
      this.marketIndexes.LaborCost = Math.round(response['Labor Cost'].toFixed(2));
      this.marketIndexes.SkillSetEmployers = Math.round(response['Skill Set Employers per 10,000 Workers'].toFixed(2));
      this.marketIndexes.CostSkillSets = Math.round(response['Cost Skill Sets'].toFixed(2));
      this.marketIndexes.LaborSupply = Math.round(response['Labor Supply'].toFixed(2));
      this.marketIndexes.TotalIndex = Math.round(response['Total Index'].toFixed(2));
      this.marketIndexes.AvailabilityandQuality = Math.round(response['Availability and Quality'].toFixed(2));

      sessionStorage.setItem('totalIndex', this.marketIndexes.TotalIndex);

      this.plotGraph();
    });
  }

  sampleData() {
    this.marketIndexes = {
      MacroLaborCosts: 3.75,
      LaborCost: 26.05,
      SkillSetEmployers: 28.37,
      CostSkillSets: 21.75,
      LaborSupply: 18.44,
      TotalIndex: 26.05,
      AvailabilityandQuality: 17.25,
    };
  }

  sampleplotGraph() {
    const trace1 = {
      x: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      y: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      name: 'Name of Trace 1',
      type: 'scatter',
    };
    const trace2 = {
      x: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      y: [1, 0, 3, 2, 5, 4, 7, 6, 8],
      name: 'Name of Trace 2',
      type: 'scatter',
    };
    const data = [trace1, trace2];
    const layout = {
      title: {
        text: 'Plot Title',
        font: {
          family: 'Courier New, monospace',
          size: 24,
        },
        xref: 'paper',
        x: 0.05,
      },
      xaxis: {
        title: {
          text: 'x Axis',
          font: {
            family: 'Courier New, monospace',
            size: 18,
            color: '#7f7f7f',
          },
        },
      },
      yaxis: {
        title: {
          text: 'y Axis',
          font: {
            family: 'Courier New, monospace',
            size: 18,
            color: '#7f7f7f',
          },
        },
      },
    };

    //   newPlot.newPlot('myDiv', data, layout);
  }

  plotGraph1() {
    const xAxis = [];
    xAxis.push([10, 20, 30, 40, 50, 60, 70, 80, 90]);
    const yAxis = [];
    yAxis.push([10, 20, 30, 40, 50, 60, 70, 80, 90]);

    const x = [10];
    const y = [15];

    this.marketIndexesGraph = {
      data: [
        {
          x: x,
          y: y,
          mode: 'markers',
          showlegend: false,
        },
        {
          x: [2],
          y: [4.5],
          mode: 'markers',
          showlegend: false,
        },
      ],
    };
  }

  plotGraph2() {
    const xAxis = [];
    xAxis.push(this.marketIndexes['CostSkillSets']);
    const yAxis = [];
    yAxis.push(this.marketIndexes['AvailabilityandQuality']);

    const trace1 = {
      x: xAxis,
      y: yAxis,
      name: this.city,
      type: 'scatter',
      showlegend: false,
    };
    const trace2 = {
      x: [0],
      y: [0],
      name: 'Name of Trace 2',
      type: 'scatter',
      showlegend: false,
    };
    const data = [trace1, trace2];
    const layout = {
      title: {
        text: 'Plot Title',
        font: {
          family: 'Courier New, monospace',
          size: 24,
        },
        xref: 'paper',
        x: 0.05,
      },
      xaxis: {
        title: {
          text: 'Labor Affordability',
          font: {
            family: 'Courier New, monospace',
            size: 18,
            color: '#7f7f7f',
          },
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      yaxis: {
        title: {
          text: 'Labor Supply',
          font: {
            family: 'Courier New, monospace',
            size: 18,
            color: '#7f7f7f',
          },
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      hovermode: 'closest',
    };

    this.marketIndexesGraph = {
      data: [trace1, trace2],
      layout: layout,
    };
  }

  plotGraph() {
    const xAxis = [];
    xAxis.push(this.marketIndexes['CostSkillSets']);
    const yAxis = [];
    yAxis.push(this.marketIndexes['AvailabilityandQuality']);

    const x = this.marketIndexes['CostSkillSets'] / 2;
    const y = this.marketIndexes['AvailabilityandQuality'] / 2;

    const trace1 = {
      x: xAxis,
      y: yAxis,
      type: 'scatter',
      mode: 'markers',
      name: this.city,
      marker: { size: 12 },
      showlegend: false,
    };

    const trace2 = {
      x: [2],
      mode: 'lines',
      showlegend: false,
      line: { color: 'black' },
    };

    const trace3 = {
      y: [2],
      mode: 'lines',
      showlegend: false,
      line: { color: 'black' },
    };

    const data = [trace1, trace2, trace3];

    const layout = {
      xaxis: {
        title: {
          text: 'Labor Affordability',
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      yaxis: {
        title: {
          text: 'Labor Supply',
        },
        showcrossline: true,
        spikemode: 'toaxis',
        spikesnap: 'cursor+data',
      },
      hovermode: 'closest',
    };

    this.marketIndexesGraph = {
      data: data,
      layout: layout,
    };
  }
}
