import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';
import { AnalyticsService } from '@services/analytics.service';

export type SnackBarType = 'error' | 'success' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  durationInSeconds = 5;

  constructor(private snackbar: MatSnackBar, private zone: NgZone, private analyticsService: AnalyticsService) {}

  showInformation(message: string) {
    this.snackbar.openFromComponent(SnackBarErrorComponent, {
      duration: this.durationInSeconds * 1000,
      data: message,
      panelClass: ['info-snackbar'],
    });
    this.analyticsService.on('track', 'Information event', { message });
  }

  showError(message: string) {
    this.snackbar.openFromComponent(SnackBarErrorComponent, {
      duration: this.durationInSeconds * 1000,
      data: message,
      panelClass: ['error-snackbar', 'slideInUp'],
    });
    this.analyticsService.on('track', 'Error event', { message });
  }

  showSuccess(message: string) {
    this.snackbar.openFromComponent(SnackBarErrorComponent, {
      duration: this.durationInSeconds * 1000,
      data: message,
      panelClass: ['success-snackbar'],
    });
    this.analyticsService.on('track', 'Success event', { message });
  }

  showWarning(message: string) {
    this.snackbar.openFromComponent(SnackBarErrorComponent, {
      duration: this.durationInSeconds * 1000,
      data: message,
      panelClass: ['warning-snackbar'],
    });
    this.analyticsService.on('track', 'Warning event', { message });
  }

  show(message: string, type: SnackBarType): void {
    this.zone.run(() => {
      this.snackbar.open(message, 'x', {
        panelClass: ['snackbar-container', type],
      });
    });
  }
}
