<div class="row">
  <div class="col-sm-4 align-content-start">
    <h5 style="text-align:left; font-variant-caps: all-petite-caps;">
      {{address}}</h5>
    <h5 style="text-align:left; font-variant-caps: all-petite-caps;">
      <span style="font-size: large;">Profile:</span>
      &nbsp;{{selectedProfileNameInput}}</h5>
    <h5 style="text-align:left; font-size: medium;">
      {{driveTimeInMinutesInput}}.0 Minutes</h5>
  </div>
  <div class="col-sm-1">
    <div class="vertical_dotted_line align-content-start">
    </div>
  </div>
  <div class="col-sm-2 justify-content-start">
    <div class="circle" [style.background-color]="totalIndexColorCode">{{totalIndex}}</div>
    <div class="row">
      <b>TOTAL INDEX</b>
    </div>
  </div>
  <div class="col-sm-2">
    <div class="vertical_dotted_line align-content-start">
    </div>
  </div>
  <div class="col-sm-3" style="text-align: right;">
    <img src="../../../assets/images/SingleSite_transparent.png" height="100px" width="120px" alt="SingleSite" />
  </div>
</div>
<div class="row">
  <img src="./assets/images/DT-horizontal-line logo.png" height="12px" width="100%" />
</div>