<form [formGroup]="form" class="example-form">
  <mat-form-field appearance="outline" class="matFormField" [ngClass]="{'text-autocomplete-custom': field.displaySearchIcon}" panelWidth="auto" width="100%"
    [hideRequiredMarker]="hideRequiredMarker">
    <div class="search-container">
      <lag-icon *ngIf="field.displaySearchIcon" iconName="search" iconColor="#767676"></lag-icon>      
      <input matInput [type]="field.controlType" [placeholder]="field.label" [attr.arialabel]="field.label"
        (blur)="onBlur($event)" [formControlName]="field.control" [value]="getValue()" [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger" [attr.disabled]="disabled" (focus)="onFocus()" (click)="handleClick()">
      <ng-content select="[suffixOnEmpty]" *ngIf="(filteredOptions | async)?.length === 0"></ng-content>
      <mat-icon *ngIf="field.displayDropdownIcon" class="dropdown-icon" matSuffix (click)="onDropdownClick($event, trigger)">arrow_drop_down</mat-icon>
    </div>
    <mat-label class="">{{field.label}}</mat-label>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected ? onOptionSelected($event) : null"
    (closed)="type === 'multiple' && multiSelectOnClose()">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'multiple'">
        <div class="main-virtual-scroll-block">
          <cdk-virtual-scroll-viewport [itemSize]="10" class="single-select-input-virtual-scroll"
            style="height: 256px;">
            <mat-option class="mat-option-text-style mat-option-block"
              *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
              <mat-checkbox [checked]="option.checked" (click)="$event.stopPropagation()"
                (change)="onCheckboxSelect(option)" disableRipple>
                {{option.text??option.name}}
              </mat-checkbox>
            </mat-option>
            <mat-option *ngIf="filteredOptions.length === 0">No Results</mat-option>
          </cdk-virtual-scroll-viewport>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option" class="itemLabelOption">
              <div class="optionDetails">
                <p class="name"> {{ option?.name }} </p>
                <p class="label"> {{option?.label}} </p>
              </div> 
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-autocomplete>
      <mat-chip-list #chipList *ngIf="hasChips">
        <mat-chip
        *ngFor="let item of selectedOptions">
          {{ item.name }}
          <mat-icon matChipRemove (click)="removeChip(item)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    <div *ngIf="field.helperText" class="helper-text">{{field.helperText}}</div>
</form>