import { HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Subject,
  catchError,
  map,
  Observable,
  combineLatest,
} from "rxjs";

import { CommonService } from "./common.service";
import { SearchFilter } from "../model/competition-search-filter.model";

@Injectable({
  providedIn: "root",
})
export class FormService {
  private dispatchEvent = new EventEmitter();
  private environment;
  private _analyticsInstance = null;
  private user;
  private formClear$$ = new Subject();

  formClear$ = this.formClear$$.asObservable();

  private dataGrid$$ = new BehaviorSubject({
    isLoading: true,
    data: null,
    isError: false,
  });

  dataGrid$ = this.dataGrid$$.asObservable();
  queryID?: number;

  private isLeftSideDrawerOpened$$ = new BehaviorSubject<boolean>(false);
  isLeftSideDrawerOpened$ = this.isLeftSideDrawerOpened$$.asObservable();

  private isRightSideDrawerOpened$$ = new BehaviorSubject<boolean>(false);
  isRightSideDrawerOpened$ = this.isRightSideDrawerOpened$$.asObservable();

public isRightSideDrawerOpened = ():boolean => {
  return this.isRightSideDrawerOpened$$.getValue();
  }

  private isExtraSideDrawerOpened$$ = new BehaviorSubject<boolean>(false);
  isExtraSideDrawerOpened$ = this.isExtraSideDrawerOpened$$.asObservable();

  private setExpanded$$ = new BehaviorSubject<boolean>(false);
  setExpanded$ = this.setExpanded$$.asObservable();

  private savedQueries$$ = new BehaviorSubject([]);
  savedQueries$ = this.savedQueries$$.asObservable();

  private resetForm$$ = new Subject<Object>();
  resetForm$ = this.resetForm$$.asObservable();

  private searchFilter$$ = new BehaviorSubject<SearchFilter[]>([]);
  searchFilter$ = this.searchFilter$$.asObservable();

  private formValue$$ = new Subject();
  private currentFormId;
  formValue$ = this.formValue$$.asObservable();

  private cardList$$ = new BehaviorSubject([]);
  cardList$ = this.cardList$$.asObservable();

  constructor(private commonService: CommonService) {
    this.formValue$.subscribe(
      (data: SearchFilter) => (this.currentFormId = data?.formId)
    );
  }

  public on(action: "track" | "page", eventName, extraArgs) {}

  public get analytics() {
    return this._analyticsInstance || this.on;
  }

  applyButtonClick(event) {
    this.dispatchEvent.emit(event);
  }

  clearButtonClick(event) {
    this.dispatchEvent.emit(event);
  }

  getApplyButtonEmitter() {
    return this.dispatchEvent;
  }

  leftSideDrawerHandler(v: boolean) {
    this.isLeftSideDrawerOpened$$.next(v);
  }

  rightSideDrawerHandler(value: boolean) {
    this.isRightSideDrawerOpened$$.next(value);
  }

  extraSideDrawerHandler(value: boolean) {
    this.isExtraSideDrawerOpened$$.next(value);

    if (value) {
      this.rightSideDrawerHandler(false);
    }
  }

  setSavedQueries(data) {
    this.savedQueries$$.next(data);
  }

  setAnalytics(analyticsInstance) {
    this._analyticsInstance = analyticsInstance;
  }

  setEnvironment(environment) {
    this.environment = environment;
  }
  setUser(user) {
    this.user = user;
  }

  clearQueryDetails() {
    this.searchFilter$$.next([]);
    this.dataGrid$$.next({
      data: this.dataGrid$$.value.data,
      isLoading: true,
      isError: false,
    });
  }

  setExpanded(val: boolean) {
    this.setExpanded$$.next(val);
  }

  setQueryId(id) {
    this.queryID = id;
  }

  formClear(val) {
    this.formClear$$.next(val);
  }

  resetForm(additionalProperties?: Object) {
    this.resetForm$$.next(additionalProperties);
  }

  updateDataGrid(payload: { data: any; isError: boolean; isLoading: boolean }) {
    this.dataGrid$$.next(payload);
  }

  clearSearch() {
    this.updateDataGrid({ isLoading: true, data: null, isError: false });
  }

  private formatDate(date) {
    return date
      .toLocaleString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
  }
}
